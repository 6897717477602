import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'shared/components';
import './styles.scss';

const FreePlanTypeBox = ({
  isCurrent,
  handleCtaClick,
}) => (
  <article className={`FreePlanTypeBox PlanTypeBox ${isCurrent ? 'current' : ''}`}>
    <div className="PlanTypeBox-title">{__('Free')}</div>
    <p className="PlanTypeBox-description">
      {__('For Solo Developers, start-ups, and companies with early access programs who are looking for basic licensing for their projects')}
    </p>
    <div className="PlanTypeBox-price free">0</div>
    <div className="PlanTypeBox-btn">
      <Button
        onClick={handleCtaClick}
        theme="success"
        disabled={isCurrent}
        size="lg"
      >
        {__('Start with free')}
      </Button>
    </div>
    <ul className="PlanTypeBox-features">
      <li>
        <span>5,000</span>
        {__('monthly active users')}
      </li>
      <li>
        <span>3</span>
        {__('products')}
      </li>
      <li>
        <span>1</span>
        {__('development environment')}
      </li>
      <li className="checkmark">{__('Node-Locking')}</li>
      <li className="checkmark">{__('Key-Based')}</li>
    </ul>
  </article>
);

FreePlanTypeBox.propTypes = {
  handleCtaClick: PropTypes.func.isRequired,
  isCurrent: PropTypes.bool.isRequired,
};

export default FreePlanTypeBox;
