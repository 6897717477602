import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { SlidePane, List } from 'shared/components';
import { sendErrorReport, formatDate, displayValue } from 'shared/helpers';
import { getFeatureConsumptionAnalyticsEvents } from 'src/license/actions';
import './styles.scss';

const FeatureConsumptionEventsList = ({
  closeCb,
  isOpen,
  subtitle,
  title,
  companyID,
  params,
}) => {
  const [isLoading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  // table state
  const [eventsCount, setEventsCount] = useState(null);
  const [page, setPage] = useState(0);
  const [currentSort, setSort] = useState([{
    desc: true,
    id: 'ts',
  }]);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const {
    gteParam, lteParam, objectParam,
  } = params;

  const endpointParams = `?company=${companyID}${gteParam}${lteParam}${objectParam}`;

  const fetchEvents = useCallback((
    tablePage = 0,
    rows = rowsPerPage,
    sort = currentSort,
  ) => {
    setLoading(true);
    getFeatureConsumptionAnalyticsEvents(companyID, tablePage, rows, sort, endpointParams)
      .then((res) => {
        setEvents(get(res, 'data.results') || []);
        setEventsCount(get(res, 'data.count'));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        sendErrorReport(err, 'Cannot fetch licenses list');
      });
  }, [endpointParams]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    fetchEvents(newPage, rowsPerPage, currentSort);
  };

  const handleSortChange = (newSorted) => {
    setSort(newSorted);
    fetchEvents(0, rowsPerPage, newSorted);
  };

  const handlePageSizeChange = (newPageSize, newPage) => {
    setPage(newPage);
    setRowsPerPage(newPageSize);
    fetchEvents(newPage, newPageSize, currentSort);
  };

  return (
    <SlidePane
      closeCb={closeCb}
      isOpen={isOpen}
      title={title}
      subtitle={subtitle}
      width="80%"
    >
      <div className="FeatureConsumptionEventsList">
        <List
          clickable
          data={events}
          columns={[
            {
              accessor: 'ts',
              Header: __('Created on'),
              Cell: rowData => formatDate(rowData.value),
            },
            {
              accessor: 'action',
              Header: __('Action'),
              Cell: (rowData) => {
                const { value } = rowData;
                if (value === 'consumption_add') {
                  return __('Add consumption');
                }
                if (value === 'consumption_reset') {
                  return __('Reset consumption');
                }
                return '-';
              },
            },
            {
              accessor: 'consumptions',
              Header: __('Consumptions'),
              Cell: rowData => displayValue(rowData.value),
            },
            {
              accessor: 'total_consumptions',
              Header: __('Total consumptions'),
              Cell: rowData => displayValue(rowData.value),
            },
            {
              accessor: 'max_consumptions',
              Header: __('Max consumptions'),
              Cell: rowData => displayValue(rowData.value),
            },
            {
              accessor: 'max_overages',
              Header: __('Max overages'),
              Cell: rowData => displayValue(rowData.value),
            },
            {
              accessor: 'device',
              Header: __('Device ID'),
              Cell: rowData => displayValue(rowData.value),
            },
          ]}
          page={page}
          pages={Math.ceil(eventsCount / rowsPerPage)}
          loading={isLoading}
          manual
          minRows={get(events, 'length') || 10}
          showPagination={eventsCount > 5}
          onPageChange={handlePageChange}
          onSortedChange={handleSortChange}
          defaultSorted={currentSort}
          showPageSizeOptions
          pageSize={rowsPerPage}
          onPageSizeChange={(pageSize, pageIndex) => handlePageSizeChange(pageSize, pageIndex)}
        />
      </div>
    </SlidePane>
  );
};

FeatureConsumptionEventsList.propTypes = {
  closeCb: PropTypes.func.isRequired,
  companyID: PropTypes.number.isRequired,
  isOpen: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  params: PropTypes.string,
};

FeatureConsumptionEventsList.defaultProps = {
  isOpen: false,
  subtitle: '',
  title: '',
  params: '',
};

export default FeatureConsumptionEventsList;
