import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { IconCollapse, IconExpand } from 'shared/components';
import { changeSidebarSize } from 'src/account/actions';
import './styles.scss';

const Sidebar = ({ children }) => {
  const dispatch = useDispatch();
  const sidebarSize = useSelector(state => get(state, 'account.sidebarSize'));

  const handleSizeChange = () => {
    if (sidebarSize === 'lg') {
      return dispatch(changeSidebarSize('sm'));
    }
    return dispatch(changeSidebarSize('lg'));
  };

  return (
    <aside className={`Sidebar Sidebar-${sidebarSize}`}>
      <div className="toggle-container">
        <button type="button" onClick={handleSizeChange} className="sidebar-size-toggle">
          {sidebarSize === 'lg' ? <IconCollapse fill="none" /> : <IconExpand fill="none" />}
        </button>
      </div>
      {children}
    </aside>
  );
};

Sidebar.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Sidebar;
