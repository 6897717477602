import React from 'react';
import PropTypes from 'prop-types';

const SuccessIcon = ({
  text,
}) => (
  <div className="ImportStatusCell">
    <svg width="18px" height="18px" fill="#306d12" viewBox="0 0 48 50">
      <path
        d="M24,5A19,19,0,1,0,43,24,19,19,0,0,0,24,5ZM36.41,18.41l-14,14a2,2,0,0,1-2.82,0l-6-6a2,2,0,0,1,2.82-2.82L21,28.17,33.59,15.59a2,2,0,0,1,2.82,2.82Z"
      />
    </svg>
    {text}
  </div>
);

SuccessIcon.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SuccessIcon;
