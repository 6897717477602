import React from 'react';
import PropTypes from 'prop-types';

const PendingIcon = ({
  text,
}) => (
  <div className="ImportStatusCell">
    <svg width="18px" height="18px" fill="#ff9f43" viewBox="0 0 30 27.5">
      <path
        d="M15,4C8.9,4,4,8.9,4,15s4.9,11,11,11s11-4.9,11-11S21.1,4,15,4z M21.7,16.8c-0.1,0.4-0.5,0.6-0.9,0.5l-5.6-1.1  c-0.2,0-0.4-0.2-0.6-0.3C14.2,15.7,14,15.4,14,15c0,0,0,0,0,0l0.2-8c0-0.5,0.4-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8l0.1,6.9l5.2,1.8  C21.6,15.8,21.8,16.3,21.7,16.8z"
      />
    </svg>
    {text}
  </div>
);

PendingIcon.propTypes = {
  text: PropTypes.string.isRequired,
};

export default PendingIcon;
