const UUID_V4_TEMPLATE = '10000000-1000-4000-8000-100000000000';

const toBase64 = val => btoa([...new Uint8Array(val)]
  .map(chr => String.fromCharCode(chr))
  .join(''));


const getRandomValue = () => {
  const arr = new Uint32Array(1);
  crypto.getRandomValues(arr);
  return arr[0];
};

const generateUUIDv4 = () => {
  // eslint-disable-next-line no-bitwise, no-mixed-operators
  const uuid = UUID_V4_TEMPLATE.replace(/[018]/g, c => (+c ^ getRandomValue() & 15 >> +c / 4).toString(16));
  return uuid.replace(/-/g, '');
};

export const generateCodeVerifier = () => `${generateUUIDv4()}${generateUUIDv4()}${generateUUIDv4()}`;

export const generateCodeChallenge = async (codeVerifier) => {
  if (!crypto.subtle) {
    throw new Error('Crypto.subtle is available only in secure contexts (HTTPS).');
  }

  try {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const hashed = await crypto.subtle.digest('SHA-256', data);
    return toBase64(hashed).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('CryptoUtils.generateCodeChallenge', err);
    throw err;
  }
};
