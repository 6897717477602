import React from 'react';
import Base from './Base';

export default props => (
  <Base {...props} viewBox="0 0 100 115">
    <path
      d="M85.569,5H14.431C9.221,5,5,9.221,5,14.431v71.137C5,90.779,9.221,95,14.431,95h71.137C90.779,95,95,90.779,95,85.569  V14.431C95,9.221,90.779,5,85.569,5z M55.152,67.743c0,2.051-0.472,3.513-1.415,4.362c-0.943,0.872-2.169,1.32-3.702,1.32  c-1.533,0-2.782-0.448-3.749-1.32c-0.943-0.849-1.415-2.311-1.415-4.362V49.092c0-2.028,0.471-3.49,1.415-4.362  c0.967-0.872,2.216-1.297,3.749-1.297c1.533,0,2.782,0.424,3.725,1.297c0.967,0.872,1.415,2.334,1.391,4.362V67.743z M53.808,33.955  c-0.92,0.849-2.169,1.273-3.796,1.273c-1.627,0-2.9-0.424-3.796-1.25c-0.896-0.825-1.344-1.839-1.344-3.065  c0-1.203,0.448-2.24,1.367-3.089c0.896-0.849,2.169-1.25,3.773-1.25c1.603,0,2.853,0.401,3.773,1.25  c0.896,0.849,1.368,1.886,1.368,3.089C55.152,32.116,54.704,33.129,53.808,33.955z"
    />
  </Base>
);
