import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { sendErrorReport } from 'shared/helpers';
import {
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  TextInput,
  PhoneNumberInput,
} from 'shared/components';
import { patchLicenseUser } from 'src/license/actions';
import './styles.scss';

const EditLicenseUser = ({
  closeCb,
  user,
  companyID,
  userID,
  refetchLicense,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [isDirtyFormDisplayed, setDirtyFormDisplay] = useState(false);
  // form state
  const [firstName, setFirstName] = useState(get(user, 'first_name') || '');
  const [lastName, setLastName] = useState(get(user, 'last_name') || '');
  const [phoneNumber, setPhoneNumber] = useState(get(user, 'phone_number') || '');

  const handleSuccess = (msg) => {
    refetchLicense();
    closeCb();
    Notification('success', __('Changes saved successfully'), msg);
  };

  const editUser = () => {
    const succesMsg = __('License user edited');
    const data = {
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
    };

    patchLicenseUser(userID, companyID, data)
      .then(() => {
        handleSuccess(succesMsg);
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot edit license user', data);
        setLoading(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) { return false; }

    setDirty(false);
    setLoading(true);
    editUser();
    return true;
  };

  const handleClose = () => {
    if (!isDirty) { return closeCb(); }
    return setDirtyFormDisplay(true);
  };

  return (
    <Modal
      confirmCb={handleSubmit}
      closeCb={handleClose}
      disabled={isLoading}
      size="sm"
      title={__('Edit license user')}
    >
      <form className="EditLicenseUser" onSubmit={handleSubmit}>
        <div className="form-inner">
          <div className="form-row">
            <Label text={__('Email')} inputId="email" />
            <TextInput
              id="email"
              value={get(user, 'true_email') || ''}
              disabled
              handleChange={() => {}}
            />
          </div>
          <div className="form-row">
            <Label text={__('First Name')} inputId="firstname" />
            <TextInput
              id="firstname"
              value={firstName}
              handleChange={(val) => {
                setDirty(true);
                setFirstName(val);
              }}
            />
          </div>
          <div className="form-row">
            <Label text={__('Last Name')} inputId="lastname" />
            <TextInput
              id="lastname"
              value={lastName}
              handleChange={(val) => {
                setDirty(true);
                setLastName(val);
              }}
            />
          </div>
          <div className="form-row">
            <Label text={__('Phone Number')} inputId="phone" />
            <PhoneNumberInput
              value={phoneNumber}
              handleChange={(val) => {
                setDirty(true);
                setPhoneNumber(val);
              }}
            />
          </div>
        </div>
        <button type="submit" style={{ visibility: 'hidden' }} />
      </form>
      {isDirtyFormDisplayed && (
        <DirtyFormAlert
          dirty={isDirty}
          closeAlert={() => setDirtyFormDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

EditLicenseUser.propTypes = {
  closeCb: PropTypes.func.isRequired,
  companyID: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  userID: PropTypes.number.isRequired,
  refetchLicense: PropTypes.func.isRequired,
};

export default EditLicenseUser;
