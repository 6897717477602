import React from 'react';
import Base from './Base';

export default props => (
  <Base
    {...props}
    viewBox="0 0 32 40"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <path
      d="M12,7.5l-0.988,0c-0.401,0 -0.785,0.159 -1.069,0.443c-0.284,0.284 -0.443,0.668 -0.443,1.07c-0,3.676 -0,16.298 -0,19.974c-0,0.402 0.159,0.786 0.443,1.07c0.284,0.284 0.668,0.443 1.069,0.443l9.976,-0c0.401,-0 0.785,-0.159 1.069,-0.443c0.284,-0.284 0.443,-0.668 0.443,-1.07l-0,-19.974c0,-0.402 -0.159,-0.786 -0.443,-1.07c-0.284,-0.284 -0.668,-0.443 -1.069,-0.443l-0.988,-0l-0,-5.5c-0,-0.276 -0.224,-0.5 -0.5,-0.5l-7,-0c-0.276,-0 -0.5,0.224 -0.5,0.5l-0,5.5Zm7.5,22l1.488,-0c0.135,-0 0.266,-0.054 0.362,-0.15c0.096,-0.096 0.15,-0.227 0.15,-0.363l0,-19.974c0,-0.136 -0.054,-0.267 -0.15,-0.363c-0.096,-0.096 -0.227,-0.15 -0.363,-0.15c0,-0 -9.974,0 -9.975,0c-0.135,0 -0.266,0.054 -0.362,0.15c-0.096,0.096 -0.15,0.227 -0.15,0.363l-0,19.974c-0,0.136 0.054,0.267 0.15,0.363c0.096,0.096 0.227,0.15 0.363,0.15l1.487,-0l0,-1.717c-0,-0.871 0.346,-1.706 0.961,-2.322c0.616,-0.615 1.451,-0.961 2.322,-0.961c0.144,-0 0.29,-0 0.434,0c0.871,-0 1.706,0.346 2.322,0.961c0.615,0.616 0.961,1.451 0.961,2.322l0,1.717Zm-1,-0l0,-1.717c0,-0.606 -0.24,-1.186 -0.669,-1.614c-0.428,-0.429 -1.008,-0.669 -1.614,-0.669l-0.434,0c-0.606,-0 -1.186,0.24 -1.614,0.669c-0.429,0.428 -0.669,1.008 -0.669,1.614l-0,1.717l5,-0Zm-5.5,-22l0,-5l6,0l0,5l-6,-0Zm1.25,-3.5l0,1.5c0,0.276 0.224,0.5 0.5,0.5c0.276,-0 0.5,-0.224 0.5,-0.5l0,-1.5c0,-0.276 -0.224,-0.5 -0.5,-0.5c-0.276,-0 -0.5,0.224 -0.5,0.5Zm2.5,-0l0,1.5c0,0.276 0.224,0.5 0.5,0.5c0.276,-0 0.5,-0.224 0.5,-0.5l0,-1.5c0,-0.276 -0.224,-0.5 -0.5,-0.5c-0.276,-0 -0.5,0.224 -0.5,0.5Z"
    />
  </Base>
);
