import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import {
  Button,
  IconDisabled,
} from 'shared/components';
import './styles.scss';

const Modal = ({
  className,
  children,
  size,
  closeCb,
  confirmCb,
  title,
  disabled,
  hideFooter,
  ...rest
}) => createPortal(
  <div
    className={`Modal ${className}`}
    {...rest}
  >
    <div className={`modal-inner modal-inner-size-${size}`}>
      {title && <div className="modal-header">{title}</div>}
      <div className="modal-body">
        {children}
      </div>
      <div className={hideFooter ? 'modal-footer hidden' : 'modal-footer'}>
        <Button
          theme="success"
          onClick={confirmCb}
          disabled={disabled}
        >
        Confirm
        </Button>
        <Button
          onClick={closeCb}
          disabled={disabled}
        >
        Cancel
        </Button>
      </div>
      <button
        type="button"
        className="modal-dismiss"
        onClick={closeCb}
        disabled={disabled}
      >
        <IconDisabled
          height="16px"
        />
      </button>
    </div>
  </div>,
  document.body,
);

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]).isRequired,
  className: PropTypes.string,
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  hideFooter: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

Modal.defaultProps = {
  className: '',
  disabled: false,
  hideFooter: false,
  size: 'md',
  title: '',
};

export default Modal;
