import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { BaseChart } from 'shared/components';
import { licenseAPIactions } from 'shared/constants';
import './styles.scss';

const AnalyticsProduct = React.memo(({
  product,
}) => {
  const defaultFrom = moment().startOf('month');
  const defaultTo = moment().endOf('month');

  const from = useSelector(state => get(state, 'analytics.dateRange.date_from'));
  const to = useSelector(state => get(state, 'analytics.dateRange.date_to'));

  const companyID = useSelector(state => get(state, 'company.details.id'));

  const dateFrom = from ? moment(from) : defaultFrom;
  const dateTo = to ? moment(to) : defaultTo;

  const productParam = `&product__in=${get(product, 'data.id')}`;

  return (
    <div className="AnalyticsProduct">
      <div className="individual-actions-charts">
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="license_activation"
            label={__(get(licenseAPIactions, 'activation.label'))}
            title={__(get(licenseAPIactions, 'activation.label'))}
            subtitle={get(licenseAPIactions, 'activation.endpoint')}
            displayTotal
            params={`&action__in=activation${productParam}`}
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="license_deactivation"
            label={__(get(licenseAPIactions, 'deactivation.label'))}
            title={__(get(licenseAPIactions, 'deactivation.label'))}
            subtitle={get(licenseAPIactions, 'deactivation.endpoint')}
            displayTotal
            params={`&action__in=deactivation${productParam}`}
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="license_trial_key"
            label={__(get(licenseAPIactions, 'trial_key.label'))}
            title={__(get(licenseAPIactions, 'trial_key.label'))}
            subtitle={get(licenseAPIactions, 'trial_key.endpoint')}
            displayTotal
            params={`&action__in=trial_key${productParam}`}
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="license_issued"
            label={__(get(licenseAPIactions, 'license_issued.label'))}
            title={__(get(licenseAPIactions, 'license_issued.label'))}
            subtitle={get(licenseAPIactions, 'license_issued.endpoint')}
            displayTotal
            params={`&action__in=license_issued${productParam}`}
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="license_keygen"
            label={__(get(licenseAPIactions, 'keygen.label'))}
            title={__(get(licenseAPIactions, 'keygen.label'))}
            subtitle={get(licenseAPIactions, 'keygen.endpoint')}
            displayTotal
            params={`&action__in=keygen${productParam}`}
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="license_activation_offline"
            label={__(get(licenseAPIactions, 'activation_offline.label'))}
            title={__(get(licenseAPIactions, 'activation_offline.label'))}
            subtitle={get(licenseAPIactions, 'activation_offline.endpoint')}
            displayTotal
            params={`&action__in=activation_offline${productParam}`}
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="license_deactivation_offline"
            label={__(get(licenseAPIactions, 'deactivation_offline.label'))}
            title={__(get(licenseAPIactions, 'deactivation_offline.label'))}
            subtitle={get(licenseAPIactions, 'deactivation_offline.endpoint')}
            displayTotal
            params={`&action__in=deactivation_offline${productParam}`}
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="installation_file"
            label={__(get(licenseAPIactions, 'installation_file.label'))}
            title={__(get(licenseAPIactions, 'installation_file.label'))}
            subtitle={get(licenseAPIactions, 'installation_file.endpoint')}
            displayTotal
            params={`&action__in=installation_file${productParam}`}
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="versions"
            label={__(get(licenseAPIactions, 'versions.label'))}
            title={__(get(licenseAPIactions, 'versions.label'))}
            subtitle={get(licenseAPIactions, 'versions.endpoint')}
            displayTotal
            params={`&action__in=versions${productParam}`}
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="product_details"
            label={__(get(licenseAPIactions, 'product_details.label'))}
            title={__(get(licenseAPIactions, 'product_details.label'))}
            subtitle={get(licenseAPIactions, 'product_details.endpoint')}
            displayTotal
            params={`&action__in=product_details${productParam}`}
            height={200}
            type="line"
          />
        </div>
      </div>
    </div>
  );
});

AnalyticsProduct.propTypes = {
  product: PropTypes.object.isRequired,
};

export default AnalyticsProduct;
