import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import {
  Button,
  DescriptionTable,
  ConfirmationPopup,
  IconDelete,
  List,
  PermissionMissingNotificationTitle,
  Notice,
  Notification,
} from 'shared/components';
import {
  sendErrorReport,
  displayValue,
  getDisabledMessage,
  isFeatureAvailable,
  isFeatureEnabled,
  checkUserPermission,
} from 'shared/helpers';
import { platformFeatures, userPermissions, ssoProvidersList } from 'shared/constants';
import {
  getCustomerAccUserPool,
  createCustomerAccUserPool,
  getCustomerAccProviders,
  deleteCustomerAccProviders,
} from 'src/customer/actions';
import CustomerAccountSSOProviderForm from '../CustomerAccountSSOProviderForm';
import SSOForm from '../SSOForm';
import './styles.scss';

const CustomerAccountSSO = ({
  accountID,
}) => {
  const canManageCustomers = checkUserPermission(userPermissions.customers_write);
  const companyDetails = useSelector(state => get(state, 'company.details'));
  const companyID = get(companyDetails, 'id');

  const [isLoading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [providersLoading, setProvidersLoading] = useState(true);
  const [providers, setProviders] = useState([]);
  const [providerToDelete, setProviderToDelete] = useState(null);
  const [isProviderDeleteConfirmationDisplayed, setProviderDeleteConfirmationDisplayed] = useState(false);
  const [providerDeleteLoading, setProviderDeleteLoading] = useState(false);
  const [isFormDisplayed, setFormDisplay] = useState(false);
  const [userPoolVariablesLoading, setUserPoolVariablesLoading] = useState(true);
  const [userPoolVariables, setUserPoolVariables] = useState(null);
  const [isEditRedirectFormDisplayed, setEditRedirectFormDisplay] = useState(false);

  const getProviders = useCallback(() => {
    getCustomerAccProviders(accountID, companyID)
      .then((res) => {
        const data = get(res, 'data') || [];
        setProviders(data);
        setProvidersLoading(false);
      })
      .catch(() => {
        setProvidersLoading(false);
      });
  }, [companyID]);

  const getUserPool = useCallback(() => {
    getCustomerAccUserPool(accountID, companyID)
      .then((res) => {
        const data = get(res, 'data') || {};
        setUserPoolVariables(data);
        setUserPoolVariablesLoading(false);
      })
      .catch(() => {
        setUserPoolVariablesLoading(false);
      });
  }, [companyID]);

  useEffect(() => {
    getProviders();
  }, [getProviders]);

  useEffect(() => {
    getUserPool();
  }, [getUserPool]);

  const handleUserPoolCreate = () => {
    setLoading(true);
    createCustomerAccUserPool(accountID, companyID)
      .then(() => {
        Notification('success', __('Changes saved successfully'));
        getUserPool();
        setLoading(false);
        setDirty(false);
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot add customer account user pool');
        setLoading(false);
        setDirty(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
  };

  const handleProviderDelete = () => {
    setProviderDeleteLoading(true);
    const data = { provider_name: get(providerToDelete, 'provider_name') };
    deleteCustomerAccProviders(accountID, companyID, data)
      .then(() => {
        Notification('success', __('Changes saved successfully'));
        setProviderDeleteLoading(false);
        setProviderToDelete(null);
        setProviderDeleteConfirmationDisplayed(false);
        getProviders();
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot delete customer account sso provider', data);
        setProviderDeleteLoading(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
  };

  const handleManageCustomerSSOClick = (cb) => {
    if (!canManageCustomers) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.customers_write} />,
        __('Contact you account admin for support.'),
      );
      return false;
    }
    cb(true);
    return true;
  };

  const getProviderLabel = (provider) => {
    const providerOption = ssoProvidersList.find(p => p.value === provider);
    if (providerOption) {
      return providerOption.label;
    }
    return provider;
  };

  return (
    <div className="CustomerAccountSSO">
      <Prompt
        when={isDirty}
        message={__('You have unsaved changes. Are you sure you want to leave?')}
      />
      {(!userPoolVariables && !userPoolVariablesLoading) ? (
        <div className="row btn-row">
          <Notice
            theme="error"
            size="sm"
          >
            {__('Single Sign On authentication for this customer account is not enabled')}
          </Notice>
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.extra_single_sign_on)}
            featureAvailable={isFeatureAvailable(platformFeatures.extra_single_sign_on)}
            notEnabledMessage={getDisabledMessage()}
            theme="success"
            size="sm"
            onClick={() => handleManageCustomerSSOClick(handleUserPoolCreate)}
            disabled={isLoading || userPoolVariablesLoading}
          >
            {__('Enable SSO')}
          </Button>
        </div>
      ) : (
        <div className="row btn-row">
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.extra_single_sign_on)}
            featureAvailable={isFeatureAvailable(platformFeatures.extra_single_sign_on)}
            notEnabledMessage={getDisabledMessage()}
            size="sm"
            onClick={() => handleManageCustomerSSOClick(setEditRedirectFormDisplay)}
            disabled={isLoading || userPoolVariablesLoading}
          >
            {__('Edit redirect uri')}
          </Button>
        </div>
      )}
      <DescriptionTable
        details={[
          { label: __('user_pool_id'), value: displayValue(get(userPoolVariables, 'id')) },
          { label: __('domain_name'), value: displayValue(get(userPoolVariables, 'domain_name')) },
          { label: __('name'), value: displayValue(get(userPoolVariables, 'name')) },
          { label: __('region'), value: displayValue(get(userPoolVariables, 'region')) },
          { label: __('redirect_uri'), value: displayValue(get(userPoolVariables, 'redirect_uri')) },
        ]}
        loading={userPoolVariablesLoading}
      />
      <div className="row table-row">
        <h3>{__('SSO Providers')}</h3>
        <Button
          featureEnabled={isFeatureEnabled(platformFeatures.extra_single_sign_on)}
          featureAvailable={isFeatureAvailable(platformFeatures.extra_single_sign_on)}
          notEnabledMessage={getDisabledMessage()}
          size="sm"
          theme="info"
          loading={providersLoading}
          disabled={providersLoading || isLoading || !userPoolVariables}
          onClick={() => handleManageCustomerSSOClick(setFormDisplay)}
        >
          {__('Add new provider')}
        </Button>
        <div className="providers-list">
          <List
            columns={[
              {
                accessor: 'provider_name',
                Header: __('Name'),
                Cell: cellData => displayValue(get(cellData, 'value')),
              },
              {
                accessor: 'provider_type',
                Header: __('Type'),
                Cell: cellData => getProviderLabel(get(cellData, 'value')),
              },
              {
                Header: __('Delete'),
                headerClassName: 'text-center',
                className: 'text-center',
                id: 'delete',
                width: 80,
                sortable: false,
                Cell: rowData => (
                  <Button
                    className="edit-button"
                    onClick={() => handleManageCustomerSSOClick(() => {
                      setProviderToDelete(rowData.original);
                      setProviderDeleteConfirmationDisplayed(true);
                    })}
                    type="button"
                  >
                    <IconDelete height="16px" width="16px" color="#ee5253" />
                  </Button>
                ),
              },
            ]}
            data={providers}
            minRows={2}
            showPagination={false}
            loading={providersLoading}
            clickable={false}
          />
        </div>
      </div>
      {isProviderDeleteConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setProviderToDelete(null);
            setProviderDeleteConfirmationDisplayed(false);
          }}
          confirmCb={handleProviderDelete}
          title={`${__('Are you sure you want to delete this SSO provider')}?`}
          confirmText={__('Delete')}
          theme="error"
          disabled={providerDeleteLoading}
        >
          <span style={{ fontSize: '14px', wordBreak: 'break-all' }}>{get(providerToDelete, 'provider_name')}</span>
        </ConfirmationPopup>
      )}
      {isFormDisplayed && (
        <CustomerAccountSSOProviderForm
          accountID={accountID}
          companyID={companyID}
          refetchProviders={getProviders}
          providers={providers}
          closeCb={() => setFormDisplay(false)}
        />
      )}
      {isEditRedirectFormDisplayed && (
        <SSOForm
          userPool={userPoolVariables}
          accountID={accountID}
          companyID={companyID}
          refetchUserPool={getUserPool}
          closeCb={() => setEditRedirectFormDisplay(false)}
        />
      )}
    </div>
  );
};

CustomerAccountSSO.propTypes = {
  accountID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default CustomerAccountSSO;
