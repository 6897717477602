import React, { useState } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import {
  Label,
  Selector,
  Subtitle,
  Expander,
} from 'shared/components';
import { mapProductsToSelector, getLicenseAPIactionsOptions } from 'shared/helpers';
import {
  ConsumptionsFeatures,
  ConsumptionsLicenses,
  CustomersTopCallCount,
  LicensesTopCallCount,
  OrdersTopCallCount,
} from './components';
import './styles.scss';

const AnalyticsReports = () => {
  const products = useSelector(state => get(state, 'products.list'));
  const productsList = [{ product_name: __('All Products'), id: null }, ...products];
  const productsForSelector = mapProductsToSelector(productsList);
  const endpointOptions = getLicenseAPIactionsOptions();
  const endpointOptionsList = [{ value: 'all', label: __('All endpoints') }, ...endpointOptions];

  const [selectedProduct, setSelectedProduct] = useState({
    label: get(get(productsList, '[0]'), 'product_name'),
    value: get(get(productsList, '[0]'), 'product_name'),
    data: get(productsList, '[0]'),
  });
  const [selectedAction, setSelectedActions] = useState(get(endpointOptionsList, '[0]'));

  const handleProductSelect = (val) => {
    const selectedP = productsList.find(p => get(p, 'product_name') === val);
    setSelectedProduct({
      label: get(selectedP, 'product_name'),
      value: get(selectedP, 'product_name'),
      data: selectedP,
    });
  };

  const handleEndpointSelect = (val) => {
    const selectedE = endpointOptionsList.find(e => get(e, 'value') === val);
    setSelectedActions(selectedE);
  };

  return (
    <div className="AnalyticsReports">
      <div className="AnalyticsReports-selector">
        <div className="section-row">
          <Label inputId="product-input" text={__('Product Name')} />
          <Selector
            options={productsForSelector}
            handleChange={handleProductSelect}
            value={get(selectedProduct, 'value')}
            inputId="product-input"
          />
        </div>
        <div className="section-row">
          <Label inputId="endpoint-input" text={__('API endpoint')} description={__('Where applicable')} />
          <Selector
            options={endpointOptionsList}
            handleChange={handleEndpointSelect}
            value={get(selectedAction, 'value')}
            inputId="endpoint-input"
          />
        </div>
      </div>
      <div className="row">
        <Subtitle text={__('Total API calls count')} />
        <Expander
          items={[
            {
              uuid: 'top_call_count_licenses',
              heading: __('Licenses'),
              body: <LicensesTopCallCount product={selectedProduct} action={selectedAction} />,
            },
            {
              uuid: 'top_call_count_orders',
              heading: __('Orders'),
              body: <OrdersTopCallCount product={selectedProduct} action={selectedAction} />,
            },
            {
              uuid: 'top_call_count_customers',
              heading: __('Customers'),
              body: <CustomersTopCallCount product={selectedProduct} action={selectedAction} />,
            },
          ]}
          allowMultipleExpanded
          allowZeroExpanded
          preExpanded={['top_call_count_licenses']}
        />
      </div>
      <div className="row">
        <Subtitle text={__('Max consumptions limit reached')} />
        <Expander
          items={[
            {
              uuid: 'max_consumptions_licenses',
              heading: __('Licenses'),
              body: <ConsumptionsLicenses product={selectedProduct} />,
            },
            {
              uuid: 'max_consumptions_features',
              heading: __('Product features'),
              body: <ConsumptionsFeatures product={selectedProduct} />,
            },
          ]}
          allowMultipleExpanded
          allowZeroExpanded
        />
      </div>
    </div>
  );
};

export default AnalyticsReports;
