import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { sendErrorReport } from 'shared/helpers';
import {
  DirtyFormAlert,
  Modal,
  Notification,
  Checkbox,
  Label,
  NumberInput,
} from 'shared/components';
import {
  validateRequiredNumber,
  debouncedValidateRequiredNumber,
} from 'shared/validation';
import {
  rollWebhookEndpointSecret,
} from 'src/account/actions';
import './styles.scss';

const RolloverKeyForm = ({
  closeCb,
  confirmCb,
  endpointID,
  signingKeys,
}) => {
  const validKey = signingKeys.find(k => get(k, 'valid_until') === null);
  const companyID = useSelector(state => get(state, 'company.details.id'));

  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false);
  const [expireKeyNow, setExpireKeyNow] = useState(true);
  const [expireInHours, setExpireInHours] = useState(1);
  const [expireInHoursError, setExpireInHoursError] = useState('');
  const [isLoading, setLoading] = useState(false);

  const validateExpireHours = async () => {
    if (expireKeyNow) {
      return true;
    }

    setLoading(true);
    let errors;
    try {
      errors = await validateRequiredNumber(expireInHours);
      if (!errors) {
        if (Number(expireInHours) > 24 || Number(expireInHours) < 1) {
          errors = __('The number of hours must be between 1 and 24');
        }
      }

      setExpireInHoursError(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate rollover key value change', { value: expireInHours });
    }
    setLoading(false);
    if (errors) { return false; }
    return true;
  };

  const isFormValid = async () => {
    const areExpireHoursValid = await validateExpireHours();
    return !!areExpireHoursValid;
  };

  const handleKeyRollover = () => {
    setLoading(true);

    const data = {
      expire_in_hours: expireKeyNow ? 0 : expireInHours,
    };

    rollWebhookEndpointSecret(endpointID, companyID, data)
      .then(() => {
        setLoading(false);
        confirmCb();
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot rollover webhook signing keys');
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
        setLoading(false);
      });
  };

  const handleSubmit = async () => {
    const isValid = await isFormValid();
    if (!isValid || isLoading) {
      return false;
    }
    handleKeyRollover();
    return true;
  };

  const handleClose = () => {
    if (!dirty) { return closeCb(); }
    return setDirtyFormAlertDisplay(true);
  };

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      title={__('Roll API key')}
      disabled={isLoading}
    >
      <div className="RolloverKeyForm">
        <div className="RolloverKeyForm-key">{get(validKey, 'key')}</div>
        <div className="RolloverKeyForm-desc">{__('Once this key expires, you can’t perform any actions with it and a new signing key will be created.')}</div>
        <form onSubmit={handleSubmit}>
          <div className="RolloverKeyForm-input-row">
            <Checkbox
              checked={expireKeyNow}
              onChange={() => {
                setExpireKeyNow(!expireKeyNow);
                setDirty(true);
              }}
              inputId="expireKeyNow"
              name="expireKeyNow"
              label={__('Expire immediately')}
            />
          </div>
          {!expireKeyNow && (
          <div>
            <Label text={__('Expiration in hours')} inputId="expire_in_hours" />
            <NumberInput
              id="expire_in_hours"
              value={expireInHours}
              error={expireInHoursError}
              handleChange={(val) => {
                setExpireInHours(val);
                setDirty(true);
                debouncedValidateRequiredNumber(val).then(err => setExpireInHoursError(err));
              }}
              min="1"
              max="24"
              disabled={isLoading}
            />
          </div>
          )}
        </form>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormAlertDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

RolloverKeyForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  endpointID: PropTypes.number.isRequired,
  signingKeys: PropTypes.array.isRequired,
};

export default RolloverKeyForm;
