import React from 'react';
import { checkUserPermission } from 'shared/helpers';
import { UnauthorizedNotice, PermissionDenied } from 'shared/components';
import './styles.scss';

const PermissionCheck = (WrappedComponent, permission) => {
  const Wrapped = () => {
    const isAllowed = checkUserPermission(permission);
    if (permission && !isAllowed) {
      return (
        <UnauthorizedNotice noTitle>
          <PermissionDenied permission={permission} />
        </UnauthorizedNotice>
      );
    }
    return <WrappedComponent />;
  };
  return Wrapped;
};

export default PermissionCheck;
