import React from 'react';
import { AirgapPortalData } from '../components';

const AirgapPortalPreferences = () => (
  <div className="AirgapPortalPreferences">
    <AirgapPortalData />
  </div>
);

export default AirgapPortalPreferences;
