import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  Button,
  Notice,
  DescriptionTable,
} from 'shared/components';
import {
  capitalizeFirstLetter,
  displayValue,
  formatDate,
} from 'shared/helpers';
import {
  defaultDateFormat,
  environmentOptions,
} from 'shared/constants';
import { checkCompanyConfigField } from 'shared/companyConfig';
import './styles.scss';

const getEnvLabel = (env) => {
  const selected = environmentOptions.find(e => e.value === env);
  return get(selected, 'label') || '-';
};

const installationFileData = (data) => {
  const fileLink = get(data, '[0].full_link');
  const releaseNotesLink = get(data, '[0].release_notes_link');
  const eulaLink = get(data, '[0].eula_link');

  return (
    <div className="InstallationFileData">
      <div>
        <span className="label">{`${__('Environment')}:`}</span>
        <span className="value">{getEnvLabel(get(data, '[0].environment'))}</span>
      </div>
      <div>
        <span className="label">{`${__('Initial Version')}:`}</span>
        <span className="value">{displayValue(get(data, '[0].version'))}</span>
      </div>
      <div>
        <span className="label">{`${__('Release Date')}:`}</span>
        <span className="value">{formatDate(get(data, '[0].release_date'), defaultDateFormat)}</span>
      </div>
      <div>
        <span className="label">{`${__('Channel')}:`}</span>
        <span className="value">{get(data, '[0].channel')}</span>
      </div>
      {fileLink && (
        <div>
          <span className="label">{`${__('Link to binary')}:`}</span>
          <a
            href={fileLink}
            className="value"
            rel="noreferrer noopener"
            target="_blank"
          >
            {displayValue(fileLink)}
          </a>
        </div>
      )}
      {releaseNotesLink && (
        <div>
          <span className="label">{`${__('Link to binary')}:`}</span>
          <a
            href={releaseNotesLink}
            className="value"
            rel="noreferrer noopener"
            target="_blank"
          >
            {displayValue(releaseNotesLink)}
          </a>
        </div>
      )}
      {eulaLink && (
        <div>
          <span className="label">{`${__('Link to binary')}:`}</span>
          <a
            href={eulaLink}
            className="value"
            rel="noreferrer noopener"
            target="_blank"
          >
            {displayValue(eulaLink)}
          </a>
        </div>
      )}
    </div>
  );
};

const VerifyProduct = ({
  companyID,
  companyPlan,
  formState,
  handleBack,
  handleSubmit,
  isLoading,
}) => {
  const isEnterprise = companyPlan === 'enterprise';

  return (
    <div className="VerifyProduct">
      <div className="VerifyProduct-details">
        <div className="product-information">
          <div className="product-name">{get(formState, 'product_name')}</div>
          <div className="product-code">{get(formState, 'short_code')}</div>
        </div>
        <div className="product-details">
          <DescriptionTable
            details={[
              { label: __('Authorization method'), value: displayValue(capitalizeFirstLetter(get(formState, 'authorization_method'))) },
              {
                label: checkCompanyConfigField(companyID, 'isTrial') ? __('Allow trial') : null,
                value: get(formState, 'allow_trial') ? __('Yes') : __('No'),
              },
              {
                label: (checkCompanyConfigField(companyID, 'isTrial') && get(formState, 'allow_trial')) ? __('Trial duration') : null,
                value: `${get(formState, 'trial_days')}d` || 'N/A',
              },
              {
                label: isEnterprise ? __('Floating timeout') : null,
                value: `${displayValue(get(formState, 'floating_timeout'))} min`,
              },
              // installation files
              {
                className: 'installation-files-row',
                label: get(formState, 'installation_files') ? __('Installation file') : null,
                value: installationFileData(get(formState, 'installation_files')),
              },
            ]}
          />
          <Notice theme="info">
            {__('A product always has a default license policy. This license policy can be edited at any time, or a new license policy can be configured and set as the default policy for the product.')}
          </Notice>
        </div>
      </div>
      <div className="VerifyProduct-actions">
        <Button
          theme="default"
          size="lg"
          onClick={handleBack}
          disabled={isLoading}
        >
          {__('Back')}
        </Button>
        <Button
          theme="success"
          size="lg"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {__('Create product')}
        </Button>
      </div>
    </div>
  );
};

VerifyProduct.propTypes = {
  companyID: PropTypes.number.isRequired,
  companyPlan: PropTypes.string.isRequired,
  formState: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default VerifyProduct;
