import { get } from 'lodash';

export const getReaminingFeatures = (companyFeatures, defaultFeaturesList) => {
  const remaining = [];

  if (Array.isArray(companyFeatures) && Array.isArray(defaultFeaturesList)) {
    defaultFeaturesList.forEach((dfl) => {
      const usedFeature = companyFeatures.filter(cf => get(cf, 'key') === get(dfl, 'key'));
      if (!usedFeature.length) {
        remaining.push(dfl);
      }
    });
  }
  return remaining;
};

export const mapFeaturesToSelector = (features) => {
  const featuresOptions = [];
  if (!features) {
    return featuresOptions;
  }
  if (Array.isArray(features)) {
    features.forEach((r) => {
      const option = {
        label: get(r, 'label'),
        value: get(r, 'key'),
        data: r,
      };
      featuresOptions.push(option);
    });
  }
  return featuresOptions;
};
