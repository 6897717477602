import React from 'react';
import PropTypes from 'prop-types';
import {
  RiBold,
  RiItalic,
  RiUnderline,
  RiH1,
  RiH2,
  RiH3,
  RiListOrdered,
  RiListUnordered,
  RiAlignLeft,
  RiAlignCenter,
  RiAlignRight,
  RiAlignJustify,
  RiLink,
  RiArrowGoBackLine,
  RiArrowGoForwardLine,
  RiFileImageLine,
} from 'react-icons/ri';
import './styles.scss';

const setLink = (editor) => {
  const previousUrl = editor.getAttributes('link').href;
  const url = window.prompt('URL', previousUrl);

  // cancelled
  if (url === null) {
    return;
  }

  // empty
  if (url === '') {
    editor.chain().focus().extendMarkRange('link').unsetLink()
      .run();

    return;
  }

  // update link
  editor.chain().focus().extendMarkRange('link').setLink({ href: url })
    .run();
};

const addImage = (editor) => {
  const url = window.prompt('URL');

  if (url) {
    editor.chain().focus().setImage({ src: url }).run();
  }
};

const RichTextEditorToolbar = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <div className="RichTextEditorToolbar">
      <div className="RichTextEditorToolbar-tools">
        <button
          className={`RichTextEditorToolbar-button ${editor.isActive('bold') ? 'active' : ''}`}
          onClick={() => editor.chain().focus().toggleBold().run()}
          type="button"
        >
          <RiBold size={16} color="#6d5f5f" />
        </button>
        <button
          className={`RichTextEditorToolbar-button ${editor.isActive('italic') ? 'active' : ''}`}
          onClick={() => editor.chain().focus().toggleItalic().run()}
          type="button"
        >
          <RiItalic size={16} color="#6d5f5f" />
        </button>
        <button
          className={`RichTextEditorToolbar-button ${editor.isActive('underline') ? 'active' : ''}`}
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          type="button"
        >
          <RiUnderline size={16} color="#6d5f5f" />
        </button>
        <button
          className="RichTextEditorToolbar-button"
          onClick={() => editor.chain().focus().setTextAlign('left').run()}
          type="button"
        >
          <RiAlignLeft size={16} color="#6d5f5f" />
        </button>
        <button
          className={`RichTextEditorToolbar-button ${editor.isActive({ textAlign: 'center' }) ? 'active' : ''}`}
          onClick={() => editor.chain().focus().setTextAlign('center').run()}
          type="button"
        >
          <RiAlignCenter size={16} color="#6d5f5f" />
        </button>
        <button
          className={`RichTextEditorToolbar-button ${editor.isActive({ textAlign: 'right' }) ? 'active' : ''}`}
          onClick={() => editor.chain().focus().setTextAlign('right').run()}
          type="button"
        >
          <RiAlignRight size={16} color="#6d5f5f" />
        </button>
        <button
          className={`RichTextEditorToolbar-button ${editor.isActive({ textAlign: 'justify' }) ? 'active' : ''}`}
          onClick={() => editor.chain().focus().setTextAlign('justify').run()}
          type="button"
        >
          <RiAlignJustify size={16} color="#6d5f5f" />
        </button>
        <button
          className={`RichTextEditorToolbar-button ${editor.isActive('heading', { level: 1 }) ? 'active' : ''}`}
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          type="button"
        >
          <RiH1 size={16} color="#6d5f5f" />
        </button>
        <button
          className={`RichTextEditorToolbar-button ${editor.isActive('heading', { level: 2 }) ? 'active' : ''}`}
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          type="button"
        >
          <RiH2 size={16} color="#6d5f5f" />
        </button>
        <button
          className={`RichTextEditorToolbar-button ${editor.isActive('heading', { level: 3 }) ? 'active' : ''}`}
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          type="button"
        >
          <RiH3 size={16} color="#6d5f5f" />
        </button>
        <button
          className={`RichTextEditorToolbar-button ${editor.isActive('orderedList') ? 'active' : ''}`}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          type="button"
        >
          <RiListOrdered size={16} color="#6d5f5f" />
        </button>
        <button
          className={`RichTextEditorToolbar-button ${editor.isActive('bulletList') ? 'active' : ''}`}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          type="button"
        >
          <RiListUnordered size={16} color="#6d5f5f" />
        </button>
        <button
          className={`RichTextEditorToolbar-button ${editor.isActive('link') ? 'active' : ''}`}
          onClick={() => setLink(editor)}
          type="button"
        >
          <RiLink size={16} color="#6d5f5f" />
        </button>
        <button
          className={`RichTextEditorToolbar-button ${editor.isActive('image') ? 'active' : ''}`}
          onClick={() => addImage(editor)}
          type="button"
        >
          <RiFileImageLine size={16} color="#6d5f5f" />
        </button>
        <button
          className={`RichTextEditorToolbar-button ${editor.isActive('undo') ? 'active' : ''}`}
          onClick={() => editor.chain().focus().undo().run()}
          type="button"
        >
          <RiArrowGoBackLine size={16} color="#6d5f5f" />
        </button>
        <button
          className={`RichTextEditorToolbar-button ${editor.isActive('redo') ? 'active' : ''}`}
          onClick={() => editor.chain().focus().redo().run()}
          type="button"
        >
          <RiArrowGoForwardLine size={16} color="#6d5f5f" />
        </button>
      </div>
    </div>
  );
};

RichTextEditorToolbar.propTypes = {
  editor: PropTypes.object.isRequired,
};

export default RichTextEditorToolbar;
