import React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Modal,
} from 'shared/components';

const DeviceVariablesModal = ({
  close,
  data,
}) => (
  <div className="DeviceVariablesModal">
    <Modal
      title={__('Device variables')}
      confirmCb={() => { }}
      closeCb={() => close()}
      hideFooter
    >
      <div className="device-variables-container">
        <List
          columns={[
            {
              Header: __('Device variable'),
              accessor: 'variable',
            },
            {
              Header: __('value'),
              accessor: 'value',
            },
          ]}
          data={data}
          minRows={1}
          showPagination={data.length > 20}
        />
      </div>
    </Modal>
  </div>
);

DeviceVariablesModal.propTypes = {
  close: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
};

export default DeviceVariablesModal;
