import React from 'react';
import PropTypes from 'prop-types';

const RunningIcon = ({
  text,
}) => (
  <div className="ImportStatusCell">
    <svg width="18px" height="18px" fill="#21366D" viewBox="0 0 100 125">
      <g>
        <path d="M50,15c-19.33,0-35,15.67-35,35s15.67,35,35,35s35-15.67,35-35S69.33,15,50,15z M39,70V30l30,20L39,70z" />
        <path
          d="M50,87c-20.4,0-37-16.6-37-37c0-20.4,16.6-37,37-37c20.4,0,37,16.6,37,37C87,70.4,70.4,87,50,87z M50,17   c-18.2,0-33,14.8-33,33s14.8,33,33,33s33-14.8,33-33S68.2,17,50,17z M37,73.74V26.26L72.61,50L37,73.74z M41,33.74v32.53L65.39,50   L41,33.74z"
        />
      </g>
    </svg>
    {text}
  </div>
);

RunningIcon.propTypes = {
  text: PropTypes.string.isRequired,
};

export default RunningIcon;
