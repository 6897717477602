// import { getCompanySuccess } from 'src/company/actionTypes';
import * as actionTypes from './actionTypes';

const initialState = {
  loading: true,
  list: [],
  archived: [],
};

const getActiveProducts = (list = []) => list.filter(p => !p.is_archived);
const getInctiveProducts = (list = []) => list.filter(p => p.is_archived);

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.getProductsInit:
      return Object.assign({}, state, {
        loading: true,
      });
    case actionTypes.getProductsSuccess:
      return Object.assign({}, state, {
        loading: false,
        list: getActiveProducts(action.products),
        archived: getInctiveProducts(action.products),
      });
    case actionTypes.getProductsError:
      return Object.assign({}, state, {
        loading: false,
      });
    // case getCompanySuccess:
    //   return Object.assign({}, state, {
    //     loading: false,
    //     list: getActiveProducts(action.products),
    //     archived: getInctiveProducts(action.products),
    //   });
    default:
      return state;
  }
}
