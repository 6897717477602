import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  Button,
  DescriptionTable,
  Modal,
  Label,
  DateRangePicker,
  Notification,
  IconDisabled,
} from 'shared/components';
import {
  sendErrorReport,
  filenameFromHeadersData,
  downloadResource,
  setSearchQueryParam,
} from 'shared/helpers';
import { exportLicenses } from 'src/license/actions';
import { transformSearchFilter } from '../LicenseList/helpers';
import './styles.scss';

const APIdateFormat = 'YYYY-MM-DD';
const defaultFrom = moment().subtract(30, 'days');
const defaultTo = moment();

const ExportOrderForm = ({
  closeCb,
  filters,
  handleClearFilters,
}) => {
  const {
    selectedCustomerLabelFilters,
    selectedProductFilter,
    validityPeriodLte,
    selectedEnabledFilter,
    query,
    searchBy,
  } = filters;
  const companyID = useSelector(state => get(state, 'company.details.id'));

  const [isLoading, setLoading] = useState(false);
  const [from, setFrom] = useState(defaultFrom);
  const [to, setTo] = useState(defaultTo);

  const searchFilter = query;
  const productFilter = get(selectedProductFilter, 'label');
  const validityFilter = validityPeriodLte;
  const enabledFilter = get(selectedEnabledFilter, 'label');
  const hasClFilter = !!selectedCustomerLabelFilters.length;

  const exportData = () => {
    setLoading(true);

    let filterUrl = `?company=${companyID}&created_at__date__gte=${from.format(APIdateFormat)}&created_at__date__lte=${to.format(APIdateFormat)}`;
    let queryUrl = '';

    if (hasClFilter) {
      filterUrl += `&customer_label__in=${selectedCustomerLabelFilters.map(l => l.value).join(',')}`;
    }

    if (productFilter && selectedProductFilter.value !== 'all') {
      filterUrl += `&product_id=${selectedProductFilter.value}`;
    }

    if (validityFilter) {
      filterUrl += `&validity_period__lte=${validityPeriodLte}&validity_period__gte=${moment().format('YYYY-MM-DD')}`;
    }

    if (enabledFilter && selectedEnabledFilter.value !== 'all') {
      filterUrl += selectedEnabledFilter.value === 'Enabled' ? '&enabled=1' : '&enabled=0';
    }

    if (query) {
      const searchType = transformSearchFilter(searchBy);
      queryUrl = setSearchQueryParam(query, searchType);
    }

    exportLicenses(filterUrl, queryUrl)
      .then((response) => {
        const filename = filenameFromHeadersData(response.headers);
        downloadResource(response, filename);
        closeCb();
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot export data', { filterUrl, queryUrl });
        Notification('error', __('Export data failed'));
        setLoading(false);
      });
  };

  const isDateRangeValid = !!from && !!to && from.isSameOrBefore(to);
  const isFormSubmittable = isDateRangeValid && !isLoading;

  return (
    <div className="ExportOrderForm">
      <Modal
        title={__('Export licenses')}
        size="lg"
        closeCb={closeCb}
        confirmCb={exportData}
        disabled={!isFormSubmittable}
      >
        <div>
          <div className="form-horizontal">
            <Label text={__('Select date range for the data export')} />
            <DateRangePicker
              handleChange={(dates) => {
                setFrom(dates[0]);
                setTo(dates[1]);
              }}
              initialStartDate={defaultFrom}
              initialEndDate={defaultTo}
            />
          </div>
          <div className="ExportOrderForm-filters">
            <div className="title">{__('Filters')}</div>
            <DescriptionTable
              details={[
                { label: searchFilter ? __('Search query') : null, value: searchFilter },
                { label: productFilter ? __('Product') : null, value: productFilter },
                { label: validityFilter ? __('Expires before date:') : null, value: validityFilter },
                { label: enabledFilter ? __('Is disabled') : null, value: enabledFilter },
                { label: hasClFilter ? __('Customer labels') : null, value: selectedCustomerLabelFilters.map(i => i.label).join(', ') },
              ]}
            />
            <Button
              theme="link"
              size="sm"
              disabled={isLoading}
              onClick={handleClearFilters}
            >
              <>
                <IconDisabled
                  height="10px"
                />
                {__('Clear filters')}
              </>
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

ExportOrderForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  handleClearFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
};

export default ExportOrderForm;
