import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  sendErrorReport,
} from 'shared/helpers';
import {
  Modal,
  Notification,
  Selector,
  ContentLoader,
  Notice,
} from 'shared/components';
import {
  checkLicense,
} from 'src/license/actions';
import {
  fetchLicenseApiKeys,
} from 'src/account/actions';

const DeviceRefreshFileForm = ({
  closeCb,
  companyID,
  productShortCode,
  licenseKey,
  username,
  device,
}) => {
  const isActive = get(device, 'device_active');
  const hwid = get(device, 'hardware_id');

  const [isLoading, setLoading] = useState(false);
  // license api keys
  const [isLoadingKeys, setLoadingKeys] = useState(true);
  const [licenseKeys, setLicenseKeys] = useState([]);
  const [selectedApiKey, setSelectedApiKey] = useState([]);

  const getLicenseAPIKeys = useCallback(() => {
    fetchLicenseApiKeys(companyID)
      .then((res) => {
        const data = get(res, 'data.results') || [];
        const formattedData = data.filter(item => !item.revoked).map(item => ({ value: item.uuid, label: `${item.uuid}` }));
        setLicenseKeys(formattedData);
        setSelectedApiKey(formattedData[0].value);
        setLoadingKeys(false);
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot get license api keys');
        setLoadingKeys(false);
        Notification('error', __('There was an error while getting your data'), __('Try again later'));
      });
  }, [companyID]);

  useEffect(() => {
    getLicenseAPIKeys();
  }, [getLicenseAPIKeys]);

  const getLicenseRefreshFile = () => {
    if (!isActive) {
      Notification('error', __('Device is not active'));
      return false;
    }
    setLoading(hwid);
    checkLicense(companyID, hwid, productShortCode, selectedApiKey, licenseKey, encodeURIComponent(username))
      .then((res) => {
        const responseData = encodeURIComponent((JSON.stringify(res.data)));
        const data = unescape(responseData);
        download(`data:text/plain;charset=utf-8,${btoa(data)}`, 'license_refresh.lic', 'text/plain');
        closeCb();
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot download license refresh data', { hwid });
        setLoading(false);
        Notification('error', __('Error occured'));
      });
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }

    setLoading(true);
    await getLicenseRefreshFile();
  };

  if (isLoadingKeys) {
    return (
      <Modal
        closeCb={closeCb}
        confirmCb={handleSubmit}
        disabled={isLoading}
        title={__('Download license refresh file')}
        size="sm"
        hideFooter
      >
        <div><ContentLoader /></div>
      </Modal>
    );
  }

  return (
    <Modal
      closeCb={closeCb}
      confirmCb={handleSubmit}
      disabled={isLoading}
      title={__('Download license refresh file')}
      size="sm"
    >
      <div>
        <Notice title={__('Please select your API key to download license refresh file.')}>
          {__('Make sure that selected api key is being used in the application running on the device.')}
        </Notice>
        <Selector
          handleChange={val => setSelectedApiKey(val)}
          options={licenseKeys}
          value={selectedApiKey}
        />
      </div>
    </Modal>
  );
};

DeviceRefreshFileForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  companyID: PropTypes.number.isRequired,
  productShortCode: PropTypes.string.isRequired,
  licenseKey: PropTypes.string,
  username: PropTypes.string,
  device: PropTypes.object.isRequired,
};

DeviceRefreshFileForm.defaultProps = {
  licenseKey: '',
  username: '',
};

export default DeviceRefreshFileForm;
