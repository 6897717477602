import { get } from 'lodash';
import api from 'shared/api';
import { formatOrderBy } from 'shared/helpers';
import * as actionTypes from './actionTypes';

// users actions
const getUsersInit = () => ({
  type: actionTypes.getUsersInit,
});

const getUsersSuccess = res => ({
  type: actionTypes.getUsersSuccess,
  payload: res,
});

const getUsersError = res => ({
  type: actionTypes.getUsersError,
  payload: res,
});

export const getUsers = (companyId, orderBy) => (dispatch) => {
  dispatch(getUsersInit());
  const orderUrl = formatOrderBy(orderBy);
  const url = `/api/v1/users/?company=${companyId}${orderUrl}`;
  return api.get(url).then(
    res => dispatch(getUsersSuccess(get(res, 'data'))),
    res => dispatch(getUsersError(get(res, 'data'))),
  );
};

export const clearUsers = () => dispatch => dispatch({ type: actionTypes.clearUsers });

// user company actions
const getUserCompaniesInit = () => ({
  type: actionTypes.getUserCompaniesInit,
});

const getUserCompaniesError = () => ({
  type: actionTypes.getUserCompaniesError,
});

const getUserCompaniesSuccess = (data) => {
  const action = {
    type: actionTypes.getUserCompaniesSuccess,
    companies: get(data, 'results'),
  };
  return action;
};

export const getUserCompanies = (isActive = true) => (dispatch) => {
  dispatch(getUserCompaniesInit());
  return api.get(`/api/v1/companies/?slim=1&limit=100000&is_active=${isActive ? '1' : '0'}`).then(
    res => dispatch(getUserCompaniesSuccess(get(res, 'data'))),
    () => dispatch(getUserCompaniesError()),
  );
};

export const getUserCompaniesSilent = (isActive = true) => dispatch => api.get(`/api/v1/companies/?slim=1&limit=100000&is_active=${isActive ? '1' : '0'}`)
  .then(
    res => dispatch(getUserCompaniesSuccess(get(res, 'data'))),
    () => dispatch(getUserCompaniesError()),
  );

// active company actions
const changeActiveCompanyAction = company => ({
  type: actionTypes.changeActiveCompany,
  company,
});

export const changeActiveCompany = companyId => (dispatch) => {
  dispatch(changeActiveCompanyAction(companyId));
};

const refreshDisableStaff = disableStaff => ({
  type: actionTypes.disableStaff,
  disableStaff,
});

export const changeDisableStaff = disableStaff => (dispatch) => {
  dispatch(refreshDisableStaff(disableStaff));
};

const refreshSidebarSize = sidebarSize => ({
  type: actionTypes.changeSidebarSize,
  sidebarSize,
});

export const changeSidebarSize = sidebarSize => (dispatch) => {
  dispatch(refreshSidebarSize(sidebarSize));
};

export const set2FaRequiredOnCompany = companyID => api.post(`/api/v1/companies/${companyID}/require_totp`);

// ----------------------------
export const createUser = (userData, companyID) => api.post(`/api/v1/users/?company=${companyID}`, userData);

export const deleteUser = (userID, companyID) => api.delete(`/api/v1/users/${userID}?company=${companyID}`);

export const initUser = () => api.post('/api/v1/auth/init-user/');

export const attemptPayment = companyId => api.post(`/api/v1/companies/${companyId}/attempt_payment/`);

export const deleteCard = companyId => api.delete(`/api/v1/companies/${companyId}/payment_method/`);

export const fetchInvoices = companyId => api.get(`/api/v1/companies/${companyId}/invoices/`);

// invoices actions
const getInvoicesInit = () => ({
  type: actionTypes.getInvoicesInit,
});

export const getInvoicesSuccess = res => ({
  type: actionTypes.getInvoicesSuccess,
  payload: res,
});

const getInvoicesError = res => ({
  type: actionTypes.getInvoicesError,
  payload: res,
});

export const getInvoices = (companyId, isSilent = false) => (dispatch) => {
  if (!isSilent) {
    dispatch(getInvoicesInit());
  }
  const url = `/api/v1/companies/${companyId}/invoices/`;
  return api.get(url).then(
    res => dispatch(getInvoicesSuccess(get(res, 'data') || [])),
    res => dispatch(getInvoicesError(res.data)),
  );
};

export const changeBillingDetails = (companyID, data) => {
  const url = `/api/v1/companies/${companyID}/update_customer/`;
  return api.post(url, data);
};

export const verify2FA = code => api.post('/api/v1/auth/twofa_verify/', { code });

export const submitCard = (companyId, data) => api
  .post(`/api/v1/companies/${companyId}/payment_method/`, {
    token: get(data, 'cardToken'),
    stripe_tax_id: data.stripe_tax_id,
    subscription_cycle: get(data, 'subscription_cycle'),
  });

export const getIntentSecret = companyId => api.post(`/api/v1/companies/${companyId}/setup_intent/`);

export const patchCompanyPaymentData = (company, data) => (
  api.patch(`/api/v1/companies/${company}/`, {
    payment_name: get(data, 'payment_name'),
    payment_zip_code: get(data, 'payment_zip_code'),
    payment_country: get(data, 'payment_country'),
    payment_region: get(data, 'payment_region'),
  })
);

export const updateCompany = (companyID, data) => api.patch(`/api/v1/companies/${companyID}/`, data);

export const updateToStandardPlan = (company, data) => (
  api.patch(`/api/v1/companies/${company}/`, {
    plan_type: get(data, 'planType'),
    stripe_tax_id: get(data, 'stripe_tax_id'),
    subscription_cycle: get(data, 'subscription_cycle'),
    payment_country: get(data, 'payment_country'),
    payment_region: get(data, 'payment_region'),
  })
);

export const getUser = (companyID, userId) => api.get(`/api/v1/users/${userId}/?company=${companyID}`);

export const patchUser = (userId, userData, companyID) => api.patch(`/api/v1/users/${userId}/?company=${companyID}`, userData);

export const resendInvitation = (userID, companyID) => api.post(`/api/v1/users/${userID}/resend_invite/?company=${companyID}`);

export const fetchLicenseApiKeys = companyID => api.get(`/api/v1/license-api-keys/?company=${companyID}&limit=100`);

export const createNewLicenseApiKey = (companyID, data) => api.post(`/api/v1/license-api-keys/?company=${companyID}`, data);

export const updateLicenseApiKey = (keyID, companyID, data) => api.patch(`/api/v1/license-api-keys/${keyID}?company=${companyID}`, data);

export const deleteLicenseApiKey = (keyID, companyID) => api.delete(`/api/v1/license-api-keys/${keyID}?company=${companyID}`);

export const fetchMgmgtApiKeys = companyID => api.get(`/api/v1/companies/${companyID}/api_keys/`);

export const createNewMgmgtApiKey = companyID => api.post(`/api/v1/companies/${companyID}/api_keys/`);

// always send api_key field inside data here so the BE knows which key to update
export const updateMgmgtApiKey = (companyID, data) => {
  const url = `/api/v1/companies/${companyID}/api_keys/`;
  return api.patch(url, data);
};

export const deleteMgmgtApiKey = (companyID, apiKey) => {
  const url = `/api/v1/companies/${companyID}/api_keys/`;
  return api.delete(url, { api_key: apiKey });
};

// notifications
// events actions
const getEventsInit = () => ({
  type: actionTypes.getEventsInit,
});

const getEventsSuccess = res => ({
  type: actionTypes.getEventsSuccess,
  payload: res,
});

const getEventsError = res => ({
  type: actionTypes.getEventsError,
  payload: res,
});

export const getEvents = () => (dispatch) => {
  dispatch(getEventsInit());
  const url = '/api/v1/events/';
  return api.get(url).then(
    res => dispatch(getEventsSuccess(get(res, 'data.results') || [])),
    res => dispatch(getEventsError(res.data)),
  );
};

export const updateCustomerTax = (companyID, data) => api.post(`/api/v1/companies/${companyID}/update_customer_tax/`, data);

// sso
export const getSSOProviders = companyID => api.get(`/api/v1/companies/${companyID}/cognito_identity_providers/`);

export const getKeycloakSSOProvider = companyID => api.get(`/api/v1/companies/${companyID}/identity_provider/`);

export const addSSOProvider = (companyID, data) => api.post(`/api/v1/companies/${companyID}/cognito_identity_providers/`, data); // data depends on provider type -> google or saml

export const addKeycloakSSOProvider = (companyID, data) => api.post(`/api/v1/companies/${companyID}/identity_provider/`, data); // data depends on provider type -> google or saml

export const deleteSSOProvider = (companyID, data) => api.delete(`/api/v1/companies/${companyID}/cognito_identity_providers/`, data); // example data: { "provider_name": "Google" }

export const deleteKeycloakSSOProvider = companyID => api.delete(`/api/v1/companies/${companyID}/identity_provider/`);

export const getUserPoolVariables = companyID => api.get(`/api/v1/companies/${companyID}/cognito_user_pool/`);

export const updateKeycloakSSOProvider = (companyID, data) => api.patch(`/api/v1/companies/${companyID}/identity_provider/`, data);

// air-gapped
export const fetchAirGapPublicKey = (companyID => api.get(`/api/v1/companies/${companyID}/kms_key/`));

export const generateAirGapKeyPair = (companyID => api.post(`/api/v1/companies/${companyID}/kms_key/`));

export const generateLicenseKeyPair = data => api.post('api/v1/gen-license-key-pair/', data);

export const verifyAirGapLicense = data => api.post('api/v1/verify-air-gapped-license/', data);

// offline portal
export const checkOfflinePortalDomain = domain => api.get(`/api/v1/companies/get_id?offline_portal_domain=${domain}`);

// airgap portal
export const checkAirGapPortalDomain = domain => api.get(`/api/v1/companies/get_id?airgap_portal_domain=${domain}`);

// user labels

export const getUserLabels = (companyID, userID) => api.get(`/api/v1/user-roles/list_on_user/?company=${companyID}&user=${userID}`);

export const setUserLabels = (companyID, userID, data) => api.post(`/api/v1/user-roles/set_on_user/?company=${companyID}&user=${userID}`, data);

export const addCompanyUserLabel = (companyId, data) => api.post(`/api/v1/user-roles/?company=${companyId}`, data);

export const editUserLabel = (labelID, companyID, data) => api.patch(`/api/v1/user-roles/${labelID}/?company=${companyID}`, data);

export const deleteUserLabel = (labelID, companyID) => api.delete(`/api/v1/user-roles/${labelID}/?company=${companyID}`);

// audit log

export const getPlatformAuditLogs = (
  companyID,
  page,
  orderBy,
  pageSize = 20,
  params,
  range = {},
  filters,
) => {
  const startDate = get(range, 'from') ? `&ts__date__gte=${get(range, 'from')}` : '';
  const endDate = get(range, 'to') ? `&ts__date__lte=${get(range, 'to')}` : '';
  const offsetUrl = page ? page * pageSize : 0;
  const orderUrl = formatOrderBy(orderBy);

  const { labels } = filters;
  const userLabelsFilter = (labels && labels.length) ? `&user_role__in=${labels.map(l => l.value).join(',')}` : '';
  const url = `/api/v1/audit-logs/?company=${companyID}&limit=${pageSize}&offset=${offsetUrl}${orderUrl}${params}${startDate}${endDate}${userLabelsFilter}`;
  return api.get(url);
};

export const assignTemplateToLabel = (companyID, labelID, data) => (
  api.post(`/api/v1/user-permissions/set_from_template/?company=${companyID}&user_role=${labelID}`, data)
);

export const initSalesforceIntegration = (baseURL, data) => api.post(`${baseURL}/credentials/salesforce/initial`, data);

export const deactivateSalesforceIntegration = (baseURL, data) => api.post(`${baseURL}/credentials/salesforce/deactivate`, data);

// webhooks
export const getWebhookEndpoints = companyID => api.get(`/api/v1/endpoints/?company=${companyID}`);

export const getWebhookEndpoint = (endpointID, companyID) => api.get(`/api/v1/endpoints/${endpointID}/?company=${companyID}`);

export const createWebhookEndpoint = (companyID, data) => api.post(`/api/v1/endpoints/?company=${companyID}`, data);

export const updateWebhookEndpoint = (endpointID, companyID, data) => api.patch(`/api/v1/endpoints/${endpointID}/?company=${companyID}`, data);

export const deleteWebhookEndpoint = (endpointID, companyID) => api.delete(`/api/v1/endpoints/${endpointID}/?company=${companyID}`);

export const getWebhookEvents = companyID => api.get(`/api/v1/endpoint-events/?company=${companyID}`);

export const getWebhookEvent = (eventID, companyID) => api.get(`/api/v1/endpoint-events/${eventID}/?company=${companyID}`);

export const setWebhookEndpointEvents = (endpointID, companyID, data) => api.post(`/api/v1/endpoints/${endpointID}/set_events/?company=${companyID}`, data);

export const rollWebhookEndpointSecret = (endpointID, companyID, data) => api.post(`/api/v1/endpoints/${endpointID}/roll_signing_key/?company=${companyID}`, data);

export const getSigningKeys = (endpointID, companyID) => api.get(`/api/v1/signing-keys/?endpoint=${endpointID}&company=${companyID}`);

export const getSigningKey = (keyID, companyID) => api.get(`/api/v1/signing-keys/${keyID}/?company=${companyID}`);

export const getWebhookEndpointHistory = (endpointID, companyID, page, pageSize) => {
  const offsetUrl = page ? page * pageSize : 0;
  return api.get(`/api/v1/webhook-histories/?endpoint=${endpointID}&company=${companyID}&order_by=-ts&limit=${pageSize}&offset=${offsetUrl}`);
};

export const getWebhookHistoryItem = (historyID, companyID) => api.get(`/api/v1/webhook-histories/${historyID}/?company=${companyID}`);

export const resendWebhookHistoryItem = (historyID, companyID) => api.post(`/api/v1/webhook-histories/${historyID}/resend/?company=${companyID}`);
