import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import {
  DateRangePicker,
  Forbidden,
  List,
} from 'shared/components';
import {
  sendErrorReport,
  displayValue,
  formatDate,
  capitalizeFirstLetter,
  isFeatureAvailable,
  isFeatureEnabled,
} from 'shared/helpers';
import { APIdateFormat, platformFeatures } from 'shared/constants';
import { getDistributionActivities } from 'src/order/actions';
import './styles.scss';

const LicenseManagerReport = ({
  manager,
  companyID,
}) => {
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const managerID = get(manager, 'id');
  const defaultFrom = moment().startOf('month');
  const defaultTo = moment().endOf('month');

  const [from, setFrom] = useState(defaultFrom);
  const [to, setTo] = useState(defaultTo);
  const [isLoading, setLoading] = useState(true);
  // data
  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(null);
  const [tablePage, setTablePage] = useState(0);
  const [currentSort, setSort] = useState([{
    desc: true,
    id: 'ts',
  }]);

  const fetchData = useCallback((
    page = 0,
    sort = currentSort,
  ) => {
    setLoading(true);
    getDistributionActivities(
      companyID,
      page,
      sort,
      from.format(APIdateFormat),
      to.format(APIdateFormat),
    )
      .then((res) => {
        setData(get(res, 'data.results') || []);
        setDataCount(get(res, 'data.count'));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        sendErrorReport(err, 'Cannot fetch distribution activities list');
      });
  }, [companyID, from, to]);

  const handleSortChange = (newSorted) => {
    setSort(newSorted);
    fetchData(0, newSorted);
  };

  const handlePageChange = (newPage) => {
    setTablePage(newPage);
    fetchData(newPage, currentSort);
  };

  const handleDateChange = (dates) => {
    setFrom(dates[0]);
    setTo(dates[1]);
  };

  const handleRedirect = (rowData) => {
    const licenseID = get(rowData, 'original.license');
    const orderID = get(rowData, 'original.order');
    if (licenseID) {
      return history.push(`/${companyID}/orders/${orderID}/${licenseID}`);
    }
    return history.push(`/${companyID}/orders/${orderID}`);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const featureEnabled = isFeatureEnabled(platformFeatures.extra_distribution_portal);
  const featureAvailable = isFeatureAvailable(platformFeatures.extra_distribution_portal);
  const isAvailable = featureEnabled && featureAvailable;

  if (!isAvailable) {
    return (
      <div className="LicenseManagerReport">
        <Forbidden
          text={__('This feature is not available in your current plan type')}
          description={__('Contact sales if you wish to change this policy.')}
        />
      </div>
    );
  }

  return (
    <div className="LicenseManagerReport">
      <div className="list-header">
        <div />
        <div>
          <DateRangePicker
            handleChange={handleDateChange}
            initialStartDate={from}
            initialEndDate={to}
            minimumNights={0}
            disabled={isLoading}
          />
        </div>
      </div>
      <div className="lists-cont">
        <List
          clickable
          columns={[
            {
              accessor: 'ts',
              Header: __('Created on'),
              Cell: cellInfo => formatDate(cellInfo.value),
            },
            {
              accessor: 'action',
              Header: __('Action'),
              Cell: (cellInfo) => {
                const { value } = cellInfo;
                const label = value.split('_').join(' ');
                return capitalizeFirstLetter(label);
              },
            },
            {
              accessor: 'license',
              Header: __('License ID'),
              sortable: false,
              Cell: cellInfo => displayValue(cellInfo.value),
            },
            {
              accessor: 'order',
              Header: __('Order ID'),
              sortable: false,
              Cell: cellInfo => displayValue(cellInfo.value),
            },
          ]}
          data={data}
          loading={isLoading}
          manual
          defaultSorted={currentSort}
          page={tablePage}
          pages={Math.ceil(get(data, 'count') / 20)}
          showPagination={dataCount > 5}
          minRows={2}
          onPageChange={handlePageChange}
          onSortedChange={handleSortChange}
          handleClick={rowData => handleRedirect(rowData)}
        />
      </div>
    </div>
  );
};

LicenseManagerReport.propTypes = {
  companyID: PropTypes.number.isRequired,
  manager: PropTypes.object.isRequired,
};

export default LicenseManagerReport;
