import React from 'react';
import moment from 'moment';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { BaseChart } from 'shared/components';
import { licenseAPIactions } from 'shared/constants';
import './styles.scss';

const AnalyticsTotal = React.memo(() => {
  const defaultFrom = moment().startOf('month');
  const defaultTo = moment().endOf('month');

  const from = useSelector(state => get(state, 'analytics.dateRange.date_from'));
  const to = useSelector(state => get(state, 'analytics.dateRange.date_to'));

  const companyID = useSelector(state => get(state, 'company.details.id'));

  const dateFrom = from ? moment(from) : defaultFrom;
  const dateTo = to ? moment(to) : defaultTo;

  return (
    <div className="AnalyticsTotal">
      <BaseChart
        companyID={companyID}
        dateFrom={dateFrom}
        dateTo={dateTo}
        chartID="license_api"
        label={__('License API')}
        title={__('Total License API calls')}
        displayTotal
      />
      <div className="individual-actions-charts">
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="license_check"
            label={__(get(licenseAPIactions, 'check.label'))}
            title={__(get(licenseAPIactions, 'check.label'))}
            subtitle={get(licenseAPIactions, 'check.endpoint')}
            displayTotal
            params="&action__in=check"
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="license_activation"
            label={__(get(licenseAPIactions, 'activation.label'))}
            title={__(get(licenseAPIactions, 'activation.label'))}
            subtitle={get(licenseAPIactions, 'activation.endpoint')}
            displayTotal
            params="&action__in=activation"
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="license_deactivation"
            label={__(get(licenseAPIactions, 'deactivation.label'))}
            title={__(get(licenseAPIactions, 'deactivation.label'))}
            subtitle={get(licenseAPIactions, 'deactivation.endpoint')}
            displayTotal
            params="&action__in=deactivation"
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="license_trial_key"
            label={__(get(licenseAPIactions, 'trial_key.label'))}
            title={__(get(licenseAPIactions, 'trial_key.label'))}
            subtitle={get(licenseAPIactions, 'trial_key.endpoint')}
            displayTotal
            params="&action__in=trial_key"
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="license_order"
            label={__(get(licenseAPIactions, 'order.label'))}
            title={__(get(licenseAPIactions, 'order.label'))}
            subtitle={get(licenseAPIactions, 'order.endpoint')}
            displayTotal
            params="&action__in=order"
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="license_issued"
            label={__(get(licenseAPIactions, 'license_issued.label'))}
            title={__(get(licenseAPIactions, 'license_issued.label'))}
            subtitle={get(licenseAPIactions, 'license_issued.endpoint')}
            displayTotal
            params="&action__in=license_issued"
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="license_keygen"
            label={__(get(licenseAPIactions, 'keygen.label'))}
            title={__(get(licenseAPIactions, 'keygen.label'))}
            subtitle={get(licenseAPIactions, 'keygen.endpoint')}
            displayTotal
            params="&action__in=keygen"
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="license_activation_offline"
            label={__(get(licenseAPIactions, 'activation_offline.label'))}
            title={__(get(licenseAPIactions, 'activation_offline.label'))}
            subtitle={get(licenseAPIactions, 'activation_offline.endpoint')}
            displayTotal
            params="&action__in=activation_offline"
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="license_deactivation_offline"
            label={__(get(licenseAPIactions, 'deactivation_offline.label'))}
            title={__(get(licenseAPIactions, 'deactivation_offline.label'))}
            subtitle={get(licenseAPIactions, 'deactivation_offline.endpoint')}
            displayTotal
            params="&action__in=deactivation_offline"
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="license_consumption"
            label={__(get(licenseAPIactions, 'consumption.label'))}
            title={__(get(licenseAPIactions, 'consumption.label'))}
            subtitle={get(licenseAPIactions, 'consumption.endpoint')}
            displayTotal
            params="&action__in=consumption"
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="license_f_consumption"
            label={__(get(licenseAPIactions, 'f_consumption.label'))}
            title={__(get(licenseAPIactions, 'f_consumption.label'))}
            subtitle={get(licenseAPIactions, 'f_consumption.endpoint')}
            displayTotal
            params="&action__in=f_consumption"
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="installation_file"
            label={__(get(licenseAPIactions, 'installation_file.label'))}
            title={__(get(licenseAPIactions, 'installation_file.label'))}
            subtitle={get(licenseAPIactions, 'installation_file.endpoint')}
            displayTotal
            params="&action__in=installation_file"
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="versions"
            label={__(get(licenseAPIactions, 'versions.label'))}
            title={__(get(licenseAPIactions, 'versions.label'))}
            subtitle={get(licenseAPIactions, 'versions.endpoint')}
            displayTotal
            params="&action__in=versions"
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="floating_release"
            label={__(get(licenseAPIactions, 'floating_release.label'))}
            title={__(get(licenseAPIactions, 'floating_release.label'))}
            subtitle={get(licenseAPIactions, 'floating_release.endpoint')}
            displayTotal
            params="&action__in=floating_release"
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="product_details"
            label={__(get(licenseAPIactions, 'product_details.label'))}
            title={__(get(licenseAPIactions, 'product_details.label'))}
            subtitle={get(licenseAPIactions, 'product_details.endpoint')}
            displayTotal
            params="&action__in=product_details"
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="get_device_variables"
            label={__(get(licenseAPIactions, 'get_device_variables.label'))}
            title={__(get(licenseAPIactions, 'get_device_variables.label'))}
            subtitle={get(licenseAPIactions, 'get_device_variables.endpoint')}
            displayTotal
            params="&action__in=get_device_variables"
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="track_device_variables"
            label={__(get(licenseAPIactions, 'track_device_variables.label'))}
            title={__(get(licenseAPIactions, 'track_device_variables.label'))}
            subtitle={get(licenseAPIactions, 'track_device_variables.endpoint')}
            displayTotal
            params="&action__in=track_device_variables"
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="change_password"
            label={__(get(licenseAPIactions, 'change_password.label'))}
            title={__(get(licenseAPIactions, 'change_password.label'))}
            subtitle={get(licenseAPIactions, 'change_password.endpoint')}
            displayTotal
            params="&action__in=change_password"
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="customer_license_users"
            label={__(get(licenseAPIactions, 'customer_license_users.label'))}
            title={__(get(licenseAPIactions, 'customer_license_users.label'))}
            subtitle={get(licenseAPIactions, 'customer_license_users.endpoint')}
            displayTotal
            params="&action__in=customer_license_users"
            height={200}
            type="line"
          />
        </div>
        <div className="individual-chart">
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID="single_sign_on"
            label={__(get(licenseAPIactions, 'single_sign_on.label'))}
            title={__(get(licenseAPIactions, 'single_sign_on.label'))}
            subtitle={get(licenseAPIactions, 'single_sign_on.endpoint')}
            displayTotal
            params="&action__in=single_sign_on"
            height={200}
            type="line"
          />
        </div>
      </div>
    </div>
  );
});

export default AnalyticsTotal;
