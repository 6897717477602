import React from 'react';
import PropTypes from 'prop-types';
import { IconInfo, Tooltip } from 'shared/components';
import './styles.scss';

const Label = ({
  description,
  inputId,
  text,
}) => (
  <div className="Label">
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label htmlFor={inputId} className="Label-elem">{text}</label>
    {description && (
      <Tooltip content={description} active>
        <span><IconInfo height="12px" width="12px" /></span>
      </Tooltip>
    )
    }
  </div>
);

/* eslint-disable react/require-default-props */
Label.propTypes = {
  inputId: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};
/* eslint-enable react/require-default-props */

Label.defaultProps = {
  description: undefined,
  inputId: 'placeholderID',
  text: 'Label',
};

export default Label;
