import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { sendErrorReport } from 'shared/helpers';
import {
  Checkbox,
  CheckboxSelector,
  DirtyFormAlert,
  EventStatus,
  // InputErrorMessage,
  // MultiEmailInput,
  Label,
  Modal,
  Notification,
} from 'shared/components';
import {
  eventsConfig,
  // errorMsg,
} from 'shared/constants';
import { resendNotification } from 'src/notifications/actions';
import './styles.scss';

const mapRecipients = (list = []) => {
  if (!Array.isArray(list)) {
    return [];
  }

  return list.map(r => ({
    value: r.email,
    label: r.email,
    data: r,
  }));
};

const ResendForm = ({
  notification,
  closeCb,
  confirmCb,
  companyID,
}) => {
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormDisplay] = useState(false);
  const [sendToAll, setSendToAll] = useState(true);
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  // const [customEmails, setCustomEmails] = useState({
  //   emailError: '',
  //   value: '',
  //   emails: [],
  // });

  const notificationID = get(notification, 'id');
  const eventCode = get(notification, 'event.code');
  const eventName = eventsConfig[eventCode] ? get(eventsConfig, `${eventCode}.label`) : __('notification');
  const isError = get(notification, 'result.status') === 'failed';
  const recipients = isError ? [] : mapRecipients(get(notification, 'result.recipients_data'));

  const handleSendToAll = (val) => {
    if (!val) {
      setDirty(true);
      setSelectedRecipients([]);
      setSendToAll(val);
    } else {
      setDirty(true);
      setSendToAll(val);
    }
  };

  // const handleEmailSubmit = (val) => {
  //   setDirty(true);
  //   setCustomEmails(val);
  // };

  // const validateCustomEmails = () => {
  //   if (customEmails.value) {
  //     setCustomEmails({
  //       ...customEmails,
  //       validationError: `${get(errorMsg, 'unsubmittedEmail')} (${get(customEmails, 'value')})`,
  //     });
  //     return false;
  //   }
  //   return true;
  // };

  const isValidData = () => {
    if (isError || sendToAll) {
      return true;
    }
    return !!selectedRecipients.length;
  };

  const isFormValid = () => {
    const areRecipientsValid = isValidData();
    // const isCustomDataValid = validateCustomEmails();
    // return areRecipientsValid && isCustomDataValid;
    return areRecipientsValid;
  };

  const mapRecipientsToData = () => {
    const list = [];
    selectedRecipients.forEach(sr => list.push({ email: sr.value }));
    // customEmails.emails.forEach(ce => list.push({ email: ce }));
    return list;
  };

  const handleSubmit = () => {
    const isValid = isFormValid();
    if (loading || !isValid) {
      return false;
    }

    setLoading(true);

    const data = {
      recipients_data: sendToAll ? [] : mapRecipientsToData(),
    };

    resendNotification(notificationID, companyID, data)
      .then(() => {
        Notification('success', __('Notification was resent successfully'));
        confirmCb();
      })
      .catch((err) => {
        sendErrorReport(err, 'Resending notification failed');
        setLoading(false);
        Notification('error', __('Error occured'), __('Notification was not resent'));
      });
    return true;
  };

  const handleClose = () => {
    if (!dirty) { return closeCb(); }
    return setDirtyFormDisplay(true);
  };

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={loading}
      title={__('Resend notification')}
      size="sm"
    >
      <div className="ResendForm">
        <div className="ResendForm-eventName">
          <EventStatus status={isError ? 'failed' : 'success'} text={eventName} />
        </div>
        {isError ? (
          <div className="ResendForm-errorNotice">
            <span>{__('Previous notification failed.')}</span>
            <span>{__('This will try to resend the notification to all recipients defined with notification policy for the event.')}</span>
          </div>
        ) : (
          <div className="ResendForm-main">
            <div className="row">
              <Checkbox
                label={__('Send to all recipients')}
                checked={sendToAll}
                inputId="send-all-checkbox"
                handleChange={handleSendToAll}
              />
            </div>
            <div className="row">
              <Label text={__('Select recipients')} inputId="recipients-selector" />
              <CheckboxSelector
                text={`${sendToAll ? recipients.length : selectedRecipients.length} ${__('selected recipients')}`}
                // text={sendToAll ? '' : `${selectedRecipients.length} ${__('selected recipients')}`}
                options={recipients}
                value={selectedRecipients}
                onChangeCallback={val => setSelectedRecipients(val)}
                onMenuClose={() => { }}
                disabled={sendToAll}
              />
            </div>
            {/* <div className="row">
              <Label text={__('Additional recipients')} inputId="emails-input" />
              <MultiEmailInput
                emails={get(customEmails, 'emails')}
                onEmailSubmit={val => handleEmailSubmit(val)}
                isDisabled={sendToAll || loading}
              />
              <InputErrorMessage text={get(customEmails, 'validationError') || ''} />
            </div> */}
          </div>
        )}
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

ResendForm.propTypes = {
  companyID: PropTypes.number.isRequired,
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  notification: PropTypes.object.isRequired,
};

export default ResendForm;
