import React, { useState } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  DescriptionTable,
  Notice,
  Notification,
  PermissionMissingNotificationTitle,
  Page,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
} from 'shared/components';
import { userPermissions } from 'shared/constants';
import { checkUserPermission } from 'shared/helpers';
import { changeCardData, getCompanySuccess } from 'src/company/actions';
import {
  BillingDetails,
  InvoicesList,
  PaymentDetails,
  PaymentMethod,
  PaymentMethodForm,
} from './components';
import './styles.scss';

const BillingContainer = () => {
  const canManageBilling = checkUserPermission(userPermissions.billing_write);
  const dispatch = useDispatch();
  const cardType = useSelector(state => get(state, 'company.details.payment_card_type'));
  const companyDetails = useSelector(state => get(state, 'company.details'));
  const companyID = useSelector(state => get(state, 'company.details.id'));
  const invoices = useSelector(state => get(state, 'account.invoices') || []);
  const hasUnpaidInvoices = invoices.some(i => i.status === 'open' || i.status === 'uncollectible');
  const taxRates = useSelector(state => get(state, 'billing.taxRates'));

  const isPaymentManual = get(companyDetails, 'is_payment_manual');

  const [isBillingPopupDisplayed, setBillingPopupDisplay] = useState(false);

  const mainActionTitle = cardType ? __('Change payment method') : __('Add payment method');

  const handleManageBillingClick = (cb) => {
    if (!canManageBilling) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.billing_write} />,
        __('Contact you account admin for support.'),
      );
      return false;
    }
    cb(true);
    return true;
  };

  return (
    <Page title={__('Billing')}>
      <div className="BillingContainer">
        <Tabs>
          <TabsHeader>
            <Tab>{__('Payment method')}</Tab>
            <Tab>{__('Invoices')}</Tab>
          </TabsHeader>
          <TabContent>
            <div>
              {isPaymentManual ? (
                <div className="manual-payment">
                  <DescriptionTable
                    details={[
                      {
                        label: __('Payment method'),
                        value: __('Bank transfer'),
                      },
                    ]}
                  />
                </div>
              ) : (
                <div>
                  <div className="BillingContainer-actions">
                    <Button theme="info" onClick={() => handleManageBillingClick(setBillingPopupDisplay)}>
                      {mainActionTitle}
                    </Button>
                  </div>
                  {hasUnpaidInvoices &&
                    cardType && (
                      <div className="BillingContainer-notice">
                        <Notice theme="error" title={__('Your payment didn’t go through.')}>
                          {__('Please make sure you have enough money on your account, change to another payment method or attempt payment again')}
                        </Notice>
                      </div>
                  )}
                  <PaymentMethod />
                </div>
              )}
              <div className="BillingContainer-info">
                <PaymentDetails />
                <BillingDetails
                  companyDetails={companyDetails}
                />
              </div>
            </div>
          </TabContent>
          <TabContent>
            <InvoicesList />
          </TabContent>
        </Tabs>
      </div>
      {isBillingPopupDisplayed && (
        <PaymentMethodForm
          closeCb={() => setBillingPopupDisplay(false)}
          refreshPaymentMethod={data => dispatch(changeCardData(data))}
          refreshCompany={data => dispatch(getCompanySuccess(data))}
          title={mainActionTitle}
          companyId={companyID}
          company={companyDetails}
          showCompanyInputs={!get(companyDetails, 'stripe_has_subscription')}
          taxRates={taxRates}
        />
      )}
    </Page>
  );
};

export default BillingContainer;
