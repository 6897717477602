import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import {
  Page,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
} from 'shared/components';
import { getCompanySilent } from 'src/company/actions';
import PlanTypeContainer from '../components/PlanTypeContainer';
import EnterpriseSubscription from '../components/EnterpriseSubscription';
import StandardSubscription from '../components/StandardSubscription';
import StandardTrial from '../components/StandardTrial';
import EnterpriseTrial from '../components/EnterpriseTrial';
import CompanyFeatures from '../components/CompanyFeatures';
import Settings from '../components/Settings';

const CompanySettings = ({
  companyDetails,
  planType,
}) => {
  const isSelfHosted = process.env.REACT_APP_SELF_HOSTED === 'true';
  const dispatch = useDispatch();
  const hasSubscription = get(companyDetails, 'stripe_has_subscription');
  const companyID = get(companyDetails, 'id');

  const coupons = useSelector(state => get(state, 'billing.coupons'));
  const pricingPlans = useSelector(state => get(state, 'billing.pricingPlans'));
  const standardPricingPlans = useSelector(state => get(state, 'billing.standardPricingPlans'));
  const taxRates = useSelector(state => get(state, 'billing.taxRates'));
  const companyFeatures = useSelector(state => get(state, 'company.features'));


  return (
    <Page title={__('Company settings')}>
      <div className="CompanySettings">
        <Tabs>
          <TabsHeader>
            {!isSelfHosted && (
              <Tab>{__('Billing')}</Tab>
            )}
            <Tab>{__('Features')}</Tab>
            <Tab>{__('Settings')}</Tab>
          </TabsHeader>
          {!isSelfHosted && (
          <TabContent>
            <div className="CompanySettings-billing">
              <PlanTypeContainer
                companyDetails={companyDetails}
                hasSubscription={hasSubscription}
              />
              {planType === 'enterprise' && !hasSubscription && (
              <EnterpriseTrial companyDetails={companyDetails} />
              )}
              {planType === 'enterprise' && (
              <EnterpriseSubscription
                companyDetails={companyDetails}
                coupons={coupons}
                dispatchCompanyChange={() => dispatch(getCompanySilent(companyID))}
                hasSubscription={hasSubscription}
                pricingPlans={pricingPlans}
                taxRates={taxRates}
              />
              )}
              {planType === 'standard' && hasSubscription && (
              <StandardSubscription
                companyDetails={companyDetails}
                coupons={coupons}
                dispatchCompanyChange={() => dispatch(getCompanySilent(companyID))}
                hasSubscription={hasSubscription}
                standardPricingPlans={standardPricingPlans}
                taxRates={taxRates}
              />
              )}
              {planType === 'standard' && !hasSubscription && (
              <StandardTrial
                companyDetails={companyDetails}
                coupons={coupons}
                standardPricingPlans={standardPricingPlans}
                taxRates={taxRates}
              />
              )}
            </div>
          </TabContent>
          )}
          <TabContent>
            <CompanyFeatures companyDetails={companyDetails} features={companyFeatures} />
          </TabContent>
          <TabContent>
            <Settings companyDetails={companyDetails} />
          </TabContent>
        </Tabs>
      </div>
    </Page>
  );
};

CompanySettings.propTypes = {
  companyDetails: PropTypes.object.isRequired,
  planType: PropTypes.string.isRequired,
};

export default CompanySettings;
