import React from 'react';
import PropTypes from 'prop-types';
import { isString } from 'lodash';
import Table from 'react-table';
import 'react-table/react-table.css';
import './styles.scss';

const List = ({
  clickable,
  columns,
  data,
  handleClick,
  resizable,
  ...rest
}) => (
  <div className={`List ${clickable ? 'List-clickable' : ''}`}>
    <Table
      columns={columns}
      data={data}
      defaultPageSize={20}
      getTrProps={(state, rowInfo) => ({
        onClick: () => handleClick(rowInfo),
      })
      }
      getTdProps={() => ({
        style: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        },
        onClick: (e, handleOriginal) => {
          const targetClassName = e.target.className;
          let isExpanderClicked;
          if (isString(targetClassName)) {
            isExpanderClicked = targetClassName.includes('rt-expander');
          } else {
            isExpanderClicked = false;
          }

          if (handleOriginal) {
            if (isExpanderClicked) {
              handleOriginal();
              e.preventDefault();
              e.stopPropagation();
            }
          }
        },
      })
      }
      resizable={resizable}
      showPageSizeOptions={false}
      previousText={__('Previous')}
      nextText={__('Next')}
      loadingText={__('Loading')}
      noDataText={__('Search returned 0 results')}
      pageText={__('Page')}
      ofText={__('of')}
      rowsText={__('rows')}
      {...rest}
    />
  </div>
);

List.propTypes = {
  clickable: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
  resizable: PropTypes.bool,
};

List.defaultProps = {
  clickable: false,
  handleClick: () => { },
  resizable: true,
};

export default List;
