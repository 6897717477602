import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  Button,
  ConfirmationPopup,
  DescriptionTable,
  IconDelete,
  IconEdit,
  List,
  Notification,
  PermissionMissingNotificationTitle,
  Switcher,
} from 'shared/components';
import {
  defaultDateFormat,
  environmentOptions,
  platformFeatures,
  userPermissions,
} from 'shared/constants';
import {
  displayValue,
  formatDate,
  getDisabledMessage,
  isFeatureEnabled,
  isFeatureAvailable,
  sendErrorReport,
  checkUserPermission,
} from 'shared/helpers';
import { deleteVersion } from 'src/product/actions';

const Versions = ({
  companyID,
  versions,
  handleVersionStatusChange,
  showNewVersionForm,
  showEditVersionForm,
  isLoading,
  refetchProduct,
}) => {
  const canManageProducts = checkUserPermission(userPermissions.products_write);
  const [tableExpanded, setTableExpanded] = useState({});
  const [versionToDelete, setVersionToDelete] = useState(null);
  const [isVersionDeleteConfirmationDisplayed, setVersionDeleteConfirmationDisplayed] = useState(false);
  const [versionDeleteLoading, setVersionDeleteLoading] = useState(false);

  const handleVersionDelete = () => {
    const versionID = get(versionToDelete, 'id');
    setVersionDeleteLoading(true);

    deleteVersion(companyID, versionID)
      .then(() => {
        setVersionDeleteLoading(false);
        setVersionToDelete(null);
        setVersionDeleteConfirmationDisplayed(false);
        refetchProduct();
        Notification('success', __('Product version deleted successfully'));
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot delete product version');
        setVersionDeleteLoading(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
  };

  const handleManageVersionClick = (cb) => {
    if (!canManageProducts) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.products_write} />,
        __('Contact you account admin for support.'),
      );
      return false;
    }
    cb(true);
    return true;
  };

  return (
    <div className="Versions versions-tab">
      <Button
        featureAvailable={isFeatureAvailable(platformFeatures.deployment_product_versioning)}
        notAvailableMessage={__('You may only have a single version of your product in your current plan.')}
        ctaText={__('Upgrade for product versioning.')}
        featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_product)}
        notEnabledMessage={getDisabledMessage()}
        onClick={() => handleManageVersionClick(showNewVersionForm)}
        size="sm"
        theme="info"
      >
        {__('Add new version')}
      </Button>
      <List
        onExpandedChange={expanded => setTableExpanded(expanded)}
        expanded={tableExpanded}
        SubComponent={row => (
          <div className="SubComponent">
            <DescriptionTable
              details={[
                {
                  label: __('Created at'),
                  value: formatDate(get(row, 'original.created_at'), defaultDateFormat),
                },
                {
                  label: __('Updated at'),
                  value: formatDate(get(row, 'original.updated_at'), defaultDateFormat),
                },
                {
                  label: __('Link to file'),
                  value: displayValue(get(row, 'original.full_link')),
                },
                {
                  label: __('File hash (MD5)'),
                  value: displayValue(get(row, 'original.hash_md5')),
                },
                {
                  label: __('File size (in bytes)'),
                  value: displayValue(get(row, 'original.size')),
                },
                {
                  label: __('Link to release notes'),
                  value: displayValue(get(row, 'original.release_notes_link')),
                },
                {
                  label: __('Link to EULA'),
                  value: displayValue(get(row, 'original.eula_link')),
                },
                {
                  label: __('Requires version'),
                  value: displayValue(get(row, 'original.requires_version')),
                },
                {
                  label: __('Channel'),
                  value: displayValue(get(row, 'original.channel')),
                },
              ]}
            />
          </div>
        )}
        columns={[
          {
            expander: true,
            Header: __('Details'),
            headerClassName: 'text-center',
            width: 80,
            style: {
              fontSize: 25,
              padding: '0',
              textAlign: 'center',
              userSelect: 'none',
            },
          },
          { accessor: 'version', Header: __('Version') },
          {
            accessor: 'environment',
            Header: __('Environment'),
            Cell: (cellData) => {
              if (!get(cellData, 'value')) { return '-'; }
              const env = environmentOptions.find(o => o.value === cellData.value);
              return displayValue(get(env, 'label'));
            },
          },
          {
            accessor: 'release_date',
            Header: __('Release Date'),
            Cell: cellData => formatDate(cellData.value, defaultDateFormat),
          },
          {
            accessor: 'filename',
            Header: __('Filename'),
            Cell: cellData => displayValue(cellData.value),
          },
          {
            accessor: 'enabled',
            Header: __('Enabled'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: cellData => (
              <Switcher
                isFeatureEnabled={isFeatureEnabled(platformFeatures.platform_edit_product)}
                notEnabledMessage={getDisabledMessage()}
                checked={cellData.value}
                handleChange={() => handleManageVersionClick(() => handleVersionStatusChange(cellData.original, !cellData.value))}
                size="sm"
              />
            ),
            maxWidth: 120,
          },
          {
            Cell: rowData => (
              <Button
                featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_product)}
                notEnabledMessage={getDisabledMessage()}
                className="edit-button"
                onClick={() => handleManageVersionClick(() => showEditVersionForm(rowData.original))}
                type="button"
              >
                <IconEdit height="16px" width="16px" />
              </Button>
            ),
            className: 'text-center',
            headerClassName: 'text-center',
            maxWidth: 50,
          },
          {
            headerClassName: 'text-center',
            className: 'text-center',
            id: 'delete',
            maxWidth: 50,
            sortable: false,
            Cell: rowData => (
              <Button
                className="edit-button"
                onClick={() => handleManageVersionClick(() => {
                  setVersionToDelete(rowData.original);
                  setVersionDeleteConfirmationDisplayed(true);
                })}
                type="button"
              >
                <IconDelete height="16px" width="16px" color="#ee5253" />
              </Button>
            ),
          },
        ]}
        data={versions}
        minRows={2}
        pageSize={20}
        loading={isLoading}
      />
      {isVersionDeleteConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setVersionToDelete(null);
            setVersionDeleteConfirmationDisplayed(false);
          }}
          confirmCb={handleVersionDelete}
          title={`${__('Are you sure you want to delete this product version')}?`}
          confirmText={__('Delete')}
          theme="error"
          disabled={versionDeleteLoading}
        >
          <span style={{ fontSize: '14px', wordBreak: 'break-all' }}>{get(versionToDelete, 'version')}</span>
        </ConfirmationPopup>
      )}
    </div>
  );
};

Versions.propTypes = {
  versions: PropTypes.array,
  handleVersionStatusChange: PropTypes.func.isRequired,
  showNewVersionForm: PropTypes.func.isRequired,
  showEditVersionForm: PropTypes.func.isRequired,
  refetchProduct: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  companyID: PropTypes.number.isRequired,
};

Versions.defaultProps = {
  versions: [],
};

export default Versions;
