import React from 'react';
import PropTypes from 'prop-types';
import {
  IconLaptop,
  IconTablet,
  IconMobile,
  IconUsb,
} from 'shared/components';

const DeviceIcon = ({
  device,
}) => {
  if (device === 'desktop') {
    return <IconLaptop />;
  }
  if (device === 'tablet') {
    return <IconTablet />;
  }
  if (device === 'mobile') {
    return <IconMobile />;
  }
  if (device === 'hardware_key') {
    return <IconUsb color="#030303" />;
  }
  return '-';
};

DeviceIcon.propTypes = {
  device: PropTypes.string,
};

DeviceIcon.defaultProps = {
  device: null,
};

export default DeviceIcon;
