import React from 'react';
import {
  IconInfo,
  Tooltip,
} from 'shared/components';

const TooltipHeader = (label, content) => (
  <div className="tooltip-header">
    {label}
    <Tooltip
      content={content || __('This license will no longer work on this date at 00:00 UTC')}
      active
    >
      <span><IconInfo height="12px" width="12px" /></span>
    </Tooltip>
  </div>
);


export default TooltipHeader;
