/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { parseHTML } from 'shared/helpers';
import LogoLS from './logo.png';
import './styles.scss';

const EmailPreview = ({
  // eslint-disable-next-line no-unused-vars
  policy,
  subject,
  title,
  text,
  accentColor,
  variables,
  userPortalVariables,
  licensesVariables,
}) => {
  const logo = useSelector(state => get(state, 'company.details.logo'));
  const keyLicensesVariables = licensesVariables.filter(lv => lv.value !== 'custom_fields' && lv.value !== 'features');
  const customFields = licensesVariables.find(lv => lv.value === 'custom_fields');
  const productFeatures = licensesVariables.find(lv => lv.value === 'features');

  return (
    <div className="EmailPreview">
      <div className="EmailPreview-subject">
        <div className="label">{`${__('Subject')}:`}</div>
        <div className="value">{subject}</div>
      </div>
      <div className="EmailPreview-main">
        <div className="Logo">
          <img src={logo || LogoLS} alt="company logo" />
        </div>
        <div className="Title">{title}</div>
        <div className="Text" dangerouslySetInnerHTML={parseHTML(text)} />
        {variables.length ? (
          <div className="Variables">
            {variables.map((v) => {
              const customFieldsVar = v.value === 'custom_fields';
              const productFeaturesVar = v.value === 'features';

              if (customFieldsVar) {
                return (
                  <div className="variable-item">
                    <div className="license-custom-fields">
                      <div className="license-custom-fields-title">{`${__('Custom fields')}:`}</div>
                      <div className="custom-field-item">
                        <span className="custom-field-name">{__('Name')}</span>
                        <span className="custom-field-divider">:</span>
                        <span className="custom-field-value">{__('Value')}</span>
                      </div>
                    </div>
                  </div>
                );
              }

              if (productFeaturesVar) {
                return (
                  <div className="variable-item">
                    <div className="license-custom-fields">
                      <div className="license-custom-fields-title">{`${__('Product features')}:`}</div>
                      <div className="custom-field-item">
                        <span className="custom-field-name">{__('Feature 1, Feature 2')}</span>
                      </div>
                    </div>
                  </div>
                );
              }

              return (
                <div className="variable-item" key={v.value}>
                  <div className="value">-</div>
                  <div className="label">{v.label}</div>
                </div>
              );
            })}
          </div>
        ) : null}
        {userPortalVariables.length ? (
          <div className="UserPortalCredentials" style={{ borderTopColor: accentColor }}>
            <div className="section-title">
              {__('User portal credentials')}
            </div>
            {userPortalVariables.map(up => (
              <div className="variable-item" key={up.value}>
                <div className="value">-</div>
                <div className="label">{up.label}</div>
              </div>
            ))}
          </div>
        ) : null}
        {licensesVariables.length ? (
          <div className="LicensesList" style={{ borderTopColor: accentColor }}>
            <div className="section-title">
              {__('Licenses')}
            </div>
            <div className="license">
              {keyLicensesVariables.map(lp => (
                <div className="license-item-wrapper" key={lp.value}>
                  <div className="license-item">
                    <div className="value">-</div>
                    <div className="label">{lp.label}</div>
                  </div>
                </div>
              ))}
              {customFields ? (
                <div className="license-custom-fields">
                  <div className="license-custom-fields-title">{`${__('Custom fields')}:`}</div>
                  <div className="custom-field-item">
                    <span className="custom-field-name">{__('Name')}</span>
                    <span className="custom-field-divider">:</span>
                    <span className="custom-field-value">{__('Value')}</span>
                  </div>
                </div>
              ) : null}
              {productFeatures ? (
                <div className="license-custom-fields">
                  <div className="license-custom-fields-title">{`${__('Product features')}:`}</div>
                  <div className="custom-field-item">
                    <span className="custom-field-name">{__('Feature 1, Feature 2')}</span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

EmailPreview.propTypes = {
  policy: PropTypes.object.isRequired,
  subject: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  accentColor: PropTypes.string,
  variables: PropTypes.array,
  userPortalVariables: PropTypes.array,
  licensesVariables: PropTypes.array,
};

EmailPreview.defaultProps = {
  subject: '',
  title: '',
  text: '',
  accentColor: '#47c6ef',
  variables: [],
  userPortalVariables: [],
  licensesVariables: [],
};

export default EmailPreview;
