import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { Tooltip, Spinner } from 'shared/components';
import Option from './components/Option';
import './styles.scss';

const Selector = ({
  containerStyle,
  disabled,
  featureAvailable,
  featureEnabled,
  handleChange,
  loading,
  maxWidth,
  multi,
  notAvailableMessage,
  notEnabledMessage,
  options,
  value,
  valueKey,
  ...props
}) => {
  let valueForSelect = null;
  if (value) {
    valueForSelect = !multi ? options.find(o => o[valueKey] === value) : value;
  }

  if (!featureAvailable) {
    return (
      <Tooltip
        active={!featureAvailable}
        content={(
          <div className="feature-unavailable-tooltip">
            {notAvailableMessage}
          </div>
        )}
      >
        <div
          className="Selector"
          style={{
            maxWidth,
            ...containerStyle,
          }}
        >
          <Select
            className="selector-container"
            classNamePrefix="selector-inner"
            components={{ Option }}
            isDisabled={disabled || !featureAvailable}
            isMulti={multi}
            onChange={option => handleChange(option[valueKey])}
            options={options}
            value={valueForSelect}
            {...props}
          />
          { loading && <Spinner />}
        </div>
      </Tooltip>
    );
  }

  return (
    <div
      className="Selector"
      style={{
        maxWidth,
        ...containerStyle,
      }}
    >
      <Select
        className="selector-container"
        classNamePrefix="selector-inner"
        components={{ Option }}
        isDisabled={disabled}
        isMulti={multi}
        onChange={option => handleChange(option[valueKey])}
        options={options}
        value={valueForSelect}
        {...props}
      />
      {loading && <Spinner />}
    </div>
  );
};

Selector.propTypes = {
  /** Is selector disabled */
  disabled: PropTypes.bool,
  /** Callback for handling selector change */
  handleChange: PropTypes.func,
  /** Selector shows loading indicator in loading state */
  loading: PropTypes.bool,
  /** Max width that selector container can have */
  maxWidth: PropTypes.string,
  /** Can select multiple values */
  multi: PropTypes.bool,
  /** Data for the selector */
  options: PropTypes.array.isRequired,
  /** Optional styling object */
  style: PropTypes.object,
  /** Value of the selector */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number]),
  featureAvailable: PropTypes.bool,
  notAvailableMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  featureEnabled: PropTypes.bool,
  notEnabledMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  containerStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  valueKey: PropTypes.string,
};

Selector.defaultProps = {
  disabled: false,
  handleChange: () => {},
  loading: false,
  maxWidth: 'auto',
  multi: false,
  style: {},
  value: undefined,
  featureAvailable: true,
  notAvailableMessage: 'This feature is not available in your plan type.',
  featureEnabled: true,
  notEnabledMessage: '',
  valueKey: 'value',
  containerStyle: '',
};

export default Selector;
