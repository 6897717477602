import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  IconEdit,
  List,
  TextInput,
  Notice,
} from 'shared/components';
import { formatCustomFieldsToTable } from './helpers';
import './styles.scss';

const CustomFieldsEditableTable = ({
  selectedCustomFields,
  setErrorState,
  handleFieldsEdit,
}) => {
  const [hasErrors, setHasErrors] = useState(false);
  const [cFields, setCFields] = useState(formatCustomFieldsToTable(selectedCustomFields));

  useEffect(() => {
    const newCFields = formatCustomFieldsToTable(selectedCustomFields);
    const errors = newCFields.some(cf => cf.cf_value_error);
    setCFields(newCFields);
    setErrorState(errors);
    setHasErrors(errors);
  }, [selectedCustomFields]);

  const submitChange = (newCFields) => {
    setCFields(newCFields);
    const errors = newCFields.some(cf => cf.cf_value_error);
    if (errors) {
      setHasErrors(true);
      setErrorState(true);
      return false;
    }
    setHasErrors(false);
    setErrorState(false);
    handleFieldsEdit(newCFields);
    return true;
  };

  const handleValueChange = (cellData, val) => {
    const fieldID = get(cellData, 'original.value');
    const updatedFields = cFields.map((cf) => {
      if (cf.value === fieldID) {
        const isError = !val ? 'error' : '';
        const newCF = {
          ...cf,
          cf_value: val,
          cf_value_error: isError,
        };
        return newCF;
      }
      return cf;
    });
    submitChange(updatedFields);
  };

  const renderValueEditable = (cellData) => {
    const field = cFields.find(cf => cf.value === cellData.original.value);
    const cellValue = get(field, 'cf_value');
    return (
      <TextInput
        defaultValue={cellValue}
        error={cellData.original.cf_value_error}
        disabled={hasErrors && !cellData.original.cf_value_error}
        handleChange={() => { }}
        onBlur={e => handleValueChange(cellData, e.target.value)}
      />
    );
  };

  return (
    <div className="CustomFieldsEditableTable">
      {hasErrors && (
        <Notice size="sm" theme="error">{__('Fix erorrs to continue saving the edits')}</Notice>
      )}
      <List
        columns={[
          { accessor: 'name', Header: __('Name') },
          {
            accessor: 'cf_value',
            Header: () => (
              <div className="custom-header">
                {__('Value')}
                <IconEdit height="12px" width="12px" fill="#909090" />
              </div>
            ),
            headerClassName: 'editable-column',
            Cell: cellData => renderValueEditable(cellData),
          },
        ]}
        data={cFields}
        clickable={false}
        showPagination={false}
        minRows={2}
      />
    </div>
  );
};

CustomFieldsEditableTable.propTypes = {
  selectedCustomFields: PropTypes.array,
  setErrorState: PropTypes.func.isRequired,
  handleFieldsEdit: PropTypes.func.isRequired,
};

CustomFieldsEditableTable.defaultProps = {
  selectedCustomFields: [],
};

export default CustomFieldsEditableTable;
