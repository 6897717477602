import React from 'react';
import Base from './Base';

export default props => (
  <Base {...props}>
    <path
      d="M81.944,16.668C74.999,16.668,62.5,15.001,50,5C40,15,26.667,16.668,18.889,16.668  C15,16.668,12.5,16.25,12.5,16.25v21.475C12.5,82.725,50,95,50,95s37.5-12.275,37.5-57.275V16.25  C87.5,16.25,85.417,16.668,81.944,16.668z M45.313,65L29.375,48.125l4.688-5.625l11.25,7.969L68.75,33.125l4.219,3.75L45.313,65z"
    />
  </Base>
);
