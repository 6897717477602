import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  Button,
  ContentLoader,
  DirtyFormAlert,
  Label,
  Notice,
  Selector,
  SlidePane,
} from 'shared/components';
import {
  mapProductsToSelector,
  sendErrorReport,
  formatPolicies,
} from 'shared/helpers';
import { getProductsInBundle } from 'src/product/actions';
import PolicySelector from './PolicySelector';
import './styles.scss';

const SelectBundleForm = ({
  closeCb,
  handleSubmit,
  isOpen,
  bundleToEdit,
  title,
  width,
}) => {
  const isBundleChanged = useRef(false);
  const products = useSelector(state => get(state, 'products.list'));
  const companyID = useSelector(state => get(state, 'company.details.id'));
  const bundles = products.filter(p => p.is_bundle);
  const bundleOptions = mapProductsToSelector(bundles);

  const initialSelectedPolicies = bundleToEdit ? get(bundleToEdit, 'selected_bundle_policies') : [];

  const [isDirty, setDirty] = useState(false);
  const [isDirtyFormDisplayed, setDirtyFormDisplay] = useState(false);
  const [selectedBundle, setSelectedBundle] = useState(get(bundleToEdit, 'bundle') || bundleOptions[0]);
  const [productsInBundleLoading, setProductsInBundleLoading] = useState(true);
  const [productsInBundle, setProductsInBundle] = useState([]);
  const [selectedProductPolicies, setSelectedProductPolicies] = useState(initialSelectedPolicies);

  const selectedBundleID = get(selectedBundle, 'data.id');

  const setInitialState = (items = []) => {
    const productsList = [];
    const policiesList = [];
    items.forEach((i) => {
      const pr = products.find(p => p.id === i.id);
      const policies = get(pr, 'license_templates') || [];
      const mappedPolicies = formatPolicies(policies);
      pr.license_templates = mappedPolicies;
      productsList.push(pr);
      policiesList.push(mappedPolicies[0]);
    });
    setProductsInBundle(productsList);
    if (!bundleToEdit || isBundleChanged.current) {
      setSelectedProductPolicies(policiesList);
    }
    setProductsInBundleLoading(false);
  };

  const handlePolicySelect = (policy) => {
    const policyProductID = get(policy, 'product');
    const filtered = selectedProductPolicies.filter(spp => spp.product !== policyProductID);
    setSelectedProductPolicies([...filtered, policy]);
  };

  const getBundledProducts = useCallback(() => {
    setProductsInBundleLoading(true);
    getProductsInBundle(selectedBundleID, companyID)
      .then((res) => {
        const data = get(res, 'data') || [];
        setInitialState(data);
      })
      .catch((err) => {
        setProductsInBundleLoading(false);
        sendErrorReport(err, 'Cannot get bundled products');
      });
  }, [selectedBundleID]);

  useEffect(() => {
    getBundledProducts();
  }, [getBundledProducts]);

  const handleBundleSelect = (val) => {
    const selectedB = bundleOptions.find(b => get(b, 'data.product_name') === val);
    setDirty(true);
    isBundleChanged.current = true;
    setSelectedBundle(selectedB);
  };

  const handleBundleSubmit = () => {
    handleSubmit({
      bundle: selectedBundle,
      selected_bundle_policies: selectedProductPolicies,
    });
  };

  const handleClose = () => {
    if (isDirty) {
      setDirtyFormDisplay(true);
    } else {
      closeCb();
    }
  };

  const getTitle = () => (
    <div className="SelectBundleFormTitle">
      <div className="title">{title}</div>
      <div className="confirm-btn">
        <Button
          theme="success"
          onClick={handleBundleSubmit}
          disabled={productsInBundleLoading || !productsInBundle.length}
        >
          {__('Confirm')}
        </Button>
      </div>
    </div>
  );

  const isBundleProductCreated = bundles && Array.isArray(bundles) && bundles.length > 0;

  return (
    <SlidePane
      closeCb={handleClose}
      isOpen={isOpen}
      title={getTitle()}
      width={width}
    >
      <div className="SelectBundleForm">
        <div className="section-row">
          <Label inputId="bundle-select" text={__('Bundle Name')} />
          <Selector
            options={bundleOptions}
            handleChange={handleBundleSelect}
            value={get(selectedBundle, 'value')}
            inputId="bundle-select"
            disabled={productsInBundleLoading}
          />
        </div>
        {!isBundleProductCreated && (
          <Notice theme="error" size="sm" title={__('Bundle products are not defined')}>
            <div className="bundle-prod-warning">
              <span>{__('Configure product bundle before issuing licenses')}</span>
              <NavLink to={`/${companyID}/products`}>{__('here')}</NavLink>
            </div>
          </Notice>
        )}
        <div className="section-row">
          {productsInBundleLoading ? (
            <div className="bundle-products-loading">
              <ContentLoader />
            </div>
          ) : (
            <div className="bundle-products-list">
              <div className="bundle-products-heading">
                {__('Products in a bundle')}
              </div>
              {productsInBundle.map(p => (
                <PolicySelector
                  key={p.id}
                  product={p}
                  selectedPolicies={selectedProductPolicies}
                  handlePolicySelect={handlePolicySelect}
                />
              ))}
            </div>
          )}
        </div>
        {isDirtyFormDisplayed && (
          <DirtyFormAlert
            dirty={isDirty}
            closeAlert={() => setDirtyFormDisplay(false)}
            closeCb={closeCb}
          />
        )}
      </div>
    </SlidePane>
  );
};


SelectBundleForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  bundleToEdit: PropTypes.object,
  title: PropTypes.string.isRequired,
  width: PropTypes.string,
};

SelectBundleForm.defaultProps = {
  bundleToEdit: null,
  width: '80%',
};

export default SelectBundleForm;

// bundleToEdit ->
