import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  Button,
  DescriptionTable,
  Label,
  TextInput,
} from 'shared/components';
import { displayValue } from 'shared/helpers';
import './styles.scss';

const ExistingOrderDetails = ({
  formState,
  handleForward,
}) => (
  <div className="ExistingOrderDetails">
    <div className="ExistingOrderDetails-data">
      <div className="order-data">
        <div>
          <Label text={__('Order ID')} inputId="customer-order-id" />
          <TextInput
            id="customer-order-id"
            handleChange={() => { }}
            value={get(formState, 'orderId')}
            disabled
          />
        </div>
        {get(formState, 'orderReference') && (
          <div>
            <Label text={__('Order reference')} inputId="order-reference" />
            <TextInput
              id="order-reference"
              handleChange={() => { }}
              value={get(formState, 'orderReference')}
              disabled
            />
          </div>
        )}
      </div>
      <div><Label text={__('Customer details')} inputId="customer-details" /></div>
      <DescriptionTable
        details={[
          { label: __('Email'), value: displayValue(get(formState, 'customerEmail')) },
          { label: __('First name'), value: displayValue(get(formState, 'customerFirstName')) },
          { label: __('Last name'), value: displayValue(get(formState, 'customerLastName')) },
          { label: __('Organization'), value: displayValue(get(formState, 'customerOrganization')) },
          { label: __('Address'), value: displayValue(get(formState, 'customerAddress')) },
          { label: __('City'), value: displayValue(get(formState, 'customerCity')) },
          { label: __('State / Province'), value: displayValue(get(formState, 'customerState')) },
          { label: __('Country'), value: displayValue(get(formState, 'customerCountry')) },
          { label: __('Zipcode / Postcode'), value: displayValue(get(formState, 'customerZipcode')) },
          { label: __('Phone number'), value: displayValue(get(formState, 'customerPhoneNumber')) },
          { label: __('Customer reference'), value: displayValue(get(formState, 'customerReference')) },
        ]}
      />
    </div>
    <div className="ExistingOrderDetails-actions">
      <Button theme="info" size="lg" onClick={handleForward}>
        {__('Next')}
      </Button>
    </div>
  </div>
);

ExistingOrderDetails.propTypes = {
  formState: PropTypes.object.isRequired,
  handleForward: PropTypes.func.isRequired,
};

export default ExistingOrderDetails;
