import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  sendErrorReport,
  mapFeaturesToSelector,
} from 'shared/helpers';
import {
  CheckboxSelector,
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
} from 'shared/components';
import {
  createLicenseFeature,
} from 'src/license/actions';
import {
  getReaminingFeatures,
} from './helpers';
import ProductFeaturesEditor from '../../../ProductFeaturesEditor';

const AddProductFeatureForm = ({
  closeCb,
  companyID,
  product,
  refetchLicense,
  featuresList,
  license,
}) => {
  const licenseID = get(license, 'id');
  const remainingFeatures = getReaminingFeatures(product, featuresList);

  const [isLoading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [isDirtyFormDisplayed, setDirtyFormDisplay] = useState(false);
  const [features, setFeatures] = useState([]);
  const [error, setError] = useState('');

  const handleClose = () => {
    if (!isDirty) { return closeCb(); }
    return setDirtyFormDisplay(true);
  };

  const validateFeaturesConsumption = (values) => {
    if (!values.length) {
      setError(false);
      return true;
    }

    const hasError = values.some(f => f.max_consumption_error || f.max_overages_error || f.expiry_date_error || f.metadata_error);
    if (hasError || error) {
      setError(true);
      return false;
    }
    setError(false);
    return true;
  };

  const handleSelection = (values) => {
    setDirty(true);
    if (!features.length) {
      setFeatures(values);
      validateFeaturesConsumption(values);
      return true;
    }
    const newSelectedList = values.map((v) => {
      const isSelected = features.find(sf => sf.value === v.value);
      if (isSelected) { return isSelected; }
      return v;
    });
    setFeatures(newSelectedList);
    validateFeaturesConsumption(newSelectedList);
    return true;
  };

  const isFormValid = () => {
    const areProductFeaturesValid = validateFeaturesConsumption(features);
    return areProductFeaturesValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = await isFormValid();
    if (!isValid || isLoading || error) {
      return false;
    }

    if (!isDirty || !features.length) {
      closeCb();
      return false;
    }

    setLoading(true);
    const selectedFeatures = features.map(f => ({
      license: licenseID,
      product_feature: get(f, 'data.id'),
      max_consumption: Number(f.max_consumption),
      total_consumptions: 0,
      expiry_date: get(f, 'expiry_date') || null,
      allow_overages: get(f, 'allow_overages') || false,
      max_overages: get(f, 'max_overages') || 0,
      reset_consumption: get(f, 'reset_consumption') || false,
      consumption_period: get(f, 'consumption_period') || 'monthly',
      allow_unlimited_consumptions: get(f, 'allow_unlimited_consumptions') || false,
      allow_negative_consumptions: get(f, 'allow_negative_consumptions') || false,
      metadata: get(f, 'metadata') || '',
      is_floating: get(f, 'is_floating'),
      is_floating_cloud: get(f, 'is_floating_cloud'),
      floating_users: get(f, 'floating_users'),
      floating_timeout: get(f, 'floating_timeout') || 120,
    }));

    try {
      await Promise.all(selectedFeatures.map(sf => createLicenseFeature(companyID, sf)));
      refetchLicense();
      Notification('success', __('Changes saved successfully'), __('Product features added to license'));
      closeCb();
    } catch (err) {
      sendErrorReport(err, 'Cannot add license product features', selectedFeatures);
      setLoading(false);
      Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
    }
    return true;
  };

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={isLoading}
      title={__('Add product features')}
      size="xl"
    >
      <form className="AddProductFeatureForm" onSubmit={handleSubmit}>
        <div className="ProductFeatures-selector">
          <Label text={__('Remaining product features')} inputId="features" />
          <CheckboxSelector
            text={__('Features list')}
            options={mapFeaturesToSelector(remainingFeatures)}
            value={features}
            onChangeCallback={handleSelection}
            onMenuClose={() => { }}
          />
        </div>
        <div>
          <ProductFeaturesEditor
            selectedFeatures={features}
            onFeaturesChange={data => setFeatures(data)}
            disableFeatureExpiry={false}
          />
        </div>
      </form>
      {isDirtyFormDisplayed && (
        <DirtyFormAlert
          dirty={isDirty}
          closeAlert={() => setDirtyFormDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

AddProductFeatureForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  companyID: PropTypes.number.isRequired,
  product: PropTypes.object.isRequired,
  refetchLicense: PropTypes.func.isRequired,
  featuresList: PropTypes.array.isRequired,
  license: PropTypes.object.isRequired,
};

export default AddProductFeatureForm;
