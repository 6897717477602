export const initialState = {
  add_customer: true,
  orderId: '',
  orderReference: '',
  customerReference: '',
  customerFirstName: '',
  customerLastName: '',
  customerEmail: '',
  customerOrganization: '',
  customerAddress: '',
  customerCity: '',
  customerState: '',
  customerCountry: '',
  customerZipcode: '',
  customerPhoneNumber: '',
  selectedCustomerType: 'customer-existing',
  existingCustomer: null,
  products: [],
  productToEditIndex: null,
  productToEditData: false,
  is_manager: false,
  is_bundle: false,
  bundle: null,
};

// reducer actions
export const existingOrderPrefill = 'EXISTING_ORDER_PREFILL';
export const customerDetails = 'CUSTOMER_DETAILS';
export const selectedProducts = 'SELECTED_PRODUCTS';

export const actionTypes = {
  existingOrderPrefill,
  customerDetails,
  selectedProducts,
};
