import React from 'react';
import { Notice } from 'shared/components';
import './styles.scss';

const MissingPolicyAlert = () => (
  <div className="MissingPolicyAlert">
    <Notice theme="warning" title={__('Notification policies are not created')}>
      {__('Add at least one notification policy to view and edit email template.')}
    </Notice>
  </div>
);

export default MissingPolicyAlert;
