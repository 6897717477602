import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {
  DirtyFormAlert,
  InputErrorMessage,
  Label,
  Modal,
  Notification,
  Selector,
  TextInput,
} from 'shared/components';
import {
  mapCountriesToSelector,
  mapRegionsToSelector,
  sendErrorReport,
} from 'shared/helpers';
import { countriesList } from 'shared/countriesAlpha2Code';
import { regionData } from 'shared/constants';
import {
  validateRequiredValue,
  debouncedValidateRequiredValue,
} from 'shared/validation';
import { getIntentSecret, submitCard, patchCompanyPaymentData } from 'src/account/actions';
import { getValue } from './helpers';
import StripeLogo from './powered_by_stripe.png';
import './styles.scss';

const PaymentMethodForm = ({
  closeCb,
  company,
  companyId,
  refreshCompany,
  refreshPaymentMethod,
  title,
  showCompanyInputs,
  taxRates,
  tempCountry,
  tempRegion,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const initalCountry = getValue(get(tempCountry, 'value'), 'value') || getValue(get(company, 'payment_country'), 'value') || null;
  const initalRegion = getValue(get(tempRegion, 'name'), 'name') || getValue(get(company, 'payment_region'), 'name') || null;

  const [isLoading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false);

  const [name, setName] = useState(decodeURIComponent(get(company, 'payment_name')) || '');
  const [nameError, setNameError] = useState('');
  const [zipcode, setZipcode] = useState(decodeURIComponent(get(company, 'payment_zip_code')) || '');
  const [zipcodeError, setZipcodeError] = useState('');
  const [country, setCountry] = useState(initalCountry);
  const [countryError, setCountryError] = useState('');
  const [region, setRegion] = useState(initalRegion);
  const [regionError, setRegionError] = useState('');
  const [cardNumberComplete, setCardNumberComplete] = useState(false);
  const [cardNumberError, setCardNumberError] = useState('');
  const [cardExpireComplete, setCardExpireComplete] = useState(false);
  const [cardExpireError, setCardExpireError] = useState('');
  const [cardCvcComplete, setCardCvcComplete] = useState(false);
  const [cardCvcError, setCardCvcError] = useState('');

  const countryOptions = mapCountriesToSelector(countriesList);

  const validateValue = async (val, cb) => {
    let errors;
    try {
      errors = await validateRequiredValue(val);
      cb(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate payment method form value', { value: val });
    }
    if (errors) { return false; }
    return true;
  };

  const validateCountry = async () => {
    if (!showCompanyInputs) { return true; }

    let errors;
    try {
      errors = await validateRequiredValue(get(country, 'value'));
      setCountryError(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate payment country value', { val: get(country, 'value') });
    }
    if (errors) { return false; }
    return true;
  };

  const validateRegion = async () => {
    if (!showCompanyInputs) { return true; }
    if (get(country, 'value') !== 'Canada') { return true; }

    let errors;
    try {
      errors = await validateRequiredValue(get(region, 'name'));
      setRegionError(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate payment region value', { val: get(region, 'name') });
    }
    if (errors) { return false; }
    return true;
  };

  const updateCompany = () => {
    const companyInfo = {
      payment_name: encodeURIComponent(name),
      payment_zip_code: encodeURIComponent(zipcode),
      payment_country: get(country, 'value'),
      payment_region: get(region, 'name'),
    };

    patchCompanyPaymentData(companyId, companyInfo)
      .then((res) => {
        const resData = get(res, 'data');
        refreshCompany(resData);
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot update company after payment method change', companyInfo);
      });
  };

  const isFormValid = async () => {
    const isNameValid = await validateValue(name, setNameError);
    const isZipcodeValid = await validateValue(zipcode, setZipcodeError);
    const isCountryValid = await validateCountry();
    const isRegionValid = await validateRegion();
    return isNameValid && isZipcodeValid && isCountryValid && isRegionValid;
  };

  const handleSubmit = async () => {
    const isValid = await isFormValid();
    const isCardInfoValid = cardNumberComplete && cardExpireComplete && cardCvcComplete;

    if (!isValid || isLoading) { return false; }
    if (!isCardInfoValid) {
      Notification('error', __('Error occured'), __('Credit card info is not valid'));
      return false;
    }

    setLoading(true);

    const secretResponse = await getIntentSecret(companyId);
    if (secretResponse.error) {
      Notification('error', __('Error occured'), `${__(secretResponse.error.message)}.`);
      setLoading(false);
      return false;
    }

    const cardElement = elements.getElement(CardNumberElement);

    const response = await stripe
      .confirmCardSetup(
        secretResponse.data.client_secret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              name,
              address: { postal_code: zipcode },
            },
          },
        },
      );

    if (response.error) {
      Notification('error', __('Error occured'), `${__(response.error.message)}`);
      setLoading(false);
      return false;
    }

    const tax = taxRates.filter(t => t.jurisdiction === get(region, 'name'));
    const taxId = tax.length ? get(tax, '[0].id') : '';

    const requestData = {
      cardToken: response.setupIntent.payment_method,
      stripe_tax_id: taxId,
    };

    submitCard(companyId, requestData)
      .then((res) => {
        const cardData = res.data;
        refreshPaymentMethod(cardData);
        updateCompany();
        closeCb();
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot submit card data', requestData);
        setLoading(false);
        Notification('error', __('Error occured'), `${__(response.error.message)}`);
      });
    return true;
  };

  const handleClose = () => {
    if (!dirty) { return closeCb(); }
    return setDirtyFormAlertDisplay(true);
  };

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      title={title}
      disabled={isLoading}
    >
      <div className="PaymentMethodForm">
        <div className="form-row">
          <div className="form-row-column cardholder">
            <Label
              text={__('Cardholder name')}
              inputId="cardholder-name-input"
              description={__('As it appears on the card.')}
            />
            <TextInput
              disabled={isLoading}
              id="cardholder-name-input"
              value={name}
              error={nameError}
              handleChange={(val) => {
                setDirty(true);
                setName(val);
                debouncedValidateRequiredValue(val).then(err => setNameError(err));
              }}
            />
          </div>
          <div className="form-row-column">
            <Label
              text={__('Postal code / Zipcode')}
              inputId="zipcode-input"
            />
            <TextInput
              disabled={isLoading}
              id="zipcode-input"
              value={zipcode}
              error={zipcodeError}
              handleChange={(val) => {
                setDirty(true);
                setZipcode(val);
                debouncedValidateRequiredValue(val).then(err => setZipcodeError(err));
              }}
            />
          </div>
        </div>
        {showCompanyInputs && (
          <div className="form-row">
            <div className="form-row-column">
              <div>
                <Label text={__('Country')} inputId="country-input" />
                <Selector
                  options={countryOptions}
                  isDisabled={isLoading}
                  value={get(country, 'value')}
                  id="upgrade-country"
                  handleChange={(val) => {
                    const selected = countryOptions.find(option => option.value === val);
                    setDirty(true);
                    setCountry(selected);
                    setCountryError('');
                    if (selected.value !== 'Canada') {
                      setRegion(null);
                    }
                  }}
                />
                <InputErrorMessage text={countryError} />
              </div>
            </div>
            {get(country, 'value') === 'Canada' && (
              <div className="form-row-column">
                <Label text={__('State/Province')} inputId="state-input" />
                <Selector
                  options={mapRegionsToSelector(get(regionData, 'Canada'))}
                  value={get(region, 'name')}
                  valueKey="label"
                  isDisabled={isLoading}
                  handleChange={(val) => {
                    const selected = get(regionData, 'Canada').find(option => option.name === val);
                    setDirty(true);
                    setRegion(selected);
                    debouncedValidateRequiredValue(selected).then(err => setRegionError(err));
                  }}
                />
                <InputErrorMessage text={regionError} />
              </div>
            )}
          </div>
        )}
        <div className="form-row">
          <div>
            <Label text={__('Card number')} />
            <div className="StripeInput">
              <CardNumberElement
                disabled={isLoading}
                options={{
                  style: {
                    base: { lineHeight: '34px', color: '#464854' },
                  },
                }}
                onChange={(res) => {
                  setDirty(true);
                  setCardNumberComplete(get(res, 'complete'));
                  setCardNumberError(get(res, 'error.message'));
                }}
              />
            </div>
            <InputErrorMessage text={cardNumberError} />
          </div>
        </div>
        <div className="form-row">
          <div className="form-row-column">
            <div>
              <Label text={__('Card expiry')} />
              <div className="StripeInput">
                <CardExpiryElement
                  disabled={isLoading}
                  options={{
                    style: {
                      base: { lineHeight: '34px', color: '#464854' },
                    },
                  }}
                  onChange={(res) => {
                    setDirty(true);
                    setCardExpireComplete(get(res, 'complete'));
                    setCardExpireError(get(res, 'error.message'));
                  }}
                />
              </div>
              <InputErrorMessage text={cardExpireError} />
            </div>
          </div>
          <div className="form-row-column">
            <Label text={__('CVC')} />
            <div className="StripeInput">
              <CardCvcElement
                disabled={isLoading}
                options={{
                  style: {
                    base: { lineHeight: '34px', color: '#464854' },
                  },
                }}
                onChange={(res) => {
                  setDirty(true);
                  setCardCvcComplete(get(res, 'complete'));
                  setCardCvcError(get(res, 'error.message'));
                }}
              />
            </div>
            <InputErrorMessage text={cardCvcError} />
          </div>
        </div>
        <div className="extra-information">
          <div>{__('The charge will appear as LicenseSpring Software on your credit card statement.')}</div>
          <div className="stripe-info">
            <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">
              <img src={StripeLogo} alt="stripe logo" />
            </a>
          </div>
        </div>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormAlertDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

PaymentMethodForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  companyId: PropTypes.number.isRequired,
  refreshCompany: PropTypes.func.isRequired,
  refreshPaymentMethod: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  showCompanyInputs: PropTypes.bool,
  taxRates: PropTypes.array,
  tempCountry: PropTypes.object,
  tempRegion: PropTypes.object,
};

PaymentMethodForm.defaultProps = {
  taxRates: [],
  showCompanyInputs: true,
  tempCountry: null,
  tempRegion: null,
};

export default PaymentMethodForm;
