import React from 'react';
import Base from './Base';

export default props => (
  <Base {...props}>
    <path
      d="m88.266 5.6777c-0.39928-1.985e-4 -0.7996 0.15312-1.1035 0.45703l-4.4922 4.4922 6.7148 6.7188 4.4941-4.4961c0.60782-0.60782 0.60782-1.5969 0-2.2031l-4.5098-4.5117c-0.30391-0.30312-0.70424-0.45683-1.1035-0.45703zm-82.6 6.332v82.309h82.309v-71.086l-2.3555 2.3574-0.70703 0.70898-41.713 41.707-12.312 3.3613-1.9414 1.9414c-0.30866 0.30866-0.71197 0.46289-1.1172 0.46289s-0.81048-0.15423-1.1191-0.46289c-0.6189-0.6189-0.6189-1.6194 0-2.2383l1.9395-1.9414 3.3574-12.314 44.805-44.805h-71.145zm74.766 0.85938-2.2383 2.2383 6.7148 6.7109 2.2383-2.2344-6.7148-6.7148zm-4.4766 4.4766-40.295 40.291 6.7148 6.7148 40.295-40.295-6.7148-6.7109zm-41.723 43.34-1.9121 7.0098 7.0098-1.9141-5.0977-5.0957z"
    />
  </Base>
);
