import React from 'react';
import PropTypes from 'prop-types';
import {
  IconDisabled,
} from 'shared/components';
import './styles.scss';

const SimpleFilterTag = ({
  value,
  label,
  filtersArray,
  customFilterSetter,
  filterSetter,
  isDisabled,
  ...rest
}) => {
  if (value === 'all' || !value) return null;

  return (
    <button
      type="button"
      className="SimpleFilterTag"
      disabled={isDisabled}
      onClick={() => {
        if (!customFilterSetter) {
          return filterSetter(filtersArray[0]);
        }
        return customFilterSetter();
      }}
      {...rest}
    >
      <>
        <p>{__(label)}</p>
        <IconDisabled color="red" height="8px" />
      </>
    </button>
  );
};

SimpleFilterTag.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  filtersArray: PropTypes.array,
  customFilterSetter: PropTypes.func,
  filterSetter: PropTypes.func,
  isDisabled: PropTypes.bool,
};

SimpleFilterTag.defaultProps = {
  customFilterSetter: null,
  filterSetter: () => {},
  filtersArray: [],
  isDisabled: false,
};

export default SimpleFilterTag;
