import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { sendErrorReport } from 'shared/helpers';
import {
  Checkbox,
  DateInput,
  DirtyFormAlert,
  InputErrorMessage,
  Label,
  Modal,
  Notification,
  Selector,
  TextInput,
} from 'shared/components';
import { environmentOptions } from 'shared/constants';
import {
  validateRequiredValue,
  debouncedValidateRequiredValue,
  validateLink,
  debouncedValidateLink,
  validateDate,
  debouncedValidateDate,
  validateMD5Hash,
  debouncedValidateMD5Hash,
} from 'shared/validation';
import { addVersion, updateVersion } from 'src/product/actions';
import './styles.scss';

const formatVersionsToSelector = data => data.map(d => ({
  label: d.version,
  value: d.version,
  ...d,
}));

const ProductVersionForm = ({
  version,
  versions,
  closeModal,
  fetchFiles,
  productId,
  productName,
  companyID,
}) => {
  const title = version ? __('Edit file') : `${__('Add file for')} ${productName}`;
  const formattedVersions = formatVersionsToSelector(versions);

  const [isLoading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false);
  const [fileVersion, setFileVersion] = useState(get(version, 'version') || '');
  const [fileVersionError, setFileVersionError] = useState('');
  const [hasRequiredVersion, setHasRequiredVersion] = useState(get(version, 'requires_version') || false);
  const [requiredVersion, setRequiredVersions] = useState(get(version, 'requires_version') || get(formattedVersions, '[0].value'));
  const [fileLink, setFileLink] = useState(get(version, 'full_link') || '');
  const [fileLinkError, setFileLinkError] = useState('');
  const [fileHash, setFileHash] = useState(get(version, 'hash_md5') || '');
  const [fileHashError, setFileHashError] = useState('');
  const [fileDate, setFileDate] = useState(get(version, 'release_date') || '');
  const [fileDateError, setFileDateError] = useState('');
  const [fileEnvironment, setFileEnvironment] = useState(get(version, 'environment') || '');
  const [fileEnvironmentError, setFileEnvironmentError] = useState('');
  const [fileReleaseNotesLink, setFileReleaseNotesLink] = useState(get(version, 'release_notes_link') || '');
  const [fileReleaseNotesLinkError, setFileReleaseNotesLinkError] = useState('');
  const [eulaLink, setEulaLink] = useState(get(version, 'eula_link') || '');
  const [eulaLinkError, setEulaLinkError] = useState('');
  const [size, setSize] = useState(get(version, 'size') || '');
  const [channel, setChannel] = useState(get(version, 'channel') || '');

  const validateValue = async (val, cb) => {
    let errors;
    try {
      errors = await validateRequiredValue(val);
      cb(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate version form value', { value: val });
    }
    if (errors) { return false; }
    return true;
  };

  const handleLinkValidation = async (val, cb, required = true) => {
    let errors;
    try {
      errors = await validateLink(val, required);
      cb(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate version link value', { value: val });
    }
    if (errors) { return false; }
    return true;
  };

  const handleHashValidation = async () => {
    let errors;
    try {
      errors = await validateMD5Hash(fileHash);
      setFileHashError(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate version hash value', { value: fileHash });
    }
    if (errors) { return false; }
    return true;
  };

  const handleDateValidation = async () => {
    let errors;
    try {
      errors = await validateDate(fileDate);
      setFileDateError(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate version date value', { value: fileDate });
    }
    if (errors) { return false; }
    return true;
  };

  const isFormValid = async () => {
    const isEnvValid = await validateValue(fileEnvironment, setFileEnvironmentError);
    const isVersionValid = await validateValue(fileVersion, setFileVersionError);
    const isLinkValid = await handleLinkValidation(fileLink, setFileLinkError, false);
    const isReleaseLinkValid = await handleLinkValidation(fileReleaseNotesLink, setFileReleaseNotesLinkError, false);
    const isEulaLinkValid = await handleLinkValidation(eulaLink, setEulaLinkError, false);
    const isHashValid = await handleHashValidation();
    const isDateValid = await handleDateValidation();
    return isEnvValid && isVersionValid && isLinkValid
      && isReleaseLinkValid && isEulaLinkValid
      && isHashValid && isDateValid;
  };

  const updateFile = (data, versionID) => {
    setLoading(true);
    updateVersion(versionID, data, companyID)
      .then(() => {
        Notification('success', __('Changes saved successfully'), __('Product file update'));
        fetchFiles();
        closeModal();
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot edit product version', data);
        setLoading(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
  };

  const addFile = (data) => {
    setLoading(true);
    addVersion(data, companyID)
      .then(() => {
        Notification('success', __('Changes saved successfully'), __('Product file added'));
        fetchFiles();
        closeModal();
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot add product version', data);
        setLoading(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = await isFormValid();
    if (!isValid || isLoading) {
      return false;
    }

    const fileData = {
      environment: fileEnvironment,
      hash_md5: fileHash,
      full_link: fileLink,
      product: productId,
      release_date: fileDate,
      version: fileVersion,
      requires_version: hasRequiredVersion ? requiredVersion : null,
      release_notes_link: fileReleaseNotesLink,
      eula_link: eulaLink,
      size: size || undefined,
      channel: channel || undefined,
    };

    if (version) {
      updateFile(fileData, version.id);
    } else {
      addFile(fileData);
    }
    return true;
  };

  const handleClose = () => {
    if (!dirty) { return closeModal(); }
    return setDirtyFormAlertDisplay(true);
  };

  const isRequiredVersionDisabled = () => {
    // no previous versions
    if (versions.length === 0) { return true; }
    // only one version exists and it is being edited
    if (versions.length === 1 && !!version) { return true; }
    return false;
  };
  const isRequiresDisabled = isRequiredVersionDisabled();

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={isLoading}
      title={title}
    >
      <div className="ProductVersionForm">
        <form className="product-version-form" onSubmit={handleSubmit}>
          <div className="form-inner">
            <div className="left">
              <div className="form-row">
                <Label text={__('Environment')} inputId="env-input" />
                <Selector
                  id="env-input"
                  options={environmentOptions}
                  value={fileEnvironment}
                  handleChange={(val) => {
                    setDirty(true);
                    setFileEnvironment(val);
                    setFileEnvironmentError('');
                  }}
                />
                <InputErrorMessage text={fileEnvironmentError} />
              </div>
              <div className="form-row">
                <Label text={__('Version')} inputId="version" />
                <TextInput
                  id="version"
                  value={fileVersion}
                  error={fileVersionError}
                  handleChange={(val) => {
                    setDirty(true);
                    setFileVersion(val);
                    debouncedValidateRequiredValue(val).then(err => setFileVersionError(err));
                  }}
                />
              </div>
              <div className="form-row">
                <Checkbox
                  label={__('Requires version')}
                  description={__('Add if some previous version is required for this one to work')}
                  checked={hasRequiredVersion}
                  inputId="requires-version-checkbox"
                  handleChange={(val) => {
                    setDirty(true);
                    setHasRequiredVersion(val);
                  }}
                  disabled={isRequiresDisabled}
                />
                {hasRequiredVersion && !isRequiresDisabled && (
                  <Selector
                    id="requires-version-selector"
                    options={formattedVersions}
                    value={requiredVersion}
                    handleChange={(val) => {
                      setDirty(true);
                      setRequiredVersions(val);
                    }}
                  />
                )}
              </div>
              <div className="form-row">
                <Label text={__('File hash (MD5)')} inputId="hash" />
                <TextInput
                  id="hash"
                  value={fileHash}
                  error={fileHashError}
                  handleChange={(val) => {
                    setDirty(true);
                    setFileHash(val);
                    debouncedValidateMD5Hash(val).then(err => setFileHashError(err));
                  }}
                />
              </div>
              <div className="form-row">
                <Label text={__('File size (in bytes)')} inputId="size" />
                <TextInput
                  id="size"
                  value={size}
                  handleChange={(val) => {
                    setDirty(true);
                    setSize(val);
                  }}
                />
              </div>
            </div>
            <div className="divider active" />
            <div className="right">
              <div className="form-row">
                <Label text={__('Release Date')} inputId="release" />
                <DateInput
                  id="release"
                  value={fileDate}
                  error={fileDateError}
                  handleChange={(val) => {
                    setDirty(true);
                    setFileDate(val);
                    debouncedValidateDate(val).then(err => setFileDateError(err));
                  }}
                />
              </div>
              <div className="form-row">
                <Label text={__('Link to file')} inputId="link" />
                <TextInput
                  id="link"
                  value={fileLink}
                  error={fileLinkError}
                  handleChange={(val) => {
                    setDirty(true);
                    setFileLink(val);
                    debouncedValidateLink(val, false).then(err => setFileLinkError(err));
                  }}
                />
              </div>
              <div className="form-row">
                <Label text={__('Link to release notes')} inputId="link-release-notes" />
                <TextInput
                  id="link-release-notes"
                  value={fileReleaseNotesLink}
                  error={fileReleaseNotesLinkError}
                  handleChange={(val) => {
                    setDirty(true);
                    setFileReleaseNotesLink(val);
                    debouncedValidateLink(val, false).then(err => setFileReleaseNotesLinkError(err));
                  }}
                />
              </div>
              <div className="form-row">
                <Label text={__('Link to EULA')} inputId="link-eula" />
                <TextInput
                  id="link-eula"
                  value={eulaLink}
                  error={eulaLinkError}
                  handleChange={(val) => {
                    setDirty(true);
                    setEulaLink(val);
                    debouncedValidateLink(val, false).then(err => setEulaLinkError(err));
                  }}
                />
              </div>
              <div className="form-row">
                <Label text={__('Channel')} inputId="channel" />
                <TextInput
                  id="channel"
                  value={channel}
                  handleChange={(val) => {
                    setDirty(true);
                    setChannel(val);
                  }}
                />
              </div>
            </div>
          </div>
        </form>
        {isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={dirty}
            closeAlert={() => setDirtyFormAlertDisplay(false)}
            closeCb={closeModal}
          />
        )}
      </div>
    </Modal>
  );
};

ProductVersionForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  fetchFiles: PropTypes.func.isRequired,
  productId: PropTypes.number.isRequired,
  companyID: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired,
  version: PropTypes.object,
  versions: PropTypes.array,
};

ProductVersionForm.defaultProps = {
  version: undefined,
  versions: [],
};

export default ProductVersionForm;
