import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  Label,
  Selector,
  Switcher,
} from 'shared/components';
import {
  isFeatureEnabled,
  isFeatureAvailable,
  formatPolicies,
} from 'shared/helpers';
import {
  platformFeatures,
} from 'shared/constants';

const ProductInformation = ({
  handleProductSelect,
  products,
  selectedProduct,
  selectedPolicy,
  handlePolicySelect,
  useCustomValues,
  setUseCustomValues,
  isAirGapped,
  handleAirGappedChange,
}) => {
  const allowsAirGapped = isFeatureEnabled(platformFeatures.extra_air_gapped) && isFeatureAvailable(platformFeatures.extra_air_gapped);

  return (
    <section className="ProductInformation">
      <div className="section-header first">
        {__('Product information')}
      </div>
      <div className="section-row">
        <Label inputId="product-input" text={__('Product Name')} />
        <Selector
          options={products}
          handleChange={handleProductSelect}
          value={get(selectedProduct, 'value')}
          inputId="product-input"
        />
      </div>
      <div className="section-row">
        <Label inputId="product-policy" text={__('License policy')} />
        <Selector
          options={formatPolicies(get(selectedProduct, 'data.license_templates'))}
          handleChange={handlePolicySelect}
          value={selectedPolicy}
          inputId="product-policy"
          disabled={useCustomValues}
        />
      </div>
      {allowsAirGapped && (
        <div className="section-row">
          <Label inputId="custom-values" text={__('Set as air-gapped')} />
          <Switcher
            checked={isAirGapped}
            handleChange={handleAirGappedChange}
            size="sm"
          />
        </div>
      )}
      <div className="section-row">
        <Label inputId="custom-values" text={__('Use custom license values')} />
        <Switcher
          checked={useCustomValues}
          handleChange={() => setUseCustomValues(!useCustomValues)}
          size="sm"
          disabled={isAirGapped}
        />
      </div>
    </section>
  );
};

ProductInformation.propTypes = {
  handleProductSelect: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  selectedProduct: PropTypes.object.isRequired,
  selectedPolicy: PropTypes.string,
  handlePolicySelect: PropTypes.func.isRequired,
  useCustomValues: PropTypes.bool.isRequired,
  setUseCustomValues: PropTypes.func.isRequired,
  isAirGapped: PropTypes.bool.isRequired,
  handleAirGappedChange: PropTypes.func.isRequired,
};

ProductInformation.defaultProps = {
  selectedPolicy: '',
};

export default ProductInformation;
