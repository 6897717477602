import { actionTypes } from './constants';

export const newProductReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.productInformations:
      return {
        ...state,
        ...action.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export const setProductInformations = data => ({
  type: actionTypes.productInformations,
  data,
});
