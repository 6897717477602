export const preselect = {
  today: 'today',
  yesterday: 'yesterday',
  last_week: 'lastweek',
  last_7_days: 'last7days',
  this_week: 'thisweek',
  last_month: 'lastmonth',
  last_30_days: 'last30days',
  this_month: 'thismonth',
  all_time: 'alltime',
  custom: 'custom',
};

export const displayFormat = 'Do MMM YYYY';
