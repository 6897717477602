import React from 'react';
import PropTypes from 'prop-types';
import {
  platformFeatures,
} from 'shared/constants';

const getMessage = (type) => {
  switch (type) {
    case platformFeatures.assignment_user:
      return (
        <>
          {__('User-based licensing is not available in your plan.')}
          &nbsp;
          {__('Upgrade to unlock user-based licensing.')}
          &nbsp;
          {__('Allow your end-users to log in to your application with an email and password instead of a license key.')}
        </>
      );
    default:
      return (
        <>
          {__('This feature is not available in your current plan.')}
          &nbsp;
          {__('To compare different plan types click')}
          <a
            style={{ marginLeft: '3px' }}
            href="https://licensespring.com/pricing"
            target="_blank"
            rel="noopener noreferrer"
          >
            {__('here')}
          </a>
        </>
      );
  }
};

const ProductAuthMethodNotAvailableMessage = ({
  // eslint-disable-next-line no-unused-vars
  type,
}) => (
  <div className="feature-unavailable-tooltip">
    {getMessage(type)}
  </div>
);

ProductAuthMethodNotAvailableMessage.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ProductAuthMethodNotAvailableMessage;
