import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { sendErrorReport } from 'shared/helpers';
import {
  DirtyFormAlert,
  FileUploader,
  InputErrorMessage,
  Label,
  Modal,
  Notification,
  TextInput,
  Selector,
} from 'shared/components';
import {
  validateRequiredValue,
  debouncedValidateRequiredValue,
} from 'shared/validation';
import { ssoProvidersList, errorMsg } from 'shared/constants';
import { addCustomerAccProviders } from 'src/customer/actions';
import './styles.scss';

const isGoogleTypeUsed = list => list.find(l => l.type === 'Google');

const validateList = (options) => {
  if (isGoogleTypeUsed(options)) {
    return options.filter(o => o.provider_type !== 'Google');
  }
  return options;
};

const AddSSOProviderForm = ({
  companyID,
  closeCb,
  refetchProviders,
  providers,
  accountID,
}) => {
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormDisplay] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const providersList = validateList(ssoProvidersList);
  const [selectedProvider, setSelectedProvider] = useState(get(providersList, '[0]'));
  // apple form
  const [serviceID, setServiceID] = useState('');
  const [serviceIDError, setServiceIDError] = useState('');
  const [teamID, setTeamID] = useState('');
  const [teamIDError, setTeamIDError] = useState('');
  const [keyID, setKeyID] = useState('');
  const [keyIDError, setKeyIDError] = useState('');
  const [appleFile, setAppleFile] = useState(null);
  const [appleFileError, setAppleFileError] = useState('');
  // google form
  const [clientID, setClientID] = useState('');
  const [clientIDError, setClientIDError] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [clientSecretError, setClientSecretError] = useState('');
  // saml form
  const [samlName, setSamlName] = useState('');
  const [samlNameError, setSamlNameError] = useState('');
  const [samlFile, setSamlFile] = useState(null);
  const [samlFileError, setSamlFileError] = useState('');

  const sendSSOSetupRequest = (data) => {
    addCustomerAccProviders(accountID, companyID, data)
      .then(() => {
        closeCb();
        refetchProviders();
        Notification('success', __('Changes saved successfully'));
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot add customer account sso provider', data);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
        setLoading(false);
      });
  };

  const handleAppleSetup = () => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const readKey = e.target.result;
      const data = {
        provider_type: 'SignInWithApple',
        provider_data: {
          service_id: serviceID,
          team_id: teamID,
          key_id: keyID,
          private_key: readKey,
        },
      };
      // console.log('data', data);
      sendSSOSetupRequest(data);
    };
    reader.readAsText(appleFile.file);
  };

  const handleSamlSetup = () => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const readXml = e.target.result;
      const data = {
        provider_type: 'SAML',
        provider_data: {
          name: samlName,
          metadata: readXml,
        },
      };
      sendSSOSetupRequest(data);
    };
    reader.readAsText(samlFile.file);
  };

  const handleGoogleSetup = () => {
    const data = {
      provider_type: 'Google',
      provider_data: {
        client_id: clientID,
        client_secret: clientSecret,
      },
    };
    sendSSOSetupRequest(data);
  };

  const validateValue = async (val, cb) => {
    let errors;
    try {
      errors = await validateRequiredValue(val);
      cb(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate new user value', { value: val });
    }
    if (errors) {
      return false;
    }
    return true;
  };

  const validateFile = (type = 'SAML') => {
    if (type === 'SAML') {
      if (!samlFile) {
        setSamlFileError(errorMsg.requiredFile);
        return false;
      }
    }
    if (type === 'SignInWithApple') {
      if (!appleFile) {
        setAppleFileError(errorMsg.requiredFile);
        return false;
      }
    }
    return true;
  };

  const validateSamlNameUnique = () => {
    const isDuplicate = providers.find(p => p.name === samlName);
    if (isDuplicate) {
      setSamlNameError(errorMsg.nameDuplicateGeneric);
      return false;
    }
    return true;
  };

  const isSamlDataValid = () => {
    const isSamlNameValid = validateValue(samlName, setSamlNameError);
    const isSamlNameUnique = validateSamlNameUnique();
    const isFileValid = validateFile('SAML');

    return isSamlNameValid && isSamlNameUnique && isFileValid;
  };

  const isGoogleDataValid = () => {
    const isIDValid = validateValue(clientID, setClientSecretError);
    const isSecretValid = validateValue(clientSecret, setClientSecretError);

    return isIDValid && isSecretValid;
  };

  const isAppleDataValid = () => {
    const isServiceIDValid = validateValue(serviceID, setServiceIDError);
    const isTeamIDValid = validateValue(teamID, setTeamIDError);
    const isKeyIDValid = validateValue(keyID, setKeyIDError);
    const isFileValid = validateFile('SignInWithApple');

    return isServiceIDValid && isTeamIDValid && isKeyIDValid && isFileValid;
  };

  const validateData = (selectedProviderType) => {
    if (selectedProviderType === 'SAML') {
      return isSamlDataValid();
    }
    if (selectedProviderType === 'Google') {
      return isGoogleDataValid();
    }
    if (selectedProviderType === 'SignInWithApple') {
      return isAppleDataValid();
    }
    return false;
  };

  const handleSubmit = () => {
    const selectedProviderType = get(selectedProvider, 'provider_type'); // Google OR SAML
    const isValid = validateData(selectedProviderType);
    if (loading || !isValid) { return false; }
    setLoading(true);

    if (selectedProviderType === 'SAML') {
      handleSamlSetup();
    }
    if (selectedProviderType === 'Google') {
      handleGoogleSetup();
    }
    if (selectedProviderType === 'SignInWithApple') {
      handleAppleSetup();
    }
    return true;
  };

  const handleItemRemove = () => {
    setUploading(false);
    setProgress(0);
  };

  const handleWrongFileType = (fileType) => {
    Notification('error', __('Wrong file type'), `${__('Only this file type can be uploaded')}: ${fileType}`);
  };

  const getFormContent = () => {
    const selectedProviderType = get(selectedProvider, 'provider_type');
    if (selectedProviderType === 'SignInWithApple') {
      return (
        <div className="google-form">
          <div className="row">
            <Label text={__('Service ID')} />
            <TextInput
              value={serviceID}
              error={serviceIDError}
              handleChange={(val) => {
                setDirty(true);
                setServiceID(val);
                debouncedValidateRequiredValue(val).then(err => setServiceIDError(err));
              }}
            />
          </div>
          <div className="row">
            <Label text={__('Key ID')} />
            <TextInput
              value={keyID}
              error={keyIDError}
              handleChange={(val) => {
                setDirty(true);
                setKeyID(val);
                debouncedValidateRequiredValue(val).then(err => setKeyIDError(err));
              }}
            />
          </div>
          <div className="row">
            <Label text={__('Team ID')} />
            <TextInput
              value={teamID}
              error={teamIDError}
              handleChange={(val) => {
                setDirty(true);
                setTeamID(val);
                debouncedValidateRequiredValue(val).then(err => setTeamIDError(err));
              }}
            />
          </div>
          <div className="row">
            <Label text={__('Import your key')} iputId="key-file" />
            <FileUploader
              disabled={uploading}
              progress={progress}
              onUploadSuccess={(meta, allFiles) => {
                setAppleFile(allFiles[0]);
              }}
              onWrongFileTypeReject={() => handleWrongFileType('.p8')}
              onItemRemove={handleItemRemove}
              label={__('Drag file or click to browse')}
              subLabel={`${__('Valid file type')}:`}
              validFileTypes=".p8"
              submitButtonDisabled
            />
            <InputErrorMessage text={appleFileError} />
          </div>
        </div>
      );
    }

    if (selectedProviderType === 'Google') {
      return (
        <div className="google-form">
          <div className="row">
            <Label text={__('Client ID')} />
            <TextInput
              value={clientID}
              error={clientIDError}
              handleChange={(val) => {
                setDirty(true);
                setClientID(val);
                debouncedValidateRequiredValue(val).then(err => setClientIDError(err));
              }}
            />
          </div>
          <div className="row">
            <Label text={__('Client secret')} />
            <TextInput
              value={clientSecret}
              error={clientSecretError}
              handleChange={(val) => {
                setDirty(true);
                setClientSecret(val);
                debouncedValidateRequiredValue(val).then(err => setClientSecretError(err));
              }}
            />
          </div>
        </div>
      );
    }

    // SAML
    return (
      <div className="saml-form">
        <div className="row">
          <Label text={__('Name')} inputId="saml-name" />
          <TextInput
            id="saml-name"
            value={samlName}
            error={samlNameError}
            handleChange={(val) => {
              setDirty(true);
              setSamlName(val);
              debouncedValidateRequiredValue(val).then(err => setSamlNameError(err));
            }}
          />
        </div>
        <div className="row">
          <Label text={__('Provider metadata file')} iputId="metadata-file" />
          <FileUploader
            disabled={uploading}
            progress={progress}
            onUploadSuccess={(meta, allFiles) => {
              setSamlFile(allFiles[0]);
            }}
            onWrongFileTypeReject={() => handleWrongFileType('.xml')}
            onItemRemove={handleItemRemove}
            label={__('Drag file or click to browse')}
            subLabel={`${__('Valid file type')}:`}
            validFileTypes=".xml"
            submitButtonDisabled
          />
          <InputErrorMessage text={samlFileError} />
        </div>
      </div>
    );
  };

  const handleClose = () => {
    if (!dirty) { return closeCb(); }
    return setDirtyFormDisplay(true);
  };

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={loading}
      title={__('Add SSO Provider')}
      size="sm"
    >
      <div className="AddSSOProviderForm">
        <div className="select-container">
          <Label text={__('Select provider')} inputId="provider-select" />
          <Selector
            handleChange={(val) => {
              const selected = providersList.find(s => s.value === val);
              setSelectedProvider(selected);
            }}
            options={providersList}
            value={get(selectedProvider, 'value')}
          />
        </div>
        <div className="docs-link">
          <p>{__('Find Single Sign On documentation')}</p>
          <a href="https://docs.licensespring.com/docs/single-sign-on" target="_blank" rel="noopener noreferrer">{__('here')}</a>
        </div>
        <div className="provider-form">
          {getFormContent()}
        </div>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

AddSSOProviderForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  refetchProviders: PropTypes.func.isRequired,
  companyID: PropTypes.number.isRequired,
  providers: PropTypes.array,
  accountID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

AddSSOProviderForm.defaultProps = {
  providers: [],
};

export default AddSSOProviderForm;
