import AwesomeDebouncePromise from 'awesome-debounce-promise';
import {
  emailRegex,
  urlRegex,
  versionRegex,
  errorMsg,
  validationTimeDefault,
  validationTimeLong,
  productFeatureTypes,
} from 'shared/constants';
import api from 'shared/api';
import { isValidDate, sendErrorReport, isJSONObject } from 'shared/helpers';

export const validateRequiredValue = (val, maxLength) => new Promise((resolve) => {
  let error = '';
  if (!val) {
    error = errorMsg.required;
  }

  if (maxLength) {
    const isValidLength = val.length <= maxLength;
    if (!isValidLength) {
      error = `${errorMsg.maxChars} ${maxLength}`;
    }
  }

  resolve(error);
});

export const debouncedValidateRequiredValue = AwesomeDebouncePromise(
  validateRequiredValue, validationTimeDefault,
);

export const validateRequiredNumber = (val, includeZero = false) => new Promise((resolve) => {
  let error = '';
  if (includeZero) { // value can be set to 0
    if (val === '0' || val === 0) {
      resolve(error);
    }
  }

  if (!val || Number(val) <= 0) {
    error = errorMsg.required;
  }
  resolve(error);
});

export const debouncedValidateRequiredNumber = AwesomeDebouncePromise(
  validateRequiredNumber, validationTimeDefault,
);

export const validateEmail = val => new Promise((resolve) => {
  let error = '';
  if (!val) {
    error = errorMsg.required;
    resolve(error);
  }

  if (!emailRegex.test(val)) {
    error = errorMsg.invalidEmail;
    resolve(error);
  }
  resolve(error);
});

export const debouncedValidateEmail = AwesomeDebouncePromise(
  validateEmail, validationTimeDefault,
);

export const validateCompanyName = (val, companies) => new Promise((resolve) => {
  let error = '';
  let duplicate = null;

  if (Array.isArray(companies)) {
    duplicate = companies.filter(c => c.name === val);
  }
  if (!val) {
    error = errorMsg.required;
  }
  if (Array.isArray(duplicate) && duplicate.length !== 0) {
    error = errorMsg.companyDuplicate;
  }
  resolve(error);
});

export const debouncedValidateCompanyName = AwesomeDebouncePromise(
  validateCompanyName, validationTimeDefault,
);

export const validateCompanyCode = val => new Promise((resolve) => {
  const url = `/api/v1/companies/check_code?code=${val}`;
  let error = '';

  if (!val) {
    error = errorMsg.required;
    return resolve(error);
  }

  return api.get(url).then(
    (res) => {
      if (res.data.code_exists === true) {
        error = errorMsg.codeDuplicate;
        resolve(error);
      }

      resolve(error);
    },
  );
});

export const debouncedValidateCompanyCode = AwesomeDebouncePromise(
  validateCompanyCode, validationTimeDefault,
);

export const validateProductName = (val, products, archivedProducts = [], currentProduct = null) => new Promise((resolve) => {
  let error = '';
  if (!val) {
    error = errorMsg.required;
    resolve(error);
  }

  if (currentProduct && val === currentProduct) {
    error = '';
    resolve(error);
  }

  const duplicate = products.filter(
    product => product.product_name.toLowerCase() === val.toLowerCase(),
  );
  if (duplicate.length !== 0) {
    error = errorMsg.nameDuplicate;
    resolve(error);
  }

  const duplicateArchived = archivedProducts.filter(
    product => product.product_name.toLowerCase() === val.toLowerCase(),
  );
  if (duplicateArchived.length !== 0) {
    error = errorMsg.nameArchivedDuplicate;
    resolve(error);
  }

  resolve(error);
});

export const debouncedValidateProductName = AwesomeDebouncePromise(
  validateProductName, validationTimeDefault,
);

export const validateProductShortCode = (val, companyId) => new Promise((resolve) => {
  let error = '';
  // const lettersRegex = /^[a-z0-9]+$/;
  const url = `/api/v1/companies/${companyId}/check_product_code?code=${val}`;

  api.get(url).then(
    (res) => {
      if (!val) {
        error = errorMsg.required;
        resolve(error);
      }

      // if (!lettersRegex.test(val)) {
      //   error = errorMsg.alphanumeric;
      //   resolve(error);
      // }

      if (val.length > 20) {
        error = errorMsg.codeMaxChars;
        resolve(error);
      }

      if (res.data.code_in_use === true) {
        error = errorMsg.codeDuplicate;
        resolve(error);
      }

      resolve(error);
    },
  );
});

export const debouncedValidateProductShortCode = AwesomeDebouncePromise(
  validateProductShortCode, validationTimeDefault,
);

export const validateCustomerAccountCode = (val, companyId) => new Promise((resolve) => {
  let error = '';
  const url = `/api/v1/companies/${companyId}/check_customer_account_code?code=${val}`;

  api.get(url).then(
    (res) => {
      if (!val) {
        error = errorMsg.required;
        resolve(error);
      }

      if (res.data.code_in_use === true) {
        error = errorMsg.codeDuplicate;
        resolve(error);
      }

      resolve(error);
    },
  );
});

export const debouncedValidateCustomerAccountCode = AwesomeDebouncePromise(
  validateCustomerAccountCode, validationTimeDefault,
);

export const validateLink = (val, isRequired = true) => new Promise((resolve) => {
  let error = '';
  if (!val && !isRequired) {
    resolve(error);
  }
  if (!val && isRequired) {
    error = errorMsg.required;
    resolve(error);
  }
  if (!urlRegex.test(val)) {
    error = errorMsg.invalidUrl;
    resolve(error);
  }
  resolve(error);
});

export const debouncedValidateLink = AwesomeDebouncePromise(
  validateLink, validationTimeDefault,
);

export const validateDate = (val, isRequired = true) => new Promise((resolve) => {
  let error = '';
  if (!val && !isRequired) {
    resolve(error);
  }
  if (!val) {
    error = errorMsg.required;
    resolve(error);
  }
  if (!isValidDate(val)) {
    error = errorMsg.dateYYYYMMDDformat;
    resolve(error);
  }
  resolve(error);
});

export const debouncedValidateDate = AwesomeDebouncePromise(
  validateDate, validationTimeDefault,
);

export const validateMD5Hash = val => new Promise((resolve) => {
  let error = '';
  if (!val) {
    resolve(error);
  }

  const hashCharLength = val.split('').length;
  if (hashCharLength > 32) {
    error = errorMsg.longHash;
    resolve(error);
  }

  resolve(error);
});

export const debouncedValidateMD5Hash = AwesomeDebouncePromise(
  validateMD5Hash, validationTimeDefault,
);

export const validateVersion = val => new Promise((resolve) => {
  let error = '';
  if (!val) {
    error = errorMsg.required;
    resolve(error);
  }

  if (!versionRegex.test(val)) {
    error = errorMsg.invalidVersionFormat;
    resolve(error);
  }
  resolve(error);
});

export const debouncedValidateVersion = AwesomeDebouncePromise(
  validateVersion, validationTimeDefault,
);

export const validateFloatingUsers = (val, maxActivation = 1, allowUnlimitedActivations, isFloatingCloud) => new Promise((resolve) => {
  let error = '';
  if (!val || Number(val) <= 0) {
    error = errorMsg.required;
  }

  if ((Number(val) > Number(maxActivation)) && isFloatingCloud) {
    if (!allowUnlimitedActivations) {
      error = errorMsg.maxSimultaneousUsers;
    }
  }

  resolve(error);
});

export const debouncedValidateFloatingUsers = AwesomeDebouncePromise(
  validateFloatingUsers, validationTimeDefault,
);

export const validateFeaturesMaxConsumptions = features => new Promise((resolve) => {
  let error = '';

  features.forEach((f) => {
    if (f.feature_type === productFeatureTypes.consumption && f.max_consumption <= 0) {
      error = errorMsg.consumptionBiggerThanZero;
    }
  });

  resolve(error);
});

export const debouncedValidateFeaturesMaxConsumptions = AwesomeDebouncePromise(
  validateFeaturesMaxConsumptions, validationTimeDefault,
);

export const validateCustomFieldValues = customFields => new Promise((resolve) => {
  let error = '';

  customFields.forEach((cf) => {
    if (!cf.cf_value) {
      error = errorMsg.cfsInvalidValue;
    }
  });

  resolve(error);
});

export const debouncedValidateCustomFieldValues = AwesomeDebouncePromise(
  validateCustomFieldValues, validationTimeDefault,
);

export const validateExistingUserEmail = val => new Promise((resolve) => {
  let error = '';

  if (!val) {
    error = errorMsg.selectErr;
    resolve(error);
  }

  if (!emailRegex.test(val)) {
    error = errorMsg.invalidEmail;
    resolve(error);
  }

  resolve(error);
});

export const validateLicenseUsers = (emails = [], num) => new Promise((resolve) => {
  let error = '';

  if (!emails || !emails.length) {
    resolve(error);
  }

  if ((emails.length > Number(num)) && Number(num) !== 0) {
    error = errorMsg.tooManyEmails;
    resolve(error);
  }

  resolve(error);
});

export const debouncedValidateLicenseUsers = AwesomeDebouncePromise(
  validateLicenseUsers, validationTimeDefault,
);

export const validateNewUserEmail = (val, companyId) => new Promise((resolve) => {
  let error = '';
  const url = `/api/v1/customers/?company=${companyId}&email=${encodeURIComponent(val)}`;

  api.get(url).then(
    (res) => {
      if (!val) {
        error = errorMsg.required;
        resolve(error);
      }

      if (!emailRegex.test(val)) {
        error = errorMsg.invalidEmail;
        resolve(error);
      }

      if (res.data.count !== 0) {
        error = errorMsg.emailDuplicate;
        resolve(error);
      }

      resolve(error);
    },
  );
});

export const debouncedValidateNewUserEmail = AwesomeDebouncePromise(
  validateNewUserEmail, validationTimeLong,
);

export const validateCurrentPassword = val => new Promise((resolve) => {
  let error = '';
  if (!val.length) {
    error = errorMsg.passCurrent;
    resolve(error);
  }

  resolve(error);
});

export const debouncedValidateCurrentPassword = AwesomeDebouncePromise(
  validateCurrentPassword, validationTimeLong,
);

export const validatePassword = val => new Promise((resolve) => {
  let error = '';

  if (!val) {
    error = errorMsg.required;
    resolve(error);
  }

  if (val.length < 8) {
    error = errorMsg.passCharNum;
    resolve(error);
  }

  const lowercaseRegex = /[a-z]/;
  if (!lowercaseRegex.test(val)) {
    error = errorMsg.passLowercase;
    resolve(error);
  }

  const uppercaseRegex = /[A-Z]/;
  if (!uppercaseRegex.test(val)) {
    error = errorMsg.passUppercase;
    resolve(error);
  }

  const numberRegex = /[0-9]/;
  if (!numberRegex.test(val)) {
    error = errorMsg.passNumber;
    resolve(error);
  }

  resolve(error);
});

export const debouncedValidatePassword = AwesomeDebouncePromise(
  validatePassword, validationTimeLong,
);

export const validatePasswordAgain = (pass1, pass2) => new Promise((resolve) => {
  let error = '';
  if (pass1 !== pass2) {
    error = errorMsg.passMatch;
  }

  if (!pass1) {
    error = errorMsg.required;
  }

  resolve(error);
});

export const debouncedValidatePasswordAgain = AwesomeDebouncePromise(
  validatePasswordAgain, validationTimeLong,
);

export const validateCustomerEmail = (val, companyId, existingEmail = '') => new Promise((resolve) => {
  let error = '';
  const url = `/api/v1/customers/?company=${companyId}&email=${encodeURIComponent(val)}`;

  api.get(url)
    .then(
      (res) => {
        if (!val) {
          error = errorMsg.required;
          resolve(error);
        }

        if (!emailRegex.test(val)) {
          error = errorMsg.invalidEmail;
          resolve(error);
        }

        if (existingEmail && val.toLowerCase() === existingEmail.toLowerCase()) {
          error = '';
          resolve(error);
        }

        if (res.data.count !== 0) {
          error = errorMsg.emailDuplicate;
          resolve(error);
        }

        resolve(error);
      },
    )
    .catch((err) => {
      sendErrorReport(err, 'Cannot check customer email', { email: val });
    });
});

export const debouncedValidateCustomerEmail = AwesomeDebouncePromise(
  validateCustomerEmail, validationTimeDefault,
);


export const validateJSON = val => new Promise((resolve) => {
  let error = '';
  if (val && !isJSONObject(val)) {
    error = errorMsg.invalidJsonObject;
  }

  resolve(error);
});

export const debouncedValidateJSON = AwesomeDebouncePromise(
  validateJSON, validationTimeDefault,
);
