import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { sendErrorReport } from 'shared/helpers';
import {
  Checkbox,
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  TextInput,
} from 'shared/components';
import { updateMgmgtApiKey } from 'src/account/actions';
import './styles.scss';

const EditMgmtKeyForm = ({
  apiKey,
  companyID,
  closeCb,
  refetchKeys,
}) => {
  const keyID = get(apiKey, 'management_api_key');

  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormDisplay] = useState(false);
  const [label, setLabel] = useState(get(apiKey, 'label'));
  const [isReadOnly, setReadOnly] = useState(get(apiKey, 'read_only'));

  const handleSubmit = () => {
    if (loading) { return false; }
    setLoading(true);

    const data = { api_key: keyID, label, read_only: isReadOnly };

    updateMgmgtApiKey(companyID, data)
      .then(() => {
        closeCb();
        refetchKeys();
        Notification('success', __('Changes saved successfully'));
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot edit apikey', data);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
        setLoading(false);
      });
    return true;
  };

  const handleClose = () => {
    if (!dirty) { return closeCb(); }
    return setDirtyFormDisplay(true);
  };

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={loading}
      title={__('Edit management api key')}
      size="sm"
    >
      <div className="EditMgmtKeyForm">
        <div className="mgmt-key">{keyID}</div>
        <div>
          <Label text={__('Label')} inputId="label" />
          <TextInput
            id="label"
            value={label}
            handleChange={(val) => {
              setDirty(true);
              setLabel(val);
            }}
            disabled={loading}
          />
        </div>
        <div className="readonly-checkbox">
          <Checkbox
            label={__('Is read only')}
            description={__('If read only is enabled then only GET requests are allowed with this key.')}
            checked={isReadOnly}
            inputId="is-read-only-checkbox"
            disabled={loading}
            handleChange={(val) => {
              setDirty(true);
              setReadOnly(val);
            }}
          />
        </div>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

EditMgmtKeyForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  refetchKeys: PropTypes.func.isRequired,
  companyID: PropTypes.number.isRequired,
  apiKey: PropTypes.object.isRequired,
};

export default EditMgmtKeyForm;
