import api from 'shared/api';
import { get } from 'lodash';
import * as actionTypes from './actionTypes';

// redux actions
export const getProductsInit = () => dispatch => dispatch({ type: actionTypes.getProductsInit });

export const getProductsSuccess = res => ({
  type: actionTypes.getProductsSuccess,
  products: get(res, 'results'),
});

const getProductsError = res => ({
  type: actionTypes.getProductsError,
  payload: res,
});

export const getProducts = (companyId, isArchived = false) => dispatch => api.get(
  `/api/v1/products/?company=${companyId}&is_archived=${isArchived}&limit=1000`,
)
  .then(
    res => dispatch(getProductsSuccess(get(res, 'data'))),
    res => dispatch(getProductsError(get(res, 'data'))),
  );

export const getAllProducts = companyID => dispatch => api.get(
  `/api/v1/products/?company=${companyID}&limit=1000`,
)
  .then(
    res => dispatch(getProductsSuccess(get(res, 'data'))),
    res => dispatch(getProductsError(get(res, 'data'))),
  );

// actions
export const getProduct = (productId, activeCompany) => api.get(`/api/v1/products/${productId}/?company=${activeCompany}`);

export const createProduct = (data, companyID) => api.post(`/api/v1/products/?company=${companyID}`, data);

export const patchProduct = (productId, data, companyID) => {
  const url = `/api/v1/products/${productId}/?company=${companyID}`;
  return api.patch(url, data);
};

export const changeProductStatus = (productId, data, companyID) => {
  const url = `/api/v1/products/${productId}/activation/?company=${companyID}`;
  return api.post(url, data);
};

export const archiveProduct = (productId, data, companyID) => {
  const url = `/api/v1/products/${productId}/archivation/?company=${companyID}`;
  return api.post(url, data);
};

export const getProductsInBundle = (bundleID, companyID) => api.get(`/api/v1/products/${bundleID}/bundle/?company=${companyID}`);

export const checkWhereProductInBundle = productID => api.get(`/api/v1/products/${productID}/in_bundle/`);

export const addProductToBundle = (bundleID, productID) => api.post(`api/v1/products/${bundleID}/bundle/?product_in_bundle=${productID}`);

export const deleteProductToBundle = (bundleID, productID) => api.delete(`api/v1/products/${bundleID}/bundle/?product_in_bundle=${productID}`);

export const setProductsInBundle = (bundleID, productsList, companyID) => api.post(`/api/v1/products/${bundleID}/bundle/?company=${companyID}`, productsList);

export const reactivateProduct = (productID, companyID) => {
  const url = `/api/v1/products/${productID}/?company=${companyID}`;
  return api.patch(url, { is_archived: false });
};

export const fetchInstallationFiles = (productId, companyID) => {
  const url = `/api/v1/installation-files/?product=${productId}&company=${companyID}`;
  return api.get(url);
};

export const fetchCustomFields = (productId, companyID) => {
  const url = `/api/v1/product-custom-fields/?product=${productId}&company=${companyID}&limit=200&offset=0`;
  return api.get(url);
};

export const deleteCustomField = (fieldId, companyID) => {
  const url = `/api/v1/product-custom-fields/${fieldId}/?company=${companyID}`;
  return api.delete(url);
};

export const addCustomField = (data, companyID) => {
  const url = `/api/v1/product-custom-fields/?company=${companyID}`;
  return api.post(url, data);
};

export const editCustomField = (fieldID, data, companyID) => {
  const url = `/api/v1/product-custom-fields/${fieldID}/?company=${companyID}`;
  return api.patch(url, data);
};

export const addVersion = (data, companyID) => api.post(`/api/v1/installation-files/?company=${companyID}`, data);

export const updateVersion = (versionID, data, companyID) => api.patch(`/api/v1/installation-files/${versionID}/?company=${companyID}`, data);

export const deleteVersion = (companyID, versionID) => api.delete(`/api/v1/installation-files/${versionID}/?company=${companyID}`);

export const postFeature = (data, companyID) => api.post(`/api/v1/product-features/?company=${companyID}`, data);

export const patchFeature = (data, featureID, companyID) => api.patch(`/api/v1/product-features/${featureID}/?company=${companyID}`, data);

export const deleteFeature = (featureID, companyID) => api.delete(`/api/v1/product-features/${featureID}/?company=${companyID}`);

export const listLicensePolicies = (companyID, productID) => api.get(`/api/v1/license-templates/?company=${companyID}&product=${productID}`);

export const createLicensePolicy = (companyID, data) => api.post(`/api/v1/license-templates/?company=${companyID}`, data);

export const patchLicensePolicy = (policyID, companyID, data) => api.patch(`/api/v1/license-templates/${policyID}?company=${companyID}`, data);

export const getLicensePolicy = (policyID, companyID) => api.get(`/api/v1/license-templates/${policyID}?company=${companyID}`);

export const deleteLicensePolicy = (policyID, companyID) => api.delete(`/api/v1/license-templates/${policyID}?company=${companyID}`);
