import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { get } from 'lodash';
import {
  List,
} from 'shared/components';
import { APIdateFormat, licenseAPIactions } from 'shared/constants';
import { getAnalyticsStats } from 'src/analytics/actions';

const getTotal = (action) => {
  const { succ, fail } = action;
  return succ + fail;
};

const getSuccessRate = (action) => {
  const { succ, fail } = action;
  const total = succ + fail;
  const percentage = (succ / total) * 100;
  return percentage;
};

const getErrorRate = (action) => {
  const { succ, fail } = action;
  const total = succ + fail;
  const percentage = (fail / total) * 100;
  return percentage;
};

const AnalyticsSuccesRate = () => {
  const defaultFrom = moment().startOf('month');
  const defaultTo = moment().endOf('month');

  const from = useSelector(state => get(state, 'analytics.dateRange.date_from'));
  const to = useSelector(state => get(state, 'analytics.dateRange.date_to'));

  const companyID = useSelector(state => get(state, 'company.details.id'));

  const dateFrom = from ? moment(from) : defaultFrom;
  const dateTo = to ? moment(to) : defaultTo;

  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const getData = useCallback(() => {
    const fromDate = dateFrom.format(APIdateFormat);
    const toDate = dateTo.format(APIdateFormat);
    const duration = moment.duration(dateTo.diff(dateFrom)).asDays();

    const gteParam = `&ts__date__gte=${fromDate}`;
    const lteParam = `&ts__date__lte=${toDate}`;
    const dailyParam = duration > 7 ? '&time=daily' : '';

    setLoading(true);

    getAnalyticsStats(companyID, gteParam, lteParam, dailyParam)
      .then((res) => {
        const events = get(res, 'data') || [];
        const eventsKeys = Object.keys(events);
        const list = eventsKeys.map(key => ({
          ...events[key],
          action: key,
          total: getTotal(events[key]),
          succ_rate: getSuccessRate(events[key]),
          err_rate: getErrorRate(events[key]),
        }));
        setLoading(false);
        setData(list);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [companyID, from, to]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className="AnalyticsSuccesRate">
      <List
        columns={[
          {
            accessor: 'action',
            Header: __('Action'),
            Cell: (cell) => {
              const { value } = cell;
              const label = get(licenseAPIactions, `${value}.label`);
              return label || __('N/A');
            },
          },
          {
            id: 'endpoint',
            Header: __('Endpoint'),
            Cell: (cell) => {
              const { original } = cell;
              const { action } = original;
              const endpoint = get(licenseAPIactions, `${action}.endpoint`);
              return endpoint || __('N/A');
            },
          },
          {
            accessor: 'total',
            Header: __('Total'),
            Cell: (cell) => {
              const { value } = cell;
              return value;
            },
            maxWidth: 100,
          },
          {
            accessor: 'succ_rate',
            Header: __('Success rate'),
            Cell: (cell) => {
              const { value } = cell;
              return <span>{`${value.toFixed(2)}%`}</span>;
            },
            maxWidth: 150,
          },
          {
            accessor: 'err_rate',
            Header: __('Error rate'),
            Cell: (cell) => {
              const { value } = cell;
              return `${value.toFixed(2)}%`;
            },
            maxWidth: 150,
          },
        ]}
        data={data}
        minRows={1}
        showPagination={false}
        loading={isLoading}
      />
    </div>
  );
};

export default AnalyticsSuccesRate;
