import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ConfirmationPopup,
  DescriptionTable,
  IconDelete,
  IconEdit,
  IconSearch,
  JsonView,
  List,
  PermissionMissingNotificationTitle,
  Notice,
  Notification,
  TextInput,
} from 'shared/components';
import {
  capitalizeFirstLetter,
  displayValue,
  formatDate,
  isFeatureEnabled,
  isFeatureAvailable,
  getDisabledMessage,
  sendErrorReport,
  checkUserPermission,
} from 'shared/helpers';
import {
  platformFeatures,
  productFeatureTypes,
  defaultDateFormat,
  userPermissions,
} from 'shared/constants';
import { getCompanySilent } from 'src/company/actions';
import { deleteFeature } from 'src/product/actions';
import { ProductFeatureForm } from 'src/product/Forms';

const Features = ({
  companyID,
  product,
  refetchProduct,
}) => {
  const canManageProducts = checkUserPermission(userPermissions.products_write);
  const dispatch = useDispatch();
  const companyFeatures = useSelector(state => get(state, 'company.details.features'));
  const productFeatures = get(product, 'product_features') || [];

  const [searchQuery, setSearchQuery] = useState('');
  const [featureToEdit, setFeatureToEdit] = useState(null);
  const [isFeatureEditFormDisplayed, setFeatureEditFormDisplay] = useState(false);
  const [featureToDelete, setFeatureToDelete] = useState(null);
  const [isFeatureDeleteConfirmationDisplayed, setFeatureDeleteConfirmationDisplayed] = useState(false);
  const [featureDeleteLoading, setFeatureDeleteLoading] = useState(false);
  const [tableExpanded, setTableExpanded] = useState({});

  const getAvailableFeatures = () => {
    const companyActivationFeature = companyFeatures.find(f => f.key === 'extra:feature-licensing-activation');
    const companyConsumptionFeature = companyFeatures.find(f => f.key === 'extra:feature-licensing-consumption');

    const numOfActivationFeatures = productFeatures.filter(f => f.feature_type === 'activation');
    const maxActivationFeatures = get(companyActivationFeature, 'value');
    const numOfConsumptionFeatures = productFeatures.filter(f => f.feature_type === 'consumption');
    const maxConsumptionFeatures = get(companyConsumptionFeature, 'value');

    const getMax = (features) => {
      if (!features) {
        return __('Not available');
      }
      if (features && Number(features) < 0) {
        return __('Unlimited');
      }
      return Number(features);
    };

    const checkLimit = (maxFeatures, usedFeatures = 0) => {
      if (!maxFeatures) { return true; } // not available features
      if (Number(maxFeatures) < 0) { return false; } // unlimited features
      if (usedFeatures >= Number(maxFeatures)) { return true; } // limit reached
      return false; // available features
    };

    const data = {
      activation_used: numOfActivationFeatures.length,
      activation_max: getMax(maxActivationFeatures),
      activation_limit_reached: checkLimit(maxActivationFeatures, numOfActivationFeatures.length),
      consumption_used: numOfConsumptionFeatures.length,
      consumption_max: getMax(maxConsumptionFeatures),
      consumption_limit_reached: checkLimit(maxConsumptionFeatures, numOfConsumptionFeatures.length),
    };

    return data;
  };

  const availableFeatures = getAvailableFeatures();
  const featuresNotAvailable = availableFeatures.activation_limit_reached && availableFeatures.consumption_limit_reached;

  const handleNewFeatureForm = () => {
    if (featuresNotAvailable) {
      Notification('error', __('You have reached the limit'), __('Contact sales to upgrade to a plan that supports unlimited number of features'));
      return false;
    }
    setFeatureEditFormDisplay(true);
    return true;
  };

  const handleFeatureDelete = () => {
    const featureToDeleteID = get(featureToDelete, 'id');
    setFeatureDeleteLoading(true);

    deleteFeature(featureToDeleteID, companyID)
      .then(() => {
        setFeatureDeleteConfirmationDisplayed(false);
        setFeatureToDelete(null);
        setFeatureDeleteLoading(false);
        refetchProduct();
        dispatch(getCompanySilent(companyID));
        Notification('success', __('Changes saved successfully'), __('Product feature deleted'));
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot delete product feature');
        setFeatureDeleteLoading(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
  };

  const filterProductFeatures = () => {
    if (!productFeatures || !Array.isArray(productFeatures)) {
      return [];
    }
    if (!searchQuery) {
      return productFeatures;
    }
    const filtered = productFeatures.filter((f) => {
      const { name } = f;
      if (!name) {
        return false;
      }
      return name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
    });
    return filtered;
  };

  const handleManageProductFeaturesClick = (cb) => {
    if (!canManageProducts) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.products_write} />,
        __('Contact you account admin for support.'),
      );
      return false;
    }
    cb(true);
    return true;
  };

  const filteredFeatures = filterProductFeatures();

  return (
    <div className="Features features-tab">
      <div className="Tab-header">
        {__('Features Licensing allows you to offer multiple versions of your product using the same binary (example lite, standard, pro; or dev / production).')}
      </div>
      <div className="list-header">
        <div>
          <form onSubmit={e => e.preventDefault()}>
            <TextInput
              handleChange={val => setSearchQuery(val)}
              value={searchQuery}
              placeholder={__('Search product features')}
            />
            {searchQuery && (
              <button type="button" onClick={() => setSearchQuery('')}>
                &times;
              </button>
            )}
            <Button type="submit" theme="info">
              <IconSearch fill="#fff" />
            </Button>
          </form>
        </div>
        <div>
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.extra_feature_licensing)}
            notEnabledMessage={getDisabledMessage()}
            featureAvailable={isFeatureAvailable(platformFeatures.extra_feature_licensing)}
            notAvailableMessage={__('Licensing features in your applications is not available in your plan.')}
            ctaText={__('Upgrade to use features licensing.')}
            onClick={() => handleManageProductFeaturesClick(handleNewFeatureForm)}
            size="sm"
            theme="info"
          >
            {__('Add product feature')}
          </Button>
        </div>
      </div>
      <div className="available-features">
        <DescriptionTable
          details={[
            {
              label: __('Activation features'),
              value: `${availableFeatures.activation_used} / ${availableFeatures.activation_max}`,
            },
            {
              label: __('Consumption features'),
              value: `${availableFeatures.consumption_used} / ${availableFeatures.consumption_max}`,
            },
          ]}
        />
      </div>
      <div className="notice-container">
        {featuresNotAvailable && (
          <Notice
            theme="error"
            size="sm"
            title={__('You have reached the max limit of the available features')}
          >
            {__('Contact sales to upgrade to a plan that supports unlimited number of features')}
          </Notice>
        )}
      </div>
      <List
        onExpandedChange={expanded => setTableExpanded(expanded)}
        expanded={tableExpanded}
        SubComponent={(row) => {
          const isFloatingFeature = get(row, 'original.is_floating') || get(row, 'original.is_floating_cloud');
          const floatingTimeout = get(row, 'original.floating_timeout') || 120;
          const isConsumptionFeature = get(row, 'original.feature_type') === productFeatureTypes.consumption;

          return (
            <div className="SubComponent">
              <DescriptionTable
                details={[
                  // floating licenses data
                  {
                    label: get(row, 'original.is_floating') ? __('Offline floating feature') : null,
                    value: get(row, 'original.is_floating') ? __('Yes') : __('No'),
                  },
                  {
                    label: get(row, 'original.is_floating_cloud') ? __('Is floating cloud') : null,
                    value: get(row, 'original.is_floating_cloud') ? __('Yes') : __('No'),
                  },
                  {
                    label: isFloatingFeature ? __('Max simultaneous users') : null,
                    value: displayValue(get(row, 'original.floating_users')),
                  },
                  {
                    label: isFloatingFeature ? __('Floating timeout') : null,
                    value: `${displayValue(floatingTimeout)} min`,
                  },
                  {
                    label: isConsumptionFeature ? __('Allow_negative_consumptions') : null,
                    value: get(row, 'original.allow_negative_consumptions') ? __('Yes') : __('No'),
                  },
                  // {
                  //   label: canBorrow ? __('Can borrow') : null,
                  //   value: canBorrow ? __('Yes') : __('No'),
                  // },
                  // {
                  //   label: canBorrow ? __('Max borrow time') : null,
                  //   value: `${displayValue(get(feature, 'max_borrow_time'))} ${__('hours')}`,
                  // },
                  {
                    label: __('Metadata'),
                    value: (
                      <JsonView
                        value={get(row, 'original.metadata')}
                        name="metadata"
                      />
                    ),
                  },
                ]}
              />
            </div>
          );
        }}
        columns={[
          {
            expander: true,
            Header: __('Details'),
            headerClassName: 'text-center',
            width: 80,
            style: {
              fontSize: 25,
              padding: '0',
              textAlign: 'center',
              userSelect: 'none',
            },
          },
          {
            accessor: 'name',
            Header: __('Name'),
          },
          {
            accessor: 'code',
            Header: __('Code'),
          },
          {
            accessor: 'feature_type',
            Header: __('Type'),
            Cell: cellData => displayValue(cellData.value),
            maxWidth: 120,
          },
          {
            id: 'floating_feature',
            Header: __('Is floating'),
            Cell: (cellData) => {
              const isFloatingFeature = get(cellData, 'original.is_floating') || get(cellData, 'original.is_floating_cloud');
              return isFloatingFeature ? __('Yes') : __('No');
            },
            maxWidth: 120,
          },
          {
            accessor: 'max_consumption',
            Header: __('Max consumption'),
            Cell: (cellData) => {
              if (get(cellData, 'original.feature_type') === productFeatureTypes.activation) {
                return displayValue(undefined);
              }
              const isUnlimited = get(cellData, 'original.allow_unlimited_consumptions');
              if (isUnlimited) {
                return __('Unlimited');
              }
              return displayValue(cellData.value, 0);
            },
          },
          {
            accessor: 'max_overages',
            Header: __('Max overages'),
            Cell: (cellData) => {
              const allowOverage = get(cellData, 'original.allow_overages');
              if (!allowOverage) return __('N/A');
              return displayValue(cellData.value, __('N/A'));
            },
          },
          {
            accessor: 'consumption_period',
            Header: __('Reset consumptions'),
            Cell: (cellData) => {
              const resetConsumption = get(cellData, 'original.reset_consumption');
              if (!resetConsumption) return __('N/A');
              return displayValue(capitalizeFirstLetter(cellData.value), __('N/A'));
            },
          },
          {
            accessor: 'created_at',
            Header: __('Created on'),
            Cell: cellData => formatDate(cellData.value, defaultDateFormat),
          },
          {
            className: 'text-center',
            id: 'edit',
            width: 80,
            sortable: false,
            Cell: rowData => (
              <Button
                featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_product)}
                notEnabledMessage={getDisabledMessage()}
                className="edit-button"
                onClick={() => handleManageProductFeaturesClick(() => {
                  setFeatureToEdit(rowData.original);
                  setFeatureEditFormDisplay(true);
                })}
                type="button"
              >
                <IconEdit height="16px" width="16px" />
              </Button>
            ),
          },
          {
            className: 'text-center',
            id: 'delete',
            width: 80,
            sortable: false,
            Cell: rowData => (
              <Button
                className="edit-button"
                onClick={() => handleManageProductFeaturesClick(() => {
                  setFeatureToDelete(rowData.original);
                  setFeatureDeleteConfirmationDisplayed(true);
                })}
                type="button"
              >
                <IconDelete height="16px" width="16px" color="#ee5253" />
              </Button>
            ),
            maxWidth: 50,
          },
        ]}
        data={filteredFeatures}
        minRows={2}
        pageSize={20}
      />
      {isFeatureEditFormDisplayed && (
        <ProductFeatureForm
          availableFeatures={availableFeatures}
          closeModal={() => {
            setFeatureToEdit(null);
            setFeatureEditFormDisplay(false);
          }}
          feature={featureToEdit}
          features={productFeatures}
          fetchProduct={refetchProduct}
          productId={get(product, 'id')}
          updateProducts={() => dispatch(getCompanySilent(companyID))}
          companyID={companyID}
        />
      )}
      {isFeatureDeleteConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setFeatureToDelete(null);
            setFeatureDeleteConfirmationDisplayed(false);
          }}
          confirmCb={handleFeatureDelete}
          title={`${__('Are you sure you want to delete this product feature')}?`}
          confirmText={__('Delete')}
          theme="error"
          disabled={featureDeleteLoading}
        >
          <div className="feature-delete-popup-inner">
            {`${get(featureToDelete, 'name')} ${__('will be removed from this product and from all of the licenses which are using this feature')}`}
          </div>
        </ConfirmationPopup>
      )}
    </div>
  );
};

Features.propTypes = {
  companyID: PropTypes.number.isRequired,
  product: PropTypes.object.isRequired,
  refetchProduct: PropTypes.func.isRequired,
};

export default Features;
