import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { NavLink } from 'react-router-dom';

const LinkedCompanyNotice = ({
  theme,
  company,
  isMainAccAvailable,
}) => {
  const mainCompany = get(company, 'main_company');
  return (
    <div className={`PageNotice PageNotice-theme--${theme} LinkedCompanyNotice`}>
      <div className="PageNotice-body">
        <span>{`${__('This is an account linked to')} ${get(mainCompany, 'name')}.`}</span>
        &nbsp;
        {
          isMainAccAvailable && <NavLink to={`/${get(mainCompany, 'id')}`}>{`${__('Go to main account')}.`}</NavLink>
        }
      </div>
    </div>
  );
};

LinkedCompanyNotice.propTypes = {
  company: PropTypes.object.isRequired,
  isMainAccAvailable: PropTypes.bool.isRequired,
  theme: PropTypes.oneOf(['success', 'warning', 'info', 'error']),
};

LinkedCompanyNotice.defaultProps = {
  theme: 'error',
};

export default LinkedCompanyNotice;
