import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import {
  Button,
  DirtyFormAlert,
  Label,
  SlidePane,
  Notification,
  Checkbox,
  TextInput,
} from 'shared/components';
import { userPermissions } from 'shared/constants';
import { sendErrorReport, compareSimpleArrays } from 'shared/helpers';
import {
  validateRequiredValue,
  debouncedValidateRequiredValue,
} from 'shared/validation';
import {
  editUserPermissionTemplateName,
  addUserPermissionTemplate,
  setUserPermissionTemplate,
} from 'src/company/actions';
import './styles.scss';

const PermissionTemplateForm = ({
  closeCb,
  confirmCb,
  template,
  isOpen,
}) => {
  const companyID = useSelector(state => get(state, 'company.details.id'));
  const permissionsList = useSelector(state => get(state, 'company.permissionsList'));
  const templatePermissions = get(template, 'user_permissions') || [];
  const initialList = templatePermissions.map(tp => tp.code);
  const initialIDList = templatePermissions.map(tp => tp.id);

  const [isLoading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormDisplay] = useState(false);
  const [name, setName] = useState(get(template, 'name') || '');
  const [nameError, setNameError] = useState('');
  const [permissions, setPermissions] = useState(initialList);

  const validateValue = async (val, cb) => {
    let errors;
    try {
      errors = await validateRequiredValue(val);
      cb(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate new template value', { value: val });
    }
    if (errors) {
      return false;
    }
    return true;
  };

  const handlePermissionChange = (value) => {
    const doesExist = permissions.includes(value);
    let newList;
    if (!doesExist) {
      newList = [...permissions, value];
    } else {
      newList = permissions.filter(i => i !== value);
    }
    setPermissions(newList);
  };

  const getPermissionID = (per) => {
    const permObject = permissionsList.find(p => p.code === per);
    return get(permObject, 'id');
  };

  const editTemplatePermissions = (templateID, data) => {
    const arePermissionUnchanged = compareSimpleArrays(initialIDList, get(data, 'user_perm_ids'));
    if (arePermissionUnchanged) {
      Notification('success', __('Changes saved successfully'));
      return confirmCb();
    }
    setUserPermissionTemplate(templateID, companyID, data)
      .then(() => {
        Notification('success', __('Changes saved successfully'));
        confirmCb();
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot edit permission template', data);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
        setLoading(false);
      });
    return true;
  };

  const editTemplate = (data) => {
    const templateID = get(template, 'id');
    editUserPermissionTemplateName(templateID, companyID, data)
      .then(() => {
        editTemplatePermissions(templateID, data);
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot edit permission template', data);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
        setLoading(false);
      });
  };

  const createTemplate = (data) => {
    addUserPermissionTemplate(companyID, data)
      .then(() => {
        Notification('success', __('Changes saved successfully'));
        confirmCb();
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot add permission template', data);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
        setLoading(false);
      });
  };

  const isFormValid = async () => {
    const isNameValid = await validateValue(name, setNameError);
    return isNameValid;
  };

  const handleSubmit = async () => {
    if (!dirty) {
      closeCb();
      return false;
    }
    const isValid = await isFormValid();
    if (!isValid || isLoading) {
      return false;
    }

    setLoading(true);

    const data = {
      name,
      user_perm_ids: permissions.map(getPermissionID),
    };
    if (template) {
      editTemplate(data);
    } else {
      createTemplate(data);
    }
    return true;
  };

  const handleClose = () => {
    if (!dirty) { return closeCb(); }
    return setDirtyFormDisplay(true);
  };

  const getTitle = () => (
    <div className="PermissionTemplateFormTitle">
      <div className="title">{template ? __('Edit permission template') : __('Add new permission template')}</div>
      <div className="confirm-btn">
        <Button theme="success" onClick={handleSubmit} disabled={isLoading}>
          {__('Confirm')}
        </Button>
      </div>
    </div>
  );

  return (
    <SlidePane
      closeCb={handleClose}
      isOpen={isOpen}
      title={getTitle()}
      width="50%"
    >
      <div className="PermissionTemplateForm">
        <div className="row input-cont">
          <Label inputId="template-name" text={__('Name')} />
          <TextInput
            disabled={isLoading}
            id="template-name"
            type="text"
            value={name}
            error={nameError}
            handleChange={(val) => {
              setDirty(true);
              setName(val);
              debouncedValidateRequiredValue(val).then(err => setNameError(err));
            }}
          />
        </div>
        <Label inputId="template-permissions" text={__('Products')} />
        <div className="row checkbox-cont products">
          <Checkbox
            disabled={isLoading}
            label={__('Read')}
            checked={permissions.includes(userPermissions.products_read)}
            inputId="read-products"
            handleChange={() => {
              setDirty(true);
              handlePermissionChange(userPermissions.products_read);
            }}
          />
          <Checkbox
            disabled={isLoading}
            label={__('Manage')}
            checked={permissions.includes(userPermissions.products_write)}
            inputId="manage-products"
            handleChange={() => {
              setDirty(true);
              handlePermissionChange(userPermissions.products_write);
            }}
          />
        </div>
        <Label inputId="template-permissions" text={__('Orders')} />
        <div className="row checkbox-cont orders">
          <Checkbox
            disabled={isLoading}
            label={__('Read')}
            checked={permissions.includes(userPermissions.orders_read)}
            inputId="read-orders"
            handleChange={() => {
              setDirty(true);
              handlePermissionChange(userPermissions.orders_read);
            }}
          />
          <Checkbox
            disabled={isLoading}
            label={__('Manage')}
            checked={permissions.includes(userPermissions.orders_write)}
            inputId="manage-orders"
            handleChange={() => {
              setDirty(true);
              handlePermissionChange(userPermissions.orders_write);
            }}
          />
        </div>
        <Label inputId="template-permissions" text={__('Licenses')} />
        <div className="row checkbox-cont licenses">
          <Checkbox
            disabled={isLoading}
            label={__('Read')}
            checked={permissions.includes(userPermissions.licenses_read)}
            inputId="read-licenses"
            handleChange={() => {
              setDirty(true);
              handlePermissionChange(userPermissions.licenses_read);
            }}
          />
          <Checkbox
            disabled={isLoading}
            label={__('Manage')}
            checked={permissions.includes(userPermissions.licenses_write)}
            inputId="manage-licenses"
            handleChange={() => {
              setDirty(true);
              handlePermissionChange(userPermissions.licenses_write);
            }}
          />
        </div>
        <Label inputId="template-permissions" text={__('Customers')} />
        <div className="row checkbox-cont customers">
          <Checkbox
            disabled={isLoading}
            label={__('Read')}
            checked={permissions.includes(userPermissions.customers_read)}
            inputId="read-customers"
            handleChange={() => {
              setDirty(true);
              handlePermissionChange(userPermissions.customers_read);
            }}
          />
          <Checkbox
            disabled={isLoading}
            label={__('Manage')}
            checked={permissions.includes(userPermissions.customers_write)}
            inputId="manage-customers"
            handleChange={() => {
              setDirty(true);
              handlePermissionChange(userPermissions.customers_write);
            }}
          />
        </div>
        <Label inputId="template-permissions" text={__('Analytics')} />
        <div className="row checkbox-cont analytics">
          <Checkbox
            disabled={isLoading}
            label={__('Read')}
            checked={permissions.includes(userPermissions.analytics_read)}
            inputId="read-analytics"
            handleChange={() => {
              setDirty(true);
              handlePermissionChange(userPermissions.analytics_read);
            }}
          />
          <div />
        </div>
        <Label inputId="template-permissions" text={__('Settings')} />
        <div className="row checkbox-cont settings">
          <Checkbox
            disabled={isLoading}
            label={__('Read')}
            checked={permissions.includes(userPermissions.settings_read)}
            inputId="read-settings"
            handleChange={() => {
              setDirty(true);
              handlePermissionChange(userPermissions.settings_read);
            }}
          />
          <Checkbox
            disabled={isLoading}
            label={__('Manage')}
            checked={permissions.includes(userPermissions.settings_write)}
            inputId="manage-settings"
            handleChange={() => {
              setDirty(true);
              handlePermissionChange(userPermissions.settings_write);
            }}
          />
        </div>
        <Label inputId="template-permissions" text={__('Billing')} />
        <div className="row checkbox-cont billing">
          <Checkbox
            disabled={isLoading}
            label={__('Read')}
            checked={permissions.includes(userPermissions.billing_read)}
            inputId="read-billing"
            handleChange={() => {
              setDirty(true);
              handlePermissionChange(userPermissions.billing_read);
            }}
          />
          <Checkbox
            disabled={isLoading}
            label={__('Manage')}
            checked={permissions.includes(userPermissions.billing_write)}
            inputId="manage-billing"
            handleChange={() => {
              setDirty(true);
              handlePermissionChange(userPermissions.billing_write);
            }}
          />
        </div>
        <Label inputId="template-permissions" text={__('Platform users')} />
        <div className="row checkbox-cont platform-users">
          <Checkbox
            disabled={isLoading}
            label={__('Read')}
            checked={permissions.includes(userPermissions.platform_users_read)}
            inputId="read-platform-users"
            handleChange={() => {
              setDirty(true);
              handlePermissionChange(userPermissions.platform_users_read);
            }}
          />
          <Checkbox
            disabled={isLoading}
            label={__('Manage')}
            checked={permissions.includes(userPermissions.platform_users_write)}
            inputId="manage-platform-users"
            handleChange={() => {
              setDirty(true);
              handlePermissionChange(userPermissions.platform_users_write);
            }}
          />
        </div>
        {isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={dirty}
            closeAlert={() => setDirtyFormDisplay(false)}
            closeCb={closeCb}
          />
        )}
      </div>
    </SlidePane>
  );
};

PermissionTemplateForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  template: PropTypes.object,
};

PermissionTemplateForm.defaultProps = {
  template: null,
};

export default PermissionTemplateForm;
