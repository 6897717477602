import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { userPermissions } from 'shared/constants';
import { PermissionCheck } from 'shared/components';
import ProductList from '../ProductList';
import ProductContainer from '../ProductContainer';
import CreateProductForm from '../CreateProductForm';

const ProductsContainer = () => (
  <Switch>
    <Route exact path="/:companyId/products" component={PermissionCheck(ProductList, userPermissions.products_read)} />
    <Route exact path="/:companyId/products/new-product" component={PermissionCheck(CreateProductForm, userPermissions.products_write)} />
    <Route path="/:companyId/products/:productId" component={PermissionCheck(ProductContainer, userPermissions.products_read)} />
  </Switch>
);

export default ProductsContainer;
