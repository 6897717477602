import { get } from 'lodash';
import { licenseTypes } from 'shared/constants';

export const showValidity = (data) => {
  const licenseType = get(data, 'default_license_type');
  const isSubscription = licenseType === licenseTypes.subscription;
  const isTimeLimited = licenseType === licenseTypes.time_limited;
  const isValidFrom = get(data, 'validity_type') === 'valid_from';
  if (
    (isSubscription || isTimeLimited) && isValidFrom
  ) {
    return true;
  }
  if (isTimeLimited && isValidFrom) {
    return true;
  }
  return false;
};

export const showValidityDuration = (data) => {
  const licenseType = get(data, 'default_license_type');
  if (licenseType === licenseTypes.time_limited) {
    return get(data, 'valid_duration');
  }
  if (licenseType === licenseTypes.subscription) {
    return get(data, 'subscription_duration');
  }

  return null;
};

export const showConsumption = (data) => {
  const licenseType = get(data, 'default_license_type');
  if (licenseType === licenseTypes.consumption) {
    return true;
  }
  return false;
};

export const showValue = (data, field, value) => {
  const fieldToCheck = get(data, `${field}`);

  if (fieldToCheck === value) {
    return true;
  }

  return false;
};

export const showLicenseUsers = (users = []) => {
  if (!users) {
    return '';
  }
  if (Array.isArray(users)) {
    return users.join(', ');
  }
  return users.split(',').join(', ');
};

export const hasSelectedProductFeatures = (data) => {
  const features = get(data, 'selected_features');
  if (features.length) {
    return true;
  }
  return false;
};

export const hasSelectedCustomFields = (data) => {
  const customFields = get(data, 'selected_custom_fields');
  if (customFields.length) {
    return true;
  }
  return false;
};

export const displayExpirationDate = (license) => {
  const isTimeLimited = get(license, 'default_license_type') === licenseTypes.time_limited;
  const isSubscription = get(license, 'default_license_type') === licenseTypes.subscription;
  const validityPeriod = get(license, 'validity_period');

  if ((isTimeLimited || isSubscription) && validityPeriod) {
    return true;
  }

  return false;
};

export const displayTrialValidityDate = (license) => {
  const isTrial = get(license, 'is_trial');
  const trialDays = get(license, 'trial_days');

  if (isTrial && !trialDays) {
    return true;
  }

  return false;
};
