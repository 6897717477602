import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import {
  Button,
  ContentLoader,
  DescriptionTable,
  Label,
  CheckboxSelector,
  Notification,
  PermissionMissingNotificationTitle,
} from 'shared/components';
import {
  sendErrorReport,
  isFeatureAvailable,
  isFeatureEnabled,
  getDisabledMessage,
  checkUserPermission,
} from 'shared/helpers';
import { platformFeatures, userPermissions } from 'shared/constants';
import {
  getDefaultPermissions,
  updateDefaultPermissions,
} from 'src/order/actions';
import './styles.scss';

const DefaultPermissions = ({
  companyID,
  permissionsList,
}) => {
  const canManageOrders = checkUserPermission(userPermissions.orders_write);
  const [isDirty, setDirty] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [permissions, setPermissions] = useState([]);

  const getInitialList = (data) => {
    const allowsProductBundles = isFeatureEnabled(platformFeatures.extra_product_bundle) && isFeatureAvailable(platformFeatures.extra_product_bundle);
    const list = [];
    data.forEach((i) => {
      const found = permissionsList.find(p => p.id === i.license_manager_permission);
      if (found) {
        const isCreateBundleOrder = get(found, 'code') === 'create_bundle_order';
        if (isCreateBundleOrder) {
          if (allowsProductBundles) {
            list.push(found);
          }
        } else {
          list.push(found);
        }
      }
    });
    return list;
  };

  const fetchDefaultManagerPermissions = useCallback(() => {
    getDefaultPermissions(companyID)
      .then((res) => {
        const defaultList = get(res, 'data.results') || [];
        setPermissions(getInitialList(defaultList));
        setLoading(false);
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot get default manager permissions');
        setLoading(false);
      });
  }, [companyID]);

  useEffect(() => {
    fetchDefaultManagerPermissions();
  }, [fetchDefaultManagerPermissions]);


  const handleSubmit = () => {
    if (!canManageOrders) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.orders_write} />,
        __('Contact you account admin for support.'),
      );
      return false;
    }

    if (isSubmitting || !isDirty) {
      return false;
    }

    setSubmitting(true);
    const data = {
      lm_perm_ids: permissions.map(p => p.id),
    };

    updateDefaultPermissions(companyID, data)
      .then(() => {
        Notification('success', __('Changes saved successfully'));
        setSubmitting(false);
        setDirty(false);
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot update manager permissions', data);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
        setSubmitting(false);
      });
    return true;
  };

  if (isLoading) { return <div className="DefaultPermissions"><ContentLoader text={__('Getting default manager permissions')} /></div>; }

  return (
    <div className="DefaultPermissions">
      <Prompt
        when={isDirty}
        message={__('You have unsaved changes. Are you sure you want to leave?')}
      />
      <div className="LicenseManagerPage-permissions">
        <div className="checkbox-selector">
          <Label text={__('Select default permissions')} />
          <CheckboxSelector
            featureEnabled={isFeatureEnabled(platformFeatures.extra_distribution_portal)}
            featureAvailable={isFeatureAvailable(platformFeatures.extra_distribution_portal)}
            notEnabledMessage={getDisabledMessage()}
            text={__('Default permissions')}
            options={permissionsList}
            value={permissions}
            onChangeCallback={(val) => {
              setDirty(true);
              setPermissions(val);
            }}
            onMenuClose={() => { }}
            disabled={isSubmitting}
          />
        </div>
        <DescriptionTable
          details={permissions.map(p => ({ ...p, value: p.desc }))}
          noDataText={__('No default permissions have been created')}
        />
      </div>
      <Button
        featureEnabled={isFeatureEnabled(platformFeatures.extra_distribution_portal)}
        featureAvailable={isFeatureAvailable(platformFeatures.extra_distribution_portal)}
        notEnabledMessage={getDisabledMessage()}
        disabled={isSubmitting}
        size="lg"
        theme="success"
        onClick={handleSubmit}
      >
        {__('Save changes')}
      </Button>
    </div>
  );
};

DefaultPermissions.propTypes = {
  companyID: PropTypes.number.isRequired,
  permissionsList: PropTypes.array.isRequired,
};

export default DefaultPermissions;
