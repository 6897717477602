import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  Checkbox,
  Label,
  InputErrorMessage,
  MultiEmailInput,
  NumberInput,
} from 'shared/components';
import { platformFeatures } from 'shared/constants';
import {
  isFeatureEnabled,
  isFeatureAvailable,
  getDisabledMessage,
} from 'shared/helpers';
import { checkCompanyConfigField } from 'shared/companyConfig';

const LicenseOptionsSection = ({
  companyID,
  maxActivations,
  maxActivationsError,
  allowUnlimitedActivations,
  handleAllowUnlimitedActivations,
  handleMaxActivationsChange,
  isUserBasedProduct,
  licenseQuantity,
  licenseQuantityError,
  handleLicenseQuantityChange,
  licenseUsers,
  handleLicenseUsersChange,
  preventVm,
  handlePreventVmChange,
  preventMaxTransfers,
  setPreventMaxTransfers,
  hasMaxTransfers,
  maxTransfersError,
  handleHasMaxTransfersChange,
  maxTransfers,
  handleMaxTransfersChange,
  isFloatingCloud,
  maxLicenseUsers,
  maxLicenseUsersError,
  handleMaxLicenseUsers,
  unlimitedMaxLicenseUsers,
  handleUnlimitedMaxLicenseUsers,
}) => {
  const notAvailableMessage = (
    <>
      {__('Virtual machine detection and management is not available in your plan.')}
      &nbsp;
      {__('Contact sales to support VM prevention.')}
    </>
  );
  const notAvailableMaxTransfersMessage = (
    <>
      {__('Device transfer limits are not available in your plan.')}
      &nbsp;
      {__('Contact sales to support device transfer limit.')}
    </>
  );

  return (
    <section className="LicenseOptionsSection">
      <div className="section-header">{__('License options')}</div>
      {checkCompanyConfigField(companyID, 'maxActivations') && (
        <div className="section-row initial-checkbox">
          <Label text={__('Max Activations')} inputId="max-activations-input" />
          <NumberInput
            handleChange={handleMaxActivationsChange}
            value={maxActivations}
            error={maxActivationsError}
            min="1"
            max="2147483647"
            id="max-activations-input"
            disabled={allowUnlimitedActivations}
          />
          <Checkbox
            label={__('Allow unlimited activations')}
            inputId="allow-unlimited-activations-checkbox"
            checked={allowUnlimitedActivations}
            handleChange={handleAllowUnlimitedActivations}
          />
        </div>
      )}
      <div className="section-row">
        <Checkbox
          label={__('Prevent device transfers')}
          inputId="prevent-transfers-checkbox"
          checked={preventMaxTransfers}
          handleChange={val => setPreventMaxTransfers(val)}
          featureEnabled={isFeatureEnabled(platformFeatures.extra_max_transfers)}
          notEnabledMessage={getDisabledMessage()}
          featureAvailable={isFeatureAvailable(platformFeatures.extra_max_transfers)}
          notAvailableMessage={notAvailableMaxTransfersMessage}
        />
      </div>
      <div className="section-row">
        <Checkbox
          label={__('Has device transfer limit')}
          // description={__('Only when is floating cloud')}
          inputId="max-transfers-checkbox"
          checked={hasMaxTransfers}
          handleChange={handleHasMaxTransfersChange}
          featureEnabled={isFeatureEnabled(platformFeatures.extra_max_transfers)}
          notEnabledMessage={getDisabledMessage()}
          featureAvailable={isFeatureAvailable(platformFeatures.extra_max_transfers)}
          notAvailableMessage={notAvailableMaxTransfersMessage}
        />
        {!!hasMaxTransfers && (
          <NumberInput
            handleChange={handleMaxTransfersChange}
            value={maxTransfers}
            error={maxTransfersError}
            min="1"
            max="2147483647"
            id="max-transfers-input"
            placeholder={__('Device transfer limit')}
          />
        )}
      </div>
      {!isUserBasedProduct && (
        <div className="section-row">
          <Label text={__('Number of license keys')} inputId="license-num-input" />
          <NumberInput
            handleChange={handleLicenseQuantityChange}
            value={licenseQuantity}
            error={licenseQuantityError}
            min="1"
            max="1000"
            id="license-num-input"
          />
        </div>
      )}
      {isUserBasedProduct && (
        <div className="max-users">
          <div className="section-row row-max-license-users">
            <Label text={__('Max license users')} />
            <NumberInput
              handleChange={handleMaxLicenseUsers}
              value={maxLicenseUsers}
              error={maxLicenseUsersError}
              min="0"
              max="2147483647"
              disabled={unlimitedMaxLicenseUsers}
            />
          </div>
          <div className="section-row row-max-license-users-checkbox">
            <Checkbox
              label={__('Unlimited users')}
              description={__('Only when is floating cloud')}
              inputId="max-license-users-checkbox"
              checked={unlimitedMaxLicenseUsers}
              handleChange={handleUnlimitedMaxLicenseUsers}
              disabled={!isFloatingCloud}
            />
          </div>
        </div>
      )}
      {isUserBasedProduct && (
        <div className="section-row">
          <Label
            text={__('Users')}
            inputId="multi-email-input"
            description={__('Type or paste email addresses and press `Enter`...')}
          />
          <MultiEmailInput
            emails={get(licenseUsers, 'emails') || []}
            onEmailSubmit={handleLicenseUsersChange}
            id="multi-email-input"
          />
          <InputErrorMessage text={get(licenseUsers, 'validationError') || ''} />
        </div>
      )}
      <div className="section-row">
        <Checkbox
          label={__('Prevent virtual machine')}
          inputId="product-prevent-vm"
          checked={preventVm}
          handleChange={handlePreventVmChange}
          featureEnabled={isFeatureEnabled(platformFeatures.extra_prevent_vm)}
          notEnabledMessage={getDisabledMessage()}
          featureAvailable={isFeatureAvailable(platformFeatures.extra_prevent_vm)}
          notAvailableMessage={notAvailableMessage}
        />
      </div>
    </section>
  );
};

LicenseOptionsSection.propTypes = {
  companyID: PropTypes.number.isRequired,
  isUserBasedProduct: PropTypes.bool,
  maxActivations: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxActivationsError: PropTypes.string,
  allowUnlimitedActivations: PropTypes.bool,
  handleAllowUnlimitedActivations: PropTypes.func.isRequired,
  handleMaxActivationsChange: PropTypes.func.isRequired,
  licenseQuantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  licenseQuantityError: PropTypes.string,
  handleLicenseQuantityChange: PropTypes.func.isRequired,
  licenseUsers: PropTypes.object.isRequired,
  handleLicenseUsersChange: PropTypes.func.isRequired,
  preventVm: PropTypes.bool,
  handlePreventVmChange: PropTypes.func.isRequired,
  preventMaxTransfers: PropTypes.bool,
  setPreventMaxTransfers: PropTypes.func.isRequired,
  hasMaxTransfers: PropTypes.bool,
  handleHasMaxTransfersChange: PropTypes.func.isRequired,
  maxTransfers: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxTransfersError: PropTypes.string,
  handleMaxTransfersChange: PropTypes.func.isRequired,
  isFloatingCloud: PropTypes.bool,
  maxLicenseUsers: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxLicenseUsersError: PropTypes.string,
  handleMaxLicenseUsers: PropTypes.func.isRequired,
  unlimitedMaxLicenseUsers: PropTypes.bool,
  handleUnlimitedMaxLicenseUsers: PropTypes.func.isRequired,
};

LicenseOptionsSection.defaultProps = {
  maxActivations: '',
  maxActivationsError: '',
  allowUnlimitedActivations: false,
  isUserBasedProduct: false,
  licenseQuantity: '',
  licenseQuantityError: '',
  preventVm: false,
  preventMaxTransfers: false,
  hasMaxTransfers: false,
  maxTransfers: 0,
  maxTransfersError: '',
  isFloatingCloud: false,
  maxLicenseUsers: '',
  maxLicenseUsersError: '',
  unlimitedMaxLicenseUsers: false,
};

export default LicenseOptionsSection;
