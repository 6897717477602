import React from 'react';
import PropTypes from 'prop-types';
import {
  IconInfo,
  IconStatusInfo,
  IconStatusError,
  IconStatusSuccess,
} from 'shared/components';

const LevelIcon = ({
  level,
}) => {
  if (level === 'info') {
    return <IconStatusInfo fill="#1d71c5" />;
  }
  if (level === 'error') {
    return <IconStatusError fill="#AE0721" />;
  }
  if (level === 'success') {
    return <IconStatusSuccess fill="#0e7359" />;
  }
  if (level === 'warning') {
    return <IconInfo height="18px" width="18px" fill="#ff9f43" />;
  }
  return <IconStatusInfo fill="#1d71c5" />;
};

LevelIcon.propTypes = {
  level: PropTypes.string,
};

LevelIcon.defaultProps = {
  level: null,
};

export default LevelIcon;
