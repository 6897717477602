import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import MissingPolicyAlert from './components/MissingPolicyAlert';
import EmailConfiguration from './components/EmailConfiguration';
import './styles.scss';

const Emails = () => {
  const notificationPolicies = useSelector(state => get(state, 'notifications.notification_policies'));

  if (!notificationPolicies || !notificationPolicies.length) {
    return <div className="Emails"><MissingPolicyAlert /></div>;
  }

  return (
    <div className="Emails">
      <EmailConfiguration />
    </div>
  );
};

export default Emails;
