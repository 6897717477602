/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { sendErrorReport } from 'shared/helpers';
import {
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  Notice,
  TextInput,
} from 'shared/components';
import {
  validateRequiredValue,
  debouncedValidateRequiredValue,
} from 'shared/validation';
import { changeRedirectUri } from 'src/customer/actions';
import './styles.scss';

const SSOForm = ({
  accountID,
  companyID,
  userPool,
  refetchUserPool,
  closeCb,
}) => {
  const initialRedirectURI = get(userPool, 'redirect_uri');

  const [isLoading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false);
  const [uri, setUri] = useState({
    value: initialRedirectURI || '',
    error: '',
  });

  const validateValue = async (val, cb) => {
    setLoading(true);
    let errors;
    try {
      errors = await validateRequiredValue(val);
      cb(prev => ({ ...prev, error: errors }));
    } catch (err) {
      sendErrorReport(err, 'Cannot validate sso form value', { value: val });
    }
    setLoading(false);
    if (errors) { return false; }
    return true;
  };

  const isFormValid = async () => {
    const isUriValid = await validateValue(uri.value, setUri);
    return isUriValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isDirty) {
      closeCb();
      return false;
    }
    const isValid = await isFormValid();
    if (!isValid || isLoading) { return false; }

    setLoading(true);

    const data = { redirect_uri: uri.value };

    changeRedirectUri(accountID, companyID, data)
      .then(() => {
        Notification('success', __('Changes saved successfully'));
        refetchUserPool();
        closeCb();
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot edit redirect uri', data);
        setLoading(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
    return true;
  };

  const handleClose = () => {
    if (!isDirty) { return closeCb(); }
    return setDirtyFormAlertDisplay(true);
  };

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={isLoading}
      title={__('Edit redirect uri')}
      size="sm"
    >
      <div className="SSOForm">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <Notice size="sm">{__('Set this redirect url if you are planing to use SSO via License API instead of SDK')}</Notice>
          </div>
          <div className="row">
            <Label text={__('Redirect uri')} inputId="redirect_uri" />
            <TextInput
              id="uri"
              value={get(uri, 'value')}
              error={get(uri, 'error')}
              handleChange={(val) => {
                setDirty(true);
                setUri(prev => ({ ...prev, value: val, loading: true }));
                debouncedValidateRequiredValue(val).then(err => setUri(prev => ({ ...prev, error: err })));
              }}
              placeholder={__('Eg. https://test.example.com')}
            />
          </div>
        </form>
        {isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={isDirty}
            closeAlert={() => setDirtyFormAlertDisplay(false)}
            closeCb={closeCb}
          />
        )}
      </div>
    </Modal>
  );
};

SSOForm.propTypes = {
  accountID: PropTypes.number.isRequired,
  companyID: PropTypes.number.isRequired,
  userPool: PropTypes.object.isRequired,
  refetchUserPool: PropTypes.func.isRequired,
  closeCb: PropTypes.func.isRequired,
};

export default SSOForm;
