import * as actionTypes from './actionTypes';

const initialState = {
  loading: true,
  events: [],
  policiesLoading: true,
  notification_policies: [],
  loadingWebhookEvents: true,
  webhookEvents: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.getEventsInit:
      return Object.assign({}, state, {
        loading: true,
      });
    case actionTypes.getEventsSuccess:
      return Object.assign({}, state, {
        loading: false,
        events: action.payload,
      });
    case actionTypes.getEventsError:
      return Object.assign({}, state, {
        loading: false,
      });
    case actionTypes.getNotificationsInit:
      return Object.assign({}, state, {
        policiesLoading: true,
      });
    case actionTypes.getNotificationsSuccess:
      return Object.assign({}, state, {
        policiesLoading: false,
        notification_policies: action.payload,
      });
    case actionTypes.getNotificationsError:
      return Object.assign({}, state, {
        policiesLoading: false,
      });
    case actionTypes.getWebhookEventsInit:
      return Object.assign({}, state, {
        loadingWebhookEvents: true,
      });
    case actionTypes.getWebhookEventsSuccess:
      return Object.assign({}, state, {
        loadingWebhookEvents: false,
        webhookEvents: action.payload,
      });
    case actionTypes.getWebhookEventsError:
      return Object.assign({}, state, {
        loadingWebhookEvents: false,
      });
    default:
      return state;
  }
}
