/* eslint-disable no-useless-escape */

export const searchType = {
  all: 'all',
  license: 'license',
  order: 'store_id',
  user: 'customer__email',
  hwid: 'devices__hardware_id',
  hostname: 'devices__hostname',
  os: 'devices__os',
  email: 'email',
  name: 'name',
  code: 'code',
  reference: 'reference',
  trueEmail: 'true_email',
  licenseNumber: 'license_key',
  // licenseUserEmail: 'license_user.true_email',
  licenseUserEmail: 'license_users.true_email',
  note: 'note',
  customerEmail: 'customer_email',
  customerCompany: 'customer_company_name',
  customerReference: 'customer__reference',
  orderReference: 'customer_reference',
  customerName: 'customer_name',
  firstName: 'first_name',
  lastName: 'last_name',
  phoneNumber: 'phone',
  companyName: 'company_name',
  customerAccountName: 'customer__account__name',
  customerAccountCode: 'customer__account__code',
  customerAccountReference: 'customer__account__reference',
  accountCode: 'account__code',
  accountName: 'account__name',
  productName: 'product_name',
  productCode: 'short_code',
};

export const defaultDateTimeFormat = 'DD/MM/YYYY HH:mm';

export const validityPeriodTimeFormat = 'YYYY-MM-DD HH:mm:ss';

export const defaultTimeFormat = 'HH:mm';

export const defaultDateFormat = 'DD/MM/YYYY';

export const APIdateFormat = 'YYYY-MM-DD';

export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

export const numberRegex = /\d+/g;
export const lettersRegex = /[a-zA-Z]+/g;
export const versionRegex = /^v?\d+(\.\d+){0,2}$/;

export const productFeatureCodeMaxLength = 50;

export const customFieldNameMaxLength = 50;

export const licenseTypesTitles = {
  normal: 'Normal/Trial',
  subscription: 'Subscription',
  node_locked: 'Node locked',
  user_locked: 'User locked',
  concurrent: 'Concurrent',
};

export const productFeatureTypes = {
  activation: 'activation',
  consumption: 'consumption',
};

export const licenseTypes = {
  perpetual: 'perpetual',
  subscription: 'subscription',
  time_limited: 'time-limited',
  consumption: 'consumption',
};

export const environmentOptions = [
  { label: 'Linux 32/64', value: 'linux' },
  { label: 'Linux 32', value: 'linux32' },
  { label: 'Linux 64', value: 'linux64' },
  { label: 'Mac OS', value: 'mac' },
  { label: 'Windows 32/64', value: 'win' },
  { label: 'Windows 32', value: 'win32' },
  { label: 'Windows 64', value: 'win64' },
];

export const validationTimeDefault = 300;
export const validationTimeLong = 1000;

export const errorMsg = {
  alphanumeric: 'Use lowercase characters and numbers only',
  consumptionBiggerThanZero: 'Selected consumption values have to be bigger than zero',
  customerRequiredAssignManager: 'Select customer if you want to assign it as license manager',
  cfsInvalidValue: 'Custom field is missing a value',
  codeDuplicate: 'This code is already in use',
  codeMaxChars: 'Product code can have max 20 characters',
  companyDuplicate: 'This company name is already in use',
  dateYYYYMMDDformat: 'Please enter date in YYYY-MM-DD format',
  emailDuplicate: 'This email is already in use',
  globalError: 'There was an error, please try again later',
  longHash: 'File hash  can\'t have more than 32 characters',
  invalidData: 'Enter valid data',
  invalidEmail: 'Enter valid email',
  invalidEmails: 'Enter valid emails',
  invalidJson: 'Invalid JSON',
  invalidJsonObject: 'Invalid JSON object',
  invalidUrl: 'Enter valid URL',
  invalidVersionFormat: 'Version should consist of max 3 numbers separated with a dot and optional `v` in front - ex: 1.2.3, v1.2, 0.0.1',
  maxActivations: 'Max activations number cannot be lower than max license users',
  maxActivationsDevices: 'Number of max activations is less than the number of currently active devices – please deactivate one or more devices before decreasing the number of activations.',
  maxChars: 'Max allowed characters count for this field:',
  maxSimultaneousUsers: 'Max simultaneous license users number cannot be higher than max activations',
  maxSimultaneousUsersLessThanInUse: 'Max simultaneous license users number cannot be lower than currently in use devices. Please deactivate or revoke one or more devices before decreasing the number of simultaneous users.',
  maxQuantity: 'Max allowed quantity is: ',
  nameDuplicate: 'This product name is already in use',
  nameDuplicateGeneric: 'This name is already in use',
  nameArchivedDuplicate: 'Product with this is name is archived. Change the name or activate archived product.',
  notAssigned: 'Not assigned',
  passCharNum: 'Password should contain at least 8 characters',
  passCurrent: 'Enter your current password',
  passLowercase: 'Password should contain at least 1 lowercase letter',
  passMatch: 'Passwords do not match',
  passNumber: 'Password should contain at least 1 number',
  passUppercase: 'Password should contain at least 1 uppercase letter',
  required: 'This field is required',
  requiredFile: 'This file is required',
  selectCustomer: 'Select customer from the dropdown',
  selectErr: 'Select from dropdown',
  tooManyEmails: 'You have entered too many emails, check your max license users number input',
  unsubmittedEmail: 'In order to submit a user email, please add the email address of your desired user and press enter',
  userDuplicate: 'A user is already a member of this company.',
};

export const initialOrderListColumns = [{
  label: 'Created on',
  value: 'created_at',
},
{
  label: 'Customer email',
  value: 'customer.email',
},
{
  label: 'Customer name',
  value: 'customer.last_name',
},
{
  label: 'Customer account',
  value: 'customer.customer_account.name',
},
{
  label: 'Order ID',
  value: 'store_id',
},
];

export const initialOrderListSort = [{
  desc: true,
  id: 'created_at',
}];

export const orderListColumns = [{
  label: 'Created on',
  value: 'created_at',
},
{
  label: 'Customer email',
  value: 'customer.email',
},
{
  label: 'Customer name',
  value: 'customer.last_name',
},
{
  label: 'Customer company',
  value: 'customer.company_name',
},
{
  label: 'Customer account',
  value: 'customer.customer_account.name',
},
{
  label: 'Customer labels',
  value: 'customer.labels',
},
{
  label: 'Customer reference',
  value: 'customer.reference',
},
{
  label: 'Order ID',
  value: 'store_id',
},
{
  label: 'Order reference',
  value: 'customer_reference',
},
{
  label: 'Last updated',
  value: 'updated_at',
},
{
  label: 'Type',
  value: 'type',
},
{
  label: 'Campaign parameters',
  value: 'campaign_params',
},
{
  label: 'Is trial',
  value: 'is_trial',
},
{
  label: 'Language',
  value: 'language',
},
{
  label: 'ID',
  value: 'id',
},
];

export const initialLicenseListColumns = [{
  label: 'Created on',
  value: 'created_at',
},
{
  label: 'License Number',
  value: 'license_key',
},
{
  label: 'Product',
  value: 'product',
},
{
  label: 'License Type',
  value: 'license_type',
},
{
  label: 'Expiration Date',
  value: 'validity_period',
},
{
  label: 'Times activated',
  value: 'times_activated',
},
{
  label: 'Is trial',
  value: 'is_trial',
},
{
  label: 'Status',
  value: 'enabled',
},
];

export const initialLicenseListSort = [{
  desc: true,
  id: 'created_at',
}];

export const licenseListColumns = [{
  label: 'Created on',
  value: 'created_at',
},
{
  label: 'License Number',
  value: 'license_key',
},
{
  label: 'Product',
  value: 'product',
},
{
  label: 'License Type',
  value: 'license_type',
},
{
  label: 'Expiration Date',
  value: 'validity_period',
},
{
  label: 'Is trial',
  value: 'is_trial',
},
{
  label: 'Status',
  value: 'enabled',
},
{
  label: 'Times activated',
  value: 'times_activated',
},
{
  label: 'Max activations',
  value: 'max_activations',
},
{
  label: 'Prevent virtual machine',
  value: 'prevent_vm',
},
{
  label: 'Customer email',
  value: 'customer.email',
},
{
  label: 'Customer name',
  value: 'customer.last_name',
},
{
  label: 'Customer company',
  value: 'customer.company_name',
},
{
  label: 'Customer account',
  value: 'customer.customer_account.name',
},
{
  label: 'Customer reference',
  value: 'customer.reference',
},
{
  label: 'Customer labels',
  value: 'customer.labels',
},
{
  label: 'Active',
  value: 'active',
},
{
  label: 'Start Date',
  value: 'start_date',
},
{
  label: 'Is hardware key',
  value: 'is_hardware_key_auth',
},
{
  label: 'Is air-gapped',
  value: 'is_air_gapped',
},
{
  label: 'Is bundle',
  value: 'is_bundle',
},
{
  label: 'Is floating',
  value: 'is_floating',
},
{
  label: 'Is floating cloud',
  value: 'is_floating_cloud',
},
{
  label: 'Max simultaneous license users',
  value: 'floating_users',
},
{
  label: 'Can borrow',
  value: 'can_borrow',
},
{
  label: 'Max borrow time',
  value: 'max_borrow_time',
},
{
  label: 'Max license users',
  value: 'max_license_users',
},
{
  label: 'Enable maintenance period',
  value: 'enable_maintenance_period',
},
{
  label: 'Maintenance duration',
  value: 'maintenance_duration',
},
{
  label: 'Maintenance End Date',
  value: 'maintenance_period',
},
{
  label: 'Max consumptions',
  value: 'max_consumptions',
},
{
  label: 'Total consumptions',
  value: 'total_consumptions',
},
{
  label: 'Product features',
  value: 'product_features',
},
{
  label: 'Disabled on',
  value: 'time_disabled',
},
{
  label: 'Activated on',
  value: 'time_activated',
},
{
  label: 'Last updated',
  value: 'updated_at',
},
{
  label: 'Valid duration',
  value: 'valid_duration',
},
{
  label: 'Order',
  value: 'order',
},
{
  label: 'Last checked',
  value: 'last_check',
},
{
  label: 'Active Devices Up to Date',
  value: 'active_up_to_date_devices',
},
{
  label: 'Note',
  value: 'note',
},
];

export const enabledFilterFields = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'Enabled',
    label: 'Enabled',
  },
  {
    value: 'Disabled',
    label: 'Disabled',
  },
];

export const initialDevicesListSort = [{
  desc: true,
  id: 'last_check',
}];

export const initialDeviceCertificatesListSort = [{
  desc: true,
  id: 'last_check',
}];

export const initialCustomerAccountsListSort = [{
  desc: false,
  id: 'name',
}];

export const initialCustomersListSort = [{
  desc: true,
  id: 'email',
}];

export const initialCustomersListFilter = {
  clabels: [],
};

export const initialLicenseListFilter = {
  clabels: [],
  enabled: enabledFilterFields[0],
  validity_period_lte: '',
  product: 'all',
};

export const initialAuditLogFilter = {
  labels: [],
};

export const initialOrdersListFilter = {
  clabels: [],
};

export const initialLicenseListSize = 20;

export const initialOrderListSize = 20;

export const initialCustomersListSize = 20;

export const initialPortalData = {
  topHeaderBackground: '#555555',
  sidebarBackground: '#ffffff',
  pageBackground: '#fafafa',
  showProductLinks: false,
  allowUsersLicenseReset: true,
  allowUsersLicenseBorrow: false,
  loginTitle: '',
  loginMessage: '',
};

export const initialOfflinePortalData = {
  background: '#fafafa',
  text: {
    colorPrimary: '#464854',
    colorSecondary: '#525462',
  },
  button: {
    background: '#2e86de',
    textColor: '#fff',
  },
  accentColors: {
    success: '#10ac84',
    error: '#ee5253',
    info: '#2e86de',
  },
  title: '',
  message: '',
};

export const initialAirgapPortalData = {
  background: '#fafafa',
  text: {
    colorPrimary: '#464854',
    colorSecondary: '#525462',
  },
  button: {
    background: '#2e86de',
    textColor: '#fff',
  },
  accentColors: {
    success: '#10ac84',
    error: '#ee5253',
    info: '#2e86de',
  },
  title: '',
  message: '',
};

export const platformFeatures = {
  account_admin: 'account:admin',
  account_billing: 'account:billing',
  account_developer: 'account:developer',
  account_geo_server_restriction: 'account:geo-server-restriction',
  account_marketing: 'account:marketing',
  account_sub_roles: 'account:sub-roles',
  account_support: 'account:support',
  assignment_custom_auth: 'assignment:custom-authentication',
  assignment_key: 'assignment:key',
  assignment_node: 'assignment:node',
  assignment_user: 'assignment:user',
  check_offline: 'check:offline',
  check_offline_local: 'check:offline-local',
  check_online: 'check:online',
  deployment_maintenance: 'deployment:maintenance',
  deployment_product_upgrades: 'deployment:product-upgrades',
  deployment_product_versioning: 'deployment:product-versioning',
  extra_custom_fields: 'extra:custom-fields',
  extra_device_variables: 'extra:device-variables',
  extra_feature_licensing: 'extra:feature-licensing',
  extra_feature_licensing_activation: 'extra:feature-licensing-activation',
  extra_feature_licensing_consumption: 'extra:feature-licensing-consumption',
  extra_account_based_licensing: 'extra:account-based-licensing',
  extra_air_gapped: 'extra:air-gapped',
  extra_hardware_token: 'extra:hardware-token',
  extra_inapp_dialogue: 'extra:in-app-dialogue',
  extra_inapp_dialogue_branded: 'extra:in-app-dialogue-branded',
  extra_license_borrowing: 'extra:license:borrowing',
  extra_prevent_vm: 'extra:prevent-vm',
  extra_max_transfers: 'extra:max-transfers',
  extra_product_bundle: 'extra:product-bundle',
  extra_single_sign_on: 'extra:single-sign-on',
  extra_distribution_portal: 'extra:distribution-portal',
  extra_whitelabeling: 'extra:whitelabeling',
  extra_audit_log: 'extra:audit-log',
  extra_webhooks: 'extra:webhooks',
  extra_multiple_api_keys: 'extra:multiple-api-keys',
  integrations_enduser_offline_portal: 'integrations:end-user-offline-portal',
  integrations_enduser_offline_portal_branded: 'integrations:end-user-offline-portal-branded',
  integrations_enduser_self_serve_portal: 'integrations:end-user-self-serve-portal',
  integrations_enduser_self_serve_portal_branded: 'integrations:end-user-self-serve-portal-branded',
  integrations_enduser_white_label_api: 'integrations:end-user-white-label-api',
  integrations_fastspring: 'integrations:fastspring',
  integrations_salesforce: 'integrations:salesforce',
  integrations_paypal: 'integrations:paypal',
  integrations_stripe: 'integrations:stripe',
  integrations_2checkout: 'integrations:2checkout',
  model_consumption: 'model:consumption',
  model_floating_cloud: 'model:floating-cloud',
  model_floating_on_premises: 'model:floating-on-premises',
  model_perpetual: 'model:perpetual',
  model_subscription: 'model:subscription',
  model_time_limited: 'model:time-limited',
  model_trial: 'model:trial',
  usage_environments: 'usage:environments',
  usage_mal: 'usage:mal',
  usage_mrl: 'usage:mrl',
  usage_products: 'usage:products',
  reporting_data_export: 'reporting:data-export',
  reporting_surveys: 'reporting:surveys',
  reporting_source_tracking: 'reporting:source-tracking',
  reporting_usage_reports: 'reporting:usage-reports',
  reporting_data_retention: 'reporting:data-retention',
  reporting_data_retention_show: 'reporting:data-retention-show',
  platform_add_license: 'platform:add-license',
  platform_edit_license: 'platform:edit-license',
  platform_edit_customer: 'platform:edit-customer',
  platform_add_user: 'platform:add-user',
  platform_edit_user: 'platform:edit-user',
  platform_add_product: 'platform:add-product',
  platform_edit_product: 'platform:edit-product',
};

// value - number or -1 (unlimited) OR null
// based on pricing page
export const defaultFeaturesList = [
  {
    key: 'usage:mal',
    label: 'Monthly active users',
    value: 100000,
    is_active: true,
    plans: ['free', 'standard', 'enterprise'],
  },
  {
    key: 'usage:mrl',
    label: 'Monthly requests limit',
    value: 100000,
    is_active: true,
    plans: ['free', 'standard', 'enterprise'],
  },
  {
    key: 'usage:products',
    label: 'Products number',
    value: 50,
    is_active: true,
    plans: ['free', 'standard', 'enterprise'],
  },
  {
    key: 'usage:environments',
    label: 'Development environments',
    value: -1,
    is_active: true,
    plans: ['free', 'standard', 'enterprise'],
  },
  {
    key: 'assignment:node',
    label: 'Node locking license assigment',
    value: null,
    is_active: true,
    plans: ['free', 'standard', 'enterprise'],
  },
  {
    key: 'assignment:key',
    label: 'Key based license assigment',
    value: null,
    is_active: true,
    plans: ['free', 'standard', 'enterprise'],
  },
  {
    key: 'assignment:user',
    label: 'User based license assigment',
    value: null,
    is_active: true,
    plans: ['standard', 'enterprise'],
  },
  {
    key: 'assignment:custom-authentication',
    label: 'Custom authentication based license assigment',
    value: null,
    is_active: true,
    plans: ['enterprise'],
  },
  {
    key: 'check:online',
    label: 'Online - cloud license check',
    value: null,
    is_active: true,
    plans: ['free', 'standard', 'enterprise'],
  },
  {
    key: 'check:offline',
    label: 'Offline - Local License File Check',
    value: null,
    is_active: true,
    plans: ['standard', 'enterprise'],
  },
  {
    key: 'check:offline-local',
    label: 'Offline License Check',
    value: null,
    is_active: true,
    plans: ['standard', 'enterprise'],
  },
  {
    key: 'model:trial',
    label: 'Trial licenses',
    value: null,
    is_active: true,
    plans: ['free', 'standard', 'enterprise'],
  },
  {
    key: 'model:perpetual',
    label: 'Perpetual licenses',
    value: null,
    is_active: true,
    plans: ['free', 'standard', 'enterprise'],
  },
  {
    key: 'model:time-limited',
    label: 'Time limited licenses',
    value: null,
    is_active: true,
    plans: ['free', 'standard', 'enterprise'],
  },
  {
    key: 'model:subscription',
    label: 'Subscription licenses',
    value: null,
    is_active: true,
    plans: ['standard', 'enterprise'],
  },
  {
    key: 'model:floating-cloud',
    label: 'Floating licenses (cloud)',
    value: null,
    is_active: true,
    plans: ['enterprise'],
  },
  {
    key: 'model:floating-on-premises',
    label: 'Floating licenses (on premises server)',
    value: null,
    is_active: true,
    plans: ['enterprise'],
  },
  {
    key: 'model:consumption',
    label: 'Consumption licenses',
    value: null,
    is_active: true,
    plans: ['enterprise'],
  },
  {
    key: 'extra:feature-licensing',
    label: 'Feature licensing',
    value: null,
    is_active: true,
    plans: ['standard', 'enterprise'],
  },
  {
    key: 'extra:feature-licensing-consumption',
    label: 'Feature licensing (consumption)',
    value: -1,
    is_active: true,
    plans: ['enterprise'],
  },
  {
    key: 'extra:feature-licensing-activation',
    label: 'Feature licensing (activation)',
    value: 5,
    is_active: true,
    plans: ['standard', 'enterprise'],
  },
  {
    key: 'extra:account-based-licensing',
    label: 'Account based licensing',
    value: null,
    is_active: true,
    plans: ['enterprise'],
  },
  {
    key: 'extra:air-gapped',
    label: 'Air gapped licensing',
    value: null,
    is_active: true,
    plans: ['enterprise'],
  },
  {
    key: 'extra:in-app-dialogue',
    label: 'In app dialogue screen',
    value: null,
    is_active: true,
    plans: ['standard', 'enterprise'],
  },
  {
    key: 'extra:in-app-dialogue-branded',
    label: 'In app dialogue screen (branded)',
    value: null,
    is_active: true,
    plans: ['free'],
  },
  {
    key: 'extra:custom-fields',
    label: 'Custom fields server-to-client',
    value: 1,
    is_active: true,
    plans: ['standard', 'enterprise'],
  },
  {
    key: 'extra:device-variables',
    label: 'Device custom variables',
    value: null,
    is_active: true,
    plans: ['enterprise'],
  },
  {
    key: 'extra:prevent-vm',
    label: 'Prevent VM',
    value: null,
    is_active: true,
    plans: ['enterprise'],
  },
  {
    key: 'extra:max-transfers',
    label: 'Device transfer limit',
    value: null,
    is_active: true,
    plans: ['standard', 'enterprise'],
  },
  {
    key: 'extra:single-sign-on',
    label: 'Single Sign On',
    value: null,
    is_active: true,
    plans: ['enterprise'],
  },
  {
    key: 'extra:license:borrowing',
    label: 'License borrowing',
    value: null,
    is_active: true,
    plans: ['enterprise'],
  },
  {
    key: 'extra:hardware-token',
    label: 'Hardware token',
    value: null,
    is_active: true,
    plans: ['enterprise'],
  },
  {
    key: 'extra:distribution-portal',
    label: 'Distribution portal',
    value: null,
    is_active: true,
    plans: ['enterprise'],
  },
  {
    key: 'extra:whitelabeling',
    label: 'Whitelabeling',
    value: null,
    is_active: true,
    plans: ['enterprise'],
  },
  {
    key: 'extra:audit-log',
    label: 'Audit log',
    value: null,
    is_active: true,
    plans: ['enterprise'],
  },
  {
    key: 'extra:webhooks',
    label: 'Webhooks',
    value: null,
    is_active: true,
    plans: ['enterprise'],
  },
  {
    key: 'extra:multiple-api-keys',
    label: 'Multiple API keys',
    value: null,
    is_active: true,
    plans: ['enterprise'],
  },
  {
    key: 'extra:product-bundle',
    label: 'Product bundle',
    value: null,
    is_active: true,
    plans: ['enterprise'],
  },
  {
    key: 'deployment:maintenance',
    label: 'Maintenance Period',
    value: null,
    is_active: true,
    plans: ['standard', 'enterprise'],
  },
  {
    key: 'deployment:product-versioning',
    label: 'Product Versioning',
    value: null,
    is_active: true,
    plans: ['standard', 'enterprise'],
  },
  {
    key: 'deployment:product-upgrades',
    label: 'Product upgrades',
    value: null,
    is_active: true,
    plans: ['enterprise'],
  },
  {
    key: 'integrations:fastspring',
    label: 'FastSpring integrations',
    value: null,
    is_active: true,
    plans: ['free', 'standard', 'enterprise'],
  },
  {
    key: 'integrations:salesforce',
    label: 'Salesforce integrations',
    value: null,
    is_active: true,
    plans: ['standard', 'enterprise'],
  },
  {
    key: 'integrations:paypal',
    label: 'PayPal integrations',
    value: null,
    is_active: true,
    plans: ['free', 'standard', 'enterprise'],
  },
  {
    key: 'integrations:2checkout',
    label: '2checkout integrations',
    value: null,
    is_active: true,
    plans: ['free', 'standard', 'enterprise'],
  },
  {
    key: 'integrations:stripe',
    label: 'Stripe integrations',
    value: null,
    is_active: true,
    plans: ['free', 'standard', 'enterprise'],
  },
  {
    key: 'integrations:end-user-offline-portal',
    label: 'End-user offline portal',
    value: null,
    is_active: true,
    plans: ['standard', 'enterprise'],
  },
  {
    key: 'integrations:end-user-offline-portal-branded',
    label: 'End-user offline portal (branded)',
    value: null,
    is_active: true,
    plans: ['standard', 'enterprise'],
  },
  {
    key: 'integrations:end-user-self-serve-portal',
    label: 'End-user self-serve portal',
    value: null,
    is_active: true,
    plans: ['enterprise'],
  },
  {
    key: 'integrations:end-user-self-serve-portal-branded',
    label: 'End-user self-serve portal (branded)',
    value: null,
    is_active: true,
    plans: ['standard'],
  },
  {
    key: 'integrations:end-user-white-label-api',
    label: 'End-user white label APIs',
    value: null,
    is_active: true,
    plans: ['enterprise'],
  },
  {
    key: 'reporting:data-export',
    label: 'Data export',
    value: null,
    is_active: true,
    plans: ['standard', 'enterprise'],
  },
  {
    key: 'reporting:usage-reports',
    label: 'License and product usage reports',
    value: null,
    is_active: true,
    plans: ['standard', 'enterprise'],
  },
  {
    key: 'reporting:crash-reports',
    label: 'Crash reports',
    value: null,
    is_active: true,
    plans: ['enterprise'],
  },
  {
    key: 'reporting:surveys',
    label: 'Surveys',
    value: null,
    is_active: true,
    plans: ['enterprise'],
  },
  {
    key: 'reporting:source-tracking',
    label: 'Source tracking',
    value: null,
    is_active: true,
    plans: ['enterprise'],
  },
  {
    key: 'account:admin',
    label: 'User Roles: admin',
    value: null,
    is_active: true,
    plans: ['free', 'standard', 'enterprise'],
  },
  {
    key: 'account:developer',
    label: 'User Roles: developer',
    value: null,
    is_active: true,
    plans: ['standard', 'enterprise'],
  },
  {
    key: 'account:support',
    label: 'User Roles: support',
    value: null,
    is_active: true,
    plans: ['standard', 'enterprise'],
  },
  {
    key: 'account:marketing',
    label: 'User Roles: marketing',
    value: null,
    is_active: true,
    plans: ['standard', 'enterprise'],
  },
  {
    key: 'account:billing',
    label: 'User Roles: billing',
    value: null,
    is_active: true,
    plans: ['standard', 'enterprise'],
  },
  {
    key: 'account:sub-roles',
    label: 'Sub accounts and roles',
    value: null,
    is_active: true,
    plans: ['enterprise'],
  },
  {
    key: 'account:geo-server-restriction',
    label: 'Geo-server restriction',
    value: null,
    is_active: true,
    plans: ['enterprise'],
  },
  {
    key: 'reporting:data-retention',
    label: 'Data retention policy delete',
    value: 30,
    is_active: true,
    plans: ['free', 'standard', 'enterprise'],
  },
  {
    key: 'reporting:data-retention-show',
    label: 'Data retention policy show',
    value: 30,
    is_active: true,
    plans: ['free', 'standard', 'enterprise'],
  },
  {
    key: 'platform:add-license',
    label: 'Platform - add license',
    value: null,
    is_active: true,
    plans: ['free', 'standard', 'enterprise'],
  },
  {
    key: 'platform:edit-license',
    label: 'Platform - edit license',
    value: null,
    is_active: true,
    plans: ['free', 'standard', 'enterprise'],
  },
  {
    key: 'platform:edit-customer',
    label: 'Platform - edit customer',
    value: null,
    is_active: true,
    plans: ['free', 'standard', 'enterprise'],
  },
  {
    key: 'platform:add-product',
    label: 'Platform - add product',
    value: null,
    is_active: true,
    plans: ['free', 'standard', 'enterprise'],
  },
  {
    key: 'platform:edit-product',
    label: 'Platform - edit product',
    value: null,
    is_active: true,
    plans: ['free', 'standard', 'enterprise'],
  },
  {
    key: 'platform:add-user',
    label: 'Platform - add user',
    value: null,
    is_active: true,
    plans: ['free', 'standard', 'enterprise'],
  },
  {
    key: 'platform:edit-user',
    label: 'Platform - edit user',
    value: null,
    is_active: true,
    plans: ['free', 'standard', 'enterprise'],
  },
];

export const defaultDisabledFeatureMessage = 'This feature is disabled';

export const planTypes = [
  {
    label: 'Free',
    value: 'free',
    pricing: 'Free forever',
  },
  {
    label: 'Standard',
    value: 'standard',
    pricing: '99$ monthly',
    disabledMessage: 'You have to add your payment method first in order to switch to Standard plan type',
  },
  {
    label: 'Enterprise',
    value: 'enterprise',
    pricing: 'Custom pricing',
    // isDisabled: true,
    disabledMessage: 'If you wish to have Enterprise plan type, please contact us',
  },
];

export const billingCycles = [
  {
    label: 'Billed monthly',
    value: 'monthly',
  },
  {
    label: 'Billed yearly (2 months free)',
    value: 'yearly',
  },
];

export const consumptionPeriods = [
  {
    label: 'Daily',
    value: 'daily',
  },
  {
    label: 'Weekly',
    value: 'weekly',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Annually',
    value: 'annually',
  },
];

export const currencyOptions = [
  {
    label: 'USD',
    value: 'usd',
  },
  {
    label: 'EUR',
    value: 'eur',
  },
  {
    label: 'GBP',
    value: 'gbp',
  },
  {
    label: 'CAD',
    value: 'cad',
  },
];

export const overageTypes = {
  custom: 'overages-custom',
  unlimited: 'overages-unlimited',
};

export const trialTypes = {
  days: 'trial_days',
  endDate: 'trial_end_date',
};

export const authMethods = {
  key: 'license-key',
  user: 'user',
};

export const validityTypes = {
  duration: 'duration',
  period: 'period',
};

export const regionData = {
  Canada: [
    {
      name: 'Alberta',
      shortCode: 'AB',
    },
    {
      name: 'British Columbia',
      shortCode: 'BC',
    },
    {
      name: 'Manitoba',
      shortCode: 'MB',
    },
    {
      name: 'New Brunswick',
      shortCode: 'NB',
    },
    {
      name: 'Newfoundland and Labrador',
      shortCode: 'NL',
    },
    {
      name: 'Northwest Territories',
      shortCode: 'NT',
    },
    {
      name: 'Nova Scotia',
      shortCode: 'NS',
    },
    {
      name: 'Nunavut',
      shortCode: 'NU',
    },
    {
      name: 'Ontario',
      shortCode: 'ON',
    },
    {
      name: 'Prince Edward Island',
      shortCode: 'PE',
    },
    {
      name: 'Quebec',
      shortCode: 'QC',
    },
    {
      name: 'Saskatchewan',
      shortCode: 'SK',
    },
    {
      name: 'Yukon',
      shortCode: 'YT',
    },
  ],
};

export const licenseAPIactions = {
  check: {
    endpoint: '/api/v4/check_license',
    label: 'License check',
    group_by: 'license',
  },
  activation: {
    endpoint: '/api/v4/activate_license',
    label: 'License activation',
    group_by: 'product',
  },
  activation_offline: {
    endpoint: '/api/v4/activate_offline',
    label: 'Offline license activation',
    group_by: 'product',
  },
  deactivation: {
    endpoint: '/api/v4/deactivate_license',
    label: 'License deactivation',
    group_by: 'product',
  },
  deactivation_offline: {
    endpoint: '/api/v4/deactivate_offline',
    label: 'Offline license deactivation',
    group_by: 'product',
  },
  trial_key: {
    endpoint: '/api/v4/trial_key',
    label: 'License trial key',
    group_by: 'product',
  },
  order: {
    endpoint: '',
    label: 'Order created',
    group_by: 'company',
  },
  license_issued: {
    endpoint: '',
    label: 'License issued',
    group_by: 'product',
  },
  keygen: {
    endpoint: '',
    label: 'License key generated',
    group_by: 'product',
  },
  consumption: {
    endpoint: '/api/v4/add_consumption',
    label: 'License consumption',
    group_by: 'license',
  },
  f_consumption: {
    endpoint: '/api/v4/add_feature_consumption',
    label: 'License feature consumption',
    group_by: 'license',
  },
  installation_file: {
    endpoint: '/api/v4/installation_file',
    label: 'Installation file',
    group_by: 'product',
  },
  versions: {
    endpoint: '/api/v4/versions',
    label: 'Versions',
    group_by: 'product',
  },
  floating_release: {
    endpoint: '/api/v4/floating/release',
    label: 'Floating release',
    group_by: 'license',
  },
  floating_borrow: {
    endpoint: '/api/v4/floating/borrow',
    label: 'Floating borrow',
    group_by: 'license',
  },
  product_details: {
    endpoint: '/api/v4/product_details',
    label: 'Product details',
    group_by: 'product',
  },
  track_device_variables: {
    endpoint: '/api/v4/track_device_variables',
    label: 'Track device variables',
    group_by: 'license',
  },
  get_device_variables: {
    endpoint: '/api/v4/get_device_variables',
    label: 'Get device variables',
    group_by: 'license',
  },
  change_password: {
    endpoint: '/api/v4/change_password',
    label: 'Change password',
    group_by: 'company',
  },
  customer_license_users: {
    endpoint: '/api/v4/customer_license_users',
    label: 'Customer license users',
    group_by: 'company',
  },
  ping: {
    endpoint: '/api/v4/ping',
    label: 'Ping',
    group_by: 'company',
  },
  single_sign_on: {
    endpoint: '/api/v4/sso_url',
    label: 'Single Sign On',
    group_by: 'company',
  },
};

export const eventsConfig = {
  payment_added: {
    label: 'Payment method added',
    allowed_recipients: ['company_users', 'custom_emails'],
  },
  payment_removed: {
    label: 'Payment method removed',
    allowed_recipients: ['company_users', 'custom_emails'],
  },
  charge_succeeded: {
    label: 'Charge succeeded',
    allowed_recipients: ['company_users', 'custom_emails'],
  },
  invoice_finalized: {
    label: 'Invoice finalized',
    allowed_recipients: ['company_users', 'custom_emails'],
  },
  trial_created: {
    label: 'Trial created',
    allowed_recipients: ['customer', 'company_users', 'custom_emails'],
  },
  order_created: {
    label: 'Order created',
    allowed_recipients: ['customer', 'company_users', 'custom_emails'],
  },
  license_activated: {
    label: 'License is activated',
    allowed_recipients: ['customer', 'license_managers', 'company_users', 'custom_emails'],
  },
  license_expired: {
    label: 'License has expired',
    allowed_recipients: ['customer', 'license_managers', 'license_users', 'company_users', 'custom_emails'],
  },
  trial_expired: {
    label: 'Trial license has expired',
    allowed_recipients: ['customer', 'license_managers', 'license_users', 'company_users', 'custom_emails'],
  },
  license_will_expire: {
    label: 'License will expire',
    allowed_recipients: ['customer', 'license_managers', 'license_users', 'company_users', 'custom_emails'],
    custom_data: ['check_days'],
  },
  trial_will_expire: {
    label: 'Trial license will expire',
    allowed_recipients: ['customer', 'license_managers', 'license_users', 'company_users', 'custom_emails'],
    custom_data: ['check_days'],
  },
  maintenance_expired: {
    label: 'Maintenance has expired',
    allowed_recipients: ['customer', 'license_managers', 'license_users', 'company_users', 'custom_emails'],
  },
  maintenance_will_expire: {
    label: 'Maintenance will expire',
    allowed_recipients: ['customer', 'license_managers', 'license_users', 'company_users', 'custom_emails'],
    custom_data: ['check_days'],
  },
  assigned_license_user: {
    label: 'Assigned license user',
    allowed_recipients: ['customer', 'license_users', 'company_users', 'custom_emails'],
  },
  assigned_max_license_users: {
    label: 'Assigned max number of license users',
    allowed_recipients: ['customer', 'company_users', 'custom_emails'],
  },
  will_assign_max_license_users: {
    label: 'Assigned license users number is close to limit',
    allowed_recipients: ['customer', 'company_users', 'custom_emails'],
    custom_data: ['users_left'],
  },
  assigned_license_manager: {
    label: 'Assigned license manager',
    allowed_recipients: ['customer', 'license_managers', 'company_users', 'custom_emails'],
  },
  feature_expired: {
    label: 'Feature has expired',
    allowed_recipients: ['customer', 'license_users', 'company_users', 'custom_emails'],
  },
  feature_will_expire: {
    label: 'Feature will expire',
    allowed_recipients: ['customer', 'license_users', 'company_users', 'custom_emails'],
    custom_data: ['check_days'],
  },
};

export const ssoProvidersList = [
  {
    provider_name: 'SAML',
    provider_type: 'SAML',
    provider_id: 'saml',
    value: 'SAML',
    label: 'SAML',
  },
  {
    provider_name: 'Google',
    provider_type: 'Google',
    provider_id: 'google',
    value: 'Google',
    label: 'Google',
  },
  {
    provider_name: 'Apple',
    provider_type: 'SignInWithApple',
    provider_id: 'apple',
    value: 'SignInWithApple',
    label: 'Apple',
  },
];

export const managerPermissionDescription = {
  borrow_license: 'Can manage floating license borrowing',
  create_license_policy: 'Can create new license policies',
  create_order: 'Can create new orders',
  create_bundle_order: 'Can create new product bundle orders',
  issue_license: 'Can issue new licenses on existing order',
  manage_license_users: 'Can add, edit or delete license users',
  manage_license_user_credentials: 'Can change license users passwords',
  manage_managers: 'Can add new license managers to some order',
  reset_device: 'Can reset device',
  revoke_device: 'Can revoke floating device',
  reset_license: 'Can reset license',
  show_license_usage_report: 'Can see the activity report ( number of created orders and issued licenses )',
};

export const userPermissions = {
  analytics_read: 'analytics_read',
  products_read: 'products_read',
  products_write: 'products_write',
  orders_read: 'orders_read',
  orders_write: 'orders_write',
  licenses_read: 'licenses_read',
  licenses_write: 'licenses_write',
  customers_read: 'customers_read',
  customers_write: 'customers_write',
  billing_read: 'billing_read',
  billing_write: 'billing_write',
  platform_users_read: 'platform_users_read',
  platform_users_write: 'platform_users_write',
  settings_read: 'settings_read',
  settings_write: 'settings_write',
};

export const userPermissionLabels = {
  analytics_read: 'Read analytics',
  products_read: 'Read products',
  products_write: 'Manage products',
  orders_read: 'Read orders',
  orders_write: 'Manage orders',
  licenses_read: 'Read licenses',
  licenses_write: 'Manage licenses',
  customers_read: 'Read customers',
  customers_write: 'Manage customers',
  billing_read: 'Read billing',
  billing_write: 'Manage billing',
  platform_users_read: 'Read platform users',
  platform_users_write: 'Manage platform users',
  settings_read: 'Read settings',
  settings_write: 'Manage settings',
};

export const stripeTaxIDs = [
  {
    code: 'au_abn',
    label: 'Australian Business Number (AU ABN)',
    example: '12345678912',
    country: 'Australia',
  },
  {
    code: 'au_arn',
    label: 'Australian Taxation Office Reference Number',
    example: '123456789123',
    country: 'Australia',
  },
  {
    code: 'eu_vat',
    label: 'European VAT number',
    example: 'ATU12345678',
    country: 'Austria',
  },
  {
    code: 'eu_vat',
    label: 'European VAT number',
    example: 'BE0123456789',
    country: 'Belgium',
  },
  {
    code: 'br_cnpj',
    label: 'Brazilian CNPJ number',
    example: '01.234.456/5432-10',
    country: 'Brazil',
  },
  {
    code: 'br_cpf',
    label: 'Brazilian CPF number',
    example: '123.456.789-87',
    country: 'Brazil',
  },
  {
    code: 'eu_vat',
    label: 'European VAT number',
    example: 'BG0123456789',
    country: 'Bulgaria',
  },
  {
    code: 'ca_bn',
    label: 'Canadian BN',
    example: '123456789',
    country: 'Canada',
  },
  {
    code: 'ca_gst_hst',
    label: 'Canadian GST/HST number',
    example: '123456789RT0002',
    country: 'Canada',
  },
  {
    code: 'ca_pst_bc',
    label: 'Canadian PST number (British Columbia)',
    example: 'PST-1234-5678',
    country: 'Canada',
  },
  {
    code: 'ca_pst_mb',
    label: 'Canadian PST number (Manitoba)',
    example: '123456-7',
    country: 'Canada',
  },
  {
    code: 'ca_pst_sk',
    label: 'Canadian PST number (Saskatchewan)',
    example: '1234567',
    country: 'Canada',
  },
  {
    code: 'ca_qst',
    label: 'Canadian QST number (Québec)',
    example: '1234567890TQ1234',
    country: 'Canada',
  },
  {
    code: 'cl_tin',
    label: 'Chilean TIN',
    example: '12.345.678-K',
    country: 'Chile',
  },
  {
    code: 'eu_vat',
    label: 'European VAT number',
    example: 'HR12345678912',
    country: 'Croatia',
  },
  {
    code: 'eu_vat',
    label: 'European VAT number',
    example: 'CY12345678Z',
    country: 'Cyprus',
  },
  {
    code: 'eu_vat',
    label: 'European VAT number',
    example: 'CZ1234567890',
    country: 'Czech Republic',
  },
  {
    code: 'eu_vat',
    label: 'European VAT number',
    example: 'DK12345678',
    country: 'Denmark',
  },
  {
    code: 'eu_vat',
    label: 'European VAT number',
    example: 'EE123456789',
    country: 'Estonia',
  },
  {
    code: 'eu_vat',
    label: 'European VAT number',
    example: 'FI12345678',
    country: 'Finland',
  },
  {
    code: 'eu_vat',
    label: 'European VAT number',
    example: 'FRAB123456789',
    country: 'France',
  },
  {
    code: 'ge_vat',
    label: 'Georgian VAT',
    example: '123456789',
    country: 'Georgia',
  },
  {
    code: 'eu_vat',
    label: 'European VAT number',
    example: 'DE123456789',
    country: 'Germany',
  },
  {
    code: 'eu_vat',
    label: 'European VAT number',
    example: 'EL123456789',
    country: 'Greece',
  },
  {
    code: 'hk_br',
    label: 'Hong Kong BR number',
    example: '12345678',
    country: 'Hong Kong',
  },
  {
    code: 'eu_vat',
    label: 'European VAT number',
    example: 'HU12345678912',
    country: 'Hungary',
  },
  {
    code: 'in_gst',
    label: 'Indian GST number',
    example: '12ABCDE3456FGZH',
    country: 'India',
  },
  {
    code: 'id_npwp',
    label: 'Indonesian NPWP number',
    example: '12.345.678.9-012.345',
    country: 'Indonesia',
  },
  {
    code: 'eu_vat',
    label: 'European VAT number',
    example: 'IE1234567AB',
    country: 'Ireland',
  },
  {
    code: 'il_vat',
    label: 'Israel VAT',
    example: '000012345',
    country: 'Israel',
  },
  {
    code: 'eu_vat',
    label: 'European VAT number',
    example: 'IT12345678912',
    country: 'Italy',
  },
  {
    code: 'jp_cn',
    label: 'Japanese Corporate Number (*Hōjin Bangō*)',
    example: '1234567891234',
    country: 'Japan',
  },
  {
    code: 'jp_rn',
    label: 'Japanese Registered Foreign Businesses Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)',
    example: '12345',
    country: 'Japan',
  },
  {
    code: 'eu_vat',
    label: 'European VAT number',
    example: 'LV12345678912',
    country: 'Latvia',
  },
  {
    code: 'li_uid',
    label: 'Liechtensteinian UID number',
    example: 'CHE123456789',
    country: 'Liechtenstein',
  },
  {
    code: 'eu_vat',
    label: 'European VAT number',
    example: 'LT123456789123',
    country: 'Lithuania',
  },
  {
    code: 'eu_vat',
    label: 'European VAT number',
    example: 'LU12345678',
    country: 'Luxembourg',
  },
  {
    code: 'my_frp',
    label: 'Malaysian FRP number',
    example: '12345678',
    country: 'Malaysia',
  },
  {
    code: 'my_itn',
    label: 'Malaysian ITN',
    example: 'C 1234567890',
    country: 'Malaysia',
  },
  {
    code: 'my_sst',
    label: 'Malaysian SST number',
    example: 'A12-3456-78912345',
    country: 'Malaysia',
  },
  {
    code: 'eu_vat',
    label: 'European VAT number',
    example: 'MT12345678',
    country: 'Malta',
  },
  {
    code: 'mx_rfc',
    label: 'Mexican RFC number',
    example: 'ABC010203AB9',
    country: 'Mexico',
  },
  {
    code: 'eu_vat',
    label: 'European VAT number',
    example: 'NL123456789B12',
    country: 'Netherlands',
  },
  {
    code: 'nz_gst',
    label: 'New Zealand GST number',
    example: '123456789',
    country: 'New Zealand',
  },
  {
    code: 'no_vat',
    label: 'Norwegian VAT number',
    example: '123456789MVA',
    country: 'Norway',
  },
  {
    code: 'eu_vat',
    label: 'European VAT number',
    example: 'PL1234567890',
    country: 'Poland',
  },
  {
    code: 'eu_vat',
    label: 'European VAT number',
    example: 'PT123456789',
    country: 'Portugal',
  },
  {
    code: 'eu_vat',
    label: 'European VAT number',
    example: 'RO1234567891',
    country: 'Romania',
  },
  {
    code: 'ru_inn',
    label: 'Russian INN',
    example: '1234567891',
    country: 'Russia',
  },
  {
    code: 'ru_kpp',
    label: 'Russian KPP',
    example: '123456789',
    country: 'Russia',
  },
  {
    code: 'sa_vat',
    label: 'Saudi Arabia VAT',
    example: '123456789012345',
    country: 'Saudi Arabia',
  },
  {
    code: 'sg_gst',
    label: 'Singaporean GST',
    example: 'M12345678X',
    country: 'Singapore',
  },
  {
    code: 'sg_uen',
    label: 'Singaporean UEN',
    example: '123456789F',
    country: 'Singapore',
  },
  {
    code: 'eu_vat',
    label: 'European VAT number',
    example: 'SK1234567891',
    country: 'Slovakia',
  },
  {
    code: 'eu_vat',
    label: 'European VAT number',
    example: 'SI12345678',
    country: 'Slovenia',
  },
  {
    code: 'za_vat',
    label: 'South African VAT number',
    example: '4123456789',
    country: 'South Africa',
  },
  {
    code: 'za_vat',
    label: 'South African VAT number',
    example: '4123456789',
    country: 'South Africa',
  },
  {
    code: 'kr_brn',
    label: 'Korean BRN',
    example: '123-45-67890',
    country: 'South Korea',
  },
  {
    code: 'es_cif',
    label: 'Spanish CIF number',
    example: 'A12345678',
    country: 'Spain',
  },
  {
    code: 'eu_vat',
    label: 'European VAT number',
    example: 'ESA1234567Z',
    country: 'Spain',
  },
  {
    code: 'eu_vat',
    label: 'European VAT number',
    example: 'SE123456789123',
    country: 'Sweden',
  },
  {
    code: 'ch_vat',
    label: 'Switzerland VAT number',
    example: 'CHE-123.456.789 MWST',
    country: 'Switzerland',
  },
  {
    code: 'tw_vat',
    label: 'Taiwanese VAT',
    example: '12345678',
    country: 'Taiwan',
  },
  {
    code: 'th_vat',
    label: 'Thai VAT',
    example: '1234567891234',
    country: 'Thailand',
  },
  {
    code: 'ua_vat',
    label: 'Ukrainian VAT',
    example: '123456789',
    country: 'Ukraine',
  },
  {
    code: 'ae_trn',
    label: 'United Arab Emirates TRN',
    example: '123456789012345',
    country: 'United Arab Emirates',
  },
  {
    code: 'gb_vat',
    label: 'United Kingdom VAT number',
    example: 'GB123456789',
    country: 'United Kingdom',
  },
  {
    code: 'eu_vat',
    label: 'Northern Ireland VAT number',
    example: 'XI123456789',
    country: 'United Kingdom',
  },
  {
    code: 'us_ein',
    label: 'United States EIN',
    example: '12-3456789',
    country: 'United States',
  },
];
