import React, { useState } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  ConfirmationPopup,
  IconDelete,
  Notification,
  Page,
  List,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
} from 'shared/components';
import {
  sendErrorReport,
  displayValue,
  mapCurrencySymbol,
  formatAmountValue,
} from 'shared/helpers';
import {
  deletePricingPlan,
  getPricingPlans,
} from 'src/billing/actions';
import PricingPlanForm from './PricingPlanForm';
import './styles.scss';

const PricingPlanSettings = () => {
  const dispatch = useDispatch();
  const loadingPricingPlans = useSelector(state => get(state, 'billing.loadingPricingPlans'));
  const pricingPlans = useSelector(state => get(state, 'billing.pricingPlans'));
  const loadingStandardPricingPlans = useSelector(state => get(state, 'billing.loadingStandardPricingPlans'));
  const standardPricingPlans = useSelector(state => get(state, 'billing.standardPricingPlans'));
  const companyID = useSelector(state => get(state, 'company.details.id'));

  const [deletingPlan, setDeletingPlan] = useState(false);
  const [isFormDisplayed, setFormDisplay] = useState(false);
  const [pricingPlantoDelete, setPricingPlanToDelete] = useState(null);
  const [isPricingPlanDeleteConfirmationDisplayed, setPricingPlanDeleteConfirmationDisplayed] = useState(false);

  const handlePricingPlanDelete = (plan) => {
    const planId = get(plan, 'id');
    setDeletingPlan(true);

    deletePricingPlan(planId, companyID)
      .then(() => {
        dispatch(getPricingPlans(companyID));
        Notification('success', __('Pricing plan deleted'));
        setPricingPlanDeleteConfirmationDisplayed(false);
        setPricingPlanToDelete(null);
        setDeletingPlan(false);
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot delete pricing plan');
        Notification('error', __('Error occured'), __('We could not delete the pricing plan.'));
        setDeletingPlan(false);
      });
  };

  return (
    <Page title={__('Pricing plans')}>
      <div className="PricingPlanSettings">
        <Tabs>
          <TabsHeader>
            <Tab>LicenseSpring Enterprise</Tab>
            <Tab>LicenseSpring Standard</Tab>
          </TabsHeader>
          <TabContent>
            <div className="PP-Enterprise">
              <div className="PricingPlanSettings-actions">
                <Button
                  size="sm"
                  theme="info"
                  onClick={() => setFormDisplay(true)}
                >
                  {__('Create new plan')}
                </Button>
              </div>
              <List
                columns={[
                  {
                    accessor: 'nickname',
                    Header: __('Name'),
                    Cell: cellInfo => displayValue(cellInfo.value),
                  },
                  {
                    accessor: 'amount',
                    Header: __('Amount'),
                    Cell: cellInfo => displayValue(formatAmountValue(cellInfo.value)),
                  },
                  {
                    accessor: 'currency',
                    Header: __('Currency'),
                    Cell: cellInfo => displayValue(mapCurrencySymbol(cellInfo.value)),
                  },
                  {
                    accessor: 'interval',
                    Header: __('Billing cycle'),
                    Cell: (cellInfo) => {
                      const interval = get(cellInfo, 'value', '');
                      if (interval === 'month') {
                        return __('Monthly');
                      }
                      if (interval === 'year') {
                        return __('Yearly');
                      }
                      return displayValue(interval);
                    },
                  },
                  {
                    Cell: rowData => (
                      <Button
                        className="edit-button"
                        onClick={() => {
                          setPricingPlanToDelete(rowData.original);
                          setPricingPlanDeleteConfirmationDisplayed(true);
                        }}
                        type="button"
                      >
                        <IconDelete height="16px" width="16px" color="#ee5253" />
                      </Button>
                    ),
                    maxWidth: 50,
                  },
                ]}
                data={pricingPlans}
                loading={loadingPricingPlans}
                minRows={2}
                pageSize={20}
                defaultPageSize={10}
              />
            </div>
          </TabContent>
          <TabContent>
            <div className="PP-standard">
              <List
                columns={[
                  {
                    accessor: 'nickname',
                    Header: __('Name'),
                    Cell: cellInfo => displayValue(cellInfo.value),
                  },
                  {
                    accessor: 'amount',
                    Header: __('Amount'),
                    Cell: cellInfo => displayValue(formatAmountValue(cellInfo.value)),
                  },
                  {
                    accessor: 'currency',
                    Header: __('Currency'),
                    Cell: cellInfo => displayValue(mapCurrencySymbol(cellInfo.value)),
                  },
                  {
                    accessor: 'interval',
                    Header: __('Billing cycle'),
                    Cell: (cellInfo) => {
                      const interval = get(cellInfo, 'value', '');
                      if (interval === 'month') {
                        return __('Monthly');
                      }
                      if (interval === 'year') {
                        return __('Yearly');
                      }
                      return displayValue(interval);
                    },
                  },
                ]}
                data={standardPricingPlans}
                loading={loadingStandardPricingPlans}
                minRows={2}
                pageSize={20}
                defaultPageSize={10}
              />
            </div>
          </TabContent>
        </Tabs>
      </div>
      {isFormDisplayed && (
        <PricingPlanForm
          title={__('Create new plan')}
          closeCb={() => setFormDisplay(false)}
          confirmCb={() => setFormDisplay(false)}
          fetchPricingPlans={() => dispatch(getPricingPlans(companyID))}
        />
      )}
      {isPricingPlanDeleteConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setPricingPlanToDelete(null);
            setPricingPlanDeleteConfirmationDisplayed(false);
          }}
          confirmCb={() => handlePricingPlanDelete(pricingPlantoDelete)}
          title={`${__('Are you sure you want to delete pricing plan:')} ${pricingPlantoDelete.nickname}?`}
          confirmText={__('Delete')}
          theme="error"
          warning
          disabled={deletingPlan}
        />
      )}
    </Page>
  );
};

export default PricingPlanSettings;
