import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import {
  RadioBtn,
  Checkbox,
  DateInput,
  Label,
  InputErrorMessage,
  NumberInput,
  Selector,
  TextArea,
} from 'shared/components';
import { platformFeatures, validityTypes } from 'shared/constants';
import {
  isFeatureEnabled,
  isFeatureAvailable,
  getDisabledMessage,
} from 'shared/helpers';
import { checkCompanyConfigField } from 'shared/companyConfig';

const LicenseUsageSection = ({
  companyID,
  isFloating,
  handleIsFloatingChange,
  isFloatingCloud,
  handleIsFloatingCloudChange,
  floatingUsers,
  floatingUsersError,
  handleFloatingUsersChange,
  floatingTimeout,
  floatingTimeoutError,
  handleFloatingTimeoutChange,
  enableMaintenancePeriod,
  handleProductHasMaintenanceChange,
  periodOptions,
  selectedMaintenanceDurationType,
  maintenancePeriodValue,
  maintenancePeriodLabel,
  maintenancePeriodError,
  handleMaintenancePeriodValueChange,
  handleMaintenancePeriodLabelChange,
  maintenanceEndDateError,
  maintenanceEndDate,
  handleSelectedMaintenanceDurationType,
  handleMaintenanceEndDateChange,
  startDate,
  startDateError,
  handleStartDateChange,
  licenseNote,
  handleLicenseNoteChange,
  canBorrow,
  setCanBorrow,
  maxBorrowTime,
  maxBorrowTimeError,
  setMaxBorrowTime,
  requireHwKey,
  setRequireHwKey,
  metadata,
  metadataError,
  setMetadata,
}) => {
  const companyDetails = useSelector(state => get(state, 'company.details'));
  const isEnterprisePlan = get(companyDetails, 'plan_type') === 'enterprise';

  const notAvailableFloatingMessage = (
    <>
      {__('Floating licenses are unavailable in your current plan.')}
      &nbsp;
      {__('Upgrade to use floating licenses.')}
    </>
  );
  const notAvailableMaintenanceMessage = (
    <>
      {__('Maintenance plan is unavailable in your current plan.')}
      &nbsp;
      {__('Upgrade to use maintenance plans.')}
    </>
  );

  const notAvailableHwKeyMessage = (
    <>
      {__('Hardware key licensing is not available in your current plan.')}
      &nbsp;
      {__('Contact sales to support hardware key licensing.')}
    </>
  );

  return (
    <section className="LicenseUsageSection">
      <div className="section-header">{__('License usage')}</div>
      <div className="section-row">
        <Label
          inputId="start-date"
          text={__('License start date')}
          description={__('License cannot be activated before this date')}
        />
        <DateInput
          id="start-date"
          handleChange={handleStartDateChange}
          value={startDate}
          error={startDateError}
        />
      </div>
      <div className="section-row">
        <Checkbox
          label={__('Offline floating license')}
          inputId="floating-license"
          checked={isFloating}
          handleChange={handleIsFloatingChange}
          featureEnabled={isFeatureEnabled(platformFeatures.model_floating_cloud)}
          notEnabledMessage={getDisabledMessage()}
          featureAvailable={isFeatureAvailable(platformFeatures.model_floating_cloud)}
          notAvailableMessage={notAvailableFloatingMessage}
        />
      </div>
      <div className="section-row">
        <Checkbox
          label={__('Is floating cloud')}
          inputId="floating-cloud-license"
          checked={isFloatingCloud}
          handleChange={handleIsFloatingCloudChange}
          featureEnabled={isFeatureEnabled(platformFeatures.model_floating_cloud)}
          notEnabledMessage={getDisabledMessage()}
          featureAvailable={isFeatureAvailable(platformFeatures.model_floating_cloud)}
          notAvailableMessage={notAvailableFloatingMessage}
        />
      </div>
      {(isFloatingCloud || isFloating) && (
        <div className="floating-form-fields">
          <div className="section-row row-expanded">
            <Label text={__('Max simultaneous license users')} />
            <NumberInput
              handleChange={handleFloatingUsersChange}
              value={floatingUsers}
              error={floatingUsersError}
              min="1"
              max="10000"
            />
          </div>
          <div className="section-row row-expanded">
            <Label text={__('Floating timeout')} description={__('If the license issued is a floating license, the floating timeout is the time interval in minutes that the end users application will need to perform a license check in order to remain registered to the license.')} />
            <NumberInput
              handleChange={handleFloatingTimeoutChange}
              value={floatingTimeout}
              error={floatingTimeoutError}
              min="1"
              max="2147483647"
            />
          </div>
          <Checkbox
            label={__('Can borrow license')}
            inputId="can-borrow-license"
            checked={canBorrow}
            handleChange={setCanBorrow}
            featureEnabled={isFeatureEnabled(platformFeatures.extra_license_borrowing)}
            notEnabledMessage={getDisabledMessage()}
            featureAvailable={isFeatureAvailable(platformFeatures.extra_license_borrowing)}
            notAvailableMessage={notAvailableFloatingMessage}
          />
          {canBorrow && (
            <div className="section-row row-expanded">
              <Label text={__('Max borrow time')} description={__('Hours')} />
              <NumberInput
                handleChange={setMaxBorrowTime}
                value={maxBorrowTime}
                error={maxBorrowTimeError}
                min="1"
                max="2147483647"
              />
            </div>
          )}
        </div>
      )}
      {checkCompanyConfigField(companyID, 'maintenancePeriod') && (
        <div className="section-row">
          <Checkbox
            label={__('Enable maintenance period')}
            inputId="maintenance-period"
            checked={enableMaintenancePeriod}
            handleChange={handleProductHasMaintenanceChange}
            featureEnabled={isFeatureEnabled(platformFeatures.deployment_maintenance)}
            notEnabledMessage={getDisabledMessage()}
            featureAvailable={isFeatureAvailable(platformFeatures.deployment_maintenance)}
            notAvailableMessage={notAvailableMaintenanceMessage}
          />
        </div>
      )}
      {/* {enableMaintenancePeriod && (
        <div className="section-row row-expanded">
          <Label text={__('Maintenance duration')} />
          <div className="flex-row">
            <NumberInput
              handleChange={handleMaintenancePeriodValueChange}
              value={maintenancePeriodValue}
              error={maintenancePeriodError}
              min="0"
              max="1000"
              showErrorMsg={false}
            />
            <Selector
              options={periodOptions}
              value={maintenancePeriodLabel}
              handleChange={handleMaintenancePeriodLabelChange}
            />
          </div>
          <InputErrorMessage text={maintenancePeriodError} />
        </div>
      )} */}
      {enableMaintenancePeriod && (
      <div className="section-row row-expanded">
        <div className="radio-btns">
          <RadioBtn
            name="maintenance-type-select"
            inputId="maintenance-duration"
            label={__('Maintenance duration')}
            value={validityTypes.duration}
            checked={selectedMaintenanceDurationType === validityTypes.duration}
            handleChange={handleSelectedMaintenanceDurationType}
          />
          <div>
            <div className="flex-row">
              <NumberInput
                handleChange={handleMaintenancePeriodValueChange}
                value={maintenancePeriodValue}
                min="0"
                max="1000"
                disabled={selectedMaintenanceDurationType === validityTypes.period}
              />
              <Selector
                options={periodOptions}
                value={maintenancePeriodLabel}
                handleChange={handleMaintenancePeriodLabelChange}
                disabled={selectedMaintenanceDurationType === validityTypes.period}
              />
            </div>
            <InputErrorMessage text={maintenancePeriodError} />
          </div>
          <RadioBtn
            name="maintenance-type-select"
            inputId="meaintenance-period"
            label={__('Maintenance End Date')}
            value={validityTypes.period}
            checked={selectedMaintenanceDurationType === validityTypes.period}
            handleChange={handleSelectedMaintenanceDurationType}
          />
          <DateInput
            id="maintenance-end-date"
            handleChange={handleMaintenanceEndDateChange}
            value={maintenanceEndDate}
            error={maintenanceEndDateError}
            disabled={selectedMaintenanceDurationType === validityTypes.duration}
          />
        </div>
      </div>
      )}
      <div className="section-row">
        <Checkbox
          label={__('Require hardware key')}
          inputId="require-hw-key"
          checked={requireHwKey}
          handleChange={setRequireHwKey}
          featureEnabled={isFeatureEnabled(platformFeatures.extra_hardware_token)}
          notEnabledMessage={getDisabledMessage()}
          featureAvailable={isFeatureAvailable(platformFeatures.extra_hardware_token)}
          notAvailableMessage={notAvailableHwKeyMessage}
        />
      </div>
      <div className="section-row">
        <Label text={__('Note')} inputId="license-note" />
        <TextArea
          id="license-note"
          value={licenseNote}
          handleChange={handleLicenseNoteChange}
        />
      </div>
      <div className="row">
        <Label inputId="metadata-input" text={__('Metadata JSON')} />
        <TextArea
          handleChange={setMetadata}
          id="metadata-input"
          type="metadata"
          value={metadata}
          error={metadataError}
          rows="4"
          disabled={!isEnterprisePlan}
        />
      </div>
    </section>
  );
};

LicenseUsageSection.propTypes = {
  companyID: PropTypes.number.isRequired,
  isFloating: PropTypes.bool,
  handleIsFloatingChange: PropTypes.func.isRequired,
  isFloatingCloud: PropTypes.bool,
  handleIsFloatingCloudChange: PropTypes.func.isRequired,
  handleFloatingUsersChange: PropTypes.func.isRequired,
  floatingUsers: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  floatingUsersError: PropTypes.string,
  floatingTimeout: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  floatingTimeoutError: PropTypes.string,
  handleFloatingTimeoutChange: PropTypes.func.isRequired,
  enableMaintenancePeriod: PropTypes.bool,
  handleProductHasMaintenanceChange: PropTypes.func.isRequired,
  periodOptions: PropTypes.array.isRequired,
  selectedMaintenanceDurationType: PropTypes.string.isRequired,
  maintenancePeriodValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maintenancePeriodLabel: PropTypes.string,
  maintenancePeriodError: PropTypes.string,
  handleMaintenancePeriodValueChange: PropTypes.func.isRequired,
  handleMaintenancePeriodLabelChange: PropTypes.func.isRequired,
  maintenanceEndDate: PropTypes.string,
  maintenanceEndDateError: PropTypes.string,
  handleMaintenanceEndDateChange: PropTypes.func.isRequired,
  handleSelectedMaintenanceDurationType: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  startDateError: PropTypes.string,
  handleStartDateChange: PropTypes.string.isRequired,
  licenseNote: PropTypes.string,
  handleLicenseNoteChange: PropTypes.func.isRequired,
  canBorrow: PropTypes.bool,
  setCanBorrow: PropTypes.func.isRequired,
  requireHwKey: PropTypes.bool,
  setRequireHwKey: PropTypes.func.isRequired,
  maxBorrowTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxBorrowTimeError: PropTypes.string,
  setMaxBorrowTime: PropTypes.func.isRequired,
  metadata: PropTypes.string,
  metadataError: PropTypes.string,
  setMetadata: PropTypes.func.isRequired,
};

LicenseUsageSection.defaultProps = {
  isFloating: false,
  isFloatingCloud: false,
  floatingUsers: '',
  floatingUsersError: '',
  floatingTimeout: '',
  floatingTimeoutError: '',
  enableMaintenancePeriod: false,
  maintenancePeriodValue: '',
  maintenancePeriodLabel: '',
  maintenancePeriodError: '',
  maintenanceEndDateError: '',
  maintenanceEndDate: '',
  startDate: '',
  startDateError: '',
  licenseNote: '',
  canBorrow: false,
  maxBorrowTime: 0,
  maxBorrowTimeError: '',
  requireHwKey: false,
  metadata: '',
  metadataError: '',
};

export default LicenseUsageSection;
