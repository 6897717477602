import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ConfirmationPopup,
  DescriptionTable,
  IconClipboard,
  List,
  Notice,
  Notification,
  StatusCard,
  Subtitle,
  Page,
  PermissionMissingNotificationTitle,
} from 'shared/components';
import {
  platformFeatures,
  userPermissions,
} from 'shared/constants';
import {
  getDisabledMessage,
  isFeatureEnabled,
  sendErrorReport,
  copyText,
  displayValue,
  formatDate,
  checkUserPermission,
} from 'shared/helpers';
import { getCompanySilent } from 'src/company/actions';
import {
  patchProduct,
  getProducts,
  getProductsInBundle,
} from 'src/product/actions';
import {
  ProductBundleForm,
} from '../Forms';
import './styles.scss';

const BundleContainer = ({
  bundle,
  refreshBundle,
  companyID,
}) => {
  const canManageProducts = checkUserPermission(userPermissions.products_write);
  const dispatch = useDispatch();
  const history = useHistory();

  const bundleID = get(bundle, 'id');
  const bundleStatus = get(bundle, 'active') ? 'success' : 'error';
  const bundleStatusText = get(bundle, 'active') ? __('Active') : __('Disabled');

  const [isEditFormDisplayed, setEditFormDisplay] = useState(false);
  const [isStatusChanging, setStatusChanging] = useState(false);
  const [isEnableBundleConfirmationDisplayed, setEnableBundleConfirmationDisplay] = useState(false);
  const [isDeleteBundleConfirmationDisplayed, setDeleteBundleConfirmationDisplay] = useState(false);
  const [deletingBundle, setDeletingBundle] = useState(false);
  const [productsLoading, setProductsLoading] = useState(true);
  const [products, setProducts] = useState([]);

  const fetchBundleProducts = useCallback(() => {
    getProductsInBundle(bundleID, companyID)
      .then((res) => {
        const data = get(res, 'data') || [];
        setProducts(data);
        setProductsLoading(false);
      })
      .catch((err) => {
        setProductsLoading(false);
        sendErrorReport(err, 'Cannot create new order', err);
      });
  }, [bundleID]);

  useEffect(() => {
    fetchBundleProducts();
  }, [fetchBundleProducts]);

  const isArchived = get(bundle, 'is_archived');
  const isActive = get(bundle, 'active');

  const handleBundleStatus = (isEnabled) => {
    if (isEnabled && isActive) {
      return false; // Already enabled
    }
    if (!isEnabled && !isActive) {
      return false; // Already disabled
    }

    setStatusChanging(true);
    const data = { active: isEnabled };

    patchProduct(bundleID, data, companyID)
      .then(() => {
        refreshBundle();
        setStatusChanging(false);
        setEnableBundleConfirmationDisplay(false);
        dispatch(getCompanySilent(companyID));
        Notification('success', isEnabled ? __('Product bundle enabled') : __('Product bundle disabled'));
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot edit product bundle', data);
        setStatusChanging(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
    return true;
  };

  const handleBundleDelete = () => {
    setDeletingBundle(true);
    const data = { is_archived: true };

    patchProduct(bundleID, data, companyID)
      .then(() => dispatch(getProducts(companyID)))
      .then(() => {
        history.push(`/${companyID}/products`);
        Notification('success', __('Product bundle succesfully deleted'));
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot delete product bundle', data);
        setDeletingBundle(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
  };

  const handleFieldCopy = (val, desc) => {
    copyText(val);
    Notification('success', `${desc} ${__('copied to clipboard')}`);
  };

  const createProductTitle = (productName) => {
    const label = __('Product bundle name');
    return (
      <div className="bundle-title-container">
        {__('Product bundle')}
        &nbsp;-&nbsp;
        <span>{productName}</span>
        <button
          type="button"
          className="clipboard-btn"
          onClick={() => handleFieldCopy(productName, label)}
        >
          <IconClipboard width="21.5" height="20" viewBox="0 0 51.5 50" />
        </button>
      </div>
    );
  };

  const handleManageBundleClick = (cb) => {
    if (!canManageProducts) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.products_write} />,
        __('Contact you account admin for support.'),
      );
      return false;
    }
    cb(true);
    return true;
  };

  return (
    <Page title={createProductTitle(get(bundle, 'product_name'))} description={get(bundle, 'short_code')}>
      {isArchived && (
        <div className="BundleContainer-notice">
          <Notice
            theme="warning"
            size="sm"
            title={__('This product bundle is archived')}
          />
        </div>
      )}
      <div className="list-header">
        <div>
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_product)}
            notEnabledMessage={getDisabledMessage()}
            onClick={() => handleManageBundleClick(setEditFormDisplay)}
            theme="info"
          >
            {__('Edit bundle')}
          </Button>
        </div>
        <div>
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_product)}
            notEnabledMessage={getDisabledMessage()}
            onClick={() => handleManageBundleClick(setDeleteBundleConfirmationDisplay)}
          >
            {__('Delete bundle')}
          </Button>
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_product)}
            notEnabledMessage={getDisabledMessage()}
            theme={isActive ? 'error' : 'success'}
            onClick={() => handleManageBundleClick(setEnableBundleConfirmationDisplay)}
          >
            {isActive ? __('Disable bundle') : __('Enable bundle')}
          </Button>
        </div>
      </div>
      <div className="Bundle-details">
        <DescriptionTable
          details={[
            { label: __('Bundle code'), value: displayValue(get(bundle, 'short_code')) },
            { label: __('Created on'), value: formatDate(get(bundle, 'created_at')) },
            { label: __('Status'), value: <StatusCard text={bundleStatusText} status={bundleStatus} /> },
            { label: __('Authorization method'), value: displayValue(get(bundle, 'authorization_method')) },
          ]}
        />
      </div>
      <div className="Bundle-products">
        <Subtitle text={__('Products in a bundle')} />
        <List
          loading={productsLoading}
          columns={[
            {
              accessor: 'product_name',
              Header: __('Product Name'),
            },
            {
              accessor: 'short_code',
              Header: __('Product code'),
            },
          ]}
          data={products}
          minRows={2}
          pageSize={20}
          handleClick={rowData => history.push(`/${companyID}/products/${rowData.original.id}`)}
        />
      </div>
      {isEditFormDisplayed && (
        <ProductBundleForm
          closeCb={() => setEditFormDisplay(false)}
          bundle={bundle}
          companyID={companyID}
          refreshState={refreshBundle}
        />
      )}
      {isEnableBundleConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => setEnableBundleConfirmationDisplay(false)}
          confirmCb={() => handleBundleStatus(!isActive)}
          title={__(`Are you sure you want to ${isActive ? __('disable') : __('enable')} this product bundle?`)}
          confirmText={isActive ? __('Disable') : __('Enable')}
          theme={isActive ? 'error' : 'success'}
          disabled={isStatusChanging}
        />
      )}
      {isDeleteBundleConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => setDeleteBundleConfirmationDisplay(false)}
          confirmCb={handleBundleDelete}
          title={__('Are you sure you want to delete this product bundle?')}
          confirmText={__('Delete')}
          theme="error"
          disabled={deletingBundle}
        />
      )}
    </Page>
  );
};

BundleContainer.propTypes = {
  bundle: PropTypes.object.isRequired,
  companyID: PropTypes.object.isRequired,
  refreshBundle: PropTypes.func.isRequired,
};

export default BundleContainer;
