import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { get } from 'lodash';
import {
  Page,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
} from 'shared/components';
import UserPortalPreferences from './UserPortalPreferences';
import OfflinePortalPreferences from './OfflinePortalPreferences';
import AirgapPortalPreferences from './AirgapPortalPreferences';
import { LogoUpload } from './components';
import './styles.scss';

const PortalConfiguration = () => {
  const location = useLocation();
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const queryParams = get(location, 'search');
    if (!queryParams) {
      setSelectedTab(0);
    }
    if (queryParams.indexOf('st=0') >= 0) {
      setSelectedTab(0);
    }
    if (queryParams.indexOf('st=1') >= 0) {
      setSelectedTab(1);
    }
    if (queryParams.indexOf('st=2') >= 0) {
      setSelectedTab(2);
    }
  }, [location]);

  return (
    <Page title={__('Portal configuration')}>
      <div className="PortalConfiguration">
        <LogoUpload />
        <Tabs
          defaultFocus
          selectedIndex={selectedTab}
          onSelect={(tabIndex) => {
            history.push({
              search: `?st=${tabIndex}`,
            });
            setSelectedTab(tabIndex);
          }}
        >
          <TabsHeader>
            <Tab>{__('User portal')}</Tab>
            <Tab>{__('Offline portal')}</Tab>
            <Tab>{__('Airgap portal')}</Tab>
          </TabsHeader>
          <TabContent><UserPortalPreferences /></TabContent>
          <TabContent><OfflinePortalPreferences /></TabContent>
          <TabContent><AirgapPortalPreferences /></TabContent>
        </Tabs>
      </div>
    </Page>
  );
};

export default PortalConfiguration;
