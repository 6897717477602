import React from 'react';
import PropTypes from 'prop-types';
import withClose from '../withClose';
import './styles.scss';

class Dropdown extends React.Component { // eslint-disable-line react/prefer-stateless-function
  render() {
    const { children, forwardedRef } = this.props;
    return (
      <div className="Dropdown" ref={forwardedRef}>
        {children}
      </div>
    );
  }
}

Dropdown.propTypes = {
  children: PropTypes.element.isRequired,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.shape({ current: PropTypes.instanceOf(Dropdown) }),
    PropTypes.any,
  ]).isRequired,
};

export default withClose(Dropdown);
