import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { createBreadcrumbs } from './actions';
import './styles.scss';

export const Breadcrumbs = ({ match }) => {
  let paths = createBreadcrumbs(match);
  let fullPath = '';
  if (paths[0].path === 'profile') {
    paths = [
      {
        label: 'Home',
        path: '/',
      },
      ...paths,
    ];
  }

  return (
    <ul className="Breadcrumbs">
      {
        paths.map((p, i) => {
          if (i === paths.length - 1) {
            return undefined;
          }
          fullPath += p.path;
          return (
            <Fragment key={p.path}>
              {
                i > 0 && '/ '
              }
              <NavLink to={`${fullPath}`}>
                {p.label}
              </NavLink>
              &nbsp;
            </Fragment>
          );
        })
      }
    </ul>
  );
};

Breadcrumbs.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(Breadcrumbs);
