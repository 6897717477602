import React from 'react';
import { OfflinePortalData } from '../components';

const OfflinePortalPreferences = () => (
  <div className="OfflinePortalPreferences">
    <OfflinePortalData />
  </div>
);

export default OfflinePortalPreferences;
