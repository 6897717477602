import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { sendErrorReport } from 'shared/helpers';
import {
  Button,
  ColorPicker,
  CustomerLabelsSelector,
  DirtyFormAlert,
  Modal,
  Notification,
  Label,
  TextInput,
} from 'shared/components';
import {
  validateRequiredValue,
  debouncedValidateRequiredValue,
} from 'shared/validation';
import { setUserLabels, addCompanyUserLabel } from 'src/account/actions';
import { mapUserLabelsToSelector, getAvailableLabels } from './helpers';
import './styles.scss';

const UserLabelsForm = ({
  user,
  companyLabels,
  companyID,
  closeCb,
  refetchUser,
  updateCompanyLabels,
  isLabelCreateOnly,
}) => {
  const userID = get(user, 'id');
  const availableLabels = getAvailableLabels(companyLabels, user);

  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [showLabelCreate, setLabelCreateDisplay] = useState(isLabelCreateOnly);
  const [newLabelName, setNewLabelName] = useState('');
  const [newLabelNameError, setNewLabelNameError] = useState('');
  const [selectedColor, setSelectedColor] = useState('#949494');

  const validateValue = async (val, cb) => {
    setLoading(true);
    let errors;
    try {
      errors = await validateRequiredValue(val);
      setLoading(false);
      cb(errors);
    } catch (err) {
      setLoading(false);
      sendErrorReport(err, 'Cannot validate new user label name', { value: val });
    }
    if (errors) { return false; }
    return true;
  };

  const isFormValid = async () => {
    const isLabelNameValid = await validateValue(newLabelName, setNewLabelNameError);
    return isLabelNameValid;
  };

  const handleLabelSelect = (val) => {
    setDirty(true);
    const selected = availableLabels.find(label => label.id === val.value);
    setSelectedLabel(selected);
  };

  const addExistingLabelToUser = (labelID) => {
    if (!labelID) {
      Notification('error', __('User label has not been selected.'));
      return false;
    }
    setLoading(true);

    const userLabels = get(user, 'roles') || [];
    const userLabelIDs = userLabels.map(l => l.id);
    const newList = [...userLabelIDs, labelID];

    const data = {
      user_role_ids: newList,
    };

    setUserLabels(companyID, userID, data)
      .then(() => {
        refetchUser();
        closeCb();
        Notification('success', __('Changes saved successfully'));
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot add existing label to customer', newList);
        setLoading(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
    return true;
  };

  const createAndAddNewLabelToUser = async () => {
    const isValid = await isFormValid();
    if (loading || !isValid) { return false; }

    setLoading(true);
    const data = {
      name: newLabelName,
      color: selectedColor,
    };

    let newCompanyLabel;
    try {
      newCompanyLabel = await addCompanyUserLabel(companyID, data);
    } catch (err) {
      sendErrorReport(err, 'Cannot create new company label', data);
      setLoading(false);
      Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
    }

    if (newCompanyLabel) {
      if (isLabelCreateOnly) {
        updateCompanyLabels(get(newCompanyLabel, 'data'));
        closeCb();
      } else {
        addExistingLabelToUser(get(newCompanyLabel, 'data.id'));
        updateCompanyLabels(get(newCompanyLabel, 'data'));
      }
      return true;
    }
    return false;
  };

  const handleSubmit = () => {
    if (!showLabelCreate) {
      addExistingLabelToUser(get(selectedLabel, 'id'));
    } else {
      createAndAddNewLabelToUser();
    }
  };

  const handleClose = () => {
    if (!dirty) { return closeCb(); }
    return setDirtyFormAlertDisplay(true);
  };

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={loading}
      size="sm"
      title={__('Add user label')}
    >
      <div className="UserLabelsForm">
        {showLabelCreate ? (
          <div className="UserLabelsForm-create">
            <div>
              <Label text={__('New label name')} inputId="new-label-name" />
              <TextInput
                disabled={loading}
                handleChange={(val) => {
                  setDirty(true);
                  setNewLabelName(val);
                  debouncedValidateRequiredValue(val).then(err => setNewLabelNameError(err));
                }}
                id="new-label-name"
                value={newLabelName}
                error={newLabelNameError}
              />
            </div>
            <div>
              <Label text={__('Label color')} inputId="new-label-color" />
              <ColorPicker initialColor={selectedColor} onColorSelect={val => setSelectedColor(val)} label={selectedColor} />
            </div>
            {!isLabelCreateOnly && (
              <div className="back-label-btn">
                <Button
                  onClick={() => setLabelCreateDisplay(false)}
                  theme="link"
                  size="sm"
                  disabled={loading}
                >
                  {__('Back')}
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div className="UserLabelsForm-add">
            <div>
              <Label text={__('Select label')} inputId="label-select" />
              <CustomerLabelsSelector
                options={mapUserLabelsToSelector(availableLabels)}
                value={selectedLabel}
                onChangeCallback={handleLabelSelect}
                text={__('Available labels')}
              />
            </div>
            <div className="new-label-btn">
              <Button
                onClick={() => setLabelCreateDisplay(true)}
                theme="link"
                size="sm"
                disabled={loading}
              >
                <div>
                  <span>+</span>
                  {__('Create new label')}
                </div>
              </Button>
            </div>
          </div>
        )}
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormAlertDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

UserLabelsForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  companyLabels: PropTypes.array.isRequired,
  companyID: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  isLabelCreateOnly: PropTypes.bool,
  refetchUser: PropTypes.func.isRequired,
  updateCompanyLabels: PropTypes.func.isRequired,
};

UserLabelsForm.defaultProps = {
  isLabelCreateOnly: false,
};

export default UserLabelsForm;
