import React from 'react';
import Base from './Base';

export default props => (
  <Base {...props}>
    <path
      d="M70.36,2.5H29.64L2.5,29.64V70.36L29.64,97.5H70.36L97.5,70.36V29.64Zm6.78,54.29H22.86V43.21H77.14Z"
    />
  </Base>
);
