import api from 'shared/api';
import { get } from 'lodash';
import * as actionTypes from './actionTypes';

// COUPONS actions
const getCouponsInit = () => ({
  type: actionTypes.getCouponsInit,
});

const getCouponsSuccess = res => ({
  type: actionTypes.getCouponsSuccess,
  payload: res,
});

const getCouponsError = res => ({
  type: actionTypes.getCouponsError,
  payload: res,
});

export const getCoupons = companyID => (dispatch) => {
  dispatch(getCouponsInit());
  const url = `/api/v1/stripe/coupons/?company=${companyID}`;
  return api.get(url).then(
    res => dispatch(getCouponsSuccess(get(res, 'data') || [])),
    res => dispatch(getCouponsError(res.data)),
  );
};

// PRICING PLANS actions
const getPricingPlansInit = () => ({
  type: actionTypes.getPricingPlansInit,
});

const getPricingPlansSuccess = res => ({
  type: actionTypes.getPricingPlansSuccess,
  payload: res,
});

const getPricingPlansError = res => ({
  type: actionTypes.getPricingPlansError,
  payload: res,
});

export const getPricingPlans = (companyID, type = 'enterprise') => (dispatch) => {
  dispatch(getPricingPlansInit());
  const url = `/api/v1/stripe/plans/?type=${type}&company=${companyID}`;
  return api.get(url).then(
    res => dispatch(getPricingPlansSuccess(get(res, 'data') || [])),
    res => dispatch(getPricingPlansError(res.data)),
  );
};

// Standard PRICING PLANS actions
const getStandardPricingPlansInit = () => ({
  type: actionTypes.getStandardPricingPlansInit,
});

const getStandardPricingPlansSuccess = res => ({
  type: actionTypes.getStandardPricingPlansSuccess,
  payload: res,
});

const getStandardPricingPlansError = res => ({
  type: actionTypes.getStandardPricingPlansError,
  payload: res,
});

export const getStandardPricingPlans = (companyID, type = 'standard') => (dispatch) => {
  dispatch(getStandardPricingPlansInit());
  const url = `/api/v1/stripe/plans/?type=${type}&company=${companyID}`;
  return api.get(url).then(
    res => dispatch(getStandardPricingPlansSuccess(get(res, 'data') || [])),
    res => dispatch(getStandardPricingPlansError(res.data)),
  );
};

// TAX RATES actions
const getTaxRatesInit = () => ({
  type: actionTypes.getTaxRatesInit,
});

const getTaxRatesSuccess = res => ({
  type: actionTypes.getTaxRatesSuccess,
  payload: res,
});

const getTaxRatesError = res => ({
  type: actionTypes.getTaxRatesError,
  payload: res,
});

export const getTaxRates = companyID => (dispatch) => {
  dispatch(getTaxRatesInit());
  const url = `/api/v1/stripe/tax-rates/?company=${companyID}`;
  return api.get(url).then(
    res => dispatch(getTaxRatesSuccess(get(res, 'data') || [])),
    res => dispatch(getTaxRatesError(res.data)),
  );
};

// -------------------------------------
export const createCoupon = (data, companyID) => api.post(`/api/v1/stripe/coupons/?company=${companyID}`, data);

export const deleteCoupon = (coupon, companyID) => api.delete(`/api/v1/stripe/coupons/${coupon}?company=${companyID}`);

export const createPricingPlan = (data, companyID) => api.post(`/api/v1/stripe/plans/?company=${companyID}`, data);

export const deletePricingPlan = (planId, companyID) => api.delete(`/api/v1/stripe/plans/${planId}?company=${companyID}`);

export const createTaxRate = (data, companyID) => api.post(`/api/v1/stripe/tax-rates/?company=${companyID}`, data);

export const deleteTaxRate = (taxId, companyID) => api.delete(`/api/v1/stripe/tax-rates/${taxId}/?company=${companyID}`);

export const addFeature = (data, companyID) => api.post(`/api/v1/features/?company=${companyID}`, data);

export const editFeature = (featureId, data, companyID) => api.patch(`/api/v1/features/${featureId}/?company=${companyID}`, data);

export const deleteFeature = (featureId, companyID) => api.delete(`/api/v1/features/${featureId}/?company=${companyID}`);

export const reactivateCompanyFeatures = companyID => api.post(`/api/v1/companies/${companyID}/activate_features/`);

export const changePlanType = (companyId, data) => (
  api.post(`/api/v1/companies/${companyId}/change_plan_type/`, data)
);

export const patchCompanyTrialDays = (trial, companyID) => (
  api.patch(`/api/v1/companies/${companyID}/`, { trial_days: Number(trial) })
);

export const extendTrial = (trialDays, companyID) => (
  api.post(`/api/v1/companies/${companyID}/extend_trial/`, { days: Number(trialDays) })
);

export const patchCompany = (companyID, data) => api.patch(`/api/v1/companies/${companyID}/`, data);

export const patchCompanyAsDEV = companyID => api.patch(`/api/v1/companies/${companyID}/`, { trial_days: 0, trial_start: null });

export const deactivateCompany = companyID => api.post(`/api/v1/companies/${companyID}/deactivate/`);

export const scheduleSubscriptionUpdate = (companyID, data) => {
  const url = `/api/v1/companies/${companyID}/schedule_update_subscription/`;
  return api.post(url, data);
};

export const deleteScheduledUpdate = (companyID) => {
  const url = `/api/v1/companies/${companyID}/schedule_update_cancel/`;
  return api.post(url);
};

export const rescheduleUpdate = (companyID, data) => {
  const url = `/api/v1/companies/${companyID}/schedule_update_subscription/`;
  return api.post(url, data);
};

export const editStandardSubscription = (companyId, data) => (
  api.post(`/api/v1/companies/${companyId}/update_standard_subscription/`, data)
);

export const createSubscription = (companyId, data) => (
  api.post(`/api/v1/companies/${companyId}/create_enterprise_subscription/`, data)
);

export const editSubscription = (companyId, data) => (
  api.post(`/api/v1/companies/${companyId}/update_enterprise_subscription/`, data)
);

export const cancelSubscription = companyId => (
  api.post(`/api/v1/companies/${companyId}/cancel_enterprise_subscription/`)
);

export const wipeData = (companyId, data) => (
  api.post(`/api/v1/companies/${companyId}/wipe_data/`, data)
);
