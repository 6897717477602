import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  Button,
  Label,
  IconActions,
  IconDelete,
  IconEdit,
  IconConfig,
  IconManager,
  IconPassword,
  IconReset,
  Modal,
  PermissionMissingNotificationTitle,
  Notification,
} from 'shared/components';
import {
  getDisabledMessage,
  isFeatureAvailable,
  isFeatureEnabled,
  checkUserPermission,
} from 'shared/helpers';
import { platformFeatures, userPermissions } from 'shared/constants';
import './styles.scss';

const LicenseUsersActionMenu = ({
  user,
  handleEditLicenseUser,
  handleRemoveLicenseUser,
  handleManagerLicenseUser,
  handleMaxActivationsChange,
  handleResetTotalActivations,
  handlePasswordChange,
}) => {
  const canManageLicenses = checkUserPermission(userPermissions.licenses_write);
  const [isMenuDisplayed, setMenuDisplay] = useState(false);
  const toggleMenu = () => setMenuDisplay(!isMenuDisplayed);

  const isManager = get(user, 'is_license_manager');

  const handleToggleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!canManageLicenses) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.licenses_write} />,
        __('Contact you account admin for support.'),
      );
      return false;
    }
    toggleMenu();
    return true;
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className="LicenseUsersActionMenuBtn" onClick={e => e.stopPropagation()} role="button" tabIndex={0}>
      <button
        className="table-select"
        aria-label="Select license"
        onClick={handleToggleClick}
        type="button"
      >
        <IconActions stroke="#2e86de" />
      </button>
      {isMenuDisplayed && (
        <Modal confirmCb={() => {}} hideFooter closeCb={toggleMenu} size="sm">
          <div className="LicenseUsersActionMenu">
            <div className="header">
              <Label text={__('License user')} />
              <div className="text">{get(user, 'true_email')}</div>
            </div>
            <ul className="inner-menu">
              <li>
                <Button
                  featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
                  featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
                  notEnabledMessage={getDisabledMessage()}
                  className="reset-button"
                  onClick={() => handleEditLicenseUser(user)}
                  type="button"
                >
                  <>
                    <div className="icon-wrapper">
                      <IconEdit height="18px" width="18px" />
                    </div>
                    <div>{__('Edit license user')}</div>
                  </>
                </Button>
              </li>
              <li>
                <Button
                  featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
                  featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
                  notEnabledMessage={getDisabledMessage()}
                  className="reset-button"
                  onClick={() => handlePasswordChange()}
                  type="button"
                >
                  <>
                    <div className="icon-wrapper">
                      <IconPassword height="22px" width="22px" viewBox="0 0 65 60" />
                    </div>
                    <div>{__('Change password')}</div>
                  </>
                </Button>
              </li>
              <li>
                <Button
                  featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
                  featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
                  notEnabledMessage={getDisabledMessage()}
                  className="reset-button"
                  onClick={() => handleManagerLicenseUser(user)}
                  type="button"
                >
                  <>
                    <div className="icon-wrapper">
                      <IconManager height="18px" width="18px" />
                    </div>
                    <div>{isManager ? __('Remove as license manager') : __('Set as license manager')}</div>
                  </>
                </Button>
              </li>
              <li>
                <Button
                  featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
                  featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
                  notEnabledMessage={getDisabledMessage()}
                  className="reset-button"
                  onClick={() => handleMaxActivationsChange(user)}
                  type="button"
                >
                  <>
                    <div className="icon-wrapper">
                      <IconConfig width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#555" strokeWidth="2" strokeLinecap="square" color="#555" />
                    </div>
                    <div>{__('Set max activations')}</div>
                  </>
                </Button>
              </li>
              <li>
                <Button
                  featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
                  featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
                  notEnabledMessage={getDisabledMessage()}
                  className="reset-button"
                  onClick={() => handleResetTotalActivations(user)}
                  type="button"
                >
                  <>
                    <div className="icon-wrapper">
                      <IconReset
                        fill="none"
                        stroke="#555"
                        strokeWidth="2"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                      />
                    </div>
                    <div>{__('Reset total activations')}</div>
                  </>
                </Button>
              </li>
              <li>
                <Button
                  featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
                  featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
                  notEnabledMessage={getDisabledMessage()}
                  className="reset-button"
                  onClick={() => handleRemoveLicenseUser(user)}
                  type="button"
                >
                  <>
                    <div className="icon-wrapper">
                      <IconDelete height="16px" width="16px" color="#ee5253" />
                    </div>
                    <div>{__('Remove')}</div>
                  </>
                </Button>
              </li>
            </ul>
          </div>
        </Modal>
      )}
    </div>
  );
};

LicenseUsersActionMenu.propTypes = {
  user: PropTypes.object.isRequired,
  handleEditLicenseUser: PropTypes.func.isRequired,
  handleManagerLicenseUser: PropTypes.func.isRequired,
  handleRemoveLicenseUser: PropTypes.func.isRequired,
  handleMaxActivationsChange: PropTypes.func.isRequired,
  handleResetTotalActivations: PropTypes.func.isRequired,
  handlePasswordChange: PropTypes.func.isRequired,
};

export default LicenseUsersActionMenu;
