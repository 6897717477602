import React from 'react';
import PropTypes from 'prop-types';
import {
  InputErrorMessage, IconInfo, Tooltip, Notification,
} from 'shared/components';
import { defaultDisabledFeatureMessage } from 'shared/constants';
import './styles.scss';

const RadioBtn = ({
  disabled,
  featureAvailable,
  featureEnabled,
  notAvailableMessage,
  notEnabledMessage,
  description,
  error,
  inputId,
  value,
  name,
  label,
  handleChange,
  ...rest
}) => {
  const handleInputChange = e => handleChange(e.target.value, e);

  const handleFeatureDisabledChange = (e) => {
    e.preventDefault();
    Notification('error', __(defaultDisabledFeatureMessage), __(notEnabledMessage));
  };

  if (!featureAvailable) {
    return (
      <Tooltip
        active={!featureAvailable}
        position="topLeft"
        content={<div className="feature-unavailable-tooltip">{notAvailableMessage}</div>}
      >
        <div className="RadioBtn RadioBtn-disabled">
          <label htmlFor={inputId}>
            <input
              type="radio"
              name={name}
              className={error ? 'has-error' : ''}
              id={inputId}
              value={value}
              onChange={() => {}}
              {...rest}
            />
            <span>{label}</span>
          </label>
        </div>
      </Tooltip>
    );
  }

  return (
    <div className="RadioBtn">
      <label htmlFor={inputId}>
        <input
          type="radio"
          name={name}
          className={error ? 'has-error' : ''}
          id={inputId}
          value={value}
          onChange={featureEnabled ? handleInputChange : handleFeatureDisabledChange}
          {...rest}
        />
        <span>
          {label}
          {description && (
            <Tooltip content={description} active>
              <span>
                <IconInfo height="12px" width="12px" />
              </span>
            </Tooltip>
          )
          }
        </span>
      </label>
      <InputErrorMessage text={error} />
    </div>
  );
};

RadioBtn.propTypes = {
  description: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  inputId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  featureAvailable: PropTypes.bool,
  notAvailableMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  featureEnabled: PropTypes.bool,
  notEnabledMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

RadioBtn.defaultProps = {
  disabled: false,
  description: null,
  featureAvailable: true,
  notAvailableMessage: '',
  featureEnabled: true,
  notEnabledMessage: '',
  label: '',
  error: '',
  handleChange: () => {},
};

export default RadioBtn;
