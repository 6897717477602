import React, { useState } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  ConfirmationPopup,
  DescriptionTable,
  List,
  Page,
  IconEdit,
  IconDelete,
  Notification,
  PermissionMissingNotificationTitle,
} from 'shared/components';
import { userPermissionLabels, userPermissions } from 'shared/constants';
import { sendErrorReport, checkUserPermission } from 'shared/helpers';
import {
  getUserPermissionsTemplates,
  deleteUserPermissionTemplate,
} from 'src/company/actions';
import { PermissionTemplateForm } from './components';

const Permissions = () => {
  const canManagePlatformUsers = checkUserPermission(userPermissions.platform_users_write);
  const dispatch = useDispatch();
  const companyID = useSelector(state => get(state, 'company.details.id'));
  const loadingPermissionTemplates = useSelector(state => get(state, 'company.loadingUserPermissionsTemplates'));
  const userPermissionsTemplates = useSelector(state => get(state, 'company.userPermissionsTemplates'));

  const [expandedTableRow, setExpandedTableRow] = useState({});
  const [showForm, setFormDisplay] = useState(false);
  const [templateToEdit, setTemplateToEdit] = useState(null);
  const [templateToDelete, setTemplateToDelete] = useState(null);
  const [isTemplateDeletePopupDisplayed, setTemplateDeletePopupDisplay] = useState(false);
  const [deletingTemplate, setDeletingTemplate] = useState(false);

  const handleTemplateFormClose = () => {
    setFormDisplay(false);
    setTemplateToEdit(null);
  };

  const handleTemplateDelete = (template) => {
    const templateID = get(template, 'id');
    setDeletingTemplate(true);

    deleteUserPermissionTemplate(templateID, companyID)
      .then(() => {
        setDeletingTemplate(false);
        setTemplateDeletePopupDisplay(false);
        setTemplateToDelete(null);
        dispatch(getUserPermissionsTemplates(companyID));
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot delete permission template');
        setDeletingTemplate(false);
      });
  };

  const formatPermissions = templatePermissions => (
    templatePermissions.map(up => up.code).sort().map(code => userPermissionLabels[code]).join(', ')
  );

  const handleManagePlatformUsersClick = (cb) => {
    if (!canManagePlatformUsers) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.platform_users_write} />,
        __('Contact you account admin for support.'),
      );
      return false;
    }
    cb(true);
    return true;
  };

  return (
    <Page title={__('Permissions')}>
      <div className="PermissionsList">
        <div className="list-header">
          <div>
            <Button
              onClick={() => handleManagePlatformUsersClick(setFormDisplay)}
              theme="info"
              size="sm"
              disabled={loadingPermissionTemplates}
            >
              {__('Add new permissions template')}
            </Button>
          </div>
          <div />
        </div>
        <List
          columns={[
            {
              expander: true,
              Header: __('Details'),
              headerClassName: 'text-center',
              width: 80,
              style: {
                fontSize: 25,
                padding: '0',
                textAlign: 'center',
                userSelect: 'none',
              },
            },
            { accessor: 'name', Header: __('Name') },
            {
              className: 'text-center',
              id: 'edit',
              width: 80,
              sortable: false,
              Cell: rowData => (
                <Button
                  className="edit-button"
                  onClick={() => handleManagePlatformUsersClick(() => {
                    setTemplateToEdit(get(rowData, 'original'));
                    setFormDisplay(true);
                  })}
                  type="button"
                >
                  <IconEdit height="16px" width="16px" />
                </Button>
              ),
            },
            {
              className: 'text-center',
              id: 'delete',
              width: 80,
              sortable: false,
              Cell: rowData => (
                <Button
                  className="edit-button"
                  onClick={() => handleManagePlatformUsersClick(() => {
                    setTemplateToDelete(rowData.original);
                    setTemplateDeletePopupDisplay(true);
                  })}
                  type="button"
                >
                  <IconDelete height="16px" width="16px" color="#ee5253" />
                </Button>
              ),
              maxWidth: 50,
            },
          ]}
          data={userPermissionsTemplates}
          loading={loadingPermissionTemplates}
          minRows={2}
          pageSize={20}
          onExpandedChange={expanded => setExpandedTableRow(expanded)}
          expanded={expandedTableRow}
          SubComponent={row => (
            <div className="SubComponent">
              <DescriptionTable
                details={[
                  {
                    label: __('Permissions'),
                    value: formatPermissions(get(row, 'original.user_permissions')),
                  },
                ]}
              />
            </div>
          )}
        />
        {showForm && (
          <PermissionTemplateForm
            closeCb={handleTemplateFormClose}
            confirmCb={() => {
              dispatch(getUserPermissionsTemplates(companyID));
              handleTemplateFormClose();
            }}
            template={templateToEdit}
            isOpen={showForm}
          />
        )}
        {isTemplateDeletePopupDisplayed && (
          <ConfirmationPopup
            closeCb={() => {
              setTemplateDeletePopupDisplay(false);
              setTemplateToDelete(null);
            }}
            confirmCb={() => handleTemplateDelete(templateToDelete)}
            title={`${__('Are you sure you want to delete this permissions template')}?`}
            confirmText={__('Delete')}
            theme="error"
            disabled={deletingTemplate}
          >
            <div>
              <strong>{get(templateToDelete, 'name')}</strong>
            </div>
          </ConfirmationPopup>
        )}
      </div>
    </Page>
  );
};

export default Permissions;
