import { get } from 'lodash';

export const mapCompanyFeatures = (companyFeatures, featuresList) => {
  const features = [];

  if (!companyFeatures || !featuresList) {
    return features;
  }

  if (Array.isArray(companyFeatures) && Array.isArray(featuresList)) {
    companyFeatures.forEach((cf) => {
      const featureOption = featuresList.find(f => get(f, 'key') === get(cf, 'key'));
      if (featureOption) {
        const feature = {
          ...featureOption,
          value: get(cf, 'value'),
          is_active: get(cf, 'is_active'),
          id: get(cf, 'id'),
        };
        features.push(feature);
      }
    });
  }
  return features;
};
