export const initialState = {
  active: true,
  // product information
  product_name: '',
  short_code: '',
  company: '',
  installation_files: [],
  // license usage
  default_license_type: 'perpetual',
  subscription_duration: '',
  valid_duration: '',
  enable_maintenance_period: false,
  maintenance_duration: '',
  max_consumptions: 1,
  allow_overages: false,
  max_overages: 0,
  reset_consumption: false,
  consumption_period: null,
  is_floating: false,
  is_floating_cloud: false,
  floating_users: 0,
  floating_timeout: 120,
  // license options
  max_activations: 1,
  allow_trial: false,
  trial_days: undefined,
  authorization_method: 'license-key',
  prevent_vm: false,
  // legacy
  hardware_id_required: false,
  is_upgrade: false,
  upgrade_from: [],
  is_node_locked: true,
  is_user_locked: false,
};

// reducer actions
export const productInformations = 'PRODUCT_INFORMATIONS';

export const actionTypes = {
  productInformations,
};
