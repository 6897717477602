import { get } from 'lodash';
import {
  getNumbersFromString,
  getCharsFromString,
} from 'shared/helpers';

// --------------- INITIAL VALUES ---------------------

export const getInitialProductValue = (value, products, selectedProduct = null, fallback = undefined) => {
  if (!selectedProduct) {
    return get(products, `[0].${value}`) || fallback;
  }
  return get(selectedProduct, `${value}`) || fallback;
};

export const getInitialValue = (value, policy, product = null, fallback = undefined) => {
  if (!product) {
    return get(policy, `${value}`) || fallback;
  }
  return get(product, `${value}`) || fallback;
};

export const getInitialPeriodValue = (value, policy, product = null, fallback = '1y') => {
  const data = getInitialValue(value, policy, product, fallback);
  const val = getNumbersFromString(data, fallback);
  return Number(val) || 1;
};

export const getInitialPeriodLabel = (labelOptions, value, policy, product = null, fallback = '1y') => {
  const data = getInitialValue(value, policy, product, fallback);
  const char = getCharsFromString(data, fallback);

  const label = labelOptions.find(lo => lo.value === char);
  return get(label, 'value') || 'y';
};

export const getEditProductLicenseUsersEmails = (product) => {
  if (!product) {
    return [];
  }

  const emails = get(product, 'license_users_emails');
  if (!emails) {
    return [];
  }
  return emails.split(',');
};

export const getResetConsumptionValue = (consumptionPeriods, policy, product = null, fallback = 'daily') => {
  let cp;

  if (!product) {
    cp = get(policy, 'consumption_period') || fallback;
  } else {
    cp = get(product, 'consumption_period') || fallback;
  }

  const selectedCp = consumptionPeriods.find(period => period.value === cp);
  if (!cp || !selectedCp) {
    return get(consumptionPeriods, '[0].value') || 'daily';
  }
  return get(selectedCp, 'value') || 'daily';
};

// --------------- POLICIY VALUES ---------------------

export const getPolicyValue = (value, policy, fallback = undefined) => get(policy, `${value}`) || fallback;

export const getPolicyPeriodValue = (value, policy, fallback = '1y') => {
  const data = getPolicyValue(value, policy, fallback);
  const val = getNumbersFromString(data, fallback);
  return Number(val) || 1;
};

export const getPolicyPeriodLabel = (labelOptions, value, policy, fallback = '1y') => {
  const data = getPolicyValue(value, policy, fallback);
  const char = getCharsFromString(data, fallback);

  const label = labelOptions.find(lo => lo.value === char);
  return get(label, 'value') || 'y';
};

export const getPolicyResetConsumptionValue = (consumptionPeriods, policy, fallback = 'daily') => {
  const cp = get(policy, 'consumption_period') || fallback;
  const selectedCp = consumptionPeriods.find(period => period.value === cp);

  if (!cp || !selectedCp) {
    return get(consumptionPeriods, '[0].value') || 'daily';
  }
  return get(selectedCp, 'value') || 'daily';
};
