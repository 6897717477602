import React from 'react';
import PropTypes from 'prop-types';
import {
  CheckboxSelector,
  Label,
  Notification,
} from 'shared/components';
import {
  platformFeatures,
  defaultDisabledFeatureMessage,
} from 'shared/constants';
import {
  isFeatureEnabled,
  isFeatureAvailable,
  getDisabledMessage,
} from 'shared/helpers';
import ProductFeaturesEditor from 'src/license/ProductFeaturesEditor';
import './styles.scss';

const ProductFeaturesTab = ({
  productFeatures,
  handleProductFeatureSelect,
  selectedProductFeatures,
  handleFeaturesEdit,
  showExpiryDate,
}) => {
  const notAvailableMessage = (
    <>
      {__('Licensing features in your applications is not available in your plan.')}
      &nbsp;
      {__('Upgrade to use features licensing.')}
    </>
  );

  const handleSelection = (val) => {
    if (!isFeatureEnabled(platformFeatures.extra_feature_licensing)) {
      Notification('error', __(defaultDisabledFeatureMessage), __(getDisabledMessage()));
      return false;
    }
    return handleProductFeatureSelect(val);
  };

  return (
    <section className="ProductFeaturesTab product-features-section">
      <div className="ProductFeatures-field">
        <div className="ProductFeatures-selector">
          <Label text={__('Select product features')} />
          <CheckboxSelector
            text={__('Features list')}
            options={productFeatures}
            value={selectedProductFeatures}
            onChangeCallback={handleSelection}
            onMenuClose={() => {}}
            // featureEnabled={isFeatureEnabled(platformFeatures.extra_feature_licensing)}
            // notEnabledMessage={getDisabledMessage()}
            featureAvailable={isFeatureAvailable(platformFeatures.extra_feature_licensing)}
            notAvailableMessage={notAvailableMessage}
          />
        </div>
        <div>
          <ProductFeaturesEditor
            disableFeatureExpiry={!showExpiryDate}
            selectedFeatures={selectedProductFeatures}
            onFeaturesChange={handleFeaturesEdit}
          />
        </div>
      </div>
    </section>
  );
};

ProductFeaturesTab.propTypes = {
  productFeatures: PropTypes.array.isRequired,
  handleProductFeatureSelect: PropTypes.func.isRequired,
  selectedProductFeatures: PropTypes.array.isRequired,
  handleFeaturesEdit: PropTypes.func.isRequired,
  showExpiryDate: PropTypes.bool,
};

ProductFeaturesTab.defaultProps = {
  showExpiryDate: true,
};

export default ProductFeaturesTab;
