import React from 'react';
import PropTypes from 'prop-types';
import {
  platformFeatures,
} from 'shared/constants';

const getMessage = (type) => {
  switch (type) {
    case platformFeatures.model_subscription:
      return (
        <>
          {__('Subscription licensing is not offered in your current plan.')}
          &nbsp;
          {__('Upgrade to unlock subscription licensing.')}
        </>
      );
    case platformFeatures.model_consumption:
      return (
        <>
          {__('Consumption licensing is not offered in your current plan.')}
          &nbsp;
          {__('Contact sales to support consumption licensing.')}
        </>
      );
    default:
      return (
        <>
          {__('This license model is not available in your current plan.')}
          &nbsp;
          {__('To compare different plan types click')}
          <a
            style={{ marginLeft: '3px' }}
            href="https://licensespring.com/pricing"
            target="_blank"
            rel="noopener noreferrer"
          >
            {__('here')}
          </a>
        </>
      );
  }
};

const LicenseNotAvailableMessage = ({ type }) => (
  <div className="feature-unavailable-tooltip">
    {getMessage(type)}
  </div>
);

LicenseNotAvailableMessage.propTypes = {
  type: PropTypes.string.isRequired,
};

export default LicenseNotAvailableMessage;
