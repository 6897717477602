import React from 'react';
import Base from './Base';

export default props => (
  <Base {...props} viewBox="0 0 50 50">
    <path
      d="M47.11,36.05H29.42v1.48H20.58V36.05H2.89a1.48,1.48,0,0,0,0,3H47.11a1.48,1.48,0,0,0,0-3ZM42.68,12.47A1.39,1.39,0,0,0,41.21,11H7.32a1.39,1.39,0,0,0-1.48,1.47V34.58H42.68ZM39.74,31.63H8.79V14h31Z"
      fillRule="evenodd"
    />
  </Base>
);
