import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import {
  sendErrorReport,
  mapProductsToSelector,
  mapPoliciesForSelector,
  getDefaultLicensePolicy,
} from 'shared/helpers';
import {
  Checkbox,
  CheckboxSelector,
  DirtyFormAlert,
  Label,
  Modal,
  Selector,
  NumberInput,
  Notification,
} from 'shared/components';
import {
  validateRequiredNumber,
  debouncedValidateRequiredNumber,
} from 'shared/validation';
import {
  updateLicenseManagerPermissions,
} from 'src/order/actions';
import './styles.scss';

const getRemainingProducts = (manager, companyProducts) => {
  const managerProducts = get(manager, 'products') || [];
  const remaining = companyProducts.filter(p => !managerProducts.find(m => get(m, 'product.id') === p.id));
  return remaining;
};

const LicenseManagerProductForm = ({
  confirmCb,
  closeCb,
  product,
  productPolicies,
  manager,
}) => {
  const companyID = useSelector(state => get(state, 'company.details.id'));
  const mPermissions = get(manager, 'permissions') || [];
  const mProducts = get(manager, 'products') || [];
  const mPolicies = get(manager, 'license_templates') || [];
  const managerID = get(manager, 'id');

  const companyProducts = useSelector(state => get(state, 'products.list'));
  const remainingProducts = getRemainingProducts(manager, companyProducts); // use first product if !product
  const initialProduct = get(mapProductsToSelector(remainingProducts, 'id'), '[0]'); // prefill if !product
  const initialLicensePolicy = mapPoliciesForSelector([getDefaultLicensePolicy(remainingProducts)]); // prefill if !product
  const initialPolicies = mapPoliciesForSelector(get(remainingProducts, '[0].license_templates') || []);

  // initial data when editing
  const managerProduct = companyProducts.find(p => get(p, 'id') === get(product, 'product.id'));
  const managerProductData = { value: get(managerProduct, 'id'), label: get(managerProduct, 'product_name') };
  const managerProductPolicies = mapPoliciesForSelector(get(managerProduct, 'license_templates') || []);
  const managerPolicies = mapPoliciesForSelector(productPolicies);
  const managerNumOfLicenses = get(product, 'num_of_licenses') || null;
  const isUnlimited = product ? managerNumOfLicenses === null : true;

  const [isLoading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormDisplay] = useState(false);

  const [policies, setPolicies] = useState(product ? managerProductPolicies : initialPolicies);
  const [selectedProduct, setSelectedProduct] = useState(product ? managerProductData : initialProduct);
  const [selectedPolicies, setSelectedPolicies] = useState(product ? managerPolicies : initialLicensePolicy);
  const [isUnlimitedLicenses, setIsUnlimitedLicenses] = useState(isUnlimited);
  const [numOfLicenses, setNumOfLicenses] = useState(managerNumOfLicenses || 1);
  const [numOfLicensesError, setNumOfLicensesError] = useState('');

  const validateNumOfLicenses = async () => {
    if (isUnlimitedLicenses) {
      return true;
    }

    setLoading(true);
    let errors;

    try {
      errors = await validateRequiredNumber(numOfLicenses);
      setNumOfLicensesError(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate num of licenses', { value: numOfLicenses });
    }
    setLoading(false);
    if (errors) { return false; }
    return true;
  };

  const isFormValid = async () => {
    const areNumOfLicensesValid = await validateNumOfLicenses();
    return !!areNumOfLicensesValid;
  };

  const handleProductSelect = (val) => {
    setDirty(true);

    const selectedP = mapProductsToSelector(companyProducts, 'id').find(p => get(p, 'value') === val);
    const productPolices = mapPoliciesForSelector(get(selectedP, 'data.license_templates') || []);
    const selected = [productPolices[0]];

    setSelectedProduct(selectedP);
    setPolicies(productPolices);
    setSelectedPolicies(selected);
    setIsUnlimitedLicenses(true);
    setNumOfLicenses(1);
    setNumOfLicensesError('');
  };

  const editProductPermissions = () => {
    const permisisonsData = mPermissions.map(p => p.id);
    const productsData = mProducts.map((p) => {
      const pID = get(p, 'product.id');
      if (pID === get(selectedProduct, 'value')) {
        return { product_id: selectedProduct.value, num_of_licenses: isUnlimitedLicenses ? null : Number(numOfLicenses) };
      }
      return { product_id: get(p, 'product.id'), num_of_licenses: get(p, 'num_of_licenses') };
    });

    // create policies list
    const policiesList = [];
    mPolicies.forEach((p) => {
      const pID = get(p, 'product.id');
      // if policy does not belong to the product, leave it as is, push to list
      if (pID !== get(selectedProduct, 'value')) {
        policiesList.push(p.id);
      }
      // if policy belongs to product, and it is selected, push to list
      if (pID === get(selectedProduct, 'value')) {
        const isSelected = selectedPolicies.find(sp => sp.id === p.id);
        if (isSelected) {
          policiesList.push(isSelected.id);
        }
      }
    });
    // if there is a policy in selected policy which is not present in mPolicies, push to list
    selectedPolicies.forEach((sp) => {
      const isMPolicy = mPolicies.find(p => p.id === sp.id);
      if (!isMPolicy) {
        policiesList.push(sp.id);
      }
    });

    const data = {
      permission_ids: permisisonsData,
      products: productsData,
      license_template_ids: policiesList,
    };
    updateLicenseManagerPermissions(managerID, companyID, data)
      .then(() => {
        Notification('success', __('Changes saved successfully'));
        setLoading(false);
        confirmCb();
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot update manager permissions', data);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
        setLoading(false);
      });
  };

  const addProductPermissions = () => {
    const permisisonsData = mPermissions.map(p => p.id);
    const productsData = mProducts.map(p => ({ product_id: get(p, 'product.id'), num_of_licenses: get(p, 'num_of_licenses') }));
    const expandedProductsData = [...productsData, { product_id: selectedProduct.value, num_of_licenses: isUnlimitedLicenses ? null : Number(numOfLicenses) }];
    const productsPoliciesData = mPolicies.map(p => p.id);
    const expandedProductsPoliciesData = [...productsPoliciesData, ...selectedPolicies.map(sp => sp.id)];

    const data = {
      permission_ids: permisisonsData,
      products: expandedProductsData,
      license_template_ids: expandedProductsPoliciesData,
    };
    updateLicenseManagerPermissions(managerID, companyID, data)
      .then(() => {
        Notification('success', __('Changes saved successfully'));
        setLoading(false);
        confirmCb();
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot update manager permissions', data);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
        setLoading(false);
      });
  };

  const handleSubmit = async () => {
    const isValid = await isFormValid();
    if (!isValid || isLoading) {
      return false;
    }

    if (product) {
      editProductPermissions();
    } else {
      addProductPermissions();
    }
    return true;
  };

  const handleClose = () => {
    if (!isDirty) { return closeCb(); }
    return setDirtyFormDisplay(true);
  };

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={isLoading}
      title={product ? __('Edit product permissions') : __('Create product permissions')}
      size="sm"
    >
      <div className="LicenseManagerProductForm">
        <div className="row input-cont">
          <Label inputId="product-input" text={__('Product Name')} />
          <Selector
            options={product ? mapProductsToSelector(companyProducts, 'id') : mapProductsToSelector(remainingProducts, 'id')}
            handleChange={handleProductSelect}
            value={get(selectedProduct, 'value')}
            inputId="product-input"
            disabled={!!product}
          />
        </div>
        <div className="row input-cont">
          <Label inputId="product-input" text={__('Number of licenses')} />
          <Checkbox
            label={__('Allow unlimited number of issued licenses')}
            inputId="allow-unlimited-licenses-checkbox"
            checked={isUnlimitedLicenses}
            handleChange={(val) => {
              setIsUnlimitedLicenses(val);
              setNumOfLicenses(1);
              setNumOfLicensesError('');
            }}
          />
          {!isUnlimitedLicenses && (
          <NumberInput
            value={numOfLicenses}
            error={numOfLicensesError}
            min="1"
            max="2147483647"
            handleChange={(val) => {
              setDirty(true);
              setNumOfLicenses(val);
              debouncedValidateRequiredNumber(val).then(err => setNumOfLicensesError(err));
            }}
          />
          )}
        </div>
        <div className="checkbox-selector">
          <Label text={__('Select enabled license policies')} />
          <CheckboxSelector
            text={__('Selected policies')}
            options={policies}
            value={selectedPolicies}
            onChangeCallback={(val) => {
              setDirty(true);
              setSelectedPolicies(val);
            }}
            onMenuClose={() => { }}
          />
        </div>
        <ul className="LicenseManagerProductForm-selected-policies">
          {selectedPolicies.map(p => (
            <li key={p.id}>{p.name}</li>
          ))}
        </ul>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={isDirty}
          closeAlert={() => setDirtyFormDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

LicenseManagerProductForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  product: PropTypes.object,
  productPolicies: PropTypes.array,
  manager: PropTypes.object.isRequired,
};

LicenseManagerProductForm.defaultProps = {
  product: null,
  productPolicies: [],
};

export default LicenseManagerProductForm;
