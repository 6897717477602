import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment';
import {
  Button,
  ConfirmationPopup,
  DescriptionTable,
  Notification,
} from 'shared/components';
import {
  sendErrorReport,
  capitalizeFirstLetter,
  checkTrial,
  formatAmountValue,
  mapCurrencySymbol,
  displayCouponName,
  displayTaxRate,
  getSelectedPricingPlan,
  getUpcomingInvoiceDate,
} from 'shared/helpers';
import { cancelSubscription } from 'src/billing/actions';
import ScheduledUpdateContainer from '../ScheduledUpdateContainer';
import SubscriptionForm from '../SubscriptionForm';
import ScheduledUpdateForm from '../ScheduledUpdateForm';
import './styles.scss';

const EnterpriseSubscription = ({
  companyDetails,
  coupons,
  dispatchCompanyChange,
  hasSubscription,
  pricingPlans,
  taxRates,
}) => {
  const [cancelSubscriptionLoading, setCancelSubscriptionLoading] = useState(false);
  const [isCancelSubscriptionWarningDisplayed, setIsCancelSubscriptionWarningDisplayed] = useState(false);
  const [isSubscriptionFormDisplayed, setSubscriptionFormDisplayed] = useState(false);
  const [isScheduledUpdateFormDisplayed, setScheduledUpdateFormDisplayed] = useState(false);

  const handleSubscriptionCancel = () => {
    const companyId = get(companyDetails, 'id');
    setCancelSubscriptionLoading(true);

    cancelSubscription(companyId)
      .then(() => {
        dispatchCompanyChange(companyId);
        Notification('success', __('Subscription successfully canceled'));
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot cancel subscription');
        Notification('error', __('Error occured'), __('We could not cancel the subscription.'));
        setCancelSubscriptionLoading(false);
      });
  };

  const trial = checkTrial();
  const couponName = displayCouponName(get(companyDetails, 'upcoming_invoice.discounts[0].coupon.id') || get(companyDetails, 'payment_coupon_code'), coupons);
  const taxRate = displayTaxRate(get(companyDetails, 'stripe_tax_id'), taxRates);
  const taxAmount = formatAmountValue(get(companyDetails, 'upcoming_invoice.tax'));
  const pricingPlan = getSelectedPricingPlan(get(companyDetails, 'upcoming_invoice.plan_id'), pricingPlans);
  const nextPaymentDate = getUpcomingInvoiceDate(companyDetails);
  const isTrialSubscription = get(companyDetails, 'upcoming_invoice.in_trial') || '';
  const scheduledUpdate = get(companyDetails, 'scheduled_update');

  return (
    <div className="EnterpriseSubscription">
      <div className="section-header">
        {__('Subscription')}
      </div>
      <div className="section-body">
        <div className="list-header">
          <div>
            <Button
              size="sm"
              theme="info"
              onClick={() => setSubscriptionFormDisplayed(true)}
            >
              {hasSubscription ? __('Edit subscription') : __('Create a subscription')}
            </Button>
            {hasSubscription && !isTrialSubscription && (
              <Button
                size="sm"
                theme="default"
                onClick={() => setScheduledUpdateFormDisplayed(true)}
                disabled={!!scheduledUpdate}
              >
                {__('Add scheduled update')}
              </Button>
            )}
          </div>
          <div>
            {hasSubscription && (
              <Button
                size="sm"
                theme="error"
                disabled={cancelSubscriptionLoading}
                onClick={() => setIsCancelSubscriptionWarningDisplayed(true)}
              >
                {__('Cancel subscription')}
              </Button>
            )}
          </div>
        </div>
        <div>
          {!!scheduledUpdate && (
            <ScheduledUpdateContainer
              scheduledUpdate={scheduledUpdate}
              companyDetails={companyDetails}
              coupons={coupons}
              taxRates={taxRates}
              pricingPlans={pricingPlans}
              refreshCompany={dispatchCompanyChange}
            />
          )}
        </div>
        <DescriptionTable
          details={[
            {
              label: hasSubscription ? __('Status') : null,
              value: isTrialSubscription ? __('Trial') : __('Active'),
              className: isTrialSubscription ? 'warning' : 'success',
            },
            {
              label: isTrialSubscription ? __('Trial end date') : null,
              value: moment(trial.trialEnd).format('YYYY-MM-DD'),
              className: 'warning',
            },
            { label: __('Pricing plan'), value: get(pricingPlan, 'nickname') || '-' },
            {
              label: __('Billing cycle'),
              value: capitalizeFirstLetter(get(pricingPlan, 'interval')) || '-',
            },
            {
              label: __('Amount'),
              value: formatAmountValue(get(pricingPlan, 'amount')),
            },
            {
              label: __('Currency'),
              value: mapCurrencySymbol(get(pricingPlan, 'currency')) || '-',
            },
            { label: __('Discount'), value: couponName },
            { label: __('Tax rate'), value: taxRate },
            { label: __('Tax amount'), value: taxAmount },
            {
              label: __('Total'),
              value: `${formatAmountValue(get(companyDetails, 'upcoming_invoice.total'))} ${mapCurrencySymbol(get(pricingPlan, 'currency')) || ''}`,
            },
            {
              label: nextPaymentDate ? __('Next billing date') : null,
              value: moment(nextPaymentDate).format('YYYY-MM-DD'),
            },
          ]}
        />
      </div>
      {isSubscriptionFormDisplayed && (
        <SubscriptionForm
          closeCb={() => setSubscriptionFormDisplayed(false)}
          companyDetails={companyDetails}
          title={hasSubscription ? __('Edit subscription') : __('Create a subscription')}
          coupons={coupons}
          taxRates={taxRates}
          pricingPlans={pricingPlans}
          hasSubscription={hasSubscription}
        />
      )}
      {isScheduledUpdateFormDisplayed && (
        <ScheduledUpdateForm
          closeCb={() => setScheduledUpdateFormDisplayed(false)}
          companyDetails={companyDetails}
          title={__('Scheduled subscription update')}
          coupons={coupons}
          taxRates={taxRates}
          pricingPlans={pricingPlans}
        />
      )}
      {isCancelSubscriptionWarningDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setCancelSubscriptionLoading(false);
            setIsCancelSubscriptionWarningDisplayed(false);
          }}
          confirmCb={() => handleSubscriptionCancel()}
          title={__('This action cannot be undone!')}
          confirmText={__('Confirm')}
          theme="error"
          warning
          disabled={cancelSubscriptionLoading}
        >
          {__('Please, confirm that you really want to cancel this subscription.')}
        </ConfirmationPopup>
      )}
    </div>
  );
};

EnterpriseSubscription.propTypes = {
  companyDetails: PropTypes.object.isRequired,
  coupons: PropTypes.array.isRequired,
  dispatchCompanyChange: PropTypes.func.isRequired,
  hasSubscription: PropTypes.bool.isRequired,
  pricingPlans: PropTypes.array.isRequired,
  taxRates: PropTypes.array.isRequired,
};

export default EnterpriseSubscription;
