import React, { useState, useEffect, useCallback } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import {
  Button,
  ConfirmationPopup,
  IconClipboard,
  IconDelete,
  IconEdit,
  IconInfo,
  List,
  Notification,
  PermissionMissingNotificationTitle,
  Tooltip,
  Switcher,
} from 'shared/components';
import {
  sendErrorReport,
  copyText,
  displayValue,
  checkUserPermission,
} from 'shared/helpers';
import { userPermissions } from 'shared/constants';
import {
  fetchMgmgtApiKeys,
  createNewMgmgtApiKey,
  deleteMgmgtApiKey,
  updateMgmgtApiKey,
} from 'src/account/actions';
import EditMgmtKeyForm from '../EditMgmtKeyForm';
import './styles.scss';

const ManagementAPI = () => {
  const canManageSettings = checkUserPermission(userPermissions.settings_write);
  const companyDetails = useSelector(state => get(state, 'company.details'));
  const companyID = get(companyDetails, 'id');

  const [isLoading, setLoading] = useState(true);
  // mgmt api keys
  const [mgmtKeys, setMgmtKeys] = useState([]);
  const [keyGenerateLoading, setKeyGenerateLoading] = useState(false);
  const [keyToDelete, setKeyToDelete] = useState(null);
  const [isKeyDeleteConfirmationDisplayed, setKeyDeleteConfirmationDisplayed] = useState(false);
  const [keyDeleteLoading, setKeyDeleteLoading] = useState(false);
  const [isEditFormDisplayed, setEditFormDisplay] = useState(false);
  const [keyToEdit, setKeyToEdit] = useState(null);

  const getMgmtKeys = useCallback(() => {
    fetchMgmgtApiKeys(companyID)
      .then((res) => {
        const data = get(res, 'data') || [];
        const keysList = data.filter(k => k.management_api_key);
        setMgmtKeys(keysList);
        setLoading(false);
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot get mgmt api keys');
        setLoading(false);
        Notification('error', __('There was an error while getting your data'), __('Try again later'));
      });
  }, [companyID]);

  useEffect(() => {
    getMgmtKeys();
  }, [getMgmtKeys]);

  const handleFieldCopy = (val, desc) => {
    copyText(val);
    Notification('success', `${desc} ${__('copied to clipboard')}`);
  };

  const generateNewKey = () => {
    setKeyGenerateLoading(true);

    createNewMgmgtApiKey(companyID)
      .then((res) => {
        setMgmtKeys([...mgmtKeys, get(res, 'data')]);
        setKeyGenerateLoading(false);
        Notification('success', __('New management API key generated'));
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot generate new mgmt api key');
        setKeyGenerateLoading(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
  };

  const updateApiKey = (key, keyData) => {
    const keyID = get(key, 'management_api_key');
    const data = { api_key: keyID, ...keyData };

    updateMgmgtApiKey(companyID, data)
      .then(() => {
        const updatedKeys = mgmtKeys.map((k) => {
          if (k.management_api_key === keyID) return { ...key, ...keyData };
          return k;
        });
        setMgmtKeys(updatedKeys);
        Notification('success', __('Changes saved successfully'));
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot edit mgmt key', keyData);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
  };

  const handleKeyDelete = () => {
    const key = get(keyToDelete, 'management_api_key');
    setKeyDeleteLoading(true);

    deleteMgmgtApiKey(companyID, key)
      .then(() => {
        setKeyDeleteLoading(false);
        setKeyToDelete(null);
        setKeyDeleteConfirmationDisplayed(false);
        const updatedKeys = mgmtKeys.filter(k => k.management_api_key !== key);
        setMgmtKeys(updatedKeys);
        Notification('success', __('Management API key deleted successfully'));
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot delete mgmt key');
        setKeyDeleteLoading(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
  };

  const handleManageSettingsClick = (cb) => {
    if (!canManageSettings) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.settings_write} />,
        __('Contact you account admin for support.'),
      );
      return false;
    }
    cb(true);
    return true;
  };

  return (
    <div className="ManagementAPI">
      <div className="row">
        <h3>{__('Management API keys')}</h3>
        <Button
          size="sm"
          theme="info"
          loading={keyGenerateLoading}
          disabled={keyGenerateLoading || isLoading}
          onClick={() => handleManageSettingsClick(generateNewKey)}
        >
          {__('Generate new key')}
        </Button>
        <div className="management-keys-list">
          <List
            columns={[
              {
                accessor: 'management_api_key',
                Header: __('Key'),
                Cell: (cellData) => {
                  const key = get(cellData, 'value');
                  return (
                    <div className="key-container">
                      <span>{key}</span>
                      <button
                        type="button"
                        className="clipboard-btn"
                        onClick={() => handleFieldCopy(key, __('Management API key'))}
                      >
                        <IconClipboard width="21.5" height="20" viewBox="0 0 51.5 50" />
                      </button>
                    </div>
                  );
                },
                width: 430,
              },
              {
                accessor: 'label',
                Header: __('Label'),
                Cell: cellData => displayValue(get(cellData, 'value')),
              },
              {
                accessor: 'read_only',
                className: 'text-center',
                headerClassName: 'text-center',
                Header: () => (
                  <div className="descriptive-header">
                    {__('Read only')}
                    <Tooltip
                      content={__('If read only is enabled then only GET requests are allowed with this key.')}
                      active
                    >
                      <span><IconInfo height="12px" width="12px" /></span>
                    </Tooltip>
                  </div>
                ),
                Cell: cellData => (
                  <Switcher
                    size="sm"
                    checked={cellData.value}
                    handleChange={() => handleManageSettingsClick(() => updateApiKey(cellData.original, { read_only: !cellData.value }))}
                  />
                ),
                width: 120,
              },
              {
                headerClassName: 'text-center',
                className: 'text-center',
                accessor: 'revoked',
                Header: () => (
                  <div className="descriptive-header">
                    {__('Active')}
                    <Tooltip
                      content={__('If deactivated this key will be revoked. This cannot be undone, once revoked key cannot be used anymore.')}
                      active
                    >
                      <span><IconInfo height="12px" width="12px" /></span>
                    </Tooltip>
                  </div>
                ),
                Cell: cellData => (
                  <Switcher
                    size="sm"
                    checked={!cellData.value}
                    handleChange={() => handleManageSettingsClick(() => updateApiKey(cellData.original, { revoked: !cellData.value }))}
                    disabled={cellData.value}
                  />
                ),
                width: 100,
              },
              {
                Header: __('Edit'),
                headerClassName: 'text-center',
                className: 'text-center',
                id: 'edit',
                width: 80,
                sortable: false,
                Cell: rowData => (
                  <Button
                    className="edit-button"
                    onClick={() => handleManageSettingsClick(() => {
                      setKeyToEdit(rowData.original);
                      setEditFormDisplay(true);
                    })}
                    type="button"
                  >
                    <IconEdit height="16px" width="16px" />
                  </Button>
                ),
              },
              {
                Header: __('Delete'),
                headerClassName: 'text-center',
                className: 'text-center',
                id: 'delete',
                width: 80,
                sortable: false,
                Cell: rowData => (
                  <Button
                    className="edit-button"
                    onClick={() => handleManageSettingsClick(() => {
                      setKeyToDelete(rowData.original);
                      setKeyDeleteConfirmationDisplayed(true);
                    })}
                    type="button"
                  >
                    <IconDelete height="16px" width="16px" color="#ee5253" />
                  </Button>
                ),
              },
            ]}
            data={mgmtKeys}
            minRows={2}
            showPagination={false}
            loading={isLoading}
            clickable={false}
          />
        </div>
      </div>
      {isEditFormDisplayed && (
        <EditMgmtKeyForm
          apiKey={keyToEdit}
          closeCb={() => {
            setEditFormDisplay(false);
            setKeyToEdit(null);
          }}
          companyID={get(companyDetails, 'id')}
          refetchKeys={getMgmtKeys}
        />
      )}
      {isKeyDeleteConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setKeyToDelete(null);
            setKeyDeleteConfirmationDisplayed(false);
          }}
          confirmCb={handleKeyDelete}
          title={`${__('Are you sure you want to delete this management api key')}?`}
          confirmText={__('Delete')}
          theme="error"
          disabled={keyDeleteLoading}
        >
          <span style={{ fontSize: '14px', wordBreak: 'break-all' }}>{get(keyToDelete, 'key')}</span>
        </ConfirmationPopup>
      )}
    </div>
  );
};

export default ManagementAPI;
