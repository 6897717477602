import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { sendErrorReport } from 'shared/helpers';
import {
  ContentLoader,
  DirtyFormAlert,
  Label,
  Modal,
  Notice,
  Notification,
  Selector,
} from 'shared/components';
import {
  fetchMgmgtApiKeys,
  fetchLicenseApiKeys,
} from 'src/account/actions';
import { addIntegration, updateIntegration } from 'src/company/actions';
import './styles.scss';

const FastSpringForm = ({
  closeCb,
  onConfirm,
  fastSpringIntegration,
}) => {
  const companyDetails = useSelector(state => get(state, 'company.details'));
  const companyID = get(companyDetails, 'id');

  const [isLoading, setLoading] = useState(false);
  const [isMgmtKeysLoading, setMgmtKeysLoading] = useState(true);
  const [mgmtKeys, setMgmtKeys] = useState([]);
  const [selectedMgmtApiKey, setSelectedMgmtApiKey] = useState(get(fastSpringIntegration, 'data.management_api_key'));
  const [isLicenseApiKeysLoading, setLicenseApiKeysLoading] = useState(true);
  const [licenseApiKeys, setLicenseApiKeys] = useState([]);
  const [selectedLicenseApiApiKey, setSelectedLicenseApiApiKey] = useState(get(fastSpringIntegration, 'data.license_api_key'));
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormDisplay] = useState(false);

  const getMgmtKeys = useCallback(() => {
    fetchMgmgtApiKeys(companyID)
      .then((res) => {
        const data = get(res, 'data') || [];
        const keysList = data
          .filter(k => !k.read_only && !k.revoked)
          .filter(k => k.management_api_key);
        setMgmtKeys(keysList);
        setMgmtKeysLoading(false);
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot get mgmt api keys');
        setMgmtKeysLoading(false);
      });
  }, [companyID]);

  const getLicenseApiKeys = useCallback(() => {
    fetchLicenseApiKeys(companyID)
      .then((res) => {
        const data = get(res, 'data.results') || [];
        const keysList = data
          .filter(k => !k.read_only && !k.revoked)
          .filter(k => k.uuid);
        setLicenseApiKeys(keysList);
        setLicenseApiKeysLoading(false);
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot get license api keys');
        setLicenseApiKeysLoading(false);
      });
  }, [companyID]);

  useEffect(() => {
    getMgmtKeys();
    getLicenseApiKeys();
  }, [getMgmtKeys, getLicenseApiKeys]);

  const editIntegrationInLS = (integrationData) => {
    const integrationID = get(fastSpringIntegration, 'id');
    updateIntegration(integrationID, companyID, integrationData)
      .then(() => {
        onConfirm();
      })
      .catch((err) => {
        sendErrorReport(err);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
        setLoading(false);
      });
  };

  const saveIntegrationInLS = (integrationData) => {
    addIntegration(companyID, integrationData)
      .then(() => {
        onConfirm();
      })
      .catch((err) => {
        sendErrorReport(err);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
        setLoading(false);
      });
  };

  const handleSubmit = async () => {
    if (isLoading) { return false; }

    if (!selectedMgmtApiKey || !selectedLicenseApiApiKey) {
      Notification('error', __('Management and License API keys are required'));
      return false;
    }

    setLoading(true);

    const data = {
      management_api_key: selectedMgmtApiKey,
      license_api_key: selectedLicenseApiApiKey,
    };

    const integrationData = {
      code: 'fastspring',
      is_active: true,
      is_verified: true,
      data,
    };

    if (!fastSpringIntegration) {
      saveIntegrationInLS(integrationData, data);
    } else {
      editIntegrationInLS(integrationData, data);
    }
    return true;
  };

  const handleClose = () => {
    if (!dirty) { return closeCb(); }
    return setDirtyFormDisplay(true);
  };

  if (isMgmtKeysLoading || isLicenseApiKeysLoading) {
    return (
      <Modal
        closeCb={handleClose}
        confirmCb={() => {}}
        disabled={isMgmtKeysLoading}
        title={__('Activate FastSpring integration')}
      >
        <div className="FastSpringForm">
          <ContentLoader text="" />
        </div>
      </Modal>
    );
  }


  const mgtmApiKeyOptions = mgmtKeys.map(k => ({ value: k.management_api_key, label: k.management_api_key }));
  const licenseApiKeyOptions = licenseApiKeys.map(k => ({ value: k.uuid, label: k.uuid }));

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={isLoading}
      title={fastSpringIntegration ? __('Edit FastSpring integration') : __('Activate FastSpring integration')}
    >
      <div className="FastSpringForm Integrations">
        <div>
          <Label inputId="mgmt-api-key-select" text={__('Management API key')} description={__('Key has to be active and not read_only')} />
          <Selector
            options={mgtmApiKeyOptions}
            value={selectedMgmtApiKey}
            handleChange={(val) => {
              setDirty(true);
              setSelectedMgmtApiKey(val);
            }}
            blurInputOnSelect
            isDisabled={isLoading}
          />
        </div>
        <div>
          <Label inputId="license-api-key-select" text={__('License API key')} description={__('Key has to be active and not read_only')} />
          <Selector
            options={licenseApiKeyOptions}
            value={selectedLicenseApiApiKey}
            handleChange={(val) => {
              setDirty(true);
              setSelectedLicenseApiApiKey(val);
            }}
            blurInputOnSelect
            isDisabled={isLoading}
          />
          <Notice size="sm" title={__('Use this License API key as uuid in your dashboard when setting up the webhook details')}>
            <div className="docs-link">
              <p>{__('Find integration documentation')}</p>
              <a href="https://docs.licensespring.com/integrations/fastspring" target="_blank" rel="noopener noreferrer">{__('here')}</a>
            </div>
          </Notice>
        </div>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

FastSpringForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  fastSpringIntegration: PropTypes.object,
};

FastSpringForm.defaultProps = {
  fastSpringIntegration: null,
};

export default FastSpringForm;
