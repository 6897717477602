import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import {
  sendErrorReport,
  displayValue,
  formatDate,
  checkUserPermission,
} from 'shared/helpers';
import api from 'shared/api';
import { defaultDateTimeFormat, userPermissions } from 'shared/constants';
import {
  DescriptionTable,
  FileUploader,
  ImportStatus,
  List,
  Notification,
  PermissionDenied,
} from 'shared/components';
import {
  getLicensesImportsResults,
} from 'src/license/actions';
import Template from './license_import_template.csv';
import './styles.scss';

const LicensesImport = ({
  companyID,
}) => {
  const canManageLicenses = checkUserPermission(userPermissions.licenses_write);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [importList, setImportList] = useState([]);
  const [expandedTableRow, setExpandedTableRow] = useState({});

  const fetchImports = useCallback(() => {
    getLicensesImportsResults(companyID)
      .then((res) => {
        const list = get(res, 'data.results') || [];
        setLoading(false);
        setImportList(list);
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot get license imports list');
        setLoading(false);
      });
  }, [companyID]);

  useEffect(() => {
    fetchImports();
  }, [fetchImports]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchImports();
    }, 20 * 1000);

    return () => clearInterval(interval);
  }, [fetchImports]);

  const handleWrongFileType = () => {
    Notification('error', __('Wrong file type'), __('Only .csv files can be uploaded'));
    setUploading(false);
  };

  const handleItemRemove = () => {
    setUploading(false);
    setProgress(0);
  };

  const handleSubmit = (val) => {
    const file = get(val, 'file');
    setUploading(true);

    const data = new FormData();
    data.append('file', file);

    api.post(`/api/v1/licenses/import_from_csv/?company=${companyID}`, data, {
      onUploadProgress: (progressEvent) => {
        if (progressEvent.lengthComputable) {
          const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        }
      },
    })
      .then(() => {
        Notification('success', `${__('File')} ${file.name} ${__('uploaded')}`, __('Import will be finished in a few minutes.'));
        val.remove();
        setUploading(false);
        fetchImports();
      })
      .catch((err) => {
        sendErrorReport(err, 'License import has failed');
        Notification('error', __('Upload failed'), __('Please try again or change the file'));
        setUploading(false);
      });
  };

  if (!canManageLicenses) {
    return <PermissionDenied permission={userPermissions.licenses_write} />;
  }

  return (
    <div className="LicensesImport">
      <FileUploader
        disabled={uploading}
        progress={progress}
        onSubmit={handleSubmit}
        onWrongFileTypeReject={handleWrongFileType}
        onItemRemove={handleItemRemove}
      />
      <div className="template-link">
        <Link to={Template} target="_blank" download>{__('Download import template file')}</Link>
      </div>
      <div className="docs-link">
        <p>{__('Find csv license import documentation')}</p>
        <a href="https://docs.licensespring.com/docs/import-licenses-from-csv" target="_blank" rel="noopener noreferrer">{__('here')}</a>
      </div>
      <div className="LicensesImport-list">
        <div className="heading">{__('Imports list')}</div>
        <List
          data={importList}
          noDataText={__('No imports found')}
          minRows={2}
          loading={loading}
          onExpandedChange={expanded => setExpandedTableRow(expanded)}
          expanded={expandedTableRow}
          SubComponent={row => (
            <div className="SubComponent">
              <DescriptionTable
                details={[
                  {
                    label: __('Total rows'),
                    value: displayValue(get(row, 'original.result.total_row_count')),
                  },
                  {
                    label: __('Valid rows'),
                    value: displayValue(get(row, 'original.result.valid_row_count')),
                  },
                  {
                    label: __('Existing orders'),
                    value: displayValue(get(row, 'original.result.existed_orders_count')),
                  },
                  {
                    label: __('Existing licenses'),
                    value: displayValue(get(row, 'original.result.existed_licenses_count')),
                  },
                  {
                    label: __('Existing customers'),
                    value: displayValue(get(row, 'original.result.existed_customers_count')),
                  },
                  // {
                  //   label: 'Errors',
                  //   value: showErrorsContainer(get(row, 'original.result')),
                  // },
                ]}
              />
            </div>
          )}
          columns={[
            {
              expander: true,
              Header: __('Details'),
              headerClassName: 'text-center',
              width: 80,
              style: {
                fontSize: 25,
                padding: '0',
                textAlign: 'center',
                userSelect: 'none',
              },
            },
            {
              Header: __('File name'),
              accessor: 'file_name',
              Cell: cellData => displayValue(cellData.value),
            },
            {
              Header: __('Upload date'),
              accessor: 'created_at',
              Cell: cellData => formatDate(cellData.value, defaultDateTimeFormat),
            },
            {
              Header: __('Status'),
              accessor: 'status',
              Cell: (cellData) => {
                const val = get(cellData, 'value');
                return <ImportStatus status={val} />;
              },
            },
            {
              Header: __('Created orders'),
              accessor: 'result.created_orders_count',
              Cell: cellData => displayValue(cellData.value),
            },
            {
              Header: __('Created licenses'),
              accessor: 'result.created_licenses_count',
              Cell: cellData => displayValue(cellData.value),
            },
            {
              Header: __('Created customers'),
              accessor: 'result.created_customers_count',
              Cell: cellData => displayValue(cellData.value),
            },
          ]}
        />
      </div>
    </div>
  );
};

LicensesImport.propTypes = {
  companyID: PropTypes.number.isRequired,
};

export default LicensesImport;
