import React, { useState } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom';
import {
  Button,
  CardType,
  Selector,
  InputErrorMessage,
  Label,
  NotFound,
  Notification,
  Page,
  PermissionDenied,
} from 'shared/components';
import {
  billingCycles,
  regionData,
  userPermissions,
} from 'shared/constants';
import {
  mapCountriesToSelector,
  mapRegionsToSelector,
  sendErrorReport,
  isStaffUser,
  checkUserPermission,
} from 'shared/helpers';
import {
  validateRequiredValue,
} from 'shared/validation';
import { changeCardData, getCompanySuccess } from 'src/company/actions';
import { updateToStandardPlan } from 'src/account/actions';
import { countriesList } from '../../shared/countriesAlpha2Code';
import { getCycle, getValue } from './helpers';
import { PaymentMethodForm } from '../BillingContainer/components';
import Summary from './Summary';
import './styles.scss';

const StandardPlanUpgradeContainer = () => {
  const canManageBilling = checkUserPermission(userPermissions.billing_write);
  const history = useHistory();
  const dispatch = useDispatch();
  const isStaff = isStaffUser();

  const taxRates = useSelector(state => get(state, 'billing.taxRates'));
  const companyDetails = useSelector(state => get(state, 'company.details'));

  // todo get payment details from separate endpoint
  const companyID = get(companyDetails, 'id');
  const planType = get(companyDetails, 'plan_type');
  const cardType = get(companyDetails, 'payment_card_type');
  const expires = get(companyDetails, 'payment_expires');
  const last4 = get(companyDetails, 'payment_last4');

  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [country, setCountry] = useState(getValue(get(companyDetails, 'payment_country'), 'value'));
  const [countryError, setCountryError] = useState(null);
  const [region, setRegion] = useState(getValue(get(companyDetails, 'payment_region'), 'name'));
  const [regionError, setRegionError] = useState(null);
  const [billingCycle, setBillingCycle] = useState(getCycle(get(companyDetails, 'subscription_cycle')));
  const [isBillingPopupDisplayed, setBillingPopupDisplay] = useState(false);
  const [cardTypeError, setCardTypeError] = useState('');

  const countryOptions = mapCountriesToSelector(countriesList);
  const mainActionTitle = cardType ? __('Change payment method') : __('Add payment method');
  const unitPrice = get(billingCycle, 'value') === 'monthly' ? '99.00' : '990.00';
  const tax = taxRates.filter(t => t.jurisdiction === get(region, 'name'));
  const taxValue = Number(unitPrice) * Number(get(tax, '[0].percentage') || 0) / 100;
  const total = Number(unitPrice) + Number(taxValue || 0);

  const validateCountry = async () => {
    let errors;
    try {
      errors = await validateRequiredValue(get(country, 'value'));
      setCountryError(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate payment country value', { val: get(country, 'value') });
    }
    if (errors) { return false; }
    return true;
  };

  const validateRegion = async () => {
    if (get(country, 'value') !== 'Canada') { return true; }

    let errors;
    try {
      errors = await validateRequiredValue(get(region, 'name'));
      setRegionError(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate payment region value', { val: get(region, 'name') });
    }
    if (errors) { return false; }
    return true;
  };

  const validateCardType = () => {
    if (!cardType && !isStaff) {
      setCardTypeError('Add payment method to complete the upgrade process');
      return false;
    }
    setCardTypeError('');
    return true;
  };

  const isFormValid = async () => {
    const isCountryValid = await validateCountry();
    const isRegionValid = await validateRegion();
    const isCardValid = await validateCardType();
    return isCountryValid && isRegionValid && isCardValid;
  };

  const handleSubmit = async () => {
    const isValid = await isFormValid();
    if (!isValid || loading) { return false; }

    setLoading(true);

    const taxId = tax.length ? get(tax, '[0].id') : '';
    const billing = get(billingCycle, 'value') || undefined;
    const data = {
      planType: 'standard',
      stripe_tax_id: taxId,
      subscription_cycle: billing,
      payment_country: get(country, 'value'),
      payment_region: get(region, 'name'),
    };

    updateToStandardPlan(companyID, data)
      .then((res) => {
        dispatch(getCompanySuccess(res.data));
        setDirty(false);
        Notification('success', 'Plan type changed successfuly');
        history.push(`/${companyID}/account/plan-type`);
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot update to standard plan', data);
        Notification('error', 'Error occured');
        setLoading(false);
      });
    return true;
  };

  if (!canManageBilling) {
    return (
      <Page>
        <PermissionDenied permission={userPermissions.billing_write} />
      </Page>
    );
  }

  if (planType === 'standard' || planType === 'enterprise') {
    return <Page><NotFound /></Page>;
  }

  return (
    <div className="StandardPlanUpgradeContainer">
      <Page title="Upgrade to Standard Plan">
        <Prompt
          message={__('By leaving this form you will lose all progress. Are you sure you want to leave?')}
          when={dirty}
        />
        <div className="StandardPlanUpgradeContainer-wrapper">
          <div className="form-container">
            <div className="form-row first">
              <Label inputId="upgrade-country" text="Country" />
              <Selector
                options={countryOptions}
                isDisabled={loading}
                value={get(country, 'value')}
                id="upgrade-country"
                handleChange={(val) => {
                  const selected = countryOptions.find(option => option.value === val);
                  setDirty(true);
                  setCountry(selected);
                  setCountryError('');
                  if (selected.value !== 'Canada') {
                    setRegion(null);
                  }
                }}
              />
              <InputErrorMessage text={countryError} />
            </div>
            {get(country, 'value') === 'Canada' && (
              <div className="form-row">
                <Label inputId="upgrade-region" text="State/Province" />
                <Selector
                  options={mapRegionsToSelector(get(regionData, 'Canada'))}
                  valueKey="label"
                  value={get(region, 'name')}
                  isDisabled={loading}
                  handleChange={(val) => {
                    const selected = get(regionData, 'Canada').find(option => option.name === val);
                    setDirty(true);
                    setRegion(selected);
                    setRegionError('');
                  }}
                />
                <InputErrorMessage text={regionError} />
              </div>
            )}
            <div className="form-row">
              <Label inputId="upgrade-billing" text="Select billing cycle" />
              <Selector
                options={billingCycles}
                value={get(billingCycle, 'value')}
                isDisabled={loading}
                handleChange={(val) => {
                  const selected = billingCycles.find(option => option.value === val);
                  setDirty(true);
                  setBillingCycle(selected);
                }}
              />
            </div>
            {cardType ? (
              <div className="form-row payment-method">
                <Label inputId="upgrade-payment-method" text="Payment method" />
                <div className="payment-method-card">
                  <div className="card-details">
                    <CardType type={cardType} />
                    <div className="card-last4">
                      <span>****</span>
                      <span>****</span>
                      <span>****</span>
                      <span>{last4}</span>
                    </div>
                    <div className="card-expiry">
                      <span>{__('expires')}</span>
                      <div className="card-expiration-date">
                        <span className="month">{expires}</span>
                      </div>
                    </div>
                  </div>
                  <div className="payment-action">
                    {cardType && (
                      <Button
                        theme="default"
                        disabled={loading}
                        onClick={() => {
                          setDirty(true);
                          setBillingPopupDisplay(true);
                        }}
                      >
                        {mainActionTitle}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="form-row add-card">
                <Label inputId="upgrade-payment-method" text="Payment method" />
                <Button
                  theme="info"
                  disabled={loading}
                  onClick={() => {
                    setDirty(true);
                    setBillingPopupDisplay(true);
                  }}
                >
                  {mainActionTitle}
                </Button>
                <InputErrorMessage text={cardTypeError} />
              </div>
            )}
          </div>
          <Summary
            billingCycle={billingCycle}
            unitPrice={unitPrice}
            tax={tax}
            taxValue={taxValue}
            total={total}
            handleUpgradeBtn={handleSubmit}
            loading={loading}
          />
        </div>
      </Page>
      {isBillingPopupDisplayed && (
        <PaymentMethodForm
          closeCb={() => setBillingPopupDisplay(false)}
          refreshPaymentMethod={data => dispatch(changeCardData(data))}
          refreshCompany={data => dispatch(getCompanySuccess(data))}
          title={mainActionTitle}
          companyId={companyID}
          company={companyDetails}
          tempCountry={country}
          tempRegion={region}
          taxRates={taxRates}
        />
      )}
    </div>
  );
};

export default StandardPlanUpgradeContainer;
