import React from 'react';
import PropTypes from 'prop-types';
import {
  CheckboxSelector,
  CustomFieldsEditableTable,
  Label,
  Notification,
} from 'shared/components';
import {
  platformFeatures,
  defaultDisabledFeatureMessage,
} from 'shared/constants';
import {
  isFeatureEnabled,
  isFeatureAvailable,
  getDisabledMessage,
} from 'shared/helpers';
import './styles.scss';

const CustomFieldsTab = ({
  customFields,
  handleCustomFieldsSelect,
  selectedCustomFields,
  planType,
  setCustomFieldsTabError,
  handleFieldsEdit,
}) => {
  const notAvailableMessage = planType === 'free' ?
    __('Custom Fields are not available in your plan.') :
    __('Your plan allows for one custom field.');

  const handleSelection = (val) => {
    if (!isFeatureEnabled(platformFeatures.extra_custom_fields)) {
      Notification('error', __(defaultDisabledFeatureMessage), __(getDisabledMessage()));
      return false;
    }
    return handleCustomFieldsSelect(val);
  };

  return (
    <section className="CustomFieldsTab product-features-section">
      <div className="CustomFields-field">
        <div className="CustomFields-selector">
          <Label text={__('Select custom fields')} />
          <CheckboxSelector
            text={__('Custom fields list')}
            options={customFields}
            value={selectedCustomFields}
            onChangeCallback={handleSelection}
            onMenuClose={() => { }}
            // featureEnabled={isFeatureEnabled(platformFeatures.extra_custom_fields)}
            // notEnabledMessage={getDisabledMessage()}
            featureAvailable={isFeatureAvailable(platformFeatures.extra_custom_fields)}
            notAvailableMessage={notAvailableMessage}
          />
        </div>
        <div>
          <CustomFieldsEditableTable
            selectedCustomFields={selectedCustomFields}
            setErrorState={setCustomFieldsTabError}
            handleFieldsEdit={handleFieldsEdit}
          />
        </div>
      </div>
    </section>
  );
};

CustomFieldsTab.propTypes = {
  customFields: PropTypes.array.isRequired,
  handleCustomFieldsSelect: PropTypes.func.isRequired,
  selectedCustomFields: PropTypes.array.isRequired,
  setCustomFieldsTabError: PropTypes.func.isRequired,
  handleFieldsEdit: PropTypes.func.isRequired,
  planType: PropTypes.string,
};

CustomFieldsTab.defaultProps = {
  customFieldsValueError: '',
  planType: 'free',
};

export default CustomFieldsTab;
