import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  Button,
  IconEdit,
  List,
  Notification,
  PermissionMissingNotificationTitle,
} from 'shared/components';
import {
  getDisabledMessage,
  isFeatureAvailable,
  isFeatureEnabled,
  sendErrorReport,
  checkUserPermission,
} from 'shared/helpers';
import {
  platformFeatures,
  userPermissions,
} from 'shared/constants';
import { fetchCustomFields } from 'src/license/actions';
import CustomFieldForm from '../CustomFieldForm';
import { getLicenseCustomFields } from './helpers';

const CustomFields = ({
  currentProduct,
  license,
  companyID,
}) => {
  const canManageLicenses = checkUserPermission(userPermissions.licenses_write);
  const licenseID = get(license, 'id');
  const isAirGapped = get(license, 'is_air_gapped');

  const [customFields, setCustomFields] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [showCustomFieldsForm, setsCustomFieldsForm] = useState(false);
  const [fieldToEdit, setFieldToEdit] = useState(null);

  // TODO add serverside pagination
  const getCustomFields = useCallback(async () => {
    let data;
    try {
      data = await fetchCustomFields(licenseID, companyID);
      const fields = getLicenseCustomFields(currentProduct, get(data, 'data.results'));
      setCustomFields(fields);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      sendErrorReport(err, 'Cannot fetch license custom fields');
      Notification('error', __('There was an error while getting your data'));
    }
  }, [licenseID, currentProduct]);

  const handleEditClick = (rowData) => {
    if (!canManageLicenses) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.licenses_write} />,
        __('Contact you account admin for support.'),
      );
      return false;
    }
    setFieldToEdit(rowData.original);
    setsCustomFieldsForm(true);
    return true;
  };

  useEffect(() => {
    getCustomFields();
  }, [getCustomFields]);

  return (
    <div className="CustomFields">
      <div className="Tab-header">
        {__('Custom Fields allow you to push information from the platform to your application.')}
      </div>
      <List
        columns={[
          { accessor: 'name', Header: __('Name') },
          {
            id: 'value',
            // field.value when there is license custom field
            // field.default_value when there is product custom field
            accessor: cellData => cellData.value || cellData.default_value,
            Header: __('Value'),
          },
          {
            Cell: rowData => (
              <Button
                featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
                featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
                notEnabledMessage={getDisabledMessage()}
                className="edit-button"
                onClick={() => handleEditClick(rowData)}
                type="button"
                disabled={isAirGapped}
              >
                <IconEdit height="16px" width="16px" />
              </Button>
            ),
            maxWidth: 50,
          },
        ]}
        data={customFields}
        minRows={2}
        pageSize={20}
        loading={isLoading}
      />
      {showCustomFieldsForm && (
        <CustomFieldForm
          closeCb={() => {
            setFieldToEdit(null);
            setsCustomFieldsForm(false);
          }}
          refetchCustomFields={getCustomFields}
          licenseID={get(license, 'id')}
          field={fieldToEdit}
          companyID={companyID}
        />
      )}
    </div>
  );
};

CustomFields.propTypes = {
  currentProduct: PropTypes.object.isRequired,
  license: PropTypes.object.isRequired,
  companyID: PropTypes.number.isRequired,
};

export default CustomFields;
