import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { sendErrorReport, parseMetadata } from 'shared/helpers';
import {
  Checkbox,
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  TextInput,
  TextArea,
} from 'shared/components';
import {
  validateJSON,
  debouncedValidateJSON,
} from 'shared/validation';
import { updateOrder } from 'src/order/actions';
import './styles.scss';

const EditOrderForm = ({
  companyID,
  closeCb,
  refetchOrder,
  order,
}) => {
  // todo_metadata replace this check with company feature
  const companyDetails = useSelector(state => get(state, 'company.details'));
  const isEnterprisePlan = get(companyDetails, 'plan_type') === 'enterprise';

  const [isLoading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormDisplay] = useState(false);

  const [orderReference, setOrderReference] = useState(get(order, 'customer_reference') || '');
  const [campaignParams, setCampaignParams] = useState(get(order, 'campaign_params') || '');
  const [downloadID, setDownloadID] = useState(get(order, 'campaign_params') || '');
  const [isTest, setTest] = useState(get(order, 'is_test'));
  const [isTrial, setTrial] = useState(get(order, 'is_trial'));
  // metadata
  const [metadata, setMetadata] = useState(parseMetadata(get(order, 'metadata')));
  const [metadataError, setMetadataError] = useState('');

  const validateMetadata = async (val) => {
    setLoading(true);
    let errors;
    try {
      errors = await validateJSON(metadata);
      setMetadataError(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate edit product form value', { value: val });
    }
    setLoading(false);
    if (errors) { return false; }
    return true;
  };

  const isFormValid = async () => {
    const isMetadataValid = await validateMetadata(metadata, setMetadataError);
    return isMetadataValid;
  };

  const handleSubmit = async () => {
    const isValid = await isFormValid();
    if (!isValid || isLoading) { return false; }

    setLoading(true);
    const orderID = get(order, 'id');
    const data = {
      customer_reference: orderReference || '',
      campaign_params: campaignParams || '',
      download_id: downloadID || '',
      is_test: isTest,
      is_trial: isTrial,
      metadata: metadata ? JSON.parse(metadata) : {},
    };

    updateOrder(orderID, companyID, data)
      .then(() => {
        refetchOrder();
        Notification('success', __('Changes saved successfully'));
        closeCb();
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot edit order details', data);
        setLoading(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
    return true;
  };

  const handleClose = () => {
    if (!dirty) { return closeCb(); }
    return setDirtyFormDisplay(true);
  };

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={isLoading}
      title={__('Edit order')}
      size="sm"
    >
      <div className="EditOrderForm">
        <div className="row input-cont">
          <Label inputId="order-reference" text={__('Order reference')} />
          <TextInput
            disabled={isLoading}
            id="order-reference"
            type="text"
            value={orderReference}
            handleChange={(val) => {
              setDirty(true);
              setOrderReference(val);
            }}
          />
        </div>
        <div className="row input-cont">
          <Label inputId="campaing-params" text={__('Campaign parameters')} />
          <TextInput
            disabled={isLoading}
            id="campaing-params"
            type="text"
            value={campaignParams}
            handleChange={(val) => {
              setDirty(true);
              setCampaignParams(val);
            }}
          />
        </div>
        <div className="row input-cont">
          <Label inputId="download-id" text={__('Download ID')} />
          <TextInput
            disabled={isLoading}
            id="download-id"
            type="text"
            value={downloadID}
            handleChange={(val) => {
              setDirty(true);
              setDownloadID(val);
            }}
          />
        </div>
        <div className="row checkbox-cont">
          <Checkbox
            label={__('Is test')}
            checked={isTest}
            inputId="is-test-checkbox"
            handleChange={(val) => {
              setDirty(true);
              setTest(val);
            }}
          />
        </div>
        <div className="row checkbox-cont">
          <Checkbox
            label={__('Is trial')}
            checked={isTrial}
            inputId="is-trial-checkbox"
            handleChange={(val) => {
              setDirty(true);
              setTrial(val);
            }}
          />
        </div>
        <div className="row">
          <Label inputId="metadata-input" text={__('Metadata JSON')} />
          <TextArea
            handleChange={(val) => {
              setDirty(true);
              setMetadata(val);
              debouncedValidateJSON(val)
                .then(err => setMetadataError(err));
            }}
            id="metadata-input"
            type="metadata"
            value={metadata}
            error={metadataError}
            rows="4"
            disabled={!isEnterprisePlan}
          />
        </div>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

EditOrderForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  refetchOrder: PropTypes.func.isRequired,
  companyID: PropTypes.number.isRequired,
  order: PropTypes.func.isRequired,
};

export default EditOrderForm;
