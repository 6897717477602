import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import {
  Button,
  ConfirmationPopup,
  DescriptionTable,
  IconCheckmark,
  IconDisabled,
  Notification,
  PermissionMissingNotificationTitle,
  Subtitle,
  JsonView,
} from 'shared/components';
import {
  displayValue,
  formatDate,
  getDisabledMessage,
  isFeatureAvailable,
  isFeatureEnabled,
  sendErrorReport,
  filenameFromHeadersData,
  downloadResource,
  checkUserPermission,
} from 'shared/helpers';
import { platformFeatures, userPermissions } from 'shared/constants';
import {
  checkCompanyConfigField,
} from 'shared/companyConfig';
import { deleteOrder, exportOrderDetails } from 'src/order/actions';
import PlatformAuditLog from 'src/account/AuditLog/PlatformAuditLog';
import EditOrderForm from '../EditOrderForm';

const OrderDetails = ({
  order,
  companyID,
  refetchOrder,
}) => {
  const canManageOrders = checkUserPermission(userPermissions.orders_write);
  const history = useHistory();
  const orderID = get(order, 'id');

  const [isDeleteOrderIntentDisplayed, setDeleteOrderIntentDisplay] = useState(false);
  const [isDeleteOrderWarningDisplayed, setDeleteOrderWarningDisplay] = useState(false);
  const [isEditOrderDisplayed, setEditOrderDisplay] = useState(false);
  const [orderDeleteLoading, setOrderDeleteLoading] = useState(false);
  const [orderExportLoading, setOrderExportLoading] = useState(false);

  const handleOrderDelete = () => {
    setOrderDeleteLoading(true);

    deleteOrder(orderID, companyID)
      .then(() => {
        history.push(`/${companyID}/orders`);
        Notification('success', __('Order succesfully deleted'));
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot delete order');
        setOrderDeleteLoading(false);
        Notification('error', __('Error occured'), __('Your changes were not saved'));
      });
  };

  const handleOrderExport = () => {
    if (orderExportLoading) { return false; }
    setOrderExportLoading(true);

    exportOrderDetails(orderID, companyID)
      .then((response) => {
        const filename = filenameFromHeadersData(response.headers);
        downloadResource(response, filename);
        setOrderExportLoading(false);
        Notification('success', __('Order data succesfully exported'));
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot export order data');
        Notification('error', __('Export data failed'));
        setOrderExportLoading(false);
      });
    return true;
  };

  const handleOrderDeleteIntent = () => {
    const hasActiveLicenses = get(order, 'has_active_licenses') || false;
    if (!hasActiveLicenses) {
      return handleOrderDelete();
    }
    return setDeleteOrderWarningDisplay(true);
  };

  const handleManageOrderClick = (cb) => {
    if (!canManageOrders) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.orders_write} />,
        __('Contact you account admin for support.'),
      );
      return false;
    }
    cb(true);
    return true;
  };

  return (
    <div className="OrderDetails order-details">
      <div className="list-header">
        <div>
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.platform_add_license)}
            featureAvailable={isFeatureAvailable(platformFeatures.platform_add_license)}
            notEnabledMessage={getDisabledMessage()}
            onClick={handleOrderExport}
            theme="info"
            size="sm"
            loading={orderExportLoading}
          >
            {__('Export order data')}
          </Button>
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.platform_add_license)}
            featureAvailable={isFeatureAvailable(platformFeatures.platform_add_license)}
            notEnabledMessage={getDisabledMessage()}
            onClick={() => handleManageOrderClick(setEditOrderDisplay)}
            theme="default"
            size="sm"
          >
            {__('Edit order')}
          </Button>
        </div>
        <div>
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.platform_add_license)}
            featureAvailable={isFeatureAvailable(platformFeatures.platform_add_license)}
            notEnabledMessage={getDisabledMessage()}
            onClick={() => handleManageOrderClick(setDeleteOrderIntentDisplay)}
            theme="error"
            size="sm"
          >
            {__('Delete order')}
          </Button>
        </div>
      </div>
      <DescriptionTable
        details={[
          { label: __('Created on'), value: formatDate(get(order, 'created_at')) },
          { label: __('Type'), value: displayValue(get(order, 'type')) },
          {
            label: checkCompanyConfigField(companyID, 'isTrial') ? __('Is trial') : null,
            value: get(order, 'is_trial') ?
              <IconCheckmark color="#10ac84" height="14px" /> :
              <IconDisabled color="#aaa" height="14px" />,
          },
          {
            label: checkCompanyConfigField(companyID, 'isTest') ? __('Is test') : null,
            value: get(order, 'is_test') ?
              <IconCheckmark color="#10ac84" height="14px" /> :
              <IconDisabled color="#aaa" height="14px" />,
          },
          { label: __('Order reference'), value: displayValue(get(order, 'customer_reference')) },
          { label: __('Campaign parameters'), value: displayValue(get(order, 'campaign_params')) },
          { label: __('Download ID'), value: displayValue(get(order, 'download_id')) },
          {
            label: __('Metadata'),
            value: (
              <JsonView
                value={get(order, 'metadata')}
                name="metadata"
              />
            ),
          },
        ]}
      />
      <div className="Logs">
        <Subtitle text={__('Logs')} />
        <PlatformAuditLog order={order} />
      </div>
      {isDeleteOrderIntentDisplayed && (
        <ConfirmationPopup
          closeCb={() => setDeleteOrderIntentDisplay(false)}
          confirmCb={handleOrderDeleteIntent}
          title={__('Are you sure you want to delete this order?')}
          confirmText={__('Delete')}
          theme="error"
          disabled={orderDeleteLoading}
        >
          {__('This action is permanent and all the licenses in this order will also be deleted.')}
        </ConfirmationPopup>
      )}
      {isDeleteOrderWarningDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setDeleteOrderWarningDisplay(false);
            setDeleteOrderIntentDisplay(false);
          }}
          confirmCb={handleOrderDelete}
          title={__('You have active licenses in this order!')}
          confirmText={__('Delete')}
          theme="error"
          warning
          disabled={orderDeleteLoading}
        >
          {__('Please, confirm that you really want to delete this order and all the licenses in it.')}
        </ConfirmationPopup>
      )}
      {isEditOrderDisplayed && (
        <EditOrderForm
          companyID={companyID}
          closeCb={() => setEditOrderDisplay(false)}
          refetchOrder={refetchOrder}
          order={order}
        />
      )}
    </div>
  );
};

OrderDetails.propTypes = {
  order: PropTypes.object.isRequired,
  companyID: PropTypes.number.isRequired,
  refetchOrder: PropTypes.func.isRequired,
};

export default OrderDetails;
