import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  Label,
  Selector,
  DescriptionTable,
  SelectedFeaturesTags,
  SelectedCustomFieldsTags,
} from 'shared/components';
import { licenseTypes } from 'shared/constants';
import { displayValue, displayMaxOveragesValue, capitalizeFirstLetter } from 'shared/helpers';
import './styles.scss';

const PolicySelector = ({
  product,
  selectedPolicies,
  handlePolicySelect,
}) => {
  const productID = get(product, 'id');
  const productName = get(product, 'product_name');
  const productAuthMethod = get(product, 'authorization_method') || '';
  const isUserBasedProduct = productAuthMethod === 'user';
  const productPolicyOptions = get(product, 'license_templates');
  const selectedProductPolicy = selectedPolicies.find(sp => sp.product === productID);
  const policyFeatures = get(selectedProductPolicy, 'license_product_feature_templates') || [];
  const policyCFs = get(selectedProductPolicy, 'license_custom_field_templates') || [];

  const shouldShowFeaturesTab = policyFeatures.length > 0;
  const shouldShowCustomFieldsTab = policyCFs.length > 0;
  const isTimeLimited = get(selectedProductPolicy, 'default_license_type') === licenseTypes.time_limited;
  const isSubscription = get(selectedProductPolicy, 'default_license_type') === licenseTypes.subscription;
  const isConsumption = get(selectedProductPolicy, 'default_license_type') === licenseTypes.consumption;
  const isTrial = get(selectedProductPolicy, 'allow_trial');
  const trialDays = get(selectedProductPolicy, 'trial_days');
  const trialValidity = `${trialDays} ${'days'}`;

  const handlePolicyChange = (val) => {
    const selected = productPolicyOptions.find(ppo => ppo.value === val);
    handlePolicySelect(selected);
  };

  return (
    <div className="PolicySelector">
      <div className="section-row">
        <Label inputId="product-policy" text={`${__('License policy for')} ${productName}`} />
        <Selector
          options={productPolicyOptions}
          handleChange={handlePolicyChange}
          value={selectedProductPolicy.value}
          inputId="product-policy"
        />
      </div>
      <div className="section-row">
        <DescriptionTable
          details={[
            {
              label: __('License type'),
              value: displayValue(get(selectedProductPolicy, 'default_license_type')),
            },
            {
              label: __('Max activations'),
              value: get(selectedProductPolicy, 'allow_unlimited_activations') ? __('Unlimited') : displayValue(get(selectedProductPolicy, 'max_activations')),
            },
            {
              label: isUserBasedProduct ? __('Max license users') : null,
              value: (get(selectedProductPolicy, 'unlimited_max_license_users') || get(selectedProductPolicy, 'max_license_users') === 0) ? __('Unlimited') : displayValue(get(selectedProductPolicy, 'max_license_users')),
            },
            {
              label: (isTimeLimited || isSubscription) ? __('Valid duration') : null,
              value: displayValue(get(selectedProductPolicy, 'valid_duration')),
            },
            {
              label: __('Is trial'),
              value: isTrial ? __('Yes') : __('No'),
            },
            {
              label: isTrial ? __('Trial days') : null,
              value: trialValidity,
            },
            // show consumption values
            {
              label: isConsumption ? __('Max consumptions') : null,
              value: get(selectedProductPolicy, 'allow_unlimited_consumptions') ? __('Unlimited') : displayValue(get(selectedProductPolicy, 'max_consumptions')),
            },
            {
              label: isConsumption ? __('Allow overages') : null,
              value: get(selectedProductPolicy, 'allow_overages') ? __('Yes') : __('No'),
            },
            {
              label: isConsumption ? __('Max overages') : null,
              value: displayMaxOveragesValue(selectedProductPolicy),
            },
            {
              label: isConsumption ? __('Reset consumption') : null,
              value: get(selectedProductPolicy, 'reset_consumption') ? __('Yes') : __('No'),
            },
            {
              label: isConsumption ? __('Consumption period') : null,
              value: displayValue(capitalizeFirstLetter(get(selectedProductPolicy, 'consumption_period'))),
            },
            // show everything else
            {
              label: get(selectedProductPolicy, 'enable_maintenance_period') ? __('Maintenance duration') : null,
              value: displayValue(get(selectedProductPolicy, 'maintenance_duration')),
            },
            {
              label: get(selectedProductPolicy, 'is_floating') ? __('Offline floating license') : null,
              value: get(selectedProductPolicy, 'is_floating') ? __('Yes') : __('No'),
            },
            {
              label: get(selectedProductPolicy, 'is_floating_cloud') ? __('Is floating cloud') : null,
              value: get(selectedProductPolicy, 'is_floating_cloud') ? __('Yes') : __('No'),
            },
            {
              label: (get(selectedProductPolicy, 'is_floating') || get(selectedProductPolicy, 'is_floating_cloud')) ? __('Max simultaneous license users') : null,
              value: displayValue(get(selectedProductPolicy, 'floating_users')),
            },
            {
              label: (get(selectedProductPolicy, 'is_floating') || get(selectedProductPolicy, 'is_floating_cloud')) ? __('Floating timeout') : null,
              value: `${displayValue(get(selectedProductPolicy, 'floating_timeout'))} min`,
            },
            {
              label: get(selectedProductPolicy, 'can_borrow') ? __('Can borrow') : null,
              value: get(selectedProductPolicy, 'can_borrow') ? __('Yes') : __('No'),
            },
            {
              label: get(selectedProductPolicy, 'can_borrow') ? __('Max borrow time') : null,
              value: `${displayValue(get(selectedProductPolicy, 'max_borrow_time'))} ${__('hours')}`,
            },
            {
              label: get(selectedProductPolicy, 'max_transfers') ? __('Device transfer limit') : null,
              value: displayValue(get(selectedProductPolicy, 'max_transfers')),
            },
            { label: __('Prevent virtual machine'), value: get(selectedProductPolicy, 'prevent_vm') ? __('Yes') : __('No') },
            {
              label: shouldShowFeaturesTab ? __('Product features') : null,
              // value: <SelectedFeaturesTags features={get(selectedProductPolicy, 'license_product_feature_templates')} />,
              value: <SelectedFeaturesTags features={policyFeatures} />,
            },
            {
              label: shouldShowCustomFieldsTab ? __('Custom fields') : null,
              // value: <SelectedCustomFieldsTags cFields={get(selectedProductPolicy, 'license_custom_field_templates')} />,
              value: <SelectedCustomFieldsTags cFields={policyCFs} />,
            },
          ]}
        />
      </div>
    </div>
  );
};

PolicySelector.propTypes = {
  product: PropTypes.object.isRequired,
  selectedPolicies: PropTypes.array.isRequired,
  handlePolicySelect: PropTypes.func.isRequired,
};

export default PolicySelector;
