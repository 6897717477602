import React from 'react';
import PropTypes from 'prop-types';
import {
  Subtitle,
} from 'shared/components';
import icon from './stop.svg';
import './styles.scss';

const Forbidden = ({
  description,
  text,
}) => (
  <div className="Forbidden">
    <div className="wrapper">
      <img src={icon} alt="Forbidden" height="100px" />
      <Subtitle text={text || __("You don't have access to this account")} />
      {description && <div className="Forbidden-description">{description}</div>}
    </div>
  </div>
);

Forbidden.propTypes = {
  description: PropTypes.string,
  text: PropTypes.string,
};

Forbidden.defaultProps = {
  description: '',
  text: '',
};

export default Forbidden;
