/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Select, { components } from 'react-select';
import './styles.scss';

const Option = props => (
  <div>
    <components.Option {...props}>
      <div className="CustomerLabelsSelector-option">
        <div className="option-color" style={{ backgroundColor: get(props, 'data.data.color') || '#949494' }} />
        <span>{get(props, 'label') || ''}</span>
      </div>
    </components.Option>
  </div>
);

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <div className="CustomerLabelsSelector-value">
      <div className="option-color" style={{ backgroundColor: get(props, 'data.data.color') || '#949494' }} />
      <span>{get(props, 'data.label') || ''}</span>
    </div>
  </components.SingleValue>
);

const CustomerLabelsSelector = ({
  options,
  onChangeCallback,
  text,
  value,
  ...otherProps
}) => (
  <div className="CustomerLabelsSelector Selector">
    <Select
      className="selector-container"
      classNamePrefix="selector-inner"
      placeholder={__('Available labels')}
      components={{ Option, SingleValue }}
      options={options}
      onChange={e => onChangeCallback(e)}
      {...otherProps}
    />
  </div>
);

CustomerLabelsSelector.propTypes = {
  options: PropTypes.array.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
  text: PropTypes.string,
  value: PropTypes.object,
};

CustomerLabelsSelector.defaultProps = {
  text: '',
  value: undefined,
};

export default CustomerLabelsSelector;
