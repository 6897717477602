import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  Button,
  Label,
  IconActions,
  IconBlacklist,
  IconBorrow,
  IconRevoke,
  IconReturn,
  IconReset,
  Modal,
  Notification,
  PermissionMissingNotificationTitle,
} from 'shared/components';
import {
  getDisabledMessage,
  isFeatureAvailable,
  isFeatureEnabled,
  checkUserPermission,
} from 'shared/helpers';
import {
  platformFeatures,
  userPermissions,
} from 'shared/constants';
import './styles.scss';

const DeviceActionMenu = ({
  device,
  license,
  handleDeviceRevokeForm,
  handleDeviceResetForm,
  handleDeviceBlacklistForm,
  handleManageBorrow,
  handleReturnBorrow,
}) => {
  const canManageLicenses = checkUserPermission(userPermissions.licenses_write);
  const [isMenuDisplayed, setMenuDisplay] = useState(false);
  const toggleMenu = () => setMenuDisplay(!isMenuDisplayed);

  const isFloating = get(license, 'is_floating_cloud') || get(license, 'is_floating');
  const isBorrowed = get(device, 'borrowed_until');

  const handleToggleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!canManageLicenses) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.licenses_write} />,
        __('Contact you account admin for support.'),
      );
      return false;
    }
    toggleMenu();
    return true;
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className="DeviceActionMenuBtn" onClick={e => e.stopPropagation()} role="button" tabIndex={0}>
      <button
        className="table-select"
        aria-label="Select license"
        onClick={handleToggleClick}
        type="button"
      >
        <IconActions stroke="#2e86de" />
      </button>
      {isMenuDisplayed && (
        <Modal confirmCb={() => { }} hideFooter closeCb={toggleMenu} size="sm">
          <div className="DeviceActionMenu">
            <div className="header">
              <Label text={__('Hardware ID')} />
              <div className="text">{get(device, 'hardware_id')}</div>
            </div>
            <ul className="inner-menu">
              {isFloating && (
                <li>
                  <Button
                    featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
                    featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
                    notEnabledMessage={getDisabledMessage()}
                    className="table-button"
                    type="button"
                    theme="error"
                    size="sm"
                    onClick={() => handleDeviceRevokeForm(device)}
                  >
                    <>
                      <div className="icon-wrapper"><IconRevoke width="24" height="24" viewBox="0 0 100 100" /></div>
                      <div>{__('Revoke')}</div>
                    </>
                  </Button>
                </li>
              )}
              <li>
                <Button
                  featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
                  featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
                  notEnabledMessage={getDisabledMessage()}
                  className="reset-button"
                  onClick={() => handleDeviceResetForm(device)}
                  type="button"
                >
                  <>
                    <div className="icon-wrapper">
                      <IconReset
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                      />
                    </div>
                    <div>{__('Reset')}</div>
                  </>
                </Button>
              </li>
              <li>
                <Button
                  featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
                  featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
                  notEnabledMessage={getDisabledMessage()}
                  className="table-button"
                  onClick={() => handleDeviceBlacklistForm(device)}
                  type="button"
                >
                  <>
                    <div className="icon-wrapper">
                      <IconBlacklist
                        fill="#ee5253"
                        width="20"
                        height="20"
                        viewBox="0 0 100 100"
                      />
                    </div>
                    <div>{__('Blacklist')}</div>
                  </>
                </Button>
              </li>
              {isFloating && (
                <li>
                  <Button
                    featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
                    featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
                    notEnabledMessage={getDisabledMessage()}
                    className="table-button"
                    onClick={() => handleManageBorrow(device)}
                    type="button"
                  >
                    <>
                      <div className="icon-wrapper">
                        <IconBorrow
                          fill="#000"
                          width="22"
                          height="22"
                        />
                      </div>
                      <div>{isBorrowed ? __('Manage borrowed license') : __('Borrow')}</div>
                    </>
                  </Button>
                </li>
              )}
              {isBorrowed && (
                <li>
                  <Button
                    featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
                    featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
                    notEnabledMessage={getDisabledMessage()}
                    className="table-button"
                    onClick={() => handleReturnBorrow(device)}
                    type="button"
                  >
                    <>
                      <div className="icon-wrapper">
                        <IconReturn
                          fill="#000"
                          width="24"
                          height="24"
                        />
                      </div>
                      <div>{__('Return borrowed license')}</div>
                    </>
                  </Button>
                </li>
              )}
            </ul>
          </div>
        </Modal>
      )}
    </div>
  );
};

DeviceActionMenu.propTypes = {
  device: PropTypes.object.isRequired,
  license: PropTypes.object.isRequired,
  handleDeviceRevokeForm: PropTypes.func.isRequired,
  handleDeviceResetForm: PropTypes.func.isRequired,
  handleDeviceBlacklistForm: PropTypes.func.isRequired,
  handleManageBorrow: PropTypes.func.isRequired,
  handleReturnBorrow: PropTypes.func.isRequired,
};

export default DeviceActionMenu;
