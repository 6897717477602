import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  Button,
} from 'shared/components';
import './styles.scss';

const Summary = ({
  billingCycle,
  unitPrice,
  loading,
  taxValue,
  total,
  handleUpgradeBtn,
}) => (
  <div className="Summary">
    <div className="Summary-heading">
      {__('Order summary')}
    </div>
    <div className="Summary-content">
      <div className="row">
        <div className="label">
          {__('Billing cycle')}
        </div>
        <div className="value">
          {get(billingCycle, 'label')}
        </div>
      </div>
      <div className="row">
        <div className="label">
          {__('Unit price')}
        </div>
        <div className="value">
          {unitPrice}
          <span className="currency">$</span>
        </div>
      </div>
      <div className="row">
        <div className="label">
          {__('Tax')}
        </div>
        <div className="value">
          {taxValue || '0.00'}
          <span className="currency">$</span>
        </div>
      </div>
      <div className="row total">
        <div className="label">
          {__('Total')}
        </div>
        <div className="value">
          {total || '0.00'}
          <span className="currency">$</span>
        </div>
      </div>
    </div>
    <div className="row submit-btn">
      <Button
        theme="success"
        size="lg"
        disabled={loading}
        onClick={handleUpgradeBtn}
      >
        {__('Upgrade')}
      </Button>
    </div>
  </div>
);

Summary.propTypes = {
  billingCycle: PropTypes.object.isRequired,
  handleUpgradeBtn: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  unitPrice: PropTypes.string.isRequired,
  taxValue: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

Summary.defaultProps = {
  region: null,
};

export default Summary;
