import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const FeatureTypePill = ({ type }) => (
  <div className={`FeatureTypePill FeatureTypePill-${type}`}>
    {type}
  </div>
);

FeatureTypePill.propTypes = {
  type: PropTypes.oneOf(['activation', 'consumption']).isRequired,
};

export default FeatureTypePill;
