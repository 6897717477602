import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  sendErrorReport,
} from 'shared/helpers';
import {
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  TextInput,
} from 'shared/components';
import { validateRequiredValue, debouncedValidateRequiredValue } from 'shared/validation';
import { postCustomField, patchCustomField } from 'src/license/actions';

const CustomFieldForm = ({
  closeCb,
  field,
  licenseID,
  companyID,
  refetchCustomFields,
}) => {
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormDisplay] = useState(false);
  const [isLoading, setLoading] = useState(false);
  // field.value when there is license custom field
  // field.default_value when there is product custom field
  const [fieldValue, setFieldValue] = useState(get(field, 'value') || get(field, 'default_value') || '');
  const [fieldValueError, setFieldValueError] = useState('');

  const validateValue = async (val, cb) => {
    setLoading(true);
    let errors;
    try {
      errors = await validateRequiredValue(val);
      cb(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate custom field form value', { value: val });
    }
    setLoading(false);
    if (errors) {
      return false;
    }
    return true;
  };

  const handleClose = () => {
    if (!dirty) { return closeCb(); }
    return setDirtyFormDisplay(true);
  };

  const isFormValid = async () => {
    const isValueValid = await validateValue(fieldValue, setFieldValueError);
    return isValueValid;
  };

  const patchLicenseField = () => {
    const fieldID = get(field, 'id');
    const data = {
      value: fieldValue,
      license: licenseID,
      product_custom_field: get(field, 'product_custom_field'),
    };

    patchCustomField(fieldID, data, companyID)
      .then(() => {
        Notification('success', __('Changes saved successfully'), __('Custom field edited'));
        refetchCustomFields();
        handleClose();
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot edit custom field on license', data);
        setLoading(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
  };

  const postLicenseField = async () => {
    const fieldID = get(field, 'id');
    const data = {
      value: fieldValue,
      license: licenseID,
      product_custom_field: fieldID,
    };

    postCustomField(data, companyID)
      .then(() => {
        Notification('success', __('Changes saved successfully'), __('Custom field edited'));
        refetchCustomFields();
        handleClose();
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot edit custom field on license', data);
        setLoading(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = await isFormValid();
    if (!isValid || isLoading) {
      return false;
    }

    setDirty(false);
    setLoading(true);

    if (get(field, 'product_custom_field')) {
      // patch existing license custom field
      patchLicenseField();
    } else {
      // post new license custom field created from editing product custom field
      postLicenseField();
    }
    return true;
  };

  return (
    <Modal
      confirmCb={handleSubmit}
      closeCb={handleClose}
      disabled={isLoading}
      title={__('Add custom field')}
      size="sm"
    >
      <div className="CustomFieldForm">
        <form className="custom-field-form" onSubmit={handleSubmit}>
          <Label text={__('Name')} inputId="name" />
          <TextInput
            id="name"
            value={get(field, 'name') || ''}
            handleChange={() => {}}
            disabled
          />
          <Label text={__('Value')} inputId="value" />
          <TextInput
            id="value"
            value={fieldValue}
            error={fieldValueError}
            handleChange={(val) => {
              setDirty(true);
              setFieldValue(val);
              debouncedValidateRequiredValue(val).then(err => setFieldValueError(err));
            }}
          />
          <button type="submit" style={{ visibility: 'hidden' }} />
        </form>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

CustomFieldForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  refetchCustomFields: PropTypes.func.isRequired,
  licenseID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  companyID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  field: PropTypes.object.isRequired,
};

export default CustomFieldForm;
