/* eslint-disable camelcase */
import { get } from 'lodash';
import moment from 'moment';
import api from 'shared/api';
import { objectToQueryParams, setSearchQueryParam, formatOrderBy } from 'shared/helpers';

const offset = 20;

export const getLicenses = (
  companyId,
  page,
  query,
  queryType,
  orderBy,
  pageSize = 20,
  filters,
) => {
  const offsetUrl = page ? page * pageSize : 0;
  const queryUrl = setSearchQueryParam(query, queryType);
  const orderUrl = formatOrderBy(orderBy);

  // set filters
  const {
    clabels, enabled, validity_period_lte, product,
  } = filters;
  let enabledFilters = '';
  if (enabled && get(enabled, 'value') !== 'all') {
    enabledFilters = get(enabled, 'value') === 'Enabled' ? '&enabled=1' : '&enabled=0';
  }
  let expirationFilter = '';
  if (validity_period_lte) {
    expirationFilter = `&validity_period__lte=${validity_period_lte}&validity_period__gte=${moment().format('YYYY-MM-DD')}`;
  }
  let productFilter = '';
  if (product && product !== 'all') {
    productFilter = `&product_id=${product}`;
  }
  const customerLabelsFilter = (clabels && clabels.length) ? `&customer_label__in=${clabels.map(l => l.value).join(',')}` : '';

  const url = `/api/v1/licenses/?company=${companyId}&limit=${pageSize}&offset=${offsetUrl}${queryUrl}${orderUrl}${customerLabelsFilter}${enabledFilters}${expirationFilter}${productFilter}&bundle_license__isnull=1`;
  return api.get(url);
};

export const getBundleLicenses = (bundleLicenseID, companyID) => api.get(`/api/v1/licenses/?company=${companyID}&limit=1000&offset=0&bundle_license=${bundleLicenseID}`);

export const generateLicense = (companyID, productShortCode, licenseQuantity) => {
  const url = `/api/v1/orders/generate_license/?product=${productShortCode}&quantity=${licenseQuantity}&company=${companyID}`;
  return api.get(url);
};

export const fetchLicense = (licenseId, companyId) => {
  const url = `/api/v1/licenses/${licenseId}/?company=${companyId}`;
  return api.get(url);
};

export const deleteLicense = (licenseID, companyID) => api.delete(`/api/v1/licenses/${licenseID}/?company=${companyID}`);

export const resetLicense = (licenseId, data, companyId) => {
  const url = `/api/v1/licenses/${licenseId}/reset/?company=${companyId}`;
  return api.post(url, data);
};

export const resetLicenseConsumptions = (licenseId, companyId) => {
  const url = `/api/v1/licenses/${licenseId}/reset_consumption/?company=${companyId}`;
  return api.post(url);
};

export const disableLicense = (licenseID, companyID) => {
  const url = `/api/v1/licenses/${licenseID}/disable/?company=${companyID}`;
  return api.post(url);
};

export const enableLicense = (licenseID, companyID) => {
  const url = `/api/v1/licenses/${licenseID}/enable/?company=${companyID}`;
  return api.post(url);
};

export const patchLicense = (licenseId, data, companyId) => {
  const url = `/api/v1/licenses/${licenseId}/?company=${companyId}`;
  return api.patch(url, data);
};

export const updateLicenseFeatures = (licenseId, data, companyId) => {
  const url = `/api/v1/licenses/${licenseId}/update_features/?company=${companyId}`;
  return api.post(url, data);
};

export const updateLicenseFeature = (featureID, data, companyId) => {
  const url = `/api/v1/license-product-features/${featureID}/?company=${companyId}`;
  return api.patch(url, data);
};

export const deleteLicenseFeature = (featureID, companyId) => {
  const url = `/api/v1/license-product-features/${featureID}/?company=${companyId}`;
  return api.delete(url);
};

export const createLicenseFeature = (companyId, data) => {
  const url = `/api/v1/license-product-features/?company=${companyId}`;
  return api.post(url, data);
};

export const fetchLicenseUser = (userId, companyId) => {
  const url = `/api/v1/license-users/${userId}/?company=${companyId}`;
  return api.get(url);
};

export const removeLicenseUser = (companyID, licenseID, userID) => {
  const url = `/api/v1/licenses/${licenseID}/unassign_user/?company=${companyID}`;
  return api.post(url, { license_user_id: userID });
};

export const disableBulkLicenses = (companyID, licenses) => {
  const url = `/api/v1/licenses/disable_bulk/?company=${companyID}`;
  return api.post(url, licenses);
};

export const updateUIOptions = (userPrefsID, companyID, data) => {
  const url = `/api/v1/users/${userPrefsID}/?company=${companyID}`;
  return api.patch(url, data);
};

export const fetchLicenseProductFeatures = (companyId, licenseId, page = 0, orderBy) => {
  const orderUrl = formatOrderBy(orderBy);
  const url = `/api/v1/license-product-features/?company=${companyId}&license=${licenseId}&limit=20&offset=${page * offset}${orderUrl}`;
  return api.get(url);
};

export const fetchCustomFields = (licenseId, companyID) => {
  const url = `/api/v1/license-custom-fields/?license=${licenseId}&company=${companyID}&limit=200&offset=0`;
  return api.get(url);
};

export const patchCustomField = (fieldID, data, companyID) => {
  const url = `/api/v1/license-custom-fields/${fieldID}/?company=${companyID}`;
  return api.patch(url, data);
};

export const postCustomField = (data, companyID) => {
  const url = `/api/v1/license-custom-fields/?company=${companyID}`;
  return api.post(url, data);
};

export const fetchDevices = (companyId, licenseId, page = 0, orderBy, showBlacklisted = false) => {
  const orderUrl = formatOrderBy(orderBy);
  const blacklistedParam = showBlacklisted ? '&blacklisted=1' : '&blacklisted=0';
  const url = `/api/v1/devices/?company=${companyId}&license=${licenseId}&limit=20&offset=${page * offset}${orderUrl}${blacklistedParam}`;
  return api.get(url);
};

export const resetDevice = (id, companyId) => {
  const url = `/api/v1/devices/${id}/reset/?company=${companyId}`;
  return api.post(url);
};

export const revokeDevice = (id, companyId) => {
  const url = `/api/v1/devices/${id}/?company=${companyId}`;
  return api.patch(url, { floating_in_use: false });
};

export const revokeFeatureDevice = (featureID, companyId, deviceID) => {
  const url = `/api/v1/license-product-features/${featureID}/release_device/?company=${companyId}`;
  return api.post(url, { device: deviceID });
};

export const blacklistDevice = (id, companyId) => {
  const url = `/api/v1/devices/${id}/blacklist/?company=${companyId}`;
  return api.post(url);
};

// export const blacklistDeviceLegacy = (id, companyId) => {
//   const url = `/api/v1/devices/${id}/?company=${companyId}`;
//   return api.patch(url, { blacklisted: true, device_active: false, floating_in_use: false });
// };

export const whitelistDevice = (id, companyId) => {
  const url = `/api/v1/devices/${id}/?company=${companyId}`;
  return api.patch(url, { blacklisted: false });
};

export const editDeviceBorrowed = (id, companyId, borrowedDateTime) => {
  const url = `/api/v1/devices/${id}/?company=${companyId}`;
  return api.patch(url, { borrowed_until: borrowedDateTime });
};

export const releaseBorrowedDevice = (deviceID, companyID) => {
  const url = `/api/v1/devices/${deviceID}/borrow_release/?company=${companyID}`;
  return api.post(url);
};

export const borrowDevice = (deviceID, companyID, data) => {
  const url = `/api/v1/devices/${deviceID}/borrow/?company=${companyID}`;
  return api.post(url, data);
};

export const addLicenseUser = (licenseId, data, companyId) => {
  const url = `/api/v1/licenses/${licenseId}/assign_user/?company=${companyId}`;
  return api.post(url, data);
};

export const patchLicenseUser = (userId, companyId, data) => {
  const url = `/api/v1/license-users/${userId}/?company=${companyId}`;
  return api.patch(url, data);
};

export const setLicenseUserPassword = (userId, companyId, data) => {
  const url = `/api/v1/license-users/${userId}/set_password/?company=${companyId}`;
  return api.post(url, data);
};

export const setLicenseUserActivations = (licenseID, companyId, data) => {
  const url = `/api/v1/licenses/${licenseID}/set_users_activations/?company=${companyId}`;
  return api.post(url, data);
};
// {
//   "{license_user_id_1}": {
//     "max_activations": 100
//   },
//   "{license_user_id_2}": {
//     "reset_total_activations": true
//   },
//   "{license_user_id_3}": {
//     "max_activations": 200,
//       "reset_total_activations": true
//   }
// }

export const checkExistingUser = (userEmail, companyId) => {
  const email = encodeURIComponent(userEmail);
  const url = `/api/v1/license-users/?true_email__iexact=${email}&company=${companyId}`;
  return api.get(url);
};

export const exportOrders = data => api.get(`/api/v1/orders/export/?${objectToQueryParams(data)}`);

export const exportLicenses = (filterUrl, queryUrl) => api.get(`/api/v1/licenses/export/${filterUrl}${queryUrl}`);

export const exportUsageReport = data => api.get(`/api/v1/licenses/export_history/?${objectToQueryParams(data)}`);

export const getLicenseHistory = (companyID, licenseID, page = 0, orderBy = undefined, range = {}) => {
  const startDate = get(range, 'from') ? `&ts__date__gte=${get(range, 'from')}` : '';
  const endDate = get(range, 'to') ? `&ts__date__lte=${get(range, 'to')}` : '';
  const orderUrl = formatOrderBy(orderBy);
  const url = `/api/v1/license-histories/?license=${licenseID}&limit=20&offset=${page * offset}${orderUrl}${startDate}${endDate}&company=${companyID}`;
  return api.get(url);
};

export const getLicensesImportsResults = companyID => api.get(`/api/v1/licenses-import-results/?company=${companyID}&limit=100&order_by=-created_at`);

export const getLicensesFeaturesImportsResults = companyID => api.get(`/api/v1/license-features-import-results/?company=${companyID}&limit=100&order_by=-created_at`);

export const checkLicense = (companyID, hwid, product, apiKey, licenseKey, username) => {
  const companyParam = `?company=${companyID}`;
  const hwidParam = `&hardware_id=${hwid}`;
  const productParam = `&product=${product}`;
  const apiKeyParam = `&api_key=${apiKey}`;
  const licenseParam = licenseKey ? `&license_key=${licenseKey}` : `&username=${username}`;
  return api.get(`/api/v1/licenses/check_license/${companyParam}${hwidParam}${productParam}${licenseParam}${apiKeyParam}`);
};

export const getHourlyLicenseConsumptionAnalytics = params => api.get(`/api/v1/license-consumption-activities/hourly/${params}`);

export const getDailyLicenseConsumptionAnalytics = params => api.get(`/api/v1/license-consumption-activities/daily/${params}`);

export const getLicenseConsumptionAnalyticsEvents = (companyID, page, pageSize = 20, orderBy, params) => {
  const offsetUrl = page ? page * pageSize : 0;
  const orderUrl = formatOrderBy(orderBy);
  const url = `/api/v1/license-consumption-activities/${params}${orderUrl}&limit=${pageSize}&offset=${offsetUrl}&company=${companyID}`;
  return api.get(url);
};

export const exportLicenseConsumptionAnalytics = params => api.get(`/api/v1/license-consumption-activities/export/${params}`);

export const getHourlyFeatureConsumptionAnalytics = params => api.get(`/api/v1/license-feature-consumption-activities/hourly/${params}`);

export const getDailyFeatureConsumptionAnalytics = params => api.get(`/api/v1/license-feature-consumption-activities/daily/${params}`);

export const getFeatureConsumptionAnalyticsEvents = (companyID, page, pageSize = 20, orderBy, params) => {
  const offsetUrl = page ? page * pageSize : 0;
  const orderUrl = formatOrderBy(orderBy);
  const url = `/api/v1/license-feature-consumption-activities/${params}${orderUrl}&limit=${pageSize}&offset=${offsetUrl}&company=${companyID}`;
  return api.get(url);
};

export const exportFeatureConsumptionAnalytics = params => api.get(`/api/v1/license-feature-consumption-activities/export/${params}`);

// hardware_key

export const provisionHardwareKey = (licenseID, companyID) => api.post(`/api/v1/licenses/${licenseID}/provision_hardware_key/?company=${companyID}`);

export const getDeviceCertificate = (uuid, companyID) => api.get(`/api/v1/device-certificates/?uuid=${uuid}&company=${companyID}&is_active=1`);

export const getDeviceCertificatesList = (companyID, page, orderBy) => {
  const orderUrl = formatOrderBy(orderBy);
  const url = `/api/v1/device-certificates/?company=${companyID}&limit=20&offset=${page * offset}${orderUrl}`;
  return api.get(url);
};
