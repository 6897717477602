import { combineReducers } from 'redux';
import account from './account/accountReducer';
import billing from './billing/billingConfigReducer';
import company from './company/companyReducer';
import products from './product/productsReducer';
import users from './account/usersReducer';
import notifications from './notifications/notificationsReducer';
import analytics from './analytics/analyticsReducer';

export default combineReducers({
  account,
  billing,
  company,
  products,
  users,
  analytics,
  notifications,
});
