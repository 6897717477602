import React from 'react';
import PropTypes from 'prop-types';
import TooltipRC from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import './styles.scss';

const align = {
  offset: [0, -10],
};

const Tooltip = ({
  active,
  children,
  content,
  position,
  trigger,
  mouseLeaveDelay,
  ...rest
}) => (
  <div className="Tooltip">
    {
      active ? (
        <TooltipRC
          placement={position}
          trigger={trigger}
          overlay={content}
          align={align}
          mouseLeaveDelay={mouseLeaveDelay}
          {...rest}
        >
          {children}
        </TooltipRC>
      ) : children
    }
  </div>
);

/* eslint-disable react/require-default-props */
Tooltip.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  position: PropTypes.oneOf([
    'left',
    'right',
    'top',
    'bottom',
    'topLeft',
    'topRight',
    'bottomLeft',
    'bottomRight',
  ]),
  trigger: PropTypes.arrayOf(PropTypes.string),
  mouseLeaveDelay: PropTypes.number,
};
/* eslint-enable react/require-default-props */

Tooltip.defaultProps = {
  active: false,
  position: 'top',
  trigger: ['hover'],
  content: <span>tooltip content</span>,
  mouseLeaveDelay: 0,
};

export default Tooltip;
