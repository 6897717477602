import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  Button,
  ConfirmationPopup,
  DescriptionTable,
  DeviceIcon,
  IconCheckmark,
  IconDisabled,
  List,
  StatusCard,
  Modal,
  Notification,
  IconRevoke,
} from 'shared/components';
import {
  displayValue,
  formatDate,
  sendErrorReport,
  isFeatureEnabled,
  isFeatureAvailable,
  getDisabledMessage,
} from 'shared/helpers';
import {
  defaultDateFormat,
  platformFeatures,
} from 'shared/constants';
import {
  revokeFeatureDevice,
} from 'src/license/actions';

const LicenseFeatureDevices = ({
  close,
  feature,
  companyID,
  onFeatureDeviceRevoke,
}) => {
  const featureID = get(feature, 'id');
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [deviceToRevoke, setDeviceToRevoke] = useState(null);
  const [isDeviceRevokeConfirmationDisplayed, setDeviceRevokeConfirmationDisplay] = useState(false);
  const [tableExpanded, setTableExpanded] = useState({});
  const data = get(feature, 'device_features') || [];

  const isFloatingFeature = get(feature, 'is_floating') || get(feature, 'is_floating_cloud');

  const handleDeviceRevokeClick = (device) => {
    const isInUse = get(device, 'floating_in_use');
    if (!isInUse) {
      Notification('error', __('Device is not in use'), __('Only floating devices which are in use can be revoked'));
      return false;
    }
    setDeviceToRevoke(device);
    setDeviceRevokeConfirmationDisplay(true);
    return true;
  };

  const handleDeviceRevoke = () => {
    const deviceID = get(deviceToRevoke, 'device.id');
    setConfirmationLoading(true);

    revokeFeatureDevice(featureID, companyID, deviceID)
      .then(() => {
        onFeatureDeviceRevoke();
        setDeviceToRevoke(null);
        setConfirmationLoading(false);
        setDeviceRevokeConfirmationDisplay(false);
        Notification('success', __('Changes saved successfully'), __('License device has been revoked'));
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot revoke license device');
        setConfirmationLoading(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
  };

  return (
    <div className="LicenseFeatureDevices">
      <Modal
        title={__('License feature devices')}
        confirmCb={() => { }}
        closeCb={() => close()}
        size="lg"
        hideFooter
      >
        <div className="device-variables-container">
          <List
            onExpandedChange={expanded => setTableExpanded(expanded)}
            expanded={tableExpanded}
            SubComponent={row => (
              <div className="SubComponent">
                <DescriptionTable
                  details={[
                    {
                      label: __('First activated'),
                      value: formatDate(get(row, 'original.device.time_activated'), defaultDateFormat),
                    },
                    {
                      label: isFloatingFeature ? __('Floating last check-in') : null,
                      value: formatDate(get(row, 'original.device.floating_last_seen'), defaultDateFormat),
                    },
                    {
                      label: __('OS'),
                      value: displayValue(get(row, 'original.device.os')),
                    },
                    {
                      label: __('SDK build'),
                      value: displayValue(get(row, 'original.device.sdk_build_version')),
                    },
                    {
                      label: __('App version'),
                      value: displayValue(get(row, 'original.device.app_ver')),
                    },
                    {
                      label: __('Hostname'),
                      value: displayValue(get(row, 'original.device.hostname')),
                    },
                    {
                      label: __('External IP address'),
                      value: displayValue(get(row, 'original.device.external_ip')),
                    },
                    {
                      label: __('IP address'),
                      value: displayValue(get(row, 'original.device.ip')),
                    },
                    {
                      label: __('MAC address'),
                      value: displayValue(get(row, 'original.device.mac_address')),
                    },
                    {
                      label: __('Is virtual machine'),
                      value: get(row, 'original.device.is_vm') ? __('Yes') : __('No'),
                    },
                    {
                      label: __('VM info'),
                      value: displayValue(get(row, 'original.device.vm_info')),
                    },
                  ]}
                />
              </div>
            )}
            columns={[
              {
                expander: true,
                Header: __('Details'),
                headerClassName: 'text-center',
                width: 80,
                style: {
                  fontSize: 25,
                  padding: '0',
                  textAlign: 'center',
                  userSelect: 'none',
                },
              },
              {
                accessor: 'device.type',
                Header: __('Type'),
                className: 'text-center',
                headerClassName: 'text-center',
                width: 70,
                Cell: cellInfo => (
                  <div className="device-type-icon">
                    <DeviceIcon device={cellInfo.value} />
                  </div>
                ),
              },
              {
                accessor: 'device.hardware_id',
                Header: __('Hardware ID'),
                Cell: cellInfo => displayValue(cellInfo.value),
              },
              {
                accessor: 'device.device_active',
                Header: __('Status'),
                headerClassName: 'text-center',
                className: 'text-center',
                width: 100,
                Cell: cellInfo => (
                  <StatusCard
                    status={cellInfo.value ? 'success' : 'error'}
                    text={cellInfo.value ? `${__('Active')}` : `${__('Inactive')}`}
                  />
                ),
                maxWidth: 100,
              },
              // {
              //   id: 'is_borrowed',
              //   headerClassName: 'text-center',
              //   className: 'text-center',
              //   Header: __('Is borrowed'),
              //   width: 120,
              //   Cell: (cellData) => {
              //     const isBorrowed = get(cellData, 'original.borrowed_until') || '';
              //     return (
              //       isBorrowed ? <IconCheckmark color="#10ac84" height="14px" /> : <IconDisabled color="red" height="14px" />
              //     );
              //   },
              //   show: get(license, 'is_floating_cloud') || get(license, 'is_floating'),
              // },
              {
                accessor: 'last_check',
                headerClassName: 'text-center',
                className: 'text-center',
                Header: __('Last checked'),
                Cell: cellInfo => formatDate(cellInfo.value),
              },
              {
                accessor: 'floating_in_use',
                headerClassName: 'text-center',
                className: 'text-center',
                Header: __('In use'),
                width: 80,
                Cell: cellData => (cellData.value ? <IconCheckmark color="#10ac84" height="14px" /> : <IconDisabled color="red" height="14px" />),
                show: isFloatingFeature,
              },
              {
                id: 'actions',
                className: 'select-container action-menu',
                Header: __('Revoke'),
                headerClassName: 'text-center',
                Cell: rowData => (
                  <Button
                    featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
                    featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
                    notEnabledMessage={getDisabledMessage()}
                    className="table-button"
                    type="button"
                    theme="error"
                    size="sm"
                    onClick={() => handleDeviceRevokeClick(get(rowData, 'original'))}
                  >
                    <>
                      <div className="icon-wrapper"><IconRevoke width="24" height="24" viewBox="0 0 100 100" /></div>
                    </>
                  </Button>
                ),
                width: 90,
                show: isFloatingFeature,
              },
            ]}
            data={data}
            minRows={1}
            showPagination={data.length > 20}
          />
        </div>
      </Modal>
      {isDeviceRevokeConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setDeviceToRevoke(null);
            setDeviceRevokeConfirmationDisplay(false);
          }}
          confirmCb={handleDeviceRevoke}
          title={__('Are you sure you want to revoke this device?')}
          confirmText={__('Revoke')}
          theme="error"
          disabled={confirmationLoading}
        >
          {get(deviceToRevoke, 'device.hardware_id')}
        </ConfirmationPopup>
      )}
    </div>
  );
};

LicenseFeatureDevices.propTypes = {
  close: PropTypes.func.isRequired,
  onFeatureDeviceRevoke: PropTypes.func.isRequired,
  feature: PropTypes.array.isRequired,
  companyID: PropTypes.number.isRequired,
};

export default LicenseFeatureDevices;
