import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
// import { useSelector } from 'react-redux';
import {
  Button,
  ConfirmationPopup,
  DescriptionTable,
  DeviceIcon,
  IconInfo,
  IconPopupOpen,
  IconWhitelist,
  List,
  Notification,
  StatusCard,
  Subtitle,
  Tooltip,
} from 'shared/components';
import {
  getDisabledMessage,
  isFeatureAvailable,
  isFeatureEnabled,
  sendErrorReport,
  displayValue,
  formatDate,
} from 'shared/helpers';
import {
  platformFeatures,
} from 'shared/constants';
import {
  fetchDevices,
  whitelistDevice,
} from 'src/license/actions';
import DeviceVariablesModal from '../../DeviceVariablesModal';

const BlacklistedDevicesList = ({
  companyID,
  license,
  refetchLicense,
  refetchDevices,
  shouldUpdateList,
}) => {
  const licenseID = get(license, 'id');
  // const productID = get(license, 'product.id');
  // const products = useSelector(state => get(state, 'products.list'));
  // const productDetails = products.find(p => p.id === productID);
  // const isUserBased = get(productDetails, 'authorization_method') === 'user';

  const [isLoading, setLoading] = useState(true);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [devices, setDevices] = useState({});
  const [currentDevicesSort, setCurrentDevicesSort] = useState([]);
  const [devicesPage, setDevicesPage] = useState(0);
  const [tableExpanded, setTableExpanded] = useState({});
  const [deviceToWhitelist, setDeviceToWhitelist] = useState(null);
  const [isDeviceWhitelistConfirmationDisplayed, setDeviceWhitelistConfirmationDisplay] = useState(false);
  const [deviceVariablesModalData, setDeviceVariabledModalData] = useState(null);
  const [showDeviceVariablesModal, setDeviceVariablesModalDisplay] = useState(false);

  const getBlacklistedDevices = useCallback((page, sort) => {
    setLoading(true);
    fetchDevices(companyID, licenseID, page, sort, true)
      .then((res) => {
        setDevices(get(res, 'data'));
        setLoading(false);
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot fetch license devices');
        Notification('error', __('There was an error while getting your data'));
        setLoading(false);
      });
  }, [companyID, licenseID]);

  useEffect(() => {
    getBlacklistedDevices(devicesPage, currentDevicesSort);
  }, [devicesPage, currentDevicesSort, shouldUpdateList]);

  const handleDeviceWhitelist = () => {
    const deviceID = get(deviceToWhitelist, 'id');
    setConfirmationLoading(true);

    whitelistDevice(deviceID, companyID)
      .then(() => {
        getBlacklistedDevices(devicesPage, currentDevicesSort);
        refetchDevices();
        refetchLicense();
        setDeviceToWhitelist(null);
        setConfirmationLoading(false);
        setDeviceWhitelistConfirmationDisplay(false);
        Notification('success', __('Changes saved successfully'), __('License device has been whitelisted'));
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot whitelist license device');
        setConfirmationLoading(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
  };

  return (
    <div className="BlacklistedDevicesList blacklisted-devices-list">
      <Subtitle text={__('Blacklisted devices')} />
      <List
        onExpandedChange={expanded => setTableExpanded(expanded)}
        expanded={tableExpanded}
        SubComponent={row => (
          <div className="SubComponent">
            <DescriptionTable
              details={[
                {
                  label: __('First activated'),
                  value: displayValue(get(row, 'original.time_activated')),
                },
                {
                  label: __('OS'),
                  value: displayValue(get(row, 'original.os')),
                },
                {
                  label: __('SDK build'),
                  value: displayValue(get(row, 'original.sdk_build_version')),
                },
                {
                  label: __('App version'),
                  value: displayValue(get(row, 'original.app_ver')),
                },
                {
                  label: __('Hostname'),
                  value: displayValue(get(row, 'original.hostname')),
                },
                {
                  label: __('External IP address'),
                  value: displayValue(get(row, 'original.external_ip')),
                },
                {
                  label: __('IP address'),
                  value: displayValue(get(row, 'original.ip')),
                },
                {
                  label: __('MAC address'),
                  value: displayValue(get(row, 'original.mac_address')),
                },
                {
                  label: __('Is virtual machine'),
                  value: get(row, 'original.is_vm') ? __('Yes') : __('No'),
                },
                {
                  label: __('VM info'),
                  value: displayValue(get(row, 'original.vm_info')),
                },
              ]}
            />
          </div>
        )}
        columns={[
          {
            expander: true,
            Header: __('Details'),
            headerClassName: 'text-center',
            width: 80,
            style: {
              fontSize: 25,
              padding: '0',
              textAlign: 'center',
              userSelect: 'none',
            },
          },
          {
            accessor: 'type',
            Header: __('Type'),
            className: 'text-center',
            headerClassName: 'text-center',
            width: 70,
            Cell: cellInfo => (
              <div className="device-type-icon">
                <DeviceIcon device={cellInfo.value} />
              </div>
            ),
          },
          {
            accessor: 'hardware_id',
            Header: __('Hardware ID'),
            Cell: cellInfo => displayValue(cellInfo.value),
          },
          {
            accessor: 'last_check',
            Header: __('Last checked'),
            Cell: cellInfo => formatDate(cellInfo.value),
            width: 140,
          },
          {
            accessor: 'device_active',
            Header: __('Status'),
            headerClassName: 'text-center',
            className: 'text-center',
            width: 100,
            Cell: cellInfo => (
              <StatusCard
                status={cellInfo.value ? 'success' : 'error'}
                text={cellInfo.value ? `${__('Active')}` : `${__('Inactive')}`}
              />
            ),
            maxWidth: 100,
          },
          {
            accessor: 'floating_last_seen',
            headerClassName: 'text-center',
            className: 'text-center',
            Header: __('Floating last check-in'),
            Cell: cellInfo => formatDate(cellInfo.value),
            width: 140,
            show: get(license, 'is_floating_cloud') || get(license, 'is_floating'),
          },
          {
            Header: () => (
              <div className="Device-variables-header">
                {__('Device variables')}
                <Tooltip
                  content={__('Collect data from within your application and send it back to the LicenseSpring cloud, such as fields from a customer registration form, error codes, or system information that our SDK is not collecting by default.')}
                  active
                >
                  <span><IconInfo height="12px" width="12px" /></span>
                </Tooltip>
              </div>
            ),
            accessor: 'device_variables',
            width: 150,
            className: 'text-center',
            headerClassName: 'text-center',
            sortable: false,
            Cell: cellInfo => (
              <Button
                className="table-button"
                featureEnabled={isFeatureEnabled(platformFeatures.extra_device_variables)}
                notEnabledMessage={getDisabledMessage()}
                featureAvailable={isFeatureAvailable(platformFeatures.extra_device_variables)}
                notAvailableMessage="Device variables are unavailable in your current plan."
                ctaText={__('Contact sales to upgrade to a plan that supports device custom variables.')}
                onClick={() => {
                  setDeviceVariabledModalData(cellInfo.value);
                  setDeviceVariablesModalDisplay(true);
                }}
                type="button"
              >
                <IconPopupOpen
                  fill="none"
                  stroke="#000"
                  strokeWidth="2"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                />
              </Button>
            ),
            maxWidth: 150,
          },
          {
            Header: __('Whitelist'),
            headerClassName: 'text-center',
            className: 'text-center',
            accessor: 'id',
            sortable: false,
            width: 90,
            Cell: rowData => (
              <Button
                featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
                featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
                notEnabledMessage={getDisabledMessage()}
                className="table-button"
                onClick={() => {
                  setDeviceToWhitelist(get(rowData, 'original'));
                  setDeviceWhitelistConfirmationDisplay(true);
                }}
                type="button"
              >
                <IconWhitelist
                  fill="#10ac84"
                  width="20"
                  height="20"
                  viewBox="0 0 100 100"
                />
              </Button>
            ),
            maxWidth: 90,
          },
        ]}
        data={get(devices, 'results', [])}
        loading={isLoading}
        clickable={false}
        manual
        page={devicesPage}
        pages={Math.ceil(devices.count / 20)}
        minRows={2}
        showPagination
        onPageChange={page => setDevicesPage(page)}
        onSortedChange={newSorted => setCurrentDevicesSort(newSorted)}
        defaultSorted={currentDevicesSort}
      />
      {isDeviceWhitelistConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setDeviceToWhitelist(null);
            setDeviceWhitelistConfirmationDisplay(false);
          }}
          confirmCb={handleDeviceWhitelist}
          title={__('Are you sure you want to whitelist this device?')}
          confirmText={__('Whitelist')}
          theme="success"
          disabled={confirmationLoading}
        />
      )}
      {showDeviceVariablesModal && (
        <DeviceVariablesModal
          close={() => {
            setDeviceVariabledModalData(null);
            setDeviceVariablesModalDisplay(false);
          }}
          data={deviceVariablesModalData}
        />
      )}
    </div>
  );
};

BlacklistedDevicesList.propTypes = {
  companyID: PropTypes.number.isRequired,
  license: PropTypes.object.isRequired,
  refetchLicense: PropTypes.func.isRequired,
  refetchDevices: PropTypes.func.isRequired,
  shouldUpdateList: PropTypes.number.isRequired,
};

export default BlacklistedDevicesList;
