import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import {
  Button,
  ConfirmationPopup,
  DescriptionTable,
  JsonView,
  IconEdit,
  IconDelete,
  List,
  Notification,
  PermissionMissingNotificationTitle,
  IconPopupOpen,
  IconReset,
} from 'shared/components';
import {
  capitalizeFirstLetter,
  displayValue,
  formatDate,
  getDisabledMessage,
  isFeatureAvailable,
  isFeatureEnabled,
  // formatProductFeatures,
  sendErrorReport,
  checkUserPermission,
} from 'shared/helpers';
import {
  platformFeatures,
  productFeatureTypes,
  defaultDateFormat,
  userPermissions,
} from 'shared/constants';
import {
  updateLicenseFeature,
  fetchLicenseProductFeatures,
  deleteLicenseFeature,
} from 'src/license/actions';
import { AnalyticsDatePicker } from 'src/analytics/components';
import AddProductFeatureForm from '../AddProductFeatureForm';
import EditProductFeatureForm from '../EditProductFeatureForm';
import FeatureConsumptionChart from '../FeatureConsumptionChart';
import LicenseFeatureDevices from './LicenseFeatureDevices';
import './styles.scss';

const ProductFeatures = ({
  companyID,
  currentProduct,
  features,
  license,
  refetchLicense,
  isEnterprisePlan,
}) => {
  const licenseID = get(license, 'id');
  const canManageLicenses = checkUserPermission(userPermissions.licenses_write);
  const isAirGapped = get(license, 'is_air_gapped');

  const from = useSelector(state => get(state, 'analytics.dateRange.date_from'));
  const to = useSelector(state => get(state, 'analytics.dateRange.date_to'));

  const defaultFrom = moment().startOf('month');
  const defaultTo = moment().endOf('month');
  const dateFrom = from ? moment(from) : defaultFrom;
  const dateTo = to ? moment(to) : defaultTo;

  const [isLoading, setLoading] = useState(false);
  const [list, setList] = useState({});
  const [listSort, setListSort] = useState([]);
  const [listPage, setListPage] = useState(0);

  const [featureToEdit, setFeatureToEdit] = useState(null);
  const [isFeatureEditFormDisplayed, setFeatureEditDisplay] = useState(false);
  const [featureToDelete, setFeatureToDelete] = useState(null);
  const [isFeatureDeleteConfirmationDisplayed, setFeatureDeleteDisplay] = useState(false);
  const [featureToResetConsumptions, setFeatureToResetConsumptions] = useState(null);
  const [isFeatureResetConsumptionsConfirmationDisplayed, setFeatureResetConsumptionsConfirmationDisplay] = useState(false);
  const [resettingConsumptions, setResettingConsumptions] = useState(false);
  const [isFeatureAddFormDisplayed, setFeatureAddDisplay] = useState(false);
  const [tableExpanded, setTableExpanded] = useState({});
  const [showDevicesPopup, setDevicesPopupDisplay] = useState(false);
  const [devicePopupFeature, setDevicePopupFeature] = useState(null);

  const getLicenseProductFeatures = useCallback((page, sort) => {
    setLoading(true);
    fetchLicenseProductFeatures(companyID, licenseID, page, sort)
      .then((res) => {
        setList(get(res, 'data'));
        setLoading(false);
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot fetch license product features');
        Notification('error', __('There was an error while getting your data'));
        setLoading(false);
      });
  }, [companyID, licenseID]);

  useEffect(() => {
    getLicenseProductFeatures(listPage, listSort);
  }, [listPage, listSort]);

  const handleFeatureAddClick = () => {
    if (!canManageLicenses) {
      return Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.licenses_write} />,
        __('Contact you account admin for support.'),
      );
    }
    const productFeatures = get(currentProduct, 'product_features') || [];
    const licenceFeatures = get(license, 'product_features') || [];

    if (!productFeatures.length) {
      return Notification('error', __('License product has no additional features'));
    }
    if (productFeatures.length === licenceFeatures.length) {
      return Notification('error', __('All product features have been added to the license'));
    }
    return setFeatureAddDisplay(true);
  };

  const handleFeatureEditClick = (rowData) => {
    if (!canManageLicenses) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.licenses_write} />,
        __('Contact you account admin for support.'),
      );
      return false;
    }
    const isActivationType = get(rowData, 'original.product_feature.feature_type') === productFeatureTypes.activation;
    if (isActivationType && !isEnterprisePlan) {
      Notification('error', __('Editing not allowed'), __('Can\'t edit features with activation type'));
    } else {
      setFeatureToEdit(rowData.original);
      setFeatureEditDisplay(true);
    }
    return true;
  };

  const handleFeatureDeleteClick = (rowData) => {
    if (!canManageLicenses) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.licenses_write} />,
        __('Contact you account admin for support.'),
      );
      return false;
    }
    setFeatureToDelete(rowData.original);
    setFeatureDeleteDisplay(true);
    return true;
  };

  const handleFeatureConsumptionsResetClick = (rowData) => {
    if (!canManageLicenses) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.licenses_write} />,
        __('Contact you account admin for support.'),
      );
      return false;
    }
    setFeatureToResetConsumptions(rowData.original);
    setFeatureResetConsumptionsConfirmationDisplay(true);
    return true;
  };

  const removeLicenseFeature = () => {
    const featureID = get(featureToDelete, 'id');

    setLoading(true);
    deleteLicenseFeature(featureID, companyID)
      .then(() => {
        refetchLicense();
        setLoading(false);
        setFeatureToDelete(null);
        setFeatureDeleteDisplay(false);
        getLicenseProductFeatures(listPage, listSort);
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot delete license product feature');
        setLoading(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
  };

  const resetFeatureToConsumptions = () => {
    const featureID = get(featureToResetConsumptions, 'id');
    const data = {
      total_consumptions: 0,
    };

    setResettingConsumptions(true);
    updateLicenseFeature(featureID, data, companyID)
      .then(() => {
        refetchLicense();
        setResettingConsumptions(false);
        setFeatureToResetConsumptions(null);
        setFeatureResetConsumptionsConfirmationDisplay(false);
        getLicenseProductFeatures(listPage, listSort);
        Notification('success', __('Feature consumptions reset successfully'));
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot reset license product feature consumptions', data);
        setResettingConsumptions(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
  };

  const consumptionFeatures = features.filter(f => get(f, 'product_feature.feature_type') === productFeatureTypes.consumption);
  const hasConsumptionFeatures = features.length > 0;

  return (
    <div className="ProductFeatures">
      <div className="Tab-header">
        {__('Features Licensing allows you to offer multiple versions of your product using the same binary (example lite, standard, pro; or dev / production).')}
      </div>
      <Button
        featureEnabled={isFeatureEnabled(platformFeatures.extra_feature_licensing)}
        notEnabledMessage={getDisabledMessage()}
        featureAvailable={isFeatureAvailable(platformFeatures.extra_feature_licensing)}
        notAvailableMessage="Licensing features in your applications is not available in your plan."
        ctaText={__('Upgrade to use features licensing.')}
        onClick={handleFeatureAddClick}
        size="sm"
        theme="info"
        disabled={isAirGapped}
      >
        {__('Add product feature')}
      </Button>
      <List
        onExpandedChange={expanded => setTableExpanded(expanded)}
        expanded={tableExpanded}
        SubComponent={(row) => {
          const isFloatingFeature = get(row, 'original.is_floating') || get(row, 'original.is_floating_cloud');
          const floatingTimeout = get(row, 'original.floating_timeout') || 120;
          const isConsumption = get(row, 'original.product_feature.feature_type') === 'consumption';
          const allowOverages = get(row, 'original.allow_overages');
          const resetConsumption = get(row, 'original.reset_consumption');

          return (
            <div className="SubComponent">
              <DescriptionTable
                details={[
                  // consumption data
                  {
                    label: isConsumption ? __('Max consumption') : null,
                    value: displayValue(get(row, 'original.max_consumption')),
                  },
                  {
                    label: (isConsumption && allowOverages) ? __('Max overages') : null,
                    value: displayValue(get(row, 'original.max_overages')),
                  },
                  {
                    label: (isConsumption && resetConsumption) ? __('Reset consumptions') : null,
                    value: displayValue(capitalizeFirstLetter(get(row, 'original.consumption_period')), __('N/A')),
                  },
                  {
                    label: isConsumption ? __('Allow negative consumptions') : null,
                    value: get(row, 'original.allow_negative_consumptions') ? __('Yes') : __('No'),
                  },
                  // floating licenses data
                  {
                    label: get(row, 'original.is_floating') ? __('Offline floating feature') : null,
                    value: get(row, 'original.is_floating') ? __('Yes') : __('No'),
                  },
                  {
                    label: get(row, 'original.is_floating_cloud') ? __('Is floating cloud') : null,
                    value: get(row, 'original.is_floating_cloud') ? __('Yes') : __('No'),
                  },
                  {
                    label: isFloatingFeature ? __('Max simultaneous users') : null,
                    value: displayValue(get(row, 'original.floating_users')),
                  },
                  {
                    label: isFloatingFeature ? __('Floating timeout') : null,
                    value: `${displayValue(floatingTimeout)} min`,
                  },
                  // {
                  //   label: canBorrow ? __('Can borrow') : null,
                  //   value: canBorrow ? __('Yes') : __('No'),
                  // },
                  // {
                  //   label: canBorrow ? __('Max borrow time') : null,
                  //   value: `${displayValue(get(feature, 'max_borrow_time'))} ${__('hours')}`,
                  // },
                  {
                    label: __('Metadata'),
                    value: (
                      <JsonView
                        value={get(row, 'original.metadata')}
                        name="metadata"
                      />
                    ),
                  },
                ]}
              />
            </div>
          );
        }}
        columns={[
          {
            expander: true,
            Header: __('Details'),
            headerClassName: 'text-center',
            width: 80,
            style: {
              fontSize: 25,
              padding: '0',
              textAlign: 'center',
              userSelect: 'none',
            },
          },
          {
            accessor: 'product_feature.name',
            Header: __('Name'),
          },
          {
            accessor: 'product_feature.code',
            Header: __('Code'),
            width: 100,
          },
          {
            accessor: 'product_feature.feature_type',
            Header: __('Type'),
            Cell: cellData => displayValue(cellData.value),
            width: 100,
          },
          {
            accessor: 'total_consumptions',
            Header: __('Total consumptions'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: (cellData) => {
              const type = get(cellData, 'original.product_feature.feature_type');
              if (type === productFeatureTypes.activation) { return __('N/A'); }

              const value = get(cellData, 'value') || 0;
              const maxConsumptions = get(cellData, 'original.max_consumption');
              const allowOverage = get(cellData, 'original.allow_overages');
              const maxOverages = get(cellData, 'original.max_overages');
              const isUnlimited = get(cellData, 'original.allow_unlimited_consumptions');

              let data = '';

              if (isUnlimited) {
                data = `${value} / ${__('Unlimited')}`;
              } else if (allowOverage) {
                const maxValue = Number(maxConsumptions) + Number(maxOverages);
                data = `${value} / ${maxValue}`;
              } else {
                data = `${value} / ${maxConsumptions}`;
              }

              return (
                <div className="total-consumptions-cell">
                  <div className="value">
                    {data}
                  </div>
                  <Button
                    size="sm"
                    theme="link"
                    disabled={resettingConsumptions}
                    onClick={() => handleFeatureConsumptionsResetClick(cellData)}
                  >
                    <IconReset
                      fill="none"
                      stroke="#000"
                      strokeWidth="2"
                      width="14"
                      height="14"
                      viewBox="0 0 24 24"
                    />
                  </Button>
                </div>
              );
            },
          },
          {
            accessor: 'floating_users',
            Header: __('Floating slots'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: (cellData) => {
              const isFloatingFeature = get(cellData, 'original.is_floating') || get(cellData, 'original.is_floating_cloud');
              if (!isFloatingFeature) {
                return 'N/A';
              }

              const maxUsers = get(cellData, 'value');
              const featureDevices = get(cellData, 'original.device_features') || [];
              const floatingDevicesInUse = featureDevices.reduce((acc, item) => {
                if (item.floating_in_use === true) {
                  return acc + 1;
                }
                return acc;
              }, 0);
              return (
                <div>
                  <span>{`${floatingDevicesInUse} / ${maxUsers}`}</span>
                </div>
              );
            },
            maxWidth: 120,
          },
          {
            accessor: 'device_features',
            Header: __('Devices'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: (cellData) => {
              const featureDevices = get(cellData, 'original.device_features') || [];
              return (
                <Button
                  className="table-button"
                  onClick={() => {
                    setDevicePopupFeature(get(cellData, 'original'));
                    setDevicesPopupDisplay(true);
                  }}
                  type="button"
                >
                  <div className="floating-slots-btn">
                    <span>{`${featureDevices.length}`}</span>
                    <IconPopupOpen
                      fill="none"
                      stroke="#000"
                      strokeWidth="2"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                    />
                  </div>
                </Button>
              );
            },
          },
          {
            accessor: 'product_feature.created_at',
            Header: __('Created on'),
            Cell: cellData => formatDate(cellData.value, defaultDateFormat),
            maxWidth: 95,
          },
          {
            accessor: 'expiry_date',
            Header: __('Expiry date'),
            Cell: cellData => formatDate(cellData.value, defaultDateFormat),
            show: isEnterprisePlan,
            width: 120,
          },
          {
            Cell: rowData => (
              <button
                className="edit-button"
                onClick={() => handleFeatureEditClick(rowData)}
                type="button"
                disabled={isAirGapped}
              >
                <IconEdit height="16px" width="16px" />
              </button>
            ),
            maxWidth: 50,
          },
          {
            className: 'text-center',
            Cell: rowData => (
              <button
                className="delete-button"
                onClick={() => handleFeatureDeleteClick(rowData)}
                type="button"
                disabled={isAirGapped}
              >
                <IconDelete height="16px" width="16px" color="#ee5253" />
              </button>
            ),
            width: 50,
          },
        ]}
        data={get(list, 'results') || []}
        pageSize={20}
        loading={isLoading}
        clickable={false}
        manual
        page={listPage}
        pages={Math.ceil(list.count / 20)}
        minRows={2}
        showPagination
        onPageChange={page => setListPage(page)}
        onSortedChange={(newSorted) => {
          setListSort(newSorted);
        }}
        defaultSorted={listSort}
      />
      {hasConsumptionFeatures && (
        <div className="ProductFeatures-activity">
          <AnalyticsDatePicker title={__('Features consumption activity')} />
          {consumptionFeatures.map((cp) => {
            const allowUnlimited = get(cp, 'allow_unlimited_consumptions') || false;
            const resetPeriod = get(cp, 'reset_consumption') ? get(cp, 'consumption_period') : null;
            const maxConsumptionSubtitle = `${cp.max_consumption} ${__('max consumptions')}`;
            const maxOveragesSubtitle = cp.allow_overages ? ` | ${cp.max_overages === 0 ? __('Unlimited') : cp.max_overages} ${__('max overages')}` : '';
            const resetSubtitle = resetPeriod ? ` | ${capitalizeFirstLetter(resetPeriod)} ${__('reset')}` : '';
            const subtitle = allowUnlimited ? `${__('Unlimited consumptions')}${resetSubtitle}` : `${maxConsumptionSubtitle}${maxOveragesSubtitle}${resetSubtitle}`;
            return (
              <div className="feature-chart" key={cp.id}>
                <FeatureConsumptionChart
                  companyID={companyID}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                  title={get(cp, 'product_feature.name')}
                  subtitle={subtitle}
                  feature={cp}
                />
              </div>
            );
          })}
        </div>
      )}
      {isFeatureAddFormDisplayed && (
        <AddProductFeatureForm
          closeCb={() => setFeatureAddDisplay(false)}
          refetchLicense={() => {
            refetchLicense();
            getLicenseProductFeatures(listPage, listSort);
          }}
          featuresList={features}
          product={currentProduct}
          license={license}
          companyID={companyID}
        />
      )}
      {isFeatureEditFormDisplayed && (
        <EditProductFeatureForm
          closeCb={() => {
            setFeatureEditDisplay(false);
            setFeatureToEdit(null);
          }}
          refetchLicense={() => {
            refetchLicense();
            getLicenseProductFeatures(listPage, listSort);
          }}
          feature={featureToEdit}
          companyID={companyID}
          isEnterprisePlan={isEnterprisePlan}
        />
      )}
      {isFeatureDeleteConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setFeatureToDelete(null);
            setFeatureDeleteDisplay(false);
          }}
          confirmCb={removeLicenseFeature}
          title={`${__('Are you sure you want to delete feature')} ${get(featureToDelete, 'product_feature.name')} ${__('from this licence?')}`}
          confirmText={__('Delete')}
          theme="error"
          disabled={isLoading}
        />
      )}
      {isFeatureResetConsumptionsConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setFeatureToResetConsumptions(null);
            setFeatureResetConsumptionsConfirmationDisplay(false);
          }}
          confirmCb={resetFeatureToConsumptions}
          title={`${__('Are you sure you want to reset consumption on a feature')} ${get(featureToResetConsumptions, 'product_feature.name')}?`}
          confirmText={__('Reset')}
          theme="error"
          disabled={resettingConsumptions}
        />
      )}
      {showDevicesPopup && (
        <LicenseFeatureDevices
          feature={devicePopupFeature}
          companyID={companyID}
          close={() => {
            setDevicePopupFeature(null);
            setDevicesPopupDisplay(false);
          }}
          onFeatureDeviceRevoke={() => {
            getLicenseProductFeatures();
            setDevicePopupFeature(null);
            setDevicesPopupDisplay(false);
          }}
        />
      )}
    </div>
  );
};

ProductFeatures.propTypes = {
  companyID: PropTypes.number.isRequired,
  currentProduct: PropTypes.object.isRequired,
  isEnterprisePlan: PropTypes.bool.isRequired,
  features: PropTypes.array,
  license: PropTypes.object.isRequired,
  refetchLicense: PropTypes.func.isRequired,
};

ProductFeatures.defaultProps = {
  features: [],
};

export default ProductFeatures;
