import React from 'react';
import Base from './Base';

export default props => (
  <Base {...props} viewBox="0 0 100 115">
    <path
      d="M50.012,5C25.16,5,5,25.136,5,49.988C5,74.84,25.16,95,50.012,95C74.864,95,95,74.84,95,49.988C95,25.136,74.864,5,50.012,5  z M71.893,41.382L47.347,65.927c-0.094,0.094-0.189,0.189-0.307,0.283c-0.071,0.094-0.141,0.141-0.212,0.212  c-0.354,0.307-0.707,0.566-1.037,0.802c-0.023,0.024-0.023,0-0.023,0.024c-0.354,0.212-0.684,0.401-1.037,0.495  c-0.33,0.118-0.66,0.189-0.99,0.236h-0.495c-0.896-0.047-1.792-0.33-2.57-0.919c-0.071-0.024-0.141-0.071-0.212-0.141  c-0.236-0.118-0.401-0.307-0.589-0.472c-0.094-0.071-0.189-0.165-0.259-0.236L28.107,54.727c-1.438-1.462-2.146-2.829-2.098-4.103  c0.047-1.297,0.613-2.452,1.698-3.537c1.085-1.085,2.287-1.674,3.584-1.745c1.273-0.047,2.641,0.66,4.103,2.099l7.946,7.946  L64.631,34.12c1.438-1.462,2.806-2.169,4.103-2.122c1.273,0.047,2.452,0.637,3.537,1.721c1.085,1.085,1.674,2.263,1.721,3.537  C74.039,38.552,73.355,39.92,71.893,41.382z"
    />
  </Base>
);
